import React from 'react';
import SideDrawer from './SideDrawer';
import DataCourse from './DataCourse';

const CourseUpload = ({ isAuth, setIsAuth }) => {
    return (
      <>
        {true ? (
          <div>
            <SideDrawer btn="add" />
            <DataCourse />
          </div>
        ) : (
          <p>First Login</p>
        )}
      </>
    );
  };
  
export default  CourseUpload;