const { BASE_URL } = require("./client");

const UPLOAD_GROUP = BASE_URL + '/api/admin/group/post';
const GET_GROUP = BASE_URL + '/api/admin/group/get';
const DELETE_GROUP = BASE_URL + '/api/admin/group/delete/';
const DELETE_EMAIL = BASE_URL + '/api/admin/group/emaildelete/';

export {
    UPLOAD_GROUP,
    GET_GROUP,
    DELETE_GROUP,
    DELETE_EMAIL 

};
