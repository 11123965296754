import {Grid, TextField ,Button} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { validateEmail } from "./validateEmail";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import "./styles/loginform.css";
import axios from "axios";
import ReactGA from "react-ga";
import LoginwithGoogle from "./LoginwithGoogle";

const { SIGN_IN_API } = require("../../api/auth");

function Login({ isAuth, setIsAuth,setAccessUser }) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [resp, setResp] = useState("");
  const [pathn, setPathn] = useState(window.location.pathname);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  const clearset = () => {
    setEmail("");
    setPassword("");
    setEmailError("");
    setResp("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const registered = {
      email: email,
      password: password,
    };

    axios
      .post(SIGN_IN_API, registered)
      .then((response) => {
        if (response.status === 200 ) {
          if(response.data.user.isAdmin === true){
            window.localStorage.setItem("name", response.data.user.name);
            window.localStorage.setItem("email", response.data.user.email);
            window.localStorage.setItem("id", response.data.user._id);
            window.localStorage.setItem("token", response.data.token);
            window.localStorage.setItem("isAuth", true);
            setIsAuth(true);
            clearset();
            window.location.href="/"  
          }
          else{
            setResp("You are not allowed! 🚫");
            // clearset();
          }
        } else if (response.status === 203) {
          setResp(response.data.message);
        }
      })
      .catch((error) => {
        clearset();
      });
  };

  return (
    <div className="Login_Form">
      {(pathn === "/dashboard" ||
        pathn === "/bookevent" ||
        pathn === "/membership") && (
        <div style={{ width: "21rem", margin: "auto" }} className="Alert_type">
          <Alert severity="info">
            <AlertTitle>INFO</AlertTitle>
            <strong>Please First Login to visit this Page</strong>
          </Alert>
        </div>
      )}

      {resp.length > 0 && (
        <div style={{ width: "21rem", margin: "auto" }} className="Alert_type">
          <Alert severity="error">
            <AlertTitle>ERROR OCCURED</AlertTitle>
            <strong>{resp}</strong>
          </Alert>
        </div>
      )}

      <Grid container>
        <Grid item lg={6} md={12} sm={12}>
          <div className="left_box">
            <h1 className="header_big">Startup</h1>
            <h1 className="header_big">Your</h1>
            <h1 className="header_big">Journey.</h1>
            <p className="header_small">with Markoknow</p>
          </div>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          <div className="right_box">
            <form className="login_form" onSubmit={handleSubmit}>
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
                <h2> LOGIN </h2>
                <LoginwithGoogle setIsAuth={setIsAuth} setResp={setResp}/>
              </div>

              <span className="error">{emailError}</span>
              <div>
                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={email}
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  required
                  label="Name"
                />

                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={password}
                  variant="outlined"
                  onChange={(e) => setPassword(e.target.value)}
                  type="text"
                  label="Password"
                />
                
                
                
              
              </div>

              <div className="auth_button">
                <Button
                  size="large"
                  onClick={handleSubmit}
                  style={{ background: "#121212", color: "white" , alignItems: "center", justifyContent: "space-around" }}
                >
                  Login
                </Button>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
