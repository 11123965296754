import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Container, Typography } from '@mui/material';
const DOMAIN = process.env.REACT_APP_DOMAIN;

const HeadingList = () => {
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${DOMAIN}/api/getuniqueURLs`);
        const data = response.data;
        setHeadings(data.map(item => ({ id: item._id, heading: item.heading })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Typography variant="h4" style={{ margin: '20px 0' }}>
        List of Headings
      </Typography>
      <List>
        {headings.map(item => (
          <ListItem key={item.id}>
            <Link to={`/update/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemText primary={item.heading} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default HeadingList;
