import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MessagePopup from "../../MessagePopup";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {IconButton , InputAdornment} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import {VIDEO_UPLOAD , PDF_UPLOAD , FILE_UPDATE, QUESTIONS_UPLOAD} from '../../../api/courseupload';
import axios from 'axios';


export default function ModuleUploadDialog({ courseIndex, moduleIndex, moduleId }) {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState('');
  const [fileHeading, setFileHeading] = React.useState('');
  const [videoLink , setVideoLink] = React.useState('');
  const [resp, setResp] = React.useState("");
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [added , setAdded] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [posted , setPosted] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [qa , setqa] = React.useState(['']);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChang=(e,i)=>{
    const {value}=e.target
    const onchangeVal = [...qa]
    onchangeVal[i]=value
    setqa(onchangeVal)
}
const handleDelete=(i)=>{
  const deleteVal = [...qa]
  deleteVal.splice(i,1)
  setqa(deleteVal)
}

  const handleUpload = () => {
    if(!file){
      if(videoLink.length > 0){
        const video_data = {
          title: fileHeading,
          link: videoLink,
          moduleId: moduleId,
        }
      axios
      .post(VIDEO_UPLOAD , video_data)
      .then(response => {
        setResp("Added Video Successfully");
        setOpenPopUp(true);
        setUpload(false);
        setPosted(true);
      })
      .catch(err => {
        setResp("Some Error"); 
        setOpenPopUp(true);
        setUpload(false);
      })
      }
      else {
        const questionData = {
          title: fileHeading,
          questions: qa,
          moduleId: moduleId,
        }
        axios
      .post(QUESTIONS_UPLOAD, questionData)
      .then(response => {
        setResp("Added Questions Successfully");
        setOpenPopUp(true);
        setUpload(false);
        setPosted(true);
      })
      .catch(err => {
        setResp("Some Error"); 
        setOpenPopUp(true);
        setUpload(false);
      })
      }
      
  
    }
    else if(file.type.includes('application/pdf')){
      const formData = new FormData();
      formData.append('title',fileHeading);
      formData.append('file' , file);
      formData.append('moduleId' , moduleId);
      axios
      .post(PDF_UPLOAD , formData)
      .then(response => {
        setResp("Added PDF Successfully");
      setOpenPopUp(true);
      setUpload(false);
      setPosted(true);
      })
      .catch(err => {
        setResp("Some Error"); 
        setOpenPopUp(true);
        setUpload(false);

      })
    }
    
    // if(posted){
     
    // }
    handleClose();
    // onAddCourse(courseUpload);
  }
 
  return (
    <div>
      <Button variant="text" onClick={handleClickOpen} style={{ color: '#6A58C6' }}>
        Add Chapter Item
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <DialogContentText>Upload Video, Assignment Questions,PDF files</DialogContentText>

          <TextField
            id="standard-basic"
            variant="standard"
            placeholder='Name Of File'
            onChange={(e) => setFileHeading(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
        
          <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">Select A File type To Upload</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="Video" control={<Radio />} label="Video" />
            <FormControlLabel value="Pdf" control={<Radio />} label="Pdf" />
            <FormControlLabel value="Assignment" control={<Radio />} label="Assignment" />
          </RadioGroup>
        </FormControl>
   
         {value==="Video" && <TextField
            id="standard-basic"
            variant="standard"
            placeholder='Vimeo Link (Only for Video)'
            onChange={(e) => setVideoLink(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
         }
          {value==="Pdf" && <Button variant="contained" component="label" marginTop="10px">
            Upload
            <input type="file" hidden onChange={(e) => setFile(e.target.files[0])} />
          </Button>}
          {value==="Assignment" && (
            qa.map((data , i) => (
              
                <div style={{display:'flex' , flexDirection:'column'}}>
                <TextField 
                    style={{ paddingBottom: "10px", width: "100%" }}
                    variant="outlined"
                    label="Question"
                    name="q"
                    value={data}
                    onChange={(e) => {
                        handleChang(e,i);
                    }}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Delete Question">
                            <IconButton onClick={(e) => handleDelete(i)}>
                            <DeleteIcon />

                            </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                />                
                </div>
          
            ))
          )}
          {value === 'Assignment' && (
            <div style={{display:'flex' , marginBottom:'10px'}}>
          <Button 
                    variant='contained'
                    color='primary'
                    style={{
                        alignSelf:'flex-end',

                    }}
                    onClick={
                        ()=> {
                            setqa([...qa ,''])
                        }
                    }
                >Add Questions</Button>
          </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpload}>Upload</Button>
        </DialogActions>
      </Dialog>
      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => { 
          setOpenPopUp(!openPopUp)}}
      />
    </div>
  );
  }
