const { BASE_URL } = require("./client");

const CREATE_C_L_COMPONENT = BASE_URL + '/api/clcomponent/create';
const GET_C_L_COMPONENT = BASE_URL + '/api/clcomponent/get';
const EDIT_C_L_COMPONENT = BASE_URL + '/api/clcomponent/edit/';
const DELETE_C_L_COMPONENT = BASE_URL + '/api/clcomponent/delete/';




module.exports = {
    CREATE_C_L_COMPONENT,
    GET_C_L_COMPONENT,
    EDIT_C_L_COMPONENT,
    DELETE_C_L_COMPONENT
}