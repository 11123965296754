import * as React from 'react';
import {Box , Button, Chip ,IconButton , Tooltip} from '@mui/material';
import { DataGrid , GridToolbar } from '@mui/x-data-grid';
import { ACCEPT_CERTIFICATE, GET_EVENT_CERTIFICATES, REJECT_CERTIFICATE , GET_EVENT } from '../../api/event';
import axios from 'axios';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function EventCertificateGen() {
    const [rows  , setRows] = React.useState([]);
    const [changed , setChanged] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    React.useEffect(() => {
      axios
          .get(GET_EVENT_CERTIFICATES)
          .then(async res => {
              const certificates = res.data.data;
              const updatedRows = [];
  
              for (const certificate of certificates) {
                  try {
                      const eventRes = await axios.get(GET_EVENT + certificate.eventId);
                      const eventData = eventRes.data.data;
                      const dataWithIdAndEventName = { ...certificate, id: certificate._id, eventName: eventData.title };
                      updatedRows.push(dataWithIdAndEventName);
                  } catch (error) {
                      console.error("Error fetching event:", error);
                  }
              }
  
              setRows(updatedRows.filter((event) => event.status!=='rejected'));
              setChanged(false);
          })
          .catch(err => {
              console.error(err);
          });
  }, [changed]);
  


    const handleAccept = (eventCertficateId , rowData) => {
        axios
        .patch(ACCEPT_CERTIFICATE+eventCertficateId , {userId: rowData.userId})
        .then(res => {
            setChanged(true);
        })
        .catch(err => {
            console.error(err);
        })
    };
    const handleReject = (eventCertficateId) => {
        axios
        .patch(REJECT_CERTIFICATE+eventCertficateId)
        .then(res => {
            setChanged(true);
        })
        .catch(err => {
            console.error(err);
        })
    };
    const handleSelectionChange = (newSelection) => {
        setSelectedRows(newSelection.selectionModel);
    };
    const sendCertificates = () => {

    }
  
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'userId',
          headerName: 'User',
          width: 150,
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
              `${params.row.fName || ''} ${params.row.lName || ''}`,
          },
        {
          field: 'eventName',
          headerName: 'Event Name',
          width: 150,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 110,
        },
        {
            field: 'wNumber',
            headerName: 'Whatsapp Number',
            type: 'number',
            width: 110,
          },
          {
            field: 'collegeName',
            headerName: 'College Name',
            width: 150,
          },
        {
          field: 'fullName',
          headerName: 'Full name',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 160,
          valueGetter: (params) =>
            `${params.row.fName || ''} ${params.row.lName || ''}`,
        },
        {
            field: 'topics',
            headerName: 'Topics',
            sortable: false,
            width: 160,
          },
        {
          field: 'status',
          headerName: 'Status',
          width: 150,
          renderCell: (params) => {
            const { status } = params.row;
      
            if ( status.toLowerCase() === 'pending') {
              return (
                <>
                <Tooltip title="Accept">
                <IconButton onClick={() => handleAccept(params.row._id , params.row)}>
                        <DoneIcon/>
                </IconButton>
                </Tooltip>
                <Tooltip title="Reject">
                <IconButton onClick={() => handleReject(params.row._id)}>
                        <CloseIcon/>
                </IconButton>
                </Tooltip>
                </>
              );
            } else {
              return (
                <Chip
                  label={status}
                  style={{ backgroundColor:  status === 'accepted' ? 'green' : 'red' }}
                />
              );
            }
          },
        },
        
      ];
    
  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
    <div style={{
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems:'center',
    }}>
        <h1>Events Certificate Requests ({rows?rows.length:0})</h1> 
        <div style={{display:'flex' , gap:'10px'}}>
        <Button variant='contained' href='/events/certificates/sent'>Sent Certificates</Button> 
        <Button variant='contained'
            href="/events/certificates/rejected">Rejected Certificates</Button> 
        </div>
        

    </div>
    
    {rows.length > 0 ? (
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        onSelectionModelChange={handleSelectionChange}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        slots={{ toolbar: GridToolbar }}
      />
    ) : (
      <p>Loading...</p>
    )}
  </Box>
  );
}
