import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Divider from '@mui/material/Divider';
import MessagePopup from "../../MessagePopup";
import {Typography , Tooltip , IconButton} from '@mui/material';
import { makeStyles } from "@material-ui/core";
import Modal from '@mui/material/Modal'
import { Dialog, DialogTitle, DialogContent, DialogActions , Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';


import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { GET_USER_DETAILS, } from '../../../api/userdetails';
import { GET_COURSE, GET_PROJECT, GET_USER } from '../../../api/courseupload';
import { GET_WORKEX, POST_APPROVED_WORKEX, POST_REJECTED_WORKEX } from '../../../api/userdetails';


export default function WorkEx() {
  const [workexData , setWorkexData] = React.useState([]);
  const [added , setAdded] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [selectedHtml, setSelectedHtml] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState('');
  const [userDialogOpen, setUserDialogOpen] = React.useState(false);
  const [courseDetails, setCourseDetails] = React.useState('');
  const [courseDialogOpen, setCourseDialogOpen] = React.useState(false);
  const [approved , setApproved] = React.useState(false);
const handleOpenAddDialog = () => {
  setOpenAddDialog(true);
};

const handleCloseAddDialog = () => {
  setOpenAddDialog(false);
};


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `WorkEx_${Date.now()}`;


  const exportToCSV = (workexData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(workexData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  React.useEffect(() => {
    axios
    .get(GET_USER_DETAILS)
    .then(res => {
      const updatedData = res.data
        .filter((user) => user.completedWorkexs && user.completedWorkexs.length > 0)
        .flatMap((user) =>
          user.completedWorkexs.map((workex, index) => ({
            id: `${index+1}`, 
            workexId: workex.workexId,
            userId: user._id,
            heading: workex.heading,
            visitedLink: workex.visitedLink,
            isApproved: workex.isApproved,
          }))
        );
      setWorkexData(updatedData.reverse());
      setAdded(false);
    })
    .catch(err=> {
      console.log(err)
    })
    
    
  },[approved])
  
  const handleDownload = (downloadLink) => {
    try {
      const url = new URL(downloadLink);
      window.open(url.href, '_blank');
    } catch (error) {
      console.error('Invalid URL:', downloadLink);
    }
  };
  const openDialog = (htmlContent, title) => {
    setSelectedHtml(htmlContent);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const openUserDialog = (userId) => {
    axios.get(GET_USER+userId).then(res=> {
        setUserDetails(res.data.data);
        setUserDialogOpen(true);
    })
    .catch(error => {
        console.log(error);
    })
  };

  const openCourseDialog = (courseId) => {
    axios.get(GET_COURSE+courseId).then(res=> {
        setCourseDetails(res.data.data[0]);
        setCourseDialogOpen(true);
    })
    .catch(error => {
        console.log(error);
    })
    
  };

  const closeUserDialog = () => {
    setUserDialogOpen(false);
  };

  const closeCourseDialog = () => {
    setCourseDialogOpen(false);
  };

  const handleApprove = (workexId , userId) => {
      axios
        .post(POST_APPROVED_WORKEX+workexId+"/"+userId , {isApproved: "Approved"})
        .then(res => {
            setApproved(true);
        })
        .catch(err => {
          console.log(err);
        })
  }
  const handleReject = (workexId , userId) => {
    axios
    .post(POST_REJECTED_WORKEX+workexId+"/"+userId , {isApproved: "Rejected"})
    .then(res => {
        setApproved(true);
    })
    .catch(err => {
      console.log(err);
    })
  }
  const columns = [
    {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    width: 30,
    align: 'left',
    headerAlign: 'left',
    cellClassName: 'custom-cell'},
    {
      field: 'workexId',
      headerName: 'WORKEX ID',
      type: 'string',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'custom-cell'
    },
    {
        field: 'userId',
        headerName: 'USER ID',
        type: 'string',
        width: 100,
        align: 'left',
        headerAlign: 'left',
        cellClassName: 'custom-cell',
        renderCell: (params) => (
          <div>
            <Button onClick={() => openUserDialog(params.row.userId)}>View User Details</Button>
          </div>
        ),
      },
      {
        field: 'heading',
        headerName: 'HEADING',
        type: 'string',
        width: 150,
        cellClassName: 'custom-cell',
      },
      {
        field: 'visitedLink',
        headerName: 'VISITED LINK',
        type: 'string',
        width: 150,
        cellClassName: 'custom-cell',
        renderCell: (params) => (
          <div>
            <Button onClick={() => handleDownload(params.row.visitedLink)}>
              Visit Link
            </Button>
          </div>
        ),
      },
   
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      renderCell: (params) => {
        if(params.row.isApproved === 'Approval'){
          return [
            <>
            <Tooltip title="Approve">
              <IconButton onClick={(e)=> handleApprove(params.row.workexId , params.row.userId)}>
                <CheckIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject">
              <IconButton onClick={(e)=> handleReject(params.row.workexId , params.row.userId)}>
                <CloseIcon/>
              </IconButton>
            </Tooltip>
            </>
          ]
        }
         if(params.row.isApproved === "Approved"){
          return [
            <Chip label='Approved' size="small" style={{color: 'white' , background:'green'}}/>
          ]
         }
         if(params.row.isApproved === "Rejected"){
          return [
            <Chip label='Rejected' size="small" style={{color: 'white' , background:'red'}}/>
          ]
         }
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between'}}>  
      <div style={{display:'flex' , flexDirection:'column' , justifyContent:'flex-start' , marginBottom:'10px'}}>
      <div style={{display:'flex' , flexDirection:'row' }}>
      <Typography 
      variant='h6'
      style={{marginRight:'20px' , fontWeight:'bold'}}>
        Work Experiences {`(${workexData.length})`}
      </Typography>
      </div>
      </div>
      <div style= {{display:'flex' , flexDirection:'row' , alignItems:'center' }}>
      <Button 
      variant='outlined'
      style={{color: '#000833' , borderColor:'#000833' , borderRadius:0, marginRight:'10px'}}
      onClick={(e) => exportToCSV(workexData, fileName)}
      >
        Export As CSV
      </Button>
      </div>
     
    
     </div> 
     <Divider style={{marginBottom:'10px'}}/>
    
  

     <DataGrid
        rows={workexData}
        rowHeight={100}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbarQuickFilter }}
        getRowId={(row) => row.id}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
/>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        {/* <DialogTitle>{title}</DialogTitle> */}
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: selectedHtml }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={userDialogOpen} onClose={closeUserDialog}>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent>
          <div>Name: {userDetails.name}</div>
          <div>Email: {userDetails.email}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUserDialog}>Close</Button>
        </DialogActions>
      </Dialog>
     
    </Box>
  );
}
