import {
  Box,
  Button,
  InputBase,
  Modal,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { FormGroup } from "@mui/material";
import MessagePopup from "../MessagePopup";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

const {
  GET_ALL_BLOGS,
  CREATE_BLOG,
  UPDATE_BLOG,
  DELETE_BLOG,
  SHOW_VSC,
  SHOW_D_VSC
} = require("../../api/blog");

const Usestyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
  },
  inputsStyle: {
    margin: "20px 0px 0px 0px",
    backgroundColor: "whitesmoke",
    color: "black",
    padding: "10px",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "3px",
  boxShadow: 25,
  textAlign: "center",
  color: "black",
  p: 4,
  paddingLeft: "3rem",
  paddingRight: "3rem",
};
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#000833' : '#000833',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#000833',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
export default function Blog({ isAuth }) {
  const editor = useRef(null);
  const classes = Usestyles();
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const classe = u();

  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");

  const [blogs, setBlogs] = useState([]);
  const [blog, setBlog] = useState([]);
  const [count , setCount] = useState(0);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  //   const [image , setImage] = useState(blog.image);
  const [file, setFile] = useState("");
  const [checked , setChecked] = React.useState(false);

  const [editTitle, setEditTitle] = useState("");
  const [editSubTitle, setEditSubTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");

  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [leftBlog, setLeftBlog] = React.useState('');
  const [rightBlog , setRightBlog] = React.useState('');
  const [editLeftBlog , setEditLeftBlog] = React.useState('');
  const [editRightBlog , setEditRightBlog] = React.useState('');
  const [tag , setTag] = React.useState('');
  const [points , setPoints] = React.useState(0);
  const tags = [{
    value: "Startup",
    label: "Startup"
}, {
  value: "Finance",
  label: "Finance"
},{
  value: "Marketing",
  label: "Marketing"
},{
  value: "Product",
  label: "Product"
},{
  value: "AI & Trending",
  label: "AI & Trending"
},{
  value: "Jobs/Internships",
  label: "Jobs/Internships"
},{
  value: "Colleges",
  label: "Colleges"
}]
  const handleSingleChecked = (active , id) => {
    if(!active){
      axios
      .patch(SHOW_VSC+id)
      .then(res => {
       axios
      .get(GET_ALL_BLOGS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const count = res.data.reduce((acc, obj) => {
          if (obj.showVsc) {
            return acc + 1;
          }
          return acc;
        }, 0);
        setBlogs(res.data.reverse());
        setCount(count);

      })
      .catch((err) => {
        console.log(err);
      });
      })
      .catch(err => {
        console.log(err);
      })
    } 
    if(active) {
        axios
        .patch(SHOW_D_VSC+id)
        .then(res => {
          axios
          .get(GET_ALL_BLOGS, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            const count = res.data.reduce((acc, obj) => {
              if (obj.showVsc) {
                return acc + 1;
              }
              return acc;
            }, 0);
            setBlogs(res.data);
            setCount(count);
          })
          .catch((err) => {
            console.log(err);
          });
        })
        .catch(err => {
          console.log(err);
        })
      
    }     
   
}
  const handleLeftChange = (event) => {
    setLeftBlog(event.target.value);
  };
  const handleRightChange = (event) => {
    setRightBlog(event.target.value);
  };

  // ALl Data request
  useEffect(() => {
    axios
      .get(GET_ALL_BLOGS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const count = res.data.reduce((acc, obj) => {
          if (obj.showVsc) {
            return acc + 1;
          }
          return acc;
        }, 0);
        setBlogs(res.data.reverse());
        setCount(count);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Edit box submit
  const handleEditSubmit = () => {
    const data = new FormData();
    data.append("title", editTitle);
    data.append("subTitle", editSubTitle);
    data.append("description", editDescription);
    data.append("lBlogId",leftBlog);
    data.append("rBlogId",rightBlog);
    data.append("file", file);
    data.append('tag' , tag);
    data.append('points' , points);
    const ID = blog._id;

    axios
      .put(UPDATE_BLOG + ID, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Edited Successfully");
        setOpenPopUp(true);
        window.location.href = "/blog";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenEdit(false);
  };

  const validationForm = () => {
    if (title.length < 1) return false;
    else if (subTitle.length < 1) return false;
    else if (description.length < 1) return false;
    else return true;
  };
  const handleTagChange = (e) => {
    setTag(e.target.value);
  }

  // Create submit
  const handleCreateSubmit = (e) => {
    if (validationForm()) {
      const data = new FormData();
      data.append("title", title);
      data.append("subTitle", subTitle);
      data.append("description", description);
      data.append("lBlogId",leftBlog);
      data.append("rBlogId",rightBlog);
      data.append("file", file);
      data.append('tag' , tag);
      data.append('points' , points);



      axios
        .post(CREATE_BLOG, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setResp("Created Successfully");
          setOpenPopUp(true);
          window.location.href = "/blog";
        })
        .catch((err) => {
          setResp("Something error occured");
          setOpenPopUp(true);
        });
      setOpenCreate(false);
    } else {
      setResp("All input fields are required");
      setOpenPopUp(true);
    }
  };

  // Delete submit function
  const deleteSubmit = () => {
    const ID = blog._id;

    axios
      .delete(DELETE_BLOG + ID, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/blog";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };

  // Edit Pop Up
  const editbox = (
    <Box className={classe.style}>
      <Typography
        variant="h4"
        style={{ textAlign: "center", textTransform: "uppercase" }}
      >
        Update This Blog
      </Typography>
      <FormGroup method="POST">
        <InputBase
          className={classes.inputsStyle}
          defaultValue={blog.title}
          label="Title"
          onChange={(e) => setEditTitle(e.target.value)}
        />
        <InputBase
          className={classes.inputsStyle}
          height="auto"
          defaultValue={blog.subTitle}
          label="SubTitle"
          onChange={(e) => setEditSubTitle(e.target.value)}
        />
        <InputBase
          placeholder="Points"
          defaultValue={blog.points}
          className={classes.inputsStyle}
          onChange={(e) => setPoints(e.target.value)}
        />
        <div
          style={{
            border: "none",
            width: "100%",
            margin: "30px -7px 10px -7px",
            padding: "",
          }}
        >
          <JoditEditor
            ref={editor}
            value={editDescription}
            defaultValue={blog.description}
            tabIndex={1}
            onChange={(newContent) => setEditDescription(newContent)}
          />
        </div>
        <FormControl fullWidth style={{marginBottom:'10px'}}>
        <InputLabel id="demo-simple-select-label">Left Blog Recomendation</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={leftBlog}
          label="Left Blog Recomendation"
          onChange={handleLeftChange}
        >
        {blogs.map((blog,index) => (
          <MenuItem value={blog._id}>{blog.title}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Right Blog Recomendation</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={rightBlog}
          label="Right Blog Recomendation"
          onChange={handleRightChange}
        >
        {blogs.map((blog,index) => (
          <MenuItem value={blog._id}>{blog.title}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
      <FormControl fullWidth style={{marginTop:'10px'}}>
        <InputLabel id="demo-simple-select-label">Add Tag</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tag}
          label="Add Tag"
          onChange={handleTagChange}
        >
        {tags.map((blog,index) => (
          <MenuItem value={blog.value}>{blog.label}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
        <Button variant="contained" component="label" style={{ marginTop: 10 }}>
          Upload Poster
          <input
            type="file"
            hidden
            onChange={(e) => setFile(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleEditSubmit}
        style={{
          background: "#81fccf",
          fontSize: "18px",
          marginTop: "25px",
          padding: "10px",
        }}
      >
        Update
      </Button>
    </Box>
  );

  // Create PopUp
  const createBox = (
    <Box className={classe.style}>
      <Typography variant="h4">Create A Blog</Typography>
      <FormGroup method="POST">
        <InputBase
          placeholder="Title"
          value={title}
          className={classes.inputsStyle}
          onChange={(e) => setTitle(e.target.value)}
        />
        <InputBase
          placeholder="SubTitle"
          value={subTitle}
          className={classes.inputsStyle}
          onChange={(e) => setSubTitle(e.target.value)}
        />
        <InputBase
          placeholder="Points"
          value={points}
          className={classes.inputsStyle}
          onChange={(e) => setPoints(e.target.value)}
        />
        <div
          style={{
            border: "none",
            width: "100%",
            margin: "30px -7px 10px -7px",
            padding: "",
          }}
        >
          <JoditEditor
            ref={editor}
            value={description}
            defaultValue={blog.description}
            tabIndex={1}
            onChange={(newContent) => setDescription(newContent)}
          />
        </div>
        <FormControl fullWidth style={{marginBottom:'10px'}}>
        <InputLabel id="demo-simple-select-label">Left Blog Recomendation</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={leftBlog}
          label="Left Blog Recomendation"
          onChange={handleLeftChange}
        >
        {blogs.map((blog,index) => (
          <MenuItem value={blog._id}>{blog.title}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Right Blog Recomendation</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={rightBlog}
          label="Right Blog Recomendation"
          onChange={handleRightChange}
        >
        {blogs.map((blog,index) => (
          <MenuItem value={blog._id}>{blog.title}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
      <FormControl fullWidth style={{marginTop:'10px'}}>
        <InputLabel id="demo-simple-select-label">Add Tag</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tag}
          label="Add tag"
          onChange={handleTagChange}
        >
        {tags.map((blog,index) => (
          <MenuItem value={blog.value}>{blog.label}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
        <Button
          variant="contained"
          component="label"
          style={{ marginTop: "10px" }}
        >
          Upload Poster
          <input
            type="file"
            hidden
            onChange={(e) => setFile(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleCreateSubmit}
        style={{
          background: "#81fccf",
          marginTop: "25px",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Create
      </Button>
    </Box>
  );

  // Delete PopUp
  const deleteBox = (
    <Box sx={style}>
      <Typography variant="h5" style={{ marginBottom: "5px" }}>
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={deleteSubmit}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 90, cellClassName: "Cell-Col" },
    { field: "points", headerName: "Points", width: 100, cellClassName: "Cell-Col" },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "subTitle",
      headerName: "SubTitle",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "lBlogId",
      headerName: "Left Blog",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "rBlogId",
      headerName: "Right Blog",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setBlog(params.row);
          setOpenEdit(true);

          setEditTitle(params.row.title);
          setEditSubTitle(params.row.subTitle);
          setEditDescription(params.row.description);
          setFile(params.row.imagePath);
          setLeftBlog(params.row.lBlogId);
          setRightBlog(params.row.rBlogId);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setBlog(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
    {
      field: 'Show Vsc',
      type: 'actions',
      headerName: 'Show Vsc',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          (
            // {count<3 && (
          <FormControlLabel
            control={<IOSSwitch {...label} 
            checked={params.row.showVsc}
            onChange={(e) => {
              // setChecked((prevState) => !prevState)
              handleSingleChecked(params.row.showVsc , params.row._id)
              }} /> } />      
                // )}
          
          )
        ];
      },
    },
  ];

  // UI
  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" padding="10px">
        <Typography variant="h5">Blogs Page</Typography>
        <Button
          onClick={() => setOpenCreate(true)}
          size="medium"
          variant="contained"
          color="primary"
        >
          create
        </Button>
      </Box>
      <Box
        sx={{
          height: 800,
          width: "100%",
          "& .column": {
            fontSize: "16px",
            justifyCOntent: "center",
            textAlign: "center",
          },
          "& .column-header": {
            color: "purple",
            fontSize: "20px",
          },
        }}
      >
        <DataGrid
          rows={blogs}
          className="table-box"
          getRowId={(row) => row._id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => setOpenPopUp(!openPopUp)}
      />

      <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
        {createBox}
      </Modal>

      <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
        {editbox}
      </Modal>
      <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
        {deleteBox}
      </Modal>
    </div>
  );
}

const u = makeStyles({
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 600,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    height: 600,
    overflowY: "scroll",
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      p: 3,
    },
  },
});
