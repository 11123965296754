import React, { useState,useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { DELETE_ALL, MOBILE, UPLOAD } from '../../api/mobilenumbercleanup';
import {Button} from '@material-ui/core';

const MobileNumberCleanup = ({isAuth , setIsAuth}) => {
    const [rowData, setRowData] = useState([]);
    const [file, setFile] = useState(null);
    const [fetched, setFetched] = useState(false);
    const columns = [
      { field: 'id', headerName: 'ID', width: 100 , cellClassName: 'Cell-col' },
      { field: 'Name', headerName: 'Email', width: 200 },
      { field: 'Email', headerName: 'Name', width: 200 },
      { field: 'MobileNumber', headerName: 'Mobile No.', width: 200 },
    ];
    useEffect(()=> {
      axios
      .get(MOBILE)
      .then(response=> {
        console.log(response.data.data);
        const  filteredRows = response.data.data.filter(row =>
          row.MobileNumber.match(/^(\+91|0|91)?[6789]\d{9}$/)
        );
        const dataWithIds = filteredRows.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
  
        setRowData(dataWithIds);
        setFetched(false);
      })
      .catch(err => {
        console.log(err);
      })
    },[fetched]);
    
    const handleDownload = () => {
      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = `Mobile Cleaning_${Date.now()}`;
      const ws = XLSX.utils.json_to_sheet(rowData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
    };
  
    const handleFileChange = event => {
      setFile(event.target.files[0]);
    };
  
    const handleFileUpload = () => {
      const formData = new FormData();
      formData.append('file', file);
      
      axios.post(UPLOAD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log(response.data);
        setFetched(true);
      })
      .catch(error => {
        console.error(error);
      });
    };
    const handleDelete = () => {
        axios
        .delete(DELETE_ALL)
        .then(response => {
            console.log(response);
            setFetched(true);
        })
        .catch(err => {
            console.log(err);
        })
    }
    const handleXLSXUpload = () => {
        if (!file) {
          console.error("No file selected.");
          return;
        }
      
        const reader = new FileReader();
      
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
          const csvBlob = new Blob([csvData], { type: 'text/csv' });
          const formData = new FormData();
          formData.append('file', csvBlob, 'converted.csv');
      
          axios
            .post(UPLOAD, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              console.log(response.data);
              setFetched(true);
            })
            .catch((error) => {
              console.error(error);
            });
        };
        reader.readAsArrayBuffer(file)
    }
    return (
     <div>
     {isAuth?
        (<div>
        <h1>Excel Numbers Cleaning</h1>
        {rowData.length===0&&(<><input type="file" onChange={handleFileChange} />
        <Button variant='contained' style={{background:'#000', color:'#fff'}} onClick={handleFileUpload}>Upload CSV</Button>
        <Button variant='contained' style={{background:'#000', color:'#fff'}} onClick={handleXLSXUpload}>Upload XLSX</Button>
        </>)}
        {rowData.length>0&&(<>
        <Button variant='contained' style={{background:'#000', color:'#fff'}} onClick={handleDownload}>Download Filtered Data</Button>
        <Button variant='contained' style={{background:'#000', color:'#fff'}} onClick={handleDelete}>Delete All</Button>
        <DataGrid 
        rows={rowData} 
        columns={columns}  
        style={{height:'100vh'}}
        /></>)}</div>): <p>Login First</p>}
      </div>
    );
}

export default MobileNumberCleanup