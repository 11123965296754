import * as React from 'react';
import * as XLSX from "xlsx/xlsx";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {Box , IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Addtemplate from './addtemplate';
import Audience from './Audience';
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import "./admin_emails-Style.css";
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import MessagePopup from "../../MessagePopup";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { DELETE_TEMPLATE, GET_TEMPLATES } from '../../../api/template';
const { SEND_PROMOTIONALEMAILS_EVENT } = require("../../../api/emailAdmin");


const Admin_emails_template_index = ({isAuth , a_email }) => {
    const [templates, setTemplates] = React.useState([]);
    const [emailList, setEmailList] = React.useState([]);
    const [selected , setSelected] = React.useState(null);
    const [subject , setSubject] = React.useState("");
    const [message , setMessage] = React.useState("");
    const [file, setFile] = React.useState(null);
    const [openPopUp, setOpenPopUp] = React.useState(false);
    const [resp, setResp] = React.useState("");
    React.useEffect(() => {
        axios
        .get(GET_TEMPLATES)
        .then(res => {
            setTemplates(res.data.data);
            // console.log(res);
        })
        .catch(err => {
            console.log(err);
        })
    },[openPopUp]);
    
    
    const addTemplate = (msg) => {
       setResp(msg);
       setOpenPopUp(true);
    } 
    const handleDelete = (id) => {
        axios
        .delete(DELETE_TEMPLATE+id)
        .then(res => {
            setResp("Delete Successfully");
            setOpenPopUp(true);
        })
        .catch(err => {
            setResp("Not Deleted");
            setOpenPopUp(true);
        })
    }
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const emailList1 = [];
    
          for (let cell in sheet) {
            // Iterate over all non-empty cells in the sheet
            if (cell[0] === "A" && sheet[cell].v !== undefined) {
              const email = sheet[cell].v.trim();
              // Add email to emailList if it is a valid email address
              if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                emailList1.push(email);
              }
            }
          }
    
          setEmailList([...emailList, ...emailList1]);
        };
    
        reader.readAsArrayBuffer(selectedFile);
      };
      const sendEmails = async () => {
        const sendingRate = 1000; 
        const estimatedTime = emailList.length * sendingRate;

        // Format estimated time for display
        const formatTime = (time) => {
          const hours = Math.floor(time / (1000 * 60 * 60));
          const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((time % (1000 * 60)) / 1000);
          return `${hours}h ${minutes}m ${seconds}s`;
        };

        // Initialize remaining time
        let remainingTime = estimatedTime;

        // Set a countdown timer
        const timerInterval = setInterval(() => {
          remainingTime -= 500; // Decrease by 0.5 seconds (500 milliseconds)

          // Update popup message with remaining time
          setResp(`Sending... Estimated time remaining: ${formatTime(remainingTime)}`);
          setOpenPopUp(true);
          // Check if time has elapsed
          if (remainingTime <= 0) {
            clearInterval(timerInterval); // Stop the timer
            setOpenPopUp(false); // Close the popup
          }
        }, 500);

        if (subject !== "" && message !== "" && emailList.length > 0) {
          const data = {
            emails: emailList,
            subject: subject,
            html: message,
          };
          axios
            .post(SEND_PROMOTIONALEMAILS_EVENT, data, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              setSubject("");
              setMessage("");
              setEmailList([]);
              setFile(null);
              setResp("Sended Successfully");
              setOpenPopUp(true);
              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
              setResp("Some error occured");
              setOpenPopUp(true);
            });
        } else {
          if(message === "" || subject === ""){
            setResp("Please Select A Template");
          }
          else if (emailList.length === 0){
            setResp("Please Select Emails Or Upload Emails");
          }
          else {
            setResp("All Details Are Unfilled");

          }
          setOpenPopUp(true);
        }
      };
    
    return (
      <div style={{
        marginTop:'50px',
        display:'flex',
        flexDirection:'row'
      }}>
      {a_email ? (
        <>
         <Grid sx={{ flexGrow: 1 }} container justifyContent="center" spacing={2}>
            {templates.map((item,index) => (

                <Grid item  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Card sx={{ width: 400, height:400 , background:selected===index&&'#F0F0F0' }}>
                    <CardActionArea
                    onClick={
                        ()=> {
                            setSelected(index);
                            setMessage(item.html);
                            setSubject(item.subject);
                        }
                    }
                    >
                        {/* <CardMedia
                        component="img"
                        // width="100%"
                        height="330"
                        image={item.imgUrl}
                        alt="add"
                        /> */}
                        <div style={{height: 330  }} dangerouslySetInnerHTML={{__html: item.html}} />
                        <CardContent style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between'}}>
                        <Typography gutterBottom variant="h5" component="div">
                            {item.subject}
                        </Typography>
                        <div style={{display:'flex' , alignItems:'center' , flexDirection:'row' , justifyContent:'space-between'}}>
                        {selected===index && 
                        <Tooltip title="Selected">
                            <CheckCircleOutlineRoundedIcon style={{color:'#008000'}}/>
                        </Tooltip> }
                        <Addtemplate addTemplate={addTemplate} btn="edit" sub={item.subject} html={item.html} id={item._id}/>
                        
                        <Tooltip title="Delete">
                            <IconButton onClick={()=>handleDelete(item._id)}>
                            <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                        </div>
                        </CardContent>
                        
                    </CardActionArea>
                    </Card>   
                </Grid>

            ))}
                    <Addtemplate addTemplate={addTemplate}/>
                  
                    
        </Grid>
        <div >
        <Card  style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    margin:'20px',
                    padding:'20px'
                    }}>
                <div>
                  <label htmlFor="fileInput">
                    <div className="circle">
                      <UploadRoundedIcon className="upload-icon" />
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="fileInput">
                    <div className="upload-button">
                      <Button
                        variant="contained"
                        component="span"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        Upload
                      </Button>
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                      // hidden
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
                <div
                    className="upload-button"
                  >
                    <Button
                       variant="contained"
                        component="span"
                        style={{ width: "100%", borderRadius: "10px" }}
                      onClick={sendEmails}
                    >
                      Send
                    </Button>
                  </div>
                  <div
                    className="upload-button"
                  >
                  <Audience emailList={emailList} setEmailList={setEmailList} setOpenPopUp={setOpenPopUp} setResp={setResp}/>
                  </div>

              </Card>
        </div>
        </>
      ):(
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
      <MessagePopup
              open={openPopUp}
              message={resp}
              handleAlertClose={() => setOpenPopUp(!openPopUp)}
            />
      </div>
    );
}

export default Admin_emails_template_index;