import { Box, Button, InputBase, Modal, TextareaAutosize, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { FormGroup } from "@mui/material";
import MessagePopup from "../MessagePopup";

const {
  UPDATE_B4_MAINPAGE,
  GET_B4_MAINPAGE,
  DELETE_B4_MAINPAGE,
  CREATE_B4_MAINPAGE,
  headingId
} = require("../../api/MainPage/b4");


export default function B4Main() {
  const classes = Usestyles();

  const [openPopUp,setOpenPopUp] = useState(false);
  const [resp,setResp] = useState("");

  const [heading,setHeading] = useState("");

  const [testimonials, setTestimonials] = useState([]);

  const [inputTestimonial, setInputTestimonial] = useState("");
  const [editTestimonial, setEditTestimonial] = useState("");

  const [inputName, setInputName] = useState("");
  const [editedName, setEditedName] = useState("");

  const [testimonial, setTestimonial] = useState([]);
  const [avtar, setAvtar] = useState(testimonial.avtar);

  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // ALl Data request
  useEffect(() => {
    axios
      .get(GET_B4_MAINPAGE, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTestimonials(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Edit box submit
  const handleEditSubmit = (e) => {
    const data = new FormData();

    data.append("testimonial", editTestimonial);
    data.append("name", editedName);
    if(heading)data.append("heading",heading);
    if (avtar) data.append("avtar", avtar);

    const ID = testimonial._id;

    axios
      .put(UPDATE_B4_MAINPAGE + ID, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Edited Successfully");
        setOpenPopUp(true);
        window.location.href = "/B4_Main";
      })
      .catch(err => {
        setResp("Some error occured");
        setOpenPopUp(true);
      });
    setOpenEdit(false);
  };

  const validationForm = () => {
    if (inputTestimonial.length < 1) return false;
    else if (inputName.length < 1) return false;
    else return true;
  };

  // Create submit
  const handleCreateSubmit = (e) => {
    if (validationForm()) {
      const data = new FormData();

      data.append("testimonial", inputTestimonial);
      if (avtar) data.append("avtar", avtar);
      data.append("name", inputName);

      axios
        .post(CREATE_B4_MAINPAGE, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setResp("Created Successfully");
          setOpenPopUp(true);
          window.location.href = "/B4_Main";
        })
        .catch((err) => {
          setResp("Some error occured");
          setOpenPopUp(true);
        });
      setOpenCreate(false);
    } else{
      setResp("Kindly add all the details");
      setOpenPopUp(true);
    }
  };

  // Delete submit function
  const deleteSubmit = () => {
    const ID = testimonial._id;
    axios
      .delete(DELETE_B4_MAINPAGE + ID, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/B4_Main";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };

  // Edit Pop Up
  const editbox = (
    <Box className={classes.style}>
      <Typography
        variant="h4"
        fontWeight="6400"
        style={{textAlign:"center",textTransform:"uppercase"}}
      >
        Update This Testimonial
      </Typography>
      <FormGroup method="POST">
        {
          headingId === testimonial._id && 
            <InputBase
              className={classes.inputsStyle}
              defaultValue={testimonial.heading}
              placeholder="Heading"
              onChange={(e) => setHeading(e.target.value)}
            />
        }
        <InputBase
          className={classes.inputsStyle}
          defaultValue={testimonial.name}
          label="Name"
          onChange={(e) => setEditedName(e.target.value)}
        />
        <TextareaAutosize
          className={classes.inputsStyle}
          defaultValue={testimonial.testimonial}
          label="Testimonial"
          minRows={2}
          onChange={(e) => setEditTestimonial(e.target.value)}
        />
        <Button variant="contained" component="label" style={{marginTop: '10px'}}>
          Upload avtar
          <input
            type="file"
            hidden
            onChange={(e) => setAvtar(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleEditSubmit}
        style={{
          background: "#81fccf",
          fontSize: "18px",
          marginTop: "25px",
          padding: "10px",
        }}
      >
        Update
      </Button>
    </Box>
  );

  // Create PopUp
  const createBox = (
    <Box className={classes.style}>
      <Typography variant="h4">Create a testimonial</Typography>
      <FormGroup method="POST">
        <InputBase
          placeholder="Name"
          value={inputName}
          className={classes.inputsStyle}
          onChange={(e) => setInputName(e.target.value)}
        />
        <TextareaAutosize
          placeholder="Testimonial"
          minRows={2}
          value={inputTestimonial}
          className={classes.inputsStyle}
          onChange={(e) => setInputTestimonial(e.target.value)}
        />
        <Button variant="contained" component="label">
          Upload Poster
          <input
            type="file"
            hidden
            onChange={(e) => setAvtar(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleCreateSubmit}
        style={{
          background: "#81fccf",
          marginTop: "15px",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Create
      </Button>
    </Box>
  );

  // Delete PopUp
  const deleteBox = (
    <Box className={classes.style}>
      {
        headingId === testimonial._id ? 
        <Typography variant="h5" style={{marginBottom:"5px"}}>
          This Testimonial linked to heading so You can't delete it!
        </Typography>
        :
        <Box>
          <Typography variant="h5" style={{marginBottom:"5px"}}>
            Are you sure want to delete ?
          </Typography>
          <Box>
            <Button
              onClick={deleteSubmit}
              style={{ background: "black", color: "white", margin: "3px" }}
            >
              Yes
            </Button>
            <Button
              onClick={() => setOpenDelete(false)}
              style={{ background: "black", color: "white", margin: "3px" }}
            >
              No
            </Button>
          </Box>
        </Box>
      }
      
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 150, cellClassName: "Cell-Col" },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "testimonial",
      headerName: "Testimonial",
      width: 350,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setTestimonial(params.row);
          setOpenEdit(true);
          setEditTestimonial(params.row.testimonial);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      // headerAlign: 'center',
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setTestimonial(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  // UI
  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" padding="10px">
        <Typography variant="h5">B4 Page Testimonials</Typography>
        <Button
          onClick={() => setOpenCreate(true)}
          size="medium"
          variant="contained"
          color="primary"
        >
          create
        </Button>
      </Box>
      <Box
        sx={{
          height: 800,
          width: "100%",
          "& .column": {
            fontSize: "16px",
            justifyCOntent: "center",
            textAlign: "center",
          },
          "& .column-header": {
            color: "purple",
            fontSize: "20px",
          },
        }}
      >
        <DataGrid
          rows={testimonials}
          className="table-box"
          getRowId={(row) => row._id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
      <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
      <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
        {createBox}
      </Modal>

      <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
        {editbox}
      </Modal>
      <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
        {deleteBox}
      </Modal>
    </div>
  );
}

const Usestyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
  },
  inputsStyle: {
    margin: "10px 0px 10px 0px",
    backgroundColor: "whitesmoke",
    color: "black",
    padding: "10px",
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: '#fff',
    border: "none",
    borderRadius: "3px",
    textAlign: 'center',
    color: "black",
    width: 400,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: '1rem',
    paddingBottom: '1rem',
    // height: 400,
    // overflowY: 'scroll',
    "@media only screen and (max-width: 726px)":{
        width: '90%',
        p: 3,
    }
}
});
