const { BASE_URL } = require("../client");

const b8LinkId = "63407175a0790657943d68fa";

const UPDATE_B8_MAINPAGE = BASE_URL + "/main/b8/"+b8LinkId;
const GET_B8_MAINPAGE = BASE_URL + "/main/b8/"+b8LinkId;

export {
    UPDATE_B8_MAINPAGE,
    GET_B8_MAINPAGE,
}