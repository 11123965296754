import * as React from 'react';
import * as XLSX from "xlsx/xlsx";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {Box , IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddBadge from './AddBadge';
// import Audience from './Audience';
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
// import "./admin_emails-Style.css";
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import MessagePopup from "../MessagePopup";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { DELETE_BADGE, GET_BADGES } from '../../api/badge';
import ViewBadge from './ViewBadge';
import Modal from '@mui/material/Modal';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const Badge = ({isAuth , a_badge }) => {
    const [templates, setTemplates] = React.useState([]);
    const [emailList, setEmailList] = React.useState([]);
    const [selected , setSelected] = React.useState(null);
    const [subject , setSubject] = React.useState("");
    const [message , setMessage] = React.useState("");
    const [file, setFile] = React.useState(null);
    const [openPopUp, setOpenPopUp] = React.useState(false);
    const [resp, setResp] = React.useState("");
    const [op, setOp] = React.useState(false);
    const [sBadge , setSBadge] = React.useState({});

    const handleClose = () => setOp(false);
    React.useEffect(() => {
        axios
        .get(GET_BADGES)
        .then(res => {
            setTemplates(res.data.data);
        })
        .catch(err => {
        })
    },[openPopUp]);
    const addTemplate = (msg) => {
        setResp(msg);
        setOpenPopUp(true);
     } 
    const handleDelete = (id) => {
        axios
        .delete(DELETE_BADGE+id)
        .then(res => {
            setResp("Delete Successfully");
            setOpenPopUp(true);
        })
        .catch(err => {
            setResp("Not Deleted");
            setOpenPopUp(true);
        })
    }
    //   const handleViewBadge = (item) => {
    //     setOp(true);
    //     return (
            
    //     )
    //   }
    
    return (
      <div style={{
        marginTop:'50px',
        display:'flex',
        flexDirection:'row'
      }}>
      {a_badge ? (
        <div style={{display:'flex' , flexDirection: 'column' , justifyContent:'center' , alignItems:'center' , width:'100%'}}>
        <Typography variant="h4" textAlign="center">Badges</Typography>
         <Grid sx={{ flexGrow: 1 }} container justifyContent="center" spacing={2}>
            {templates.map((item,index) => (

                <Grid item  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Card sx={{ width: 400, height:400  }}>
                    <CardActionArea
                    onClick={
                        ()=> {
                            setSelected(index);
                            setMessage(item.html);
                            setSubject(item.subject);
                        }
                    }
                    >
                        <CardMedia
                        component="img"
                        // width="100%"
                        height="330"
                        image={item.badgeUrl}
                        alt="add"
                        />
                        <CardContent style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between'}}>
                        <Typography gutterBottom variant="h5" component="div">
                            {item.title}{" "}{item.points}
                        </Typography>
                        <div style={{display:'flex' , alignItems:'center' , flexDirection:'row' , justifyContent:'space-between'}}>
                        {/* {selected===index && 

                        <Tooltip title="Selected">
                            <CheckCircleOutlineRoundedIcon style={{color:'#008000'}}/>
                        </Tooltip> } */}
                        <Tooltip title="View ">
                            <IconButton onClick={()=>{setOp(true); setSBadge(item);}}>
                            <RemoveRedEyeIcon/> 
                            </IconButton>
                        </Tooltip>
                        <AddBadge addTemplate={addTemplate} btn="edit" sub={item.message} titl={item.title} id={item._id} t={item.tag} p={item.points}/>
                        
                        <Tooltip title="Delete">
                            <IconButton onClick={()=>handleDelete(item._id)}>
                            <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                        </div>
                        </CardContent>
                        
                    </CardActionArea>
                    </Card> 
                    <Modal
                open={op}
                onClose={handleClose}
                >
                    <Box style={{display:'flex', alignItems:'center' , justifyContent:'center', position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', border:'none'}}>
                    <ViewBadge sBadge={sBadge}/>
                </Box>
                </Modal>  
                </Grid>
               

            ))}
                    <AddBadge addTemplate={addTemplate} />
                  
                    
        </Grid>
        
        </div>
      ):(
        <Box display={'flex'} justifyContent={'center'} width="100%" flexDirection={'column'}>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
      <MessagePopup
              open={openPopUp}
              message={resp}
              handleAlertClose={() => setOpenPopUp(!openPopUp)}
            />
      </div>
    );
}

export default Badge;