import { AppBar, Toolbar, Typography, IconButton, InputBase, Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Package2Icon from '@mui/icons-material/LocalShipping';
import DollarSignIcon from '@mui/icons-material/AttachMoney';
import UsersIcon from '@mui/icons-material/Group';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CircularWithValueLabel from '../Courses/CircularProgressWithLabel';
import CircularProgress from '@mui/material/CircularProgress';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import * as React from 'react';
import { GET_COURSES } from '../../../api/courseupload';
import { GET_USER_DETAILS } from '../../../api/userdetails';
import {useNavigate} from 'react-router-dom';
export default function Dashboard() {
    const navigate = useNavigate();
    const [courses , setCourses] = React.useState([]);
    const [users , setUsers] = React.useState([]);
    const [overallCompletionRate, setOverallCompletionRate] = React.useState(0);
    const [loading, setLoading] = React.useState(true); 
    const [popular , setPopular] = React.useState({});
    const [courseContribution, setCourseContribution] = React.useState([]);

    React.useEffect(() => {
        axios
        .get(GET_COURSES)
        .then(resp => {
            const coursesData = resp.data.data.map(course => ({
                ...course,
                students: 0, 
                completed: 0, 
                completionRate: 0, 
            }));

            axios
                .get(GET_USER_DETAILS)
                .then(res => {
                    const usersWithCourses = res.data.filter(user => user.courses.length > 0);
                    setUsers(usersWithCourses);
                    coursesData.forEach(course => {
                        const courseId = course._id; 
                        const students = usersWithCourses.filter(user => user.courses.includes(courseId)).length;
                        const completedUsers = usersWithCourses.filter(user => 
                            user.courses.includes(courseId) &&
                            user.courseDetails.some(cd => String(cd.courseId) === String(courseId) && cd.total === (cd.videosCompleted.length + cd.pdfsCompleted.length))
                        );
                        const completed = completedUsers.length;

                        const completionRate = students > 0 ? (completed / students) * 100 : 0;

                        course.students = students;
                        course.completed = completed;
                        course.completionRate = completionRate;
                    });
                    const totalCompletedUsers = usersWithCourses.filter(user =>
                        user.courseDetails.some(cd =>
                            cd.total === (cd.videosCompleted.length + cd.pdfsCompleted.length)
                        )
                    ).length;

                    const overallCompletionRate = (totalCompletedUsers / usersWithCourses.length) * 100;
                    setOverallCompletionRate(overallCompletionRate);
                    setCourses(coursesData);
                    const popularCourse = coursesData.reduce((prev, current) => {
                        const currentRatio = current.students > 0 ? (current.completed / current.students) : 0;
                        const prevRatio = prev.students > 0 ? (prev.completed / prev.students) : 0;
                        return currentRatio > prevRatio ? current : prev;
                    }, {});
                    setPopular(popularCourse);
                    const contributionData = coursesData.map(course => ({
                        x: course.courseName,
                        y: course.completionRate,
                    }));
                    setCourseContribution(contributionData);
                    setLoading(false);

                })
                .catch(err => {
                    console.error(err);
                });
        })
        .catch(err => {
            console.error(err);
        });
    }, []);
    const completionRateChartOptions = {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          },
          fontFamily: 'inherit'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: courses.map(course => course.courseName),
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 500,
              colors: '#333'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Completion Rate (%)',
            style: {
              fontSize: '14px',
              fontWeight: 600,
              color: '#333'
            }
          },
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 500,
              colors: '#333'
            }
          }
        },
        colors: ['#3f51b5']
      };
      
      const studentsBoughtChartOptions = {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          },
          fontFamily: 'inherit'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: courses.map(course => course.courseName),
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 500,
              colors: '#333'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Number of Students Bought',
            style: {
              fontSize: '14px',
              fontWeight: 600,
              color: '#333'
            }
          },
          labels: {
            style: {
              fontSize: '12px',
              fontWeight: 500,
              colors: '#333'
            }
          }
        },
        colors: ['#f44336']
      };
      
      const pieChartOptions = {
        chart: {
            type: 'pie',
            height: 350,
            fontFamily: 'inherit'
        },
        labels: courseContribution.map(course => course.x),
        legend: {
            fontSize: '12px',
            fontFamily: 'inherit',
        },
        colors: ['#3f51b5', '#f44336', '#4caf50', '#ff9800', '#9c27b0'],
    };
    
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <main style={{ flexGrow: 1, padding: '1rem' }}>
      {loading ? ( // Render loader if loading is true
                    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={4} >
                <Card sx={{height: 150}}>
                <CardContent>
                    <Typography variant="subtitle1" gutterBottom>Overall Course Completion Rate</Typography>
                    <CircularWithValueLabel value={overallCompletionRate}/>
                </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                <CardContent>
                    <Typography variant="subtitle1" gutterBottom>Popular Course</Typography>
                    <Typography variant="h6">{popular.courseName}</Typography>
                    <Typography variant="subtitle2">Bought: {popular.students}</Typography>
                    <Typography variant="subtitle2">Completed: {popular.completed}</Typography>

                </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card sx={{height: 150}}>
                <CardContent>
                    <Typography variant="subtitle1" gutterBottom>No . Of Users Who Have Bought Any Course </Typography>
                    <Typography variant="h4" component="div">{users.length}</Typography>
                </CardContent>
                </Card>
            </Grid>
            </Grid>
            <Grid container spacing={3} sx={{marginTop: '1rem'}}>
            <Grid item xs={12} sm={6}>
            <Card>

                <ReactApexChart
                options={completionRateChartOptions}
                series={[{ data: courses.map(course => course.completionRate) }]}
                type="bar"
                height={350}
                />
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Card>

                <ReactApexChart
                options={studentsBoughtChartOptions}
                series={[{ data: courses.map(course => course.students) }]}
                type="bar"
                height={350}
                />
                </Card>
            </Grid>
            </Grid>
            <Grid item xs={12}>
    <Card>
        <CardContent>
            <Typography variant="subtitle1" gutterBottom>Course Contribution to Overall Completion Rate</Typography>
            <ReactApexChart
                options={pieChartOptions}
                series={courseContribution.map(course => course.y)}
                type="pie"
                height={350}
            />
        </CardContent>
    </Card>
</Grid>

            <Card style={{ marginTop: '1rem' }}>
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Course</TableCell>
                    <TableCell>Users</TableCell>
                    <TableCell>Completed</TableCell>
                    <TableCell>Completion Rate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {courses.map(course => (
                        <TableRow
                        sx={{
                            cursor: 'pointer'
                        }}
                         onClick={(e) => { navigate("/analytics/courses", {state: {course: course , users: users.filter(user => user.courses.includes(course._id))}}) }}>
                            <TableCell>{course.courseName}</TableCell>
                            <TableCell>{course.students}</TableCell>
                            <TableCell>{course.completed}</TableCell>
                            <TableCell><CircularWithValueLabel value={course.completionRate}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            </Card>
        </>

        )}
      </main>
    </div>
  );
}
