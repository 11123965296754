import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import MessagePopup from "../../MessagePopup";
import JoditEditor from 'jodit-react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Tooltip } from '@mui/material';
import * as XLSX from 'xlsx';

import { UPLOAD_GROUP } from '../../../api/group';


export default function GroupView({setAdded}) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [file, setFile] = React.useState('');
  const [resp, setResp] = React.useState("");
  const [openPopUp, setOpenPopUp] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleFileChange = (e) => {
    // console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  }
  const setAllEmpty = () => {
    setName("");
  }
  const handleUpload = () =>{

    if(file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
        const reader = new FileReader();
      
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
          const csvBlob = new Blob([csvData], { type: 'text/csv' });
          const formData = new FormData();
          formData.append('name' , name);
          formData.append('file', csvBlob, 'converted.csv');
          axios
          .post(UPLOAD_GROUP , formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
            }
            })
          .then(res => {
            // console.log(res);
            setAdded(true)
            
        })
          .catch(err => console.log(err));
        }
        reader.readAsArrayBuffer(file)
    }
    else if(file.type==="text/csv"){
        const formData = new FormData();
        formData.append('name' , name);
        formData.append('file' , file);
        axios
        .post(UPLOAD_GROUP , formData)
        .then(res => {})
        .catch(err => console.log(err));
    }
    handleClose();
  }


  return (
    <div style = {{margin: '10px'}} >
      <Button variant="outlined" onClick={handleClickOpen} style={{color: '#fff'}}>
        Add Group
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle fontFamily="fantasy">Add Group </DialogTitle>
        <DialogContent>
          <TextField
            style={{ paddingBottom: "10px", width: "100%" , fontWeight: "900" }}
            variant="outlined"
            type="text"
            label="Name"
            value= {name}
            onChange={(e)=> setName(e.target.value)}
          />
           <Tooltip title="Upload Emails">
            <IconButton 
            autoFocus
            component="label"
            color="inherit" >
              <UploadFileIcon/> 
              <input
                      type="file"
                      hidden
                      id="fileInput"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                  
                    />
            </IconButton>
            </Tooltip>
     
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
         <Button onClick={handleUpload}>Add</Button>
        
        </DialogActions>
      </Dialog>
      <MessagePopup 
      open={openPopUp}
      message={resp}
      handleAlertClose={() => setOpenPopUp(!openPopUp)}
     />
    </div>
    
  );
}


