import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { UPDATE_MODULE } from '../../../api/courseupload';
import ReactQuill from 'react-quill';
export default function UpdateModuleDialog({titl , desc , id}) {
  const [open, setOpen] = React.useState(false);
  const [title , setTitle] = React.useState(titl||'');
  const [description , setDescription] = React.useState(desc||'');
  const editor = React.useRef(null);
  const handleEditorChange = (content, delta, source, editor) => {
    setDescription(content);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    const data = {
        title: title,
        description: description
    }
    axios
    .patch(UPDATE_MODULE+id , data)
    .then(res => {
        handleClose();
    })
    .catch(err => {
        console.log(err);
    })
  }
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
            <EditIcon/>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle>Update Module</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You Can Change The Module Name And Description From Here
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
           <div  style={{ paddingBottom: "10px", width: "100%" }}>
           <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={handleEditorChange}
                  placeholder="Write Description..."
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'],        
                      ['blockquote', 'code-block'],
                      [{ 'header': 1 }, { 'header': 2 }],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      [{ 'script': 'sub'}, { 'script': 'super' }],      
                      [{ 'indent': '-1'}, { 'indent': '+1' }],
                      [{ 'direction': 'rtl' }],
                      [{ 'size': ['small', false, 'large', 'huge'] }],  
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      [{ 'color': [] }, { 'background': [] }],          
                      [{ 'font': [] }],
                      [{ 'align': [] }],
                      ['clean']                                         
                    ],
                  }}
                />

  
                </div> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}