import React, { useState } from 'react';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { EDIT_LAB, POST_LAB } from '../../api/lab';
import { GET_COURSES } from '../../api/promocode';
import MenuItem from '@mui/material/MenuItem';

const LabAdminUpload = () => {
  const location = useLocation();
  const { l } = location?.state || {};

  const [labHeadings, setLabHeadings] = useState(l ? l.labHeadings : ['']);
  const [steps, setSteps] = useState(l ? l.steps : ['']);
  const [descriptions, setDescriptions] = useState(l ? l.descriptions : ['']);
  const [link, setLink] = useState(l ? l.link : '');
  const [course, setCourse] = useState(l ? l.courseId : null);

  const navigate = useNavigate();
  const [resources, setResources] = useState([
    { name: '', file: null },
    { name: '', file: null },
    { name: '', file: null },
    { name: '', file: null },
    { name: '', file: null },
    { name: '', file: null },
    { name: '', file: null },
    { name: '', file: null },
    { name: '', file: null },
  ]);
  const [courses, setCourses] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(GET_COURSES)
      .then((res) => {
        const currenciesData = res.data.data.map((course) => ({
          value: course._id,
          label: course.courseName,
        }));
        setCourses(currenciesData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleAddResource = () => {
    setResources([...resources, { name: '', file: null }]);
  };

  const handleFile = (event, index) => {
    const updatedResources = [...resources];
    updatedResources[index].file = event.target.files[0];
    setResources(updatedResources);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    labHeadings.forEach((heading) => data.append('labHeadings', heading));
    steps.forEach((step) => data.append('steps', step));
    descriptions.forEach((description) => data.append('descriptions', description));
    data.append('link', link);
    data.append('courseId', course);
    
    resources.forEach((resource, index) => {
      if (resource.file) {
        data.append(`file${index + 1}`, resource.file);
      }
    });

    const requestURL = l ? EDIT_LAB + l._id : POST_LAB;
    if(l){
      axios
      .patch(requestURL , data)
      .then(res => {
        navigate('/lab');

      })
      .catch(err => {
        console.log(err);
      })
    }
    else{
      axios
      .post(requestURL, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: window.localStorage.getItem('token'),
        },
      })
      .then((res) => {
        navigate('/lab');
      })
      .catch((err) => {
        console.error('Error:', err);
        alert(err.data);
      });
    }
    
  };

  const handleInputChange = (index, type, value) => {
    switch (type) {
      case 'labHeading':
        const updatedLabHeadings = [...labHeadings];
        updatedLabHeadings[index] = value;
        setLabHeadings(updatedLabHeadings);
        break;
      case 'step':
        const updatedSteps = [...steps];
        updatedSteps[index] = value;
        setSteps(updatedSteps);
        break;
      case 'description':
        const updatedDescriptions = [...descriptions];
        updatedDescriptions[index] = value;
        setDescriptions(updatedDescriptions);
        break;
      default:
        break;
    }
  };

  const handleAddGroup = () => {
    setLabHeadings([...labHeadings, '']);
    setSteps([...steps, '']);
    setDescriptions([...descriptions, '']);
  };

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Lab Admin Upload
      </Typography>
      <TextField
        label="Link"
        fullWidth
        margin="normal"
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <TextField
        id="outlined-select-currency"
        select
        label="Course"
        defaultValue={course}
        style={{
          width: '100%',
          marginBottom: '10px',
        }}
        onChange={(e) => {
          setCourse(e.target.value);
        }}
      >
        {courses.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {labHeadings.map((heading, index) => (
        <div key={index}>
          <TextField
            label={`Lab Heading ${index + 1}`}
            fullWidth
            margin="normal"
            value={heading}
            onChange={(e) => handleInputChange(index, 'labHeading', e.target.value)}
          />
          <TextField
            label={`Step ${index + 1}`}
            fullWidth
            margin="normal"
            value={steps[index]}
            onChange={(e) => handleInputChange(index, 'step', e.target.value)}
          />
          {/* Include ReactQuill component for description */}
          <ReactQuill
            theme="snow"
            value={descriptions[index]}
            onChange={(value) => handleInputChange(index, 'description', value)}
            placeholder={`Write something amazing for Lab Heading ${index + 1}...`}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],  
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],          
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['clean']                                         
              ],
            }}
          />
        </div>
      ))}
      <IconButton onClick={handleAddGroup}>
        <AddIcon />
      </IconButton>

      <Box mb={2}>
        <Typography variant="h6" gutterBottom>
          Resources
        </Typography>
        {resources.map((resource, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={4}>
              <div>
                <input
                  type="file"
                  required
                  placeholder={`Upload File ${index + 1}`}
                  size="large"
                  name={`file${index + 1}`}
                  onChange={(e) => handleFile(e, index)}
                />
              </div>
            </Grid>
          </Grid>
        ))}

        <IconButton onClick={handleAddResource}>
          <AddIcon />
        </IconButton>
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{ width: '100%', marginTop: '20px' }}
        onClick={handleSubmit}
      >
        {!l ? 'Submit' : 'Update'}
      </Button>
    </Box>
  );
};

export default LabAdminUpload;
