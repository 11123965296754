import { Box, Button, InputBase, Modal, TextareaAutosize, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { FormGroup } from "@mui/material";
import MessagePopup from "../MessagePopup";

const {GET_ALL_LEARN_EVENTS,SAVE_NEW_LEARN_EVENT ,
  UPDATE_LEARN_EVENT , DELETE_LEARN_EVENT } = require("../../api/learn");


export default function Learn({ isAuth ,a_education}) {
  const classes = Usestyles();
  const [openPopUp,setOpenPopUp] = useState(false);
  const [resp,setResp] = useState("");

  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState([]);

  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");

  const [editName, setEditName] = useState("");
  const [editContent, setEditContent] = useState("");
  const [editLink, setEditLink] = useState("");

  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // ALl Data request
  useEffect(() => {
    axios
      .get(GET_ALL_LEARN_EVENTS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Edit box submit
  const handleEditSubmit = (e) => {
    const data = {
      name: editName,
      content: editContent,
      link: editLink,
    };
    const ID = event._id;
    axios
      .put(UPDATE_LEARN_EVENT+ID , data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Edited Successfully");
        setOpenPopUp(true);
        window.location.href = "/learn";
      })
      .catch((err) => {
        setResp("Some error occured");
        setOpenPopUp(true);
      });
  };

  const validationForm = () => {
    if (name.length < 1) return false;
    else if (content.length < 1) return false;
    else if (link.length < 1) return false;
    else return true;
  };

  // Create submit
  const handleCreateSubmit = (e) => {
    if (validationForm()) {
      const data = {
        name: name,
        content: content,
        link: link,
      };

      axios
        .post(SAVE_NEW_LEARN_EVENT , data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setName("");
          setContent("");
          setLink("");
          setResp("Created Successfully");
          setOpenPopUp(true);
          window.location.href = "/learn";
        })
        .catch((err) => {
          setResp("Some error occured");
          setOpenPopUp(true);
        });
      setOpenCreate(false);
    } else {
        setResp("Kindly add all the details");
        setOpenPopUp(true);
    }
  };

  // Delete submit function
  const deleteSubmit = () => {
    const ID = event._id;

    axios
      .delete(DELETE_LEARN_EVENT+ID, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/learn";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };

  // Edit Pop Up
  const editbox = (
    <Box className={classes.style}>
      <Typography
        variant="h4"
      >
        Update This Instance
      </Typography>
      <FormGroup method="POST">
        <InputBase
          className={classes.inputsStyle}
          defaultValue={event.name}
          label="Name"
          onChange={(e) => setEditName(e.target.value)}
        />
        <TextareaAutosize
          minRows={2}
          className={classes.inputsStyle}
          height="auto"
          defaultValue={event.content}
          label="Content"
          onChange={(e) => setEditContent(e.target.value)}
        />
        <InputBase
          className={classes.inputsStyle}
          defaultValue={event.link}
          label="Link"
          onChange={(e) => setEditLink(e.target.value)}
        />
      </FormGroup>
      <Button
        onClick={handleEditSubmit}
        style={{
          background: "#81fccf",
          fontSize: "18px",
          marginTop: "25px",
          padding: "10px",
        }}
      >
        Update this Instance
      </Button>
    </Box>
  );

  // Create PopUp
  const createBox = (
    <Box  className={classes.style}>
      <Typography variant="h4">Create Another Instance</Typography>
      <FormGroup method="POST">
        <InputBase
          placeholder="Name"
          value={name}
          className={classes.inputsStyle}
          onChange={(e) => setName(e.target.value)}
        />
        <TextareaAutosize
          minRows={2}
          placeholder="Content"
          value={content}
          className={classes.inputsStyle}
          onChange={(e) => setContent(e.target.value)}
        />
        <InputBase
          placeholder="Link"
          value={link}
          className={classes.inputsStyle}
          onChange={(e) => setLink(e.target.value)}
        />
      </FormGroup>
      <Button
        onClick={handleCreateSubmit}
        style={{
          background: "#81fccf",
          marginTop: "25px",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Create this Instance
      </Button>
    </Box>
  );

  const deleteBox=(
    <Box className={classes.deletecontainer}>
      <Box className={classes.deletebox}>
        <Typography>Are you sure, you want to delete?</Typography>
        <Box>
          <Button onClick={deleteSubmit} className={classes.btn} style={{"marginRight":'10px'}}>Yes</Button>
          <Button onClick={()=> setOpenDelete(false) } className={classes.btn}>No</Button> 
        </Box>
      </Box>
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 150, cellClassName: "Cell-Col" },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "content",
      headerName: "Content",
      width: 250,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "link",
      headerName: "Link",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setEvent(params.row);
          setOpenEdit(true);
          setEditName(params.row.name);
          setEditContent(params.row.content);
          setEditLink(params.row.link);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setEvent(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  // UI
  return (
    <div>
      {a_education ? (
        <div className={classes.container}>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">Learn Page</Typography>
            <Button
              onClick={() => setOpenCreate(true)}
              size="medium"
              variant="contained"
              color="primary"
            >
              create
            </Button>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyCOntent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "purple",
                fontSize: "20px",
              },
            }}
          >
            <DataGrid
              rows={events}
              className="table-box"
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
          <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
            {createBox}
          </Modal>

          <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
            {editbox}
          </Modal>
          <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
            {deleteBox}
          </Modal>
        </div>
      ) : (
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
    </div>
  );
}


const Usestyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
  },
  inputsStyle: {
    margin: "20px 0px 0px 0px",
    backgroundColor: "whitesmoke",
    color: "black",
    padding: "10px",
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: '#fff',
    border: "none",
    borderRadius: "3px",
    textAlign: 'center',
    color: "black",
    width: 400,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: '1rem',
    paddingBottom: '1rem',
    "@media only screen and (max-width: 726px)":{
        width: '90%',
        p: 3,
    }
  },
  deletecontainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    //backgroundColor:'yellow'
  },
  deletebox:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    backgroundColor:'white',
    position: "absolute",
    top: '30%',
    borderRadius:'10px',
    padding:'30px'
  },
  btn:{
    backgroundColor:'#8dffcc',
    marginTop:'10px'
  },
});