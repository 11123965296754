const { BASE_URL } = require("../client");

const headingId =  "6337c62cfb92f78698db8175";

const UPDATE_B4_MAINPAGE = BASE_URL + "/main/b4/";
const GET_B4_MAINPAGE = BASE_URL + "/main/b4/";
const DELETE_B4_MAINPAGE = BASE_URL + "/main/b4/";
const CREATE_B4_MAINPAGE = BASE_URL + "/main/b4/";

export {
    UPDATE_B4_MAINPAGE,
    GET_B4_MAINPAGE,
    DELETE_B4_MAINPAGE,
    CREATE_B4_MAINPAGE,
    headingId
}