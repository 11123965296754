import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@material-ui/core";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
const DOMAIN = process.env.REACT_APP_DOMAIN;

const Contact = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${DOMAIN}/api/getContact`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  
  const handleDownload = (filePath) => {
    // Trigger download from the backend
    window.open(`http://localhost:3002/files/${filePath}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>email</TableCell>
            <TableCell>Whatsapp No.</TableCell>
            <TableCell>Issue Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Download document</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row._id}>
              <TableCell>{row.firstname}</TableCell>
              <TableCell>{row.lastname}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.whatsappno}</TableCell>
              <TableCell>{row.issuetype}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
              <TableCell>
              <Button onClick={() => handleDownload(row.filePath)}>
                  <DownloadIcon/>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Contact;
