import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import { Box, FormControlLabel , FormGroup, TextField} from '@mui/material';
import DatePickerValue from './DatePicker';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import {GridActionsCellItem} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';

import { GET_COURSES, GET_PROMOCODES, POST_PROMOCODES, UPDATE_PROMOCODE } from '../../../api/promocode';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDialog({handleAdded , btn , pickedPromoData}) {
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState(btn==='add'?'':pickedPromoData.code);
  const [numberCodes , setNumberCodes] = React.useState(btn==='add'?'':pickedPromoData.usedCount);
  const [description , setDescription] = React.useState(btn==='add'?'':pickedPromoData.description);
  const [course , setCourse] = React.useState(btn==='add'?'':pickedPromoData.course);
  const [mdp , setMdp] = React.useState(btn==='add'?'':pickedPromoData.details.discountpercentage)
  const [mdv, setMdv] = React.useState(btn==='add'?'':pickedPromoData.details.maximumdiscountvalue);
  const [mcv , setMcv] = React.useState(btn==='add'?'':pickedPromoData.details.minimumcartvalue);
  const [muc  , setMuc] = React.useState(btn==='add'?'':pickedPromoData.maxusecount);
  const [mucs , setMucs] = React.useState(btn==='add'?'':pickedPromoData.maxusecountsingleuser);
  const [validTill, setValidTill] = React.useState(btn==='add'?'':pickedPromoData.validTill);
  const [firstpurchase , setFirstPurchase] = React.useState(btn==='add'?'NO':pickedPromoData.firstpurchase);
  const [suggest , setSuggest] = React.useState(btn==='add'?'NO':pickedPromoData.suggest);
  const [firstpurchaseC , setFirstPurchaseC] = React.useState(firstpurchase==='NO'?false: true);
  const [suggestC , setSuggestC] = React.useState(suggest==='NO'?false: true);
  const [limit , setLimit] = React.useState(false);
  const [message , setMessage] = React.useState('');
  const [currencies , setCurrencies] = React.useState([]);
  const [tag , setTag] = React.useState('');
  const [validationError, setValidationError] = React.useState('');
  const [promocodesData, setPromocodesData] = React.useState([]);
  const isTagValid = () => {
    // Fetch data from the backend
    axios
      .get(GET_PROMOCODES)
      .then(res => {
        const updatedData = res.data.data.map((promo) => ({
          ...promo,
          id: promo._id,
        }));
        setPromocodesData(updatedData);
  
        const isUnique = !updatedData.some(promo => promo.tag === tag);
        const isFirstLetterCapitalized = tag.charAt(0) === tag.charAt(0).toUpperCase();
  
        if (!isUnique) {
          setValidationError('Tag must be unique.');
        } else if (!isFirstLetterCapitalized) {
          setValidationError('First letter should be capitalized.');
        } else {
          setValidationError('');
        }
  
        return isUnique && isFirstLetterCapitalized;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const handleTagChange = (e) => {
    isTagValid();
    setTag(e.target.value);
    // Clear validation error when the tag is being edited
    // setValidationError('');
  };

  const handleTagBlur = () => {
    // Validate the tag when it loses focus
    
  };
  React.useEffect(()=> {
      axios
      .get(GET_COURSES)
      .then(res => {
        const currenciesData = res.data.data.map((course) => ({
          value: course.courseName,
          label: course.courseName
        }));
        setCurrencies(currenciesData);
      })
      .catch(err => {
        console.log(err);
      })
  },[])
  const handleClickOpen = () => {
    console.log(pickedPromoData)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveDate = (value) => {
    setValidTill(value);
  }
  const resetAll = () => {
    setCode('');
    setValidTill('');
    setNumberCodes('');
    setDescription('');
    setSuggest('NO');
    setCourse('');
    setMdp('');
    setMdv('');
    setMcv('');
    setMuc('');
    setMucs('');
    setFirstPurchase('NO');
    setFirstPurchaseC(false);
    setSuggestC(false);
  }
  const handleSave = () => {
    const adjustedMdp = btn === 'add' ? mdp : (mdp <= 1 ? mdp * 100 : mdp);

    const new_data = {
        firstpurchase: firstpurchase,
        suggest: suggest,
        code: code,
        validTill: validTill,
        numberCodes: numberCodes,
        description: description,
        course: course,
        mdp: adjustedMdp,
        mdv: mdv,
        mc: mcv,
        muc: muc,
        mucs: mucs,
        tag: tag
    };
    if(btn === 'add'){
    axios
    .post(POST_PROMOCODES,new_data)
    .then(response => {
        
        resetAll();
        handleAdded(true , 'Added Successfully' , true);
    })
    .catch(err => {
        handleAdded(false , err.message , true);
    })
    }
    else {
        axios
        .patch(UPDATE_PROMOCODE+pickedPromoData._id , new_data)
        .then(res=> {
           handleAdded(true , 'Updated Successfully' , true);
        })
        .catch(err => {
            handleAdded(false , 'Some Error Occured' , true);
        })
    }
    handleClose();
  }
//   const handleLimit = () => {
//     if(mdp>100){
//         setLimit(true);
//     }
//     else{
//         setLimit(false);
//     }
          
//   }

  return (
    <div>
      {btn==='add'&&<Button variant="outlined" 
       onClick={handleClickOpen} 
       startIcon={<AddIcon style={{color:'#fff'}}/>}
       style={{backgroundColor:'#000833' , color:'#fff' , borderRadius:0}}
       >
         New 
      </Button>}
      {btn==='edit'&& 
      <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleClickOpen}
            color="inherit"
        />}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' , backgroundColor:'#000833'}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add New Promo Code
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Box
        sx={{
            padding:'16px',
            display:'flex',
            flexDirection:'row'
        }}
        >
        <Box 
        sx={{
            width:'50%'
        }}>

        <Typography>New Promo Code</Typography>
        <FormGroup style={{margin:'10px'}}>
        <FormControlLabel control={<Checkbox checked={firstpurchaseC} onChange={(e)=> {
            setFirstPurchaseC(e.target.checked);
            setFirstPurchase(firstpurchaseC?'NO':'YES');
            
        }} />} 
        label="First Purchase Only" 
        labelPlacement="end"
        />
        <FormControlLabel control={<Checkbox 
        checked={suggestC}
        onChange={(e) => {
            setSuggestC(e.target.checked);
            setSuggest(suggestC?'NO':'YES');
        }} />} label="Suggest During Checkout" />
        </FormGroup>
        <Typography style={{marginBottom:'10px'}}>
            Code: If Not Given Random Code Will Generated
        </Typography>
        <TextField 
        id="outlined-basic" 
        label="Code" 
        variant="outlined"
        size="small"
        value={code}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange={(e)=> setCode(e.target.value)}

         />
        <Typography style={{marginBottom:'10px'}}>
            No. of Codes: *
        </Typography>
        <TextField 
        id="outlined-basic" 
        label="No. of Codes" 
        variant="outlined"
        size='small'
        value={numberCodes}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setNumberCodes(e.target.value);
        }}

         />
         <Typography style={{marginBottom:'10px'}}>
            Description
        </Typography>
        <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          variant="outlined"
          value={description}
          style={{
            width:'100%',
            marginBottom:'10px'
         }}
          rows={4}
          onChange = {(e) => {
            setDescription(e.target.value);
        }}
        />
        <Typography style={{marginBottom:'10px'}}>
           Course / Package
        </Typography>
        <TextField
          id="outlined-select-currency"
          select
          label="Course"
          defaultValue={course}
          style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setCourse(e.target.value);
        }}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {/* <TextField 
        id="outlined-basic" 
        label="Course/Package" 
        variant="outlined"
        size="small"
        value={course}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setCourse(e.target.value);
        }}

         /> */}
         
         <Typography style={{marginBottom:'10px'}}>
           Discount Percentage [*]
        </Typography>
        {limit && <Alert severity="warning" style={{marginBottom:'10px'}}>{message}</Alert>}
        <TextField 
        id="outlined-basic" 
        label="Discount Percentage " 
        variant="outlined"
        size="small"
        value={mdp}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setMdp(e.target.value);
            if(e.target.value > 100){
                setLimit(true);
                setMessage('The Discount Percentage Should Be Less Than 100');
            }
            else if(e.target.value.includes('%')){
                setLimit(true);
                setMessage('Only Value To Be Written No Percentage Sign Required')
            }
            else {
                setLimit(false);
            }
        }}

         />
          
        

        </Box>
        <Divider orientation='vertical' flexItem style={{margin:'10px' , color:'#000' , fontSize:'10px'}} />
        <Box 
        sx={{width:'50%'}}>
        <Typography style={{marginBottom:'10px'}}>
           Maximum Discount Value [*]
        </Typography>
        <TextField 
        id="outlined-basic" 
        label="Maximum Discount Value" 
        variant="outlined"
        size="small"
        value={mdv}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setMdv(e.target.value);
        }}

         />
         <Typography style={{marginBottom:'10px'}}>
           Minimum Cart Value [*]
        </Typography>
        <TextField 
        id="outlined-basic" 
        label="Maximum Cart Value" 
        variant="outlined"
        size="small"
        value={mcv}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setMcv(e.target.value);
        }}
         />
        <Typography style={{marginBottom:'10px'}}>
           Valid Till [*]
        </Typography>
        <DatePickerValue style={{marginBottom:'10px'}} handleValidTill = {handleSaveDate}/>
         <Typography style={{marginBottom:'10px'}}>
           Max Use Count
        </Typography>
        <TextField 
        id="outlined-basic" 
        label="Max Use Count" 
        variant="outlined"
        size="small"
        value={muc}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setMuc(e.target.value);
        }}

         />
         <Typography style={{marginBottom:'10px'}}>
           Max Use Count By Single Learner [*]
        </Typography>
        <TextField 
        id="outlined-basic" 
        label="Max Use Count By Single Learner " 
        variant="outlined"
        size="small"
        value={mucs}
        style={{
            width:'100%',
            marginBottom:'10px'
        }}
        onChange = {(e) => {
            setMucs(e.target.value);
        }}

         />
         <Typography style={{ marginBottom: '10px' }}>
        Tag [*]
      </Typography>
      <TextField
        id="outlined-basic"
        label="Tag"
        variant="outlined"
        size="small"
        value={tag}
        error={validationError !== ''}
        helperText={validationError || "Tag Should Be Unique and First Letter Should Be Capital"}
        style={{
          width: '100%',
          marginBottom: '10px',
        }}
        onChange={handleTagChange}
        onBlur={handleTagBlur}
      />
        <div style={{display:'flex' , flexDirection:'column'}}>
            <a link="#" style={{color:'#000833' , textDecoration:'underline'}}>Link to Affliate</a>
            <a link="#" style={{color:'#000833', textDecoration:'underline'}}>Link to Learner</a>
        </div>
         


           
        </Box>
       
        </Box>
      </Dialog>
    </div>
  );
}