import { Box, Button, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MessagePopup from "../MessagePopup";

const {
  GET_ALL_DETAILS,
  DELETE_VIRTUALCAMPUS,
} = require("../../api/virtualCampus");
const VirtualCampus = () => {
  const classes = u();

  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");

  const [openDelete, setOpenDelete] = useState(false);

  const [VirtualCampusData, setVirtualCampusData] = useState([]);
  const [pickedVirtualCampus, setPickedVirtualCampus] = useState([]);

  const [openDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    axios
      .get(GET_ALL_DETAILS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setVirtualCampusData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `VirtualCampus_${Date.now()}`;

  const exportToCSV = (VirtualCampusData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(VirtualCampusData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const deleteSubmit = () => {
    const id = pickedVirtualCampus._id;

    axios
      .delete(DELETE_VIRTUALCAMPUS + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/virtualCampus";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };
  const deleteBox = (
    <Box className={classes.style}>
      <Typography variant="h5" style={{ marginBottom: "5px" }}>
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={deleteSubmit}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );
  const DetailsBox = (
    <Box>
      <Box className={classes.style}>
        <Typography
          variant="h4"
          style={{
            fontStyle: "bold",
            textTransform: "uppercase",
            marginBottom: "10px",
          }}
        >
          {pickedVirtualCampus.fName}
        </Typography>
        <Box display="flex">
          <Box
            marginBottom="auto"
            marginTop="auto"
            textAlign="left"
            marginRight="10px"
          >
            <Typography fontSize="20px" style={{ textDecoration: "underline" }}>
              First Name
            </Typography>
          </Box>
          <Typography style={{ fontSize: "17px" }}>
            {pickedVirtualCampus.fName}
          </Typography>
        </Box>
        <Box display="flex">
          <Box
            marginBottom="auto"
            marginTop="auto"
            textAlign="left"
            marginRight="10px"
          >
            <Typography fontSize="20px" style={{ textDecoration: "underline" }}>
              Last Name
            </Typography>
          </Box>
          <Typography style={{ fontSize: "17px" }}>
            {pickedVirtualCampus.lName}
          </Typography>
        </Box>
        <Box display="flex">
          <Box
            marginBottom="auto"
            marginTop="auto"
            textAlign="left"
            marginRight="10px"
          >
            <Typography fontSize="20px" style={{ textDecoration: "underline" }}>
              ContactNo
            </Typography>
          </Box>
          <Typography style={{ fontSize: "17px" }}>
            {pickedVirtualCampus.contactNo}
          </Typography>
        </Box>
        <Box display="flex">
          <Box
            marginBottom="auto"
            marginTop="auto"
            textAlign="left"
            marginRight="10px"
          >
            <Typography fontSize="20px" style={{ textDecoration: "underline" }}>
              Email
            </Typography>
          </Box>
          <Typography style={{ fontSize: "17px" }}>
            {pickedVirtualCampus.email}
          </Typography>
          {/* <a href={pickedVirtualCampus.email} target='_blank' ><Typography>click me</Typography></a> */}
        </Box>
        <Box display="flex">
          <Box
            marginBottom="auto"
            marginTop="auto"
            textAlign="left"
            marginRight="10px"
          >
            <Typography fontSize="20px" style={{ textDecoration: "underline" }}>
              College Name
            </Typography>
          </Box>
          <Typography style={{ fontSize: "17px" }}>
            {pickedVirtualCampus.collegeName}
          </Typography>
        </Box>
        <Box display="flex">
          <Box
            marginBottom="auto"
            marginTop="auto"
            textAlign="left"
            marginRight="10px"
          >
            <Typography fontSize="20px" style={{ textDecoration: "underline" }}>
              Startup Idea?
            </Typography>
          </Box>
          <Typography style={{ fontSize: "17px" }}>
            {pickedVirtualCampus.startupIdea}
          </Typography>
        </Box>
        <Box display="flex">
          <Box
            marginBottom="auto"
            marginTop="auto"
            textAlign="left"
            marginRight="10px"
          >
            <Typography fontSize="20px" style={{ textDecoration: "underline" }}>
              CoFounder?
            </Typography>
          </Box>
          <Typography style={{ fontSize: "17px" }}>
            {pickedVirtualCampus.coFounder}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 80, cellClassName: "Cell-Col" },
    {
      field: "fName",
      headerName: "First Name",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedVirtualCampus(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
    {
      field: "Details",
      headrName: "Details",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedVirtualCampus(params.row);
          setOpenDetails(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Box display="flex" justifyContent="space-between" padding="10px">
        <Typography variant="h5">Virtual Campus</Typography>
        <Button
          onClick={(e) => exportToCSV(VirtualCampusData, fileName)}
          size="medium"
          variant="contained"
          color="primary"
        >
          Download Data
        </Button>
      </Box>
      <Box
        sx={{
          height: 800,
          width: "100%",
          "& .column": {
            fontSize: "16px",
            justifyCOntent: "center",
            textAlign: "center",
          },
          "& .column-header": {
            color: "purple",
            fontSize: "20px",
          },
        }}
      >
        <DataGrid
          rows={VirtualCampusData}
          className="table-box"
          getRowId={(row) => row._id} // Use the "id" property as the unique identifier
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => setOpenPopUp(!openPopUp)}
      />
      <Box width={400}>
        <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
          {deleteBox}
        </Modal>
      </Box>
      <Box display="flex" justifyContent="center">
        <Modal open={openDetails} onClose={() => setOpenDetails(false)}>
          {DetailsBox}
        </Modal>
      </Box>
    </div>
  );
};
const u = makeStyles({
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 600,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      p: 3,
    },
  },
});

export default VirtualCampus;
