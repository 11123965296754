import React,{useState,useEffect} from "react";
import { Box,Typography,FormGroup,InputBase,Button, TextareaAutosize } from "@material-ui/core";
import axios from "axios";
import MessagePopup from "../MessagePopup";

const {
    UPDATE_FEATURES_KITPAGE
  } = require("../../api/Kitpage/Features");

const DOMAIN = process.env.REACT_APP_DOMAIN;

const Features = () => {
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [title,setTitle] = useState("");
    const [pointer1, setPointer1] = useState("");
    const [pointer2,setPointer2] = useState("");
    const [items, setItems] = useState([]);
    const [numrows,setNumrows]=useState(0);
    const [file, setFile] = useState(null);
    function uploadimage(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
    }
  const addpointer=()=>{
    setNumrows(numrows+1);
   let newArr=items;
   newArr[numrows]=numrows;
   setItems(newArr);
   
  }

    const handleUpdate = () => {
        if(title.length > 0 && pointer1.length > 0 && pointer2.length>0&&
          file){
            const new_Data = {
                title:title,
                pointer1:pointer1,
                pointer2:pointer2,
                file:file,
                items:items
              }
              axios.put( UPDATE_FEATURES_KITPAGE,new_Data,{
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
              })
              .then(response => {
                setResp("Uploaded Successfully");
                setOpenPopUp(true);
                setTitle(""); setPointer1(""); setPointer2(""); setItems([]); setNumrows(0); setFile(0);
              })
              .catch((err) => {
                setResp("Something error occured");
                setOpenPopUp(true);
              });
        } else {
            setResp("Kindly add all the details");
            setOpenPopUp(true);
        }
    } 

    return ( 
        <Box style={fullPage}>
            <Box  width={450}>
                    <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
                        Features
                    </Typography>
                <Box sx={style}>
                    <FormGroup>
                        <TextareaAutosize
                            placeholder='Heading'
                            minRows={2}
                            style={inputs}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <InputBase
                            placeholder='Pointer1'
                            minRows={2}
                            style={inputs}
                            value={pointer1}
                            onChange={(e) => setPointer1(e.target.value)}
                        />
                        <InputBase 
                            placeholder='Pointer 2' 
                            minRows={2}
                            style={inputs}
                            value={pointer2}
                            onChange={(e) => setPointer2(e.target.value)}
                        /> 
                          {items.map((name,i) => (
                            <InputBase 
                            placeholder={`Pointer ${i+3}`}
                            minRows={2}
                            style={inputs}
                            key={i}
                         
                            onChange={(e)=>{let newArr=items ;newArr[i]=e.target.value; setItems(newArr);}}
                        /> 
                          ))}
                         <Box display='flex' justifyContent='center' >
                        <Button style={btn2} size='large' onClick={addpointer}>+</Button>
                       
                    </Box>
                        <Button
                            variant="contained"
                            component="label"
                            style={{ marginTop: "10px" }}
                            
                            >
                            upload image
                            </Button>
                            <input type="file" onChange={uploadimage} style={{ marginTop: "10px" }} />
                            <img src={file} style={image}/>
                    </FormGroup>
                    
                    <Box display='flex' justifyContent='center' >
                        <Button style={btn} size='large' onClick={handleUpdate}>Update</Button>
                    </Box>
                </Box>
            </Box>
            <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
    </Box>
);
}
const image={
    width: 200
}
const style = {
border: 'none',
boxShadow: 25,
textAlign: 'center',
color:'black',
boxSizing: 'content-box',
background: '#000',
p: 2,
borderRadius: 7,
};

const inputs ={
    fontSize: 18,
padding: 8,
marginTop: 7,
marginBottom: 7,
borderRadius: 5,
background: '#eee',
border: '1px solid #aaa',
}

const fullPage ={
width:'100%',
height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
}
 const btn2={
    color: '#000' ,
background: '#68F5D4' , 
margin: '10px',
marginTop: '10px'  , 
fontWeight: '650',
padding: 0,
fontSize: 24
 }
export default Features;