import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerValue({ handleValidTill, editvalue }) {
  const [value, setValue] = React.useState(
    editvalue ? dayjs(editvalue, 'DD/MM/YYYY') : dayjs()
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          size="small"
          label="Valid Till"
          value={value}
          onChange={(newValue) => {
            handleValidTill(dayjs(newValue).format('DD/MM/YYYY'));
            setValue(newValue);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
