import React, { useState,useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton, ListItemText , IconButton, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SideDrawer from '../SideDrawer/index';
import { Box, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {GET_COURSES , DELETE_COURSE , GET_MODULES , GET_VIDEO, GET_PDF , GO_LIVE, CANCEL_LIVE, GET_PROJECTS, DELETE_PROJECT, POST_PRIORITY, GET_COURSE} from '../../../api/courseupload';
import axios from 'axios';
import MessagePopup from "../../MessagePopup";
import StreamIcon from '@mui/icons-material/Stream';
import CancelIcon from '@mui/icons-material/Cancel';
import PreviewIcon from '@mui/icons-material/Preview';
import ProjectUpload from '../ProjectUpload.jsx/index';

export default function CourseList() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [indexo, setIndexo] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [courseId , setCourseId] = useState('');
  const [deleteCourseIndex, setDeleteCourseIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [course , setCourse] = useState([]);
  const [modules, setModules] = React.useState([]);
  const [videos, setVideos] = React.useState([]);
  const [pdfs , setPdfs] = React.useState([]);
  const [moduleName , setModuleName] = React.useState('');
  const [resp, setResp] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [updated , setUpdated] = useState(false);
  const [added , setAdded] = useState(false);
  const [projects , setProjects] = useState([]);
  const [selectedProject , setSelectedProject] = useState(null);
  const [projectName , setProjectName] = useState('');
  const [projectDescription , setProjectDescription] = useState('');
  const [priority  , setPriority] = useState(10000);
  const [open  , setOpen] = useState(false);

  // const [goLive , setGoLive] = useState(false);
  


  

  


  useEffect(() => {
  
    axios
      .get(GET_COURSES)
      .then((data) => {
        const sortedCourses = data.data.data.sort((a, b) => {
          const priorityA = a.priority || 1000;
          const priorityB = b.priority || 1000; 
      
          return priorityA - priorityB;
        });
        setCourse(sortedCourses);
          setUpdated(false);
          setAdded(false);
        
      })
      .catch((err) => {
        console.log('Error fetching data', err);
      });

  }, [updated , added,openDelete, openPopUp ]);
  
  const fetchModules = ()=> {
    axios.get(GET_MODULES + `/${courseId}`)
    .then(moduledata => {
      setModules(moduledata.data.message);
    })
    .catch(err => {
      console.log(err);
    })
  }
  const fetchProjects = ()=> {
    axios.get(GET_PROJECTS + `${courseId}`)
    .then(moduledata => {
      setProjects(moduledata.data.message);
    })
    .catch(err => {
      console.log(err);
    })
  }
  const checkUpdated = (value) => {
    setUpdated(value);
  }
  
  const checkAdded = (value) => {
    setAdded(value);
  }
  const deleteSubmit = () => {
    setOpenDelete(false);
  

    axios
    .delete(DELETE_COURSE + deleteCourseIndex )
    .then(response => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
    })
    .catch(err => {
        console.log(err);
    })

  };
  const handleDelete = () => {
    axios
    .delete(DELETE_PROJECT+selectedProject)
    .then(res => {
      setSelectedProject(null);
      setAdded(true);
      handleCloseDialog();
      fetchProjects();
    })
    .catch(err => {
      console.log(err);
    })
  }
  const u = makeStyles({
    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      border: "none",
      borderRadius: "3px",
      textAlign: "center",
      color: "black",
      width: 600,
      p: 4,
      paddingLeft: "3rem",
      paddingRight: "3rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      "@media only screen and (max-width: 726px)": {
        width: "90%",
        p: 3,
      },
    },
  });
  const classes = u();

  const handleModuleClick = (module) => {
    setSelectedModule((prevCourse) => (prevCourse === course ? null : course));
    axios
    .get(GET_VIDEO+module)
    .then(data=> {
        setVideos(data.data.data);
    })
    .catch(err => {
        console.log(err);
    })
    axios
    .get(GET_PDF+module)
    .then(data=> {
        setPdfs(data.data.data);
    })
    .catch(err => {
        console.log(err);
    })
  };

  const handleCloseDialog = () => {
    setSelectedModule(null);
    setModuleName('');
    setSelectedProject(null);
    setProjectName('');
    setProjectDescription('');
    setOpen(false)
    setUpdated(true);
    };
  
 
  const deleteBox = (
    <Box className={classes.style}>
      <Typography variant="h5" style={{ marginBottom: "5px" }}>
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={deleteSubmit}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );
  const handleIndexo = (index) => {
    setIndexo((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleGoLive = (index) => {
    
    axios
    .patch(GO_LIVE+index)
    .then(response=> {
      setResp("Course Is Live");
      setOpenPopUp(true);
    })
    .catch(err => {
      console.log(err);
    })
  }
  const handleCancelGoLive = (index) => {
    
    axios
    .patch(CANCEL_LIVE+index)
    .then(response=> {
      setResp("Course Is Got Back from Live");
      setOpenPopUp(true);
    })
    .catch(err => {
      console.log(err);
    })
  }
 const handleSetPriority = () => {
    axios
      .post(POST_PRIORITY+courseId , {priority : priority})
      .then(response=> {
        setResp("Course Priority Is Set");
        setOpenPopUp(true);
        handleCloseDialog();
      })
      .catch(err => {
        console.log(err);
      })
 }

  return (
    <>
    {course.length>0 && course.map((c,index)=> (
        <div>
        <ListItem onClick={() =>{ 
            setCourseId(c._id);
            fetchModules();
            fetchProjects();
            
            }}>
      <img src={c.coursePreview} style={{ width: '10%', marginRight: '5%' }} alt="Course Thumbnail" />
      
      <ListItemText>
        {c.courseName}
        {` ₹${c.price}`}
        {" "}
        {c.priority && `Priority: ${c.priority}`}
        
      </ListItemText>
      
      <IconButton 
        onClick={() => setSelectedIndex((prevState) => (prevState === index ? null : index))}
      >
        <KeyboardDoubleArrowDownIcon />
      </IconButton>
      <ProjectUpload courseId ={c._id} setAdded={setAdded}/> 
      {/* <a href={`https://main.markoknow.com/viewCertificate/${c._id}` } target="_blank"><Button>
      View
      </Button></a>  */}
      <Button onClick={()=>{
          setOpen(true);
          setCourseId(c._id);
          axios.get(GET_COURSE+c._id).then(res=> setPriority(res.data.data[0].priority))}}>
      Set Priority
      </Button>
      
      <a href={`https://campus.markoknow.com/program/videoplayer/${c._id}` } target="_blank"><Button>
      <PreviewIcon/> 
      </Button></a>  
      <SideDrawer btn='edit'  cN={c.courseName} courseIndex={index} courseId ={c._id} cP={c.coursePreview} outcome={c?.outcomes} handleUpdate={checkUpdated} handleAdd={checkAdded} p={c.price} l={c?.learn} m={c?.material} f={c?.forWhom} ts={c?.tags} t={c?.trainingEnabled} tp={c?.trainingPrice}/>
      <Button
        onClick={() => {
          setDeleteCourseIndex(c._id);
          setOpenDelete(true);
        }}
      >
        Delete
      </Button>
      {!c.isLive?(<Button
      onClick={()=> handleGoLive(c._id)}
      
      >
        <StreamIcon/>
      </Button>):<Button
      onClick={()=> handleCancelGoLive(c._id)}
      
      >
        <CancelIcon/>
      </Button>}
      </ListItem>
    
 
        <Collapse in={selectedIndex === index}>
      <List>
      {modules&&(
            modules.map((module, subIndex) => (
                <React.Fragment key={subIndex}>
                <ListItem button onClick={() => {
                    setModuleName(module.title)
                    handleModuleClick(module._id)
                    setSelectedProject((prevCourse) => (prevCourse === course ? null : course));
                    }}>
                    {module.title}
                </ListItem>
                </React.Fragment>
            ))
            ) }
      </List>
      <List>
      {projects&&(
            projects.map((module, subIndex) => (
                <React.Fragment key={subIndex}>
                <ListItem button onClick={() => {
                    setProjectName(module.heading)
                    setSelectedProject(module._id)
                    setProjectDescription(module.description)
                    // handleModuleClick(module._id)
                    }}>
                    {module.heading}
                </ListItem>
                </React.Fragment>
            ))
            ) }
      </List>
    </Collapse>
    
    
    <Box width={400}>
        <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
          {deleteBox}
        </Modal> 
    </Box>
    </div>
    ))
    }
    <Dialog open={Boolean(selectedModule)} onClose={handleCloseDialog}>
       <DialogTitle>{moduleName}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{selectedCourse?.text}</DialogContentText>
          <DialogContentText>{selectedCourse?.link}</DialogContentText> */}
           {videos && (
            <div>
              <h3>Videos:</h3>
              {videos.map((video) => (
                <div key={video.title}>
                  <h4>{video.title}</h4>
                  <p>{video.link}</p> 
                </div>
              ))}
            </div>
          )} 
          {pdfs && (
            <div>
              <h3>Pdfs:</h3>
              {pdfs.map((pdf) => (
                <div key={pdf.title}>
                  <h4>{pdf.title}</h4>
                  <p>{pdf.link}</p> 
                </div>
              ))}
            </div>
          )} 
       </DialogContent>
        <DialogActions> 
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={Boolean(selectedProject)} onClose={handleCloseDialog}>
       <DialogTitle>{projectName}</DialogTitle>
        <DialogContent>
         Description: <br/>
         <div dangerouslySetInnerHTML={{ __html: projectDescription }} />
       </DialogContent>
        <DialogActions> 
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button onClick={handleDelete}>Delete</Button>

        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleCloseDialog}>
       <DialogTitle>Priority</DialogTitle>
        <DialogContent>
        <TextField 
          type='Number' 
          label='Priority' 
          value={priority} 
          onChange={(e) => setPriority(e.target.value)}
          />
       </DialogContent>
        <DialogActions> 
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button onClick={handleSetPriority}>Submit</Button>

        </DialogActions>
      </Dialog>
      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => setOpenPopUp(!openPopUp)}
      />
    </>
  );
}

       
     
  
