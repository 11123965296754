import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom'
import { Box, Button, Typography,InputBase,TextareaAutosize,FormGroup } from '@material-ui/core'
import axios from 'axios';
import MessagePopup from "../MessagePopup";

const {GET_COURSE,UPDATE_COURSE} = require("../../api/courses");

const DOMAIN = process.env.REACT_APP_DOMAIN;

const Courses = () => {
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");
    const [course , setCourse] = useState({});

    const [imgCollection , setImgCollection] = useState(null);

    const [img1 , setImage1] = useState(course.img1);
    const [img2 , setImage2] = useState(course.img2);
    const [img3 , setImage3] = useState(course.img3);
    const [img4 , setImage4] = useState(course.img4);

    const [title , setTitle] = useState("")
    const [subTitle , setSubTitle] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [description , setDescription] = useState("");
    // const [poster , setPoster] = useState(null);
    const [previewLink , setPreviewLink] = useState("");
    const [enrollLink , setEnrollLink] = useState("");
  
    const [data1 , setData1] = useState("");
    const [data2 , setData2] = useState("");
    const [data3 , setData3] = useState("");
    const [data4 , setData4] = useState("");
    // Course request
    useEffect(()=>{
        axios
            .get(GET_COURSE, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
            })
            .then(async(res) => {
                setCourse(res.data);
                setTitle(res.data.title);
                setSubTitle(res.data.subTitle);
                setVideoLink(res.data.videoLink);
                setPreviewLink(res.data.previewLink);
                setEnrollLink(res.data.enrollLink);
                setData1(res.data.data1);
                setData2(res.data.data2);
                setData3(res.data.data3);
                setData4(res.data.data4);
                setDescription(res.data.description);
                await setImgCollection(res.data.imgCollection);

                if(res.data.imgCollection.length <= 4){
                    await setImage1(res.data.imgCollection[0]?.name)
                    await setImage2(res.data.imgCollection[1]?.name)
                    await setImage3(res.data.imgCollection[2]?.name)
                    await setImage4(res.data.imgCollection[3]?.name)
                } else {
                    // await setPoster(res.data.imgCollection[0].name)
                    await setImage1(res.data.imgCollection[1].name)
                    await setImage2(res.data.imgCollection[2].name)
                    await setImage3(res.data.imgCollection[3].name)
                    await setImage4(res.data.imgCollection[4].name)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },[]);

    const handleSave =( ) => {
        if(imgCollection.length >= 4){
            const formdata = new FormData();

            formdata.append("title" , title);
            formdata.append("subTitle" , subTitle);
            formdata.append("videoLink" , videoLink);
            formdata.append("previewLink" , previewLink);
            formdata.append("enrollLink" , enrollLink);
            formdata.append("data1" , data1);
            formdata.append("data2" , data2);
            formdata.append("data3" , data3);
            formdata.append("data4" , data4);
            formdata.append("description" , description);

            if(imgCollection){
                Object.entries(imgCollection)
                    .forEach(file => {
                        formdata.append("imgCollection" , file[1]);
                    })
            } 
            
            axios.put(UPDATE_COURSE,formdata,{
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
            })
            .then((res)=>{
                setResp("Edited Successfully");
                setOpenPopUp(true);
                window.location.href="/courses";
            })
            .catch((err) => {
                setResp("Something error occured");
                setOpenPopUp(true);
            });
        } else {
            setResp("4 images are required !");
            setOpenPopUp(true);
        }
    }

    return ( 
        <div style={fullPage}>
            <Box width={450}>
                <Box style={{display: 'flex',justifyContent: 'space-around'}}>
                    <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
                        Courses Page
                    </Typography>
                    <Link to="/courses/testimonial" style={{textDecoration: "none" , marginRight: '10px'}}>
                        <Button style={{background: '#68F5D4'}} variant="contained" size='small'>testimonial</Button>
                    </Link>   
                </Box>
                <Box sx={style}>
                    <FormGroup>
                        <InputBase
                            placeholder='Title'
                            minRows={2}
                            style={inputs}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={subTitle}
                            onChange={(e) => setSubTitle(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Links
                        </Typography>
                        <Typography style={{color: '#fff',textAlign: 'left',fontWeight: 'bold'}}>Youtube link</Typography>
                        <InputBase
                            placeholder='Video Link' 
                            style={inputs}  
                            value={videoLink}
                            onChange={(e) => setVideoLink(e.target.value)}
                        />
                        <Typography style={{color: '#fff',textAlign: 'left',fontWeight: 'bold'}}>Preview link</Typography>
                         <InputBase 
                            placeholder='Preview Link' 
                            style={inputs}
                            value={previewLink}
                            onChange={(e) => setPreviewLink(e.target.value)}
                        /> 
                        <Typography style={{color: '#fff',textAlign: 'left',fontWeight: 'bold'}}>Enroll link</Typography>
                        <InputBase
                            minRows={2}
                            placeholder='Enroll Link' 
                            style={inputs}
                            value={enrollLink}
                            onChange={(e) => setEnrollLink(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Data's
                        </Typography>
                        <InputBase
                            placeholder='Data 1' 
                            style={inputs}  
                            value={data1}
                            onChange={(e) => setData1(e.target.value)}
                        />
                       <InputBase
                            placeholder='Data 2' 
                            style={inputs}  
                            value={data2}
                            onChange={(e) => setData2(e.target.value)}
                        />
                        <InputBase
                            placeholder='Data 3' 
                            style={inputs}  
                            value={data3}
                            onChange={(e) => setData3(e.target.value)}
                        />
                        <InputBase
                            placeholder='Data 4' 
                            style={inputs}  
                            value={data4}
                            onChange={(e) => setData4(e.target.value)}
                        />
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Images
                        </Typography>
                        <Box style={{display: 'flex'}}>
                            <img src={`${DOMAIN}/courses/${img1}`} alt="." style={iconsImage}></img>
                            <img src={`${DOMAIN}/courses/${img2}`} alt="." style={iconsImage}></img>
                            <img src={`${DOMAIN}/courses/${img3}`} alt="." style={iconsImage}></img>
                            <img src={`${DOMAIN}/courses/${img4}`} alt="." style={iconsImage}></img>
                        </Box>
                        <Button
                            variant="contained"
                            component="label"
                            style={{ marginTop: "10px" }}
                            >
                            {/* update 4 images */}
                            <input type='file' multiple onChange={(e)=>setImgCollection(e.target.files)}></input>
                        </Button>
                    </FormGroup>
                    <Box display='flex' justifyContent='center' >
                        <Button style={btn} size='large' onClick={handleSave}>Update</Button>
                    </Box>
                </Box>
                </Box>
          <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>

        </div>
     );
}

const style = {
    border: 'none',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    boxSizing: 'content-box',
    background: '#000',
    p: 2,
    borderRadius: 7,
};

const inputs ={
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
    background: '#eee',
    border: '1px solid #aaa',
}
    
const fullPage ={
width:'100%',
// height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
width: '100%',
}

const iconsImage = {
    width: '35px',
    objectFit: 'cover',
    margin: '5px'
}
 
export default Courses;