import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from "dayjs";
import Slide from '@mui/material/Slide';
import { GET_USER_DETAILS } from "../../api/userdetails";
import { GET_USER_COUNT, GET_USER_APPLIED } from "../../api/user";
import axios from "axios";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { GET_COURSE } from '../../api/courseupload';
import { PieChart } from "react-minimal-pie-chart";
import * as XLSX from 'xlsx';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function User() {
  const [activeCount, setActiveCount] = React.useState(0);
  const [notActiveCount, setNotActiveCount] = React.useState(0);
  const [details, setDetails] = React.useState([]);
  const [detailsL, setDetailsL] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [appliedCourses, setAppliedCourses] = React.useState([]);
  const [state, setState] = React.useState('signup');
  const [usersSignedUpLastMonth, setUsersSignedUpLastMonth] = React.useState(0);

  const handleClickOpen = (userId) => {
    axios
      .get(GET_USER_APPLIED + userId)
      .then(async (res) => {
        const appliedCoursesData = res.data.data;
        for (let i = 0; i < appliedCoursesData.length; i++) {
          const courseId = appliedCoursesData[i].courseId;
          try {
            const courseResponse = await axios.get(GET_COURSE + courseId);
            appliedCoursesData[i].courseName = courseResponse.data.data[0].courseName;
          } catch (error) {
            console.error("Error fetching course name:", error);
            appliedCoursesData[i].courseName = "Unknown Course";
          }
        }
        setAppliedCourses(appliedCoursesData);
        setOpen(true);
      })
      .catch((err) => {
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'userId', headerName: 'User Id', width: 150, editable: true },
    { field: 'email', headerName: 'Email', width: 150, editable: true },
    {
      field: state === "signup" ? 'signupDate' : 'loginCount',
      headerName: state === "signup" ? 'Signup Date' : 'Login Count',
      width: 110,
      editable: true,
    },
    state === "signup" && {
      field: 'appliedCourses',
      headerName: 'Applied Courses',
      width: 200,
      renderCell: (params) => {
        return (
          <Button variant="contained" onClick={(e) => handleClickOpen(params.row.userId)} color="primary">
            Applied Courses
          </Button>
        )
      }
    },
  ];

  React.useEffect(() => {
    axios
      .get(GET_USER_COUNT, {
        headers: {
          authorization: window.localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setActiveCount(res.data.activeUsers);
        setNotActiveCount(res.data.notActiveCount);
      })
      .catch((err) => console.error(err));
      axios
      .get(GET_USER_DETAILS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const dataWithIds = res.data.reverse().map((data, index) => {
          return { ...data, userId: data._id, id: index + 1 };
        });
        setDetails(dataWithIds);
        const sixMonthsAgo = dayjs().subtract(6, 'month');
        const usersLastSixMonths = new Array(6).fill(0);
        const currentDate = dayjs();
      dataWithIds.forEach((user) => {
        const signupDate = dayjs(user.signupDate);
        if (signupDate.isAfter(sixMonthsAgo) && signupDate.isBefore(currentDate)) {
          const monthIndex = currentDate.diff(signupDate, 'month');
          usersLastSixMonths[monthIndex]++;
        }
      });
        setUsersSignedUpLastMonth(usersLastSixMonths);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const exportToExcel = () => {
    const selectedRows = details.map(row => {
      return {
        ID: row.id,
        'User Id': row.userId,
        Email: row.email,
        [state === "signup" ? 'Signup Date' : 'Login Count']: state === "signup" ? row.signupDate : row.loginCount,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(selectedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users Data');
    XLSX.writeFile(workbook, `users_data_${new Date()}.xlsx`);
  };

  return (
    <div>
      <div style={{ height: '70vh' }}>
        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          <div>Total active users : {activeCount}</div>
          Total inActive users : {notActiveCount}
        </div>
        <PieChart
          totalValue={activeCount + notActiveCount}
          radius={30}
          reveal={100}
          label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
          labelStyle={{ fontSize: "7px" }}
          data={[
            { title: "Active users", value: activeCount, color: "#52efda" },
            { title: "Not active users", value: notActiveCount, color: "#C13C37" },
          ]}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginBottom: '20px'
        }}>
        <Button onClick={(e) => setState('signup')} variant="contained" color="primary">
          Sign Up Data
        </Button>
        <Button onClick={(e) => setState('login')} variant="contained" color="primary">
          Login Data
        </Button>
        <Button onClick={exportToExcel} variant="outlined" >
          Export as Excel
        </Button>
      </div>
      <Box sx={{ height: 1000 }}>
        <DataGrid
          rows={details}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        TransitionComponent={Transition}
      >
        <Box sx={{
          position: 'absolute',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '60vh',
          overflowY: 'auto',
        }}>
          <DialogTitle id="modal-modal-title">User Has Applied For The Courses</DialogTitle>
          <DialogContent>
            {Array.isArray(appliedCourses) && appliedCourses.length > 0 ? (
              appliedCourses.map((data, index) => (
                <DialogContentText key={index} id="modal-modal-description">
                  {index + 1}. (APPLYCOURSE {index + 1}) <br />
                  Name: {data.fName} {data.lName}<br />
                  Email: {data.email}<br />
                  College: {data.description}<br />
                  Data Applied: {dayjs(data.date).format("DD/MM/YYYY")}<br />
                  Course: {data.courseName}<br />
                  Whatsapp Number: {data.wNumber}
                </DialogContentText>
              ))
            ) : (
              <DialogContentText>No courses applied.</DialogContentText>
            )}
          </DialogContent>
        </Box>
      </Modal>
    </div>
  );
}

export default User;
