import React,{useState} from "react";
import { Box,Typography,FormGroup,InputBase,Button, TextareaAutosize } from "@material-ui/core";
import axios from "axios";
import MessagePopup from "../MessagePopup";



const BlueBox1 = () => {
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [heading,setHeading] = useState("");
    const [subHeading,setSubHeading] = useState("");
    
    const [linkName,setLinkName] = useState("");
    const [linkbutton,setLinkButton]=useState("");
    const [pointer1,setPointer1]=useState("");
    const [pointer2,setPointer2]=useState("");
   /* const handleUpdate = () => {
        if(heading.length > 0 && subHeading.length > 0 && 
            linkbutton.length > 0 && linkName.length > 0 && pointer1.length > 0 && pointer2.length>0){
            const new_Data = {
                heading: heading,
                subHeading: subHeading,
               linkbutton: linkbutton,
                linkName: linkName,
               pointer1:pointer1,
               pointer2:pointer2,
               
              }
              axios.put( UPDATE_FORM,new_Data,{
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
              })
              .then(response => {
                setResp("Uploaded Successfully");
                setOpenPopUp(true);
                setHeading(""); setSubHeading(""); setPicturesLink(""); setLinkColumn(""); setLinkName("");
              })
              .catch((err) => {
                setResp("Something error occured");
                setOpenPopUp(true);
              });
        } else {
            setResp("Kindly add all the details");
            setOpenPopUp(true);
        }
    }*/


    return ( 
        <Box style={fullPage}>
            <Box  width={450}>
                    <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
              Startup Shorts
                    </Typography>
                <Box sx={style}>
                    <FormGroup>
                        <TextareaAutosize
                            placeholder='Main heading'
                            minRows={2}
                            style={inputs}
                            value={heading}
                            onChange={(e) => setHeading(e.target.value)}
                        />
                        
                        <TextareaAutosize 
                            minRows={2}
                            placeholder='SubHeading' 
                            style={inputs}  
                            value={subHeading}
                            onChange={(e) => setSubHeading(e.target.value)}
                        />
                        <InputBase 
                            placeholder='Field 1' 
                            style={inputs}
                            value={pointer1}
                            onChange={(e) => setPointer1(e.target.value)}
                        /> 
                        
                         <InputBase 
                            placeholder='Field 2' 
                            style={inputs}
                            value={pointer2}
                            onChange={(e) => setPointer2(e.target.value)}
                        /> 
                        <InputBase 
                            placeholder='Button Name' 
                            style={inputs}
                            value={linkbutton}
                            onChange={(e) => setLinkButton(e.target.value)}
                        /> 
                        <InputBase 
                            placeholder='Button Link' 
                            style={inputs}
                            value={linkName}
                            onChange={(e) => setLinkName(e.target.value)}
                        /> 
                        
                         
                    </FormGroup>
                    <Box display='flex' justifyContent='center' >
                        <Button style={btn} size='large'>Submit</Button>
                    </Box>
                </Box>
                <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
            </Box>
    </Box>
);
}

const style = {
border: 'none',
boxShadow: 25,
textAlign: 'center',
color:'black',
boxSizing: 'content-box',
background: '#000',
p: 2,
borderRadius: 7,
};

const inputs ={
padding: 8,
marginTop: 7,
marginBottom: 7,
borderRadius: 5,
background: '#eee',
border: '1px solid #aaa',
}

const fullPage ={
width:'100%',
height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
}
 
export default BlueBox1;