import React,{useState,useEffect} from "react";
import { Box,Typography,FormGroup,InputBase,Button, TextareaAutosize } from "@material-ui/core";
import axios from "axios";
import {CircularProgress} from '@mui/material';
import MessagePopup from "../MessagePopup";

const {UPDATE_B2_MAINPAGE,GET_B2_MAINPAGE} = require("../../api/MainPage/b2")

const B2Main = () => {
    // const [b2Details , setB2Details] = useState([]);
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [loading,setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(GET_B2_MAINPAGE,{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
        })
        .then(async(resp) => {
            setHeading(resp.data.heading);
            setC1Heading(resp.data.C1Heading);
            setC2Heading(resp.data.C2Heading);
            setC3Heading(resp.data.C3Heading);
            setC4Heading(resp.data.C4Heading);
            setC1SubTitle(resp.data.C1SubTitle);
            setC2SubTitle(resp.data.C2SubTitle);
            setC3SubTitle(resp.data.C3SubTitle);
            setC4SubTitle(resp.data.C4SubTitle);
            setC1Link(resp.data.C1Link);
            setC2Link(resp.data.C2Link);
            setC3Link(resp.data.C3Link);
            setC4Link(resp.data.C4Link);
            setC1Description(resp.data.C1Description);
            setC2Description(resp.data.C2Description);
            setC3Description(resp.data.C3Description);
            setC4Description(resp.data.C4Description);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            // setB2Details([])
        })
    },[]);

    const [heading,setHeading] = useState("");

    const [C1Heading,setC1Heading] = useState("");
    const [C2Heading,setC2Heading] = useState("");
    const [C3Heading,setC3Heading] = useState("");
    const [C4Heading,setC4Heading] = useState("");

    const [C1Link , setC1Link] = useState("");
    const [C2Link , setC2Link] = useState("");
    const [C3Link , setC3Link] = useState("");
    const [C4Link , setC4Link] = useState("");

    const [C1SubTitle , setC1SubTitle] = useState("");
    const [C2SubTitle , setC2SubTitle] = useState("");
    const [C3SubTitle , setC3SubTitle] = useState("");
    const [C4SubTitle , setC4SubTitle] = useState("");

    const [C1Description , setC1Description] = useState("");
    const [C2Description , setC2Description] = useState("");
    const [C3Description , setC3Description] = useState("");
    const [C4Description , setC4Description] = useState("");

    const handleUpdate = () => {
        const new_Data = {
            heading: heading,
            C1Heading: C1Heading,
            C2Heading: C2Heading,
            C3Heading: C3Heading,
            C4Heading: C4Heading,
            C1Link: C1Link,
            C2Link: C2Link,
            C3Link: C3Link,
            C4Link: C4Link,
            C1SubTitle: C1SubTitle,
            C2SubTitle: C2SubTitle,
            C3SubTitle: C3SubTitle,
            C4SubTitle: C4SubTitle,
            C1Description: C1Description,
            C2Description: C2Description,
            C3Description: C3Description,
            C4Description: C4Description,
          }
          axios.put( UPDATE_B2_MAINPAGE,new_Data,{
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then(response => {
            setResp("Edited Successfully");
            setOpenPopUp(true);
          })
          .catch(err => {
            setResp("Some error occured");
            setOpenPopUp(true);
          });
    }

    return ( 
        <div>
            <Box style={fullPage}>
            {
                loading ? <CircularProgress style={loader} size={45} /> : 
                <Box  width={450}>
                    <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
                        Update B2 Page
                    </Typography>
                <Box sx={style}>
                    <FormGroup>
                        <TextareaAutosize
                            placeholder='Heading'
                            minRows={2}
                            style={inputs}
                            value={heading}
                            // defaultValue={b2Details.heading}
                            onChange={(e) => setHeading(e.target.value)}
                        />
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Card 1
                        </Typography>
                        <InputBase
                            placeholder='Heading' 
                            style={inputs}  
                            value={C1Heading}
                            onChange={(e) => setC1Heading(e.target.value)}
                        />
                         <InputBase 
                            placeholder='SubTitle' 
                            style={inputs}
                            value={C1SubTitle}
                            onChange={(e) => setC1SubTitle(e.target.value)}
                        /> 
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={C1Description}
                            onChange={(e) => setC1Description(e.target.value)}
                        /> 
                        <InputBase 
                            placeholder='Link' 
                            style={inputs}
                            value={C1Link}
                            onChange={(e) => setC1Link(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Card 2
                        </Typography>
                        <InputBase
                            placeholder='Heading' 
                            style={inputs}  
                            value={C2Heading}
                            onChange={(e) => setC2Heading(e.target.value)}
                        />
                        <InputBase 
                            placeholder='SubTitle' 
                            style={inputs}
                            value={C2SubTitle}
                            onChange={(e) => setC2SubTitle(e.target.value)}
                        /> 
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={C2Description}
                            onChange={(e) => setC2Description(e.target.value)}
                        /> 
                        <InputBase 
                            placeholder='Link' 
                            style={inputs}
                            value={C2Link}
                            onChange={(e) => setC2Link(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Card 3
                        </Typography>
                        <InputBase
                            placeholder='Heading' 
                            style={inputs}  
                            value={C3Heading}
                            onChange={(e) => setC3Heading(e.target.value)}
                        />
                        <InputBase 
                            placeholder='SubTitle' 
                            style={inputs}
                            value={C3SubTitle}
                            onChange={(e) => setC3SubTitle(e.target.value)}
                        /> 
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={C3Description}
                            onChange={(e) => setC3Description(e.target.value)}
                        /> 
                        <InputBase 
                            placeholder='Link' 
                            style={inputs}
                            value={C3Link}
                            onChange={(e) => setC3Link(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff' , borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Card 4
                        </Typography>
                        <InputBase
                            placeholder='Heading' 
                            style={inputs}  
                            value={C4Heading}
                            onChange={(e) => setC4Heading(e.target.value)}
                        />
                        <InputBase 
                            placeholder='SubTitle' 
                            style={inputs}
                            value={C4SubTitle}
                            onChange={(e) => setC4SubTitle(e.target.value)}
                        /> 
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={C4Description}
                            onChange={(e) => setC4Description(e.target.value)}
                        /> 
                        <InputBase 
                            placeholder='Link' 
                            style={inputs}
                            value={C4Link}
                            onChange={(e) => setC4Link(e.target.value)}
                        /> 
                    </FormGroup>
                    <Box display='flex' justifyContent='center' >
                        <Button style={btn} size='large' onClick={handleUpdate}>Update</Button>
                    </Box>
                </Box>
                </Box>
            }
    </Box>
    <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
        </div>
     );
}

const style = {
    border: 'none',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    boxSizing: 'content-box',
    background: '#000',
    p: 2,
    borderRadius: 7,
};

const inputs ={
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
    background: '#eee',
    border: '1px solid #aaa',
}
    
const fullPage ={
width:'100%',
// height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
width: '100%',
}

const loader = {
    // backgroundColor: 'rgba(52, 52, 52, 0.8)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color : '#fff',
  }
 
export default B2Main;