import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Divider from '@mui/material/Divider';
import MessagePopup from "../MessagePopup";
import {Typography} from '@mui/material';
// import { makeStyles } from "@material-ui/core";

import Modal from '@mui/material/Modal'
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import axios from 'axios';
import AddDialog from './AddEventDialog/index';
import { DELETE_EVENT, GET_EVENTS } from '../../api/event';
const style= {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  border: "none",
  borderRadius: "3px",
  textAlign: "center",
  color: "black",
  width: 600,
  p: 4,
  paddingLeft: "3rem",
  paddingRight: "3rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  "@media only screen and (max-width: 726px)": {
    width: "90%",
    p: 3,
  },
};
const Events = () => {
  
  const [checked , setChecked] = React.useState(false);
  const [eventsData , setEventsData] = React.useState([]);
  const [added , setAdded] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [resp, setResp] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteid , setDeleteId] = React.useState(null);
  const [pickedPromoData , setPickedPromoData] = React.useState([]);
  const [openAddDialog, setOpenAddDialog] = React.useState(false);

const handleOpenAddDialog = () => {
  setOpenAddDialog(true);
};

const handleCloseAddDialog = () => {
  setOpenAddDialog(false);
};


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `PromoCodes_${Date.now()}`;


  const exportToCSV = (eventsData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(eventsData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleChecked = async() => {
    
  }
  const handleSingleChecked = (active , id) => {
        
     
  }

  React.useEffect(() => {
    axios
    .get(GET_EVENTS)
    .then(res => {
      const dataWithIds = res.data.data.map((data , index) => ({
        ...data,
        id: data._id,
      }))
      setEventsData(dataWithIds);
    })
    .catch(err => {
      console.log(err);
    })
    
    
  }, [added,openPopUp])
  
  const handleAdded = (value , resp , open) => {
    setAdded(value);
    setResp(resp);
    setOpenPopUp(open);
  }

  const handleDeleteClick = () => {
    axios
    .delete(DELETE_EVENT+deleteid)
    .then(res => {
      setResp("Deleted Successfully")
      setOpenPopUp(true);
      setOpenDelete(false);
    }).catch(err => {
      setResp(err.message);
      setOpenPopUp(true);
      setOpenDelete(false);
    })
  };
  const deleteBox = (
    <Box sx={style}>
      <Typography variant="h5" style={{ marginBottom: "5px" }}>
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={handleDeleteClick}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );

  const columns = [
    { field: 'id', headerName: 'ID',type: 'string', width: 200, cellClassName: 'custom-cell' },
    {
      field: 'title',
      headerName: 'TITLE',
      type: 'string',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'custom-cell'
    },
    {
      field: 'eventPoster',
      headerName: 'POSTER',
      type: 'string',
      width: 400,
      height: 200,      
      cellClassName: 'custom-cell',
      renderCell: (params) => (
          <img src={`${params.value}`} width={400} height={200} alt='Image'/>
      )
    },
    {
      field: 'date',
      headerName: 'DATE',
      width: 200,
      cellClassName: 'custom-cell',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <AddDialog btn = 'edit' pickedEventData={params.row} handleAdded = {handleAdded}/>
          ,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={()=> {
              setDeleteId(params.row.id)
              setOpenDelete(true);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between'}}>  
      <div style={{display:'flex' , flexDirection:'column' , justifyContent:'flex-start' , marginBottom:'10px'}}>
      <div style={{display:'flex' , flexDirection:'row' }}>
      <Typography 
      variant='h6'
      style={{marginRight:'20px' , fontWeight:'bold'}}>
          Events {`(${eventsData.length})`}
      </Typography>
      </div>
      </div>
      <div style= {{display:'flex' , flexDirection:'row' , alignItems:'center' }}>
      <Button 
      variant='outlined'
      style={{color: '#000833' , borderColor:'#000833' , borderRadius:0, marginRight:'10px'}}
      onClick={(e) => exportToCSV(eventsData, fileName)}
      >
        Export As CSV
      </Button>
      <AddDialog btn = 'add' handleAdded = {handleAdded}/>
      </div>
     
    
     </div> 
     <Divider style={{marginBottom:'10px'}}/>
    
  

     <DataGrid
        rows={eventsData}
        rowHeight={100}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbarQuickFilter }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
/>


      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => {
          // setAdded(false);
          setOpenPopUp(!openPopUp)
          }}
      />
      <Box width={400}>
        <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
          {deleteBox}
        </Modal>
      </Box>
     
    </Box>
  );
}

export default Events