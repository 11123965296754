import React, { useState, useRef , useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { IconButton } from "@material-ui/core";
import "../Email/Component1/admin_emails-Style.css";
import axios from "axios";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DatePickerValue from '../PromoCode/AddDialog/DatePicker';
import { CREATE_DAILY_GOAL, EDIT_DAILY_GOAL } from '../../api/dailygoals';
import { GET_ALL_BLOGS } from '../../api/blog';
import { GET_CASE_STUDY } from '../../api/casestudy';
import { GET_ALL_EVENTS } from '../../api/watchnow';

const   Addtemplate = ({btn , setAdded , editdata}) => {
    const [open, setOpen] = useState(false); 
    const [goal, setGoal] = useState(""||editdata?.goal); 
    const [validTill, setValidTill] = React.useState(''||editdata?.date);
    const [link , setLink] = React.useState(''||editdata?.link);
    const [currencies , setCurrencies] = React.useState([{value: 'First7' , label: 'First7'} , {value: 'none' , label: 'None'}]);
    const [data , setData] = useState([]);
    const [blog , setBlog] = useState(''||editdata?.blog?.value);
    const [watchnow , setWatchnow] = useState(''||editdata?.watchnow?.value);
    const [casestudy , setCasestudy] = useState(''||editdata?.casestudy?.value);
    const [tag , setTag] = React.useState(''||editdata?.tag);
    const [whichLink , setWhichLink] = React.useState(''||editdata?.whichTag);
    const whichLinks = [
        {
            value: 'Blog', 
            label: 'Blog', 
        },
        {
            value: 'Watchnow',
            label: 'Watchnow', 
        },
        {
            value: 'Casestudy',
            label: 'Casestudy', 
        }
    ];
    useEffect(() => {
        if(whichLink === 'Blog'){
            axios
            .get(GET_ALL_BLOGS,{
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
            })
            .then(res => {
                const mappedBlogs = res.data.map(blog => {
                    return {
                        value: blog._id,
                        label: blog.title,
                        ...blog
                    }
                })
                setData(mappedBlogs);
            })
            .catch(err =>{
                console.error(err);
            })
        }
        else if(whichLink === 'Casestudy'){
            axios
            .get(GET_CASE_STUDY)
            .then(res => {
                const mappedCaseStudies = res.data.data.map(casestudy => {
                    return {
                        value: casestudy._id,
                        label: casestudy.title,
                        ...casestudy
                    }
                })
              setData(mappedCaseStudies);
            })
            .catch(err => {
              console.log(err);
            })
        }
        else if(whichLink==='Watchnow'){
            axios
                .get(GET_ALL_EVENTS, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
                })
                .then((res) => {
                    const mappedWatchnows = res.data.map(watchnow => {
                        return {
                            value: watchnow._id,
                            label: watchnow.title,
                            ...watchnow
                        }
                    })
                    setData(mappedWatchnows);
                })
                .catch(err =>{
                    console.error(err);
                })
        }
    },[whichLink])
    const handleSaveDate = (value) => {
        setValidTill(value);
      }
      const resetAll = () => {
        setGoal("");
        setValidTill("");
        setTag("");
        setLink("");
        setWhichLink("");
      }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubjectChange = (e) => {
        setGoal(e.target.value);
    };

    const handleCreate = () => {
        const datas = {
            goal: goal,
            date: validTill,
            tag: tag,
            whichTag: whichLink,
            blog: whichLink === 'Blog' ? data.filter(dat => dat.value === blog)[0] : null,
            watchnow: whichLink === 'Watchnow' ? data.filter(dat => dat.value === watchnow)[0] : null,
            casestudy: whichLink === 'Casestudy' ? data.filter(dat => dat.value === casestudy)[0] : null
        }

        axios
        .post(CREATE_DAILY_GOAL, datas)
        .then(res => {
            setAdded(true);
            resetAll();
        })
        .catch(err => {
            console.log(err);

        })

    }
    const handleEdit = () => {
        const datas = {
            goal: goal,
            date: validTill,
            tag: tag,
            whichTag: whichLink,
            blog: whichLink === 'Blog' ? data.filter(dat => dat.value === blog)[0] : {},
            watchnow: whichLink === 'Watchnow' ? data.filter(dat => dat.value === watchnow)[0] : {},
            casestudy: whichLink === 'Casestudy' ? data.filter(dat => dat.value === casestudy)[0] : {}
        }
        axios
        .patch(EDIT_DAILY_GOAL+editdata._id, datas)
        .then(res => {
            setAdded(true);
        })
        .catch(err => {
            console.log(err);

        })

    }
  
    return (
      <div>
        {btn === 'edit' ? (
            <Tooltip title="Edit Daily Goal">
            
            <IconButton
             onClick={
                        ()=> {
                            handleClickOpen();
                        }
                    }
            >
                <EditSharpIcon/>
            </IconButton>
            </Tooltip>
        ): (
            <Tooltip title="Add Daily Goal">
            <IconButton onClick={
                        ()=> {
                            handleClickOpen();
                        }
                    }>
                    <AddCircleOutlineRoundedIcon fontSize='large' color="grey"/>
             </IconButton>
             </Tooltip>
            
        )}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                    <DialogTitle  style={{textAlign:'center'}}>
                        {"Daily Goals"}
                    </DialogTitle>
                    <DialogContent>
                <div

                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "20px",
                    margin:'5%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Daily Goal"
                    className="subject"
                    value={goal}
                    onChange={handleSubjectChange}
                    style={{
                        height:'50px',
                        marginBottom:'10px'
                    }}
                  />
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Which Link To Upload"
                       defaultValue={whichLink}
                        style={{
                            width:'100%',
                            marginTop:'10px',
                            marginBottom:'10px'
                        }}
                        onChange = {(e) => {
                            setWhichLink(e.target.value);
                        }}
                        >
                        {whichLinks.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                        {whichLink && (
                            <Box sx={{
                            width:'100%',
                            marginTop:'10px',
                            marginBottom:'10px'
                        }}>
                        <TextField
                        id="outlined-select-currency"
                        select
                        label={`Unused ${whichLink}`}
                        defaultValue={(whichLink === 'Blog' && blog) || (whichLink === 'Watchnow' && watchnow )||( whichLink === 'Casestudy' && casestudy) }
                        style={{
                            width:'100%',
                            marginTop:'10px',
                            marginBottom:'10px'
                        }}
                        onChange = {(e) => {
                            if(whichLink === 'Blog'){
                                setBlog(e.target.value);
                            }
                            else if(whichLink === 'Watchnow'){
                                setWatchnow(e.target.value);
                            }
                            else if(whichLink === 'Casestudy'){
                                setCasestudy(e.target.value);
                            }
                        }}
                        >
                        {data.filter(dat => !dat.dailygoal).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                        <TextField
                        id="outlined-select-currency"
                        select
                        label={`Used ${whichLink}`}
                        defaultValue={(whichLink === 'Blog' && blog) || (whichLink === 'Watchnow' && watchnow )||( whichLink === 'Casestudy' && casestudy) }
                        style={{
                            width:'100%',
                            marginTop:'10px',
                            marginBottom:'10px'
                        }}
                        onChange = {(e) => {
                            if(whichLink === 'Blog'){
                                setBlog(e.target.value);
                            }
                            else if(whichLink === 'Watchnow'){
                                setWatchnow(e.target.value);
                            }
                            else if(whichLink === 'Casestudy'){
                                setCasestudy(e.target.value);
                            }
                        }}
                        >
                        {data.filter(dat => dat.dailygoal).map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                        </Box>
                        )}
                  <TextField
                        id="outlined-select-currency"
                        select
                        label="Tag"
                        defaultValue={tag}
                        style={{
                            width:'100%',
                            marginTop:'10px',
                            marginBottom:'10px'
                        }}
                        onChange = {(e) => {
                            setTag(e.target.value);
                        }}
                        >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                        </TextField>
                   {tag === 'none'&&<DatePickerValue style={{marginBottom:'10px'}} handleValidTill = {handleSaveDate} editvalue={editdata?.date}/>}
                  
                  </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={
                        ()=>{
                            if(btn==='edit'){
                                handleEdit();
                            }
                            else{
                                handleCreate();
                            }
                            
                            
                            handleClose();
                        }
                        } autoFocus>
                        {btn==='edit'?'Save':'Add'}
                    </Button>
                    </DialogActions>
                  </Dialog>
                  

      </div>
    );
}

export default  Addtemplate