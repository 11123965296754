const { BASE_URL } = require("../client");

const headingId =  "63439e11af6f3d0e0ce6db0c";

const UPDATE_B9_MAINPAGE = BASE_URL + "/main/b9/";
const GET_B9_MAINPAGE = BASE_URL + "/main/b9/";
const DELETE_B9_MAINPAGE = BASE_URL + "/main/b9/";
const CREATE_B9_MAINPAGE = BASE_URL + "/main/b9/";

export {
    UPDATE_B9_MAINPAGE,
    GET_B9_MAINPAGE,
    DELETE_B9_MAINPAGE,
    CREATE_B9_MAINPAGE,
    headingId
}