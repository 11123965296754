import React , {useRef , useEffect} from 'react';
import certificateImg from './3.png';
import signature from './signature.png';
import { useLocation , useParams} from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import {GET_COURSE} from '../../../api/courseupload';

const CertificateView = () => {
  const params = useParams();
  const location = useLocation();
  const date = new Date();
  const now = date.toLocaleString();
  const myArray = now.split(',');
  const certificateRef = useRef(null); 
  const isMobile = useMediaQuery('(max-width:600px)');
  const [certificate , setCertificate] = useState({});
  const [course,setCourse] = useState('');
  React.useEffect(() => {
    axios
      .get(GET_COURSE + params.id)
      .then((response) => {
        setCourse(response.data.data[0]);
        // console.log(course);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const certificateStyles = {
    backgroundImage: `url(${certificateImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: isMobile?'100vh':'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontSize: '24px',
    textAlign: 'center',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    margin:'2%',
    border:'2px #000 solid'
  };

  const signatureStyles = {
    width: '30%',
    height: 'auto',
    marginTop: '20px',
    
  };

  const underlineStyles = {
    marginTop:0,
    borderBottom: '2px solid #000',
    width: '15%', 
  };

  const ceoNameStyles = {
    marginTop: '10px',
    fontWeight:'bold'
  };

  const ceoDesignationStyles = {
    fontSize: '18px',
  };

  return (
    <div style={{  display:'flex' , flexDirection:!isMobile?'row':'column' }}>
      <div ref={certificateRef} style={certificateStyles}>
        <p style={{fontWeight:'bold'}}>Markoknow</p>
        <h1>
          CERTIFICATE
        </h1>
        <h3 style={{fontWeight:'initial',marginBottom:'20px'}}>OF COMPLETION</h3>
        
        <p style={{fontSize:'12px'}}>This is to certify that</p>
        <h3 style={{margin:0}}>NAME</h3>
        <p style={{fontSize:'12px'}}>Has Completed</p>
        <br />
        <h4>Course Name</h4>
        <p style={{ width: '40%' , fontSize:'15px' , marginTop:'10px'}}>
          Skillset Achieved : Brainstorming, Problem Solving, Critical Thinking, Creative Visualization, Canva Designing, Entrepreneurship
        </p>
        <img
          src={signature}
          alt="CEO Signature"
          style={signatureStyles}
        />
        <span style={underlineStyles}></span>
        <p style={ceoNameStyles}>Kaushiki</p>
        <p style={ceoDesignationStyles}>Founder And CEO, Startup Virtual Campus</p>
        <p style={{marginTop:'30px' , fontSize:'10px'}}>Issued On: </p>
        <p style={{ fontSize:'10px'}}>Seriel No.: </p>
        
      </div>
    </div>
  );
};

export default CertificateView;
