import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Divider from "@mui/material/Divider";
import { NavLink } from "react-router-dom";
import { Badge } from "@mui/material";
import axios from 'axios';
import { DELETE_NOTIFICATIONS, GET_NOTIFICATIONS } from "../../api/notification";

export default function Notifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (e, id) => {
    axios
      .delete(DELETE_NOTIFICATIONS + id)
      .then(res => {
        setRefresh(true);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    axios.get(GET_NOTIFICATIONS)
      .then(res => {
        setNotifications(res.data);
        setCount(res.data.length);
        setRefresh(false);
      })
      .catch(err => {
        console.error("Error fetching notifications:", err);
      });
  }, [refresh]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ marginRight: 2 }}
            aria-controls={open ? "notification-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Badge badgeContent={count} color="secondary">
              <NotificationsNoneIcon fontSize='large' style={{ color: '#fff' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="notification-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 4,
          sx: {
            width: 300,
            maxHeight: '100%',
            overflowY: "auto",
            "& .MuiTypography-root": {
              whiteSpace: "normal",
              my: 1,
            },
          },
        }}
      >
        {notifications?.reverse()?.map((notification) => (
          <React.Fragment key={notification._id}>
            <NavLink to={notification.link}
              onClick={(e) => handleDelete(e, notification._id)}
              exact style={{ textDecoration: "none", color: "black" }}>
              <MenuItem onClick={handleClose} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Typography variant="subtitle1">{notification.type}</Typography>
                <Typography variant="body2"> from {notification.userName}</Typography>
              </MenuItem>
            </NavLink>
            <Divider />
          </React.Fragment>
        ))}
      </Menu>
    </React.Fragment>
  );
}
