
import { Box, Button, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MessagePopup from "../MessagePopup";
import FormDialog from "./FormDialog";
import { style } from "@mui/system";

const {
  GET_ALL_DETAILS,
  DELETE_STARTUPSHORT,
  
} = require("../../api/startupShortsAdmin");

const StartupShortAdmin = ({isAuth , setIsAuth}) => {
  const classes = u();

  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");

  const [openDelete, setOpenDelete] = useState(false);

//   const [StartupShortData, setStartupShortData] = useState([{_id:'1',name1:'Deb',description:'jhguasycg' , Link: 'kjdchug' ,Image: 'jhgauy' },{_id:'2',name1:'Dino',description:'Hello',Link:'jhbauchg',Image:'kjbuhg'}]);
  const [StartupShortData, setStartupShortData] = useState([]);

  const [pickedStartupShort, setPickedStartupShort] = useState([]);
  const [added , setAdded] = useState(false);

  const [openEdit, setOpenEdit] = useState('');

  useEffect(() => {
    axios
      .get(GET_ALL_DETAILS , {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
     } )
      .then((res) => {
        setStartupShortData(res.data);
        setAdded(false);
        setOpenEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  },[openDelete,openPopUp,added,openEdit]);
   const handleAdd = (value) => {
    setAdded(value);
   }
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `StartupShort_${Date.now()}`;

  const exportToCSV = (StartupShortData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(StartupShortData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const editSubmit = (value) => {
    setOpenEdit(value);
  }
  const deleteSubmit = () => {
    const id = pickedStartupShort._id;
    
    axios
      .delete(DELETE_STARTUPSHORT + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };
  const deleteBox = (
    <Box className={classes.style}>
      <Typography variant="h5" style={{ marginBottom: "5px" }}>
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={deleteSubmit}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 100, cellClassName: "Cell-Col" },
    {
      field: "name1",
      headerName: "Name",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "link",
      headerName: "Link",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 100
     
    },
    {
      field: "image",
      headerName: "Image",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 200,

      renderCell: (params) =>  {
        return <img src={params.value} style={{width: '100%'}}  />}
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 100
    },
    {
      field: "time",
      headerName: "Time",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 100

    },
    {
        field: "Edit",
        headerName: "Edit",
        headerClassName: "column-header",
        cellClassName: "column",
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onClick = () => {
            setPickedStartupShort(params.row);
            setOpenEdit("EDIT");
          };
          return (
            <Button
              variant="contained"
              color="#fff"
              size="small"
              style={{ marginLeft: 12, padding: "5px", background: "#fff" }}
              onClick={onClick}
              

            >

            {isAuth&& <FormDialog nameP={pickedStartupShort.name1} descriptionP={pickedStartupShort.description} linkP={pickedStartupShort.link} id={pickedStartupShort._id} btn="EDIT" btnP="Save" onAdd={editSubmit}/>}
            {!isAuth && <p style = {{width: "100%"}}> Edit </p>}

            </Button>
            
          );
        },

    },
    {
    field: "Delete",
    headerName: "Delete",
    headerClassName: "column-header",
    cellClassName: "column",
    width: 100,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      const onClick = () => {
        setPickedStartupShort(params.row);
        setOpenDelete(true);
      };
      return (isAuth?
       <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 12, padding: "5px", background: "#ed4253" }}
          onClick={onClick}
        >
          Delete
        </Button>
        :<p style={{width: "100%"}}>Delete</p>
         
        
      );
    }
  }  
  ];

  return (
    <div>
      <Box display="flex" justifyContent="space-between" padding="10px">
        <Typography variant="h5">Startup Short</Typography>
        
        <div className="Dialog-Download-btn"> 
          {isAuth && <FormDialog btn = 'ADD' btnP = 'ADD' onAdd={handleAdd}/>}
       
      
        <Button
          margin= "10px"
          onClick={(e) => exportToCSV(StartupShortData, fileName)}
          size="small"
          variant="contained"
          color="primary"
        >
          Download Data
        </Button>
        </div>
        
      </Box>
      <Box
        sx={{
          height: 800,
          width: "100%",
          "& .column": {
            fontSize: "16px",
            justifyCOntent: "center",
            textAlign: "center",
          },
          "& .column-header": {
            color: "purple",
            fontSize: "20px",
          },
        }}
      >
        <DataGrid
          rows={StartupShortData}
          className="table-box"
          getRowId={(row) => row._id }
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => {
          // setAdded(false);
          setOpenPopUp(!openPopUp)
          }}
      />
      <Box width={400}>
        <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
          {deleteBox}
        </Modal>
      </Box>
      {/* <Box display="flex" justifyContent="center">
        <Modal open={openDetails} onClose={() => setOpenDetails(false)}>
          {EditBox}
        </Modal>
      </Box> */}
    </div>
  );
};
const u = makeStyles({
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 600,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      p: 3,
    },
  },
});



export default StartupShortAdmin
