import { Box, Button, InputBase, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MessagePopup from "../MessagePopup";
import { validateEmail } from "../auth/validateEmail";

const {
  GET_ALL_DETAILS,
  NEW_ACCESS,
  UPDATE_ACCESS,
  DELETE_ACCESS,
  REVOKE_ACCESS,
} = require("../../api/websiteaccess");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "3px",
  boxShadow: 25,
  textAlign: "center",
  color: "black",
  p: 4,
  paddingLeft: "3rem",
  paddingRight: "3rem",
};

const useStyles = makeStyles({
  inputsStyle: {
    margin: "10px 0px 0px 0px",
    backgroundColor: "whitesmoke",
    color: "black",
    padding: "10px",
  },
});

export default function WebsiteAccess({ isAuth, a_websiteAccess }) {
  const classes = useStyles();
  const classeStyle = u();

  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");

  const [allAccess, setAllAccess] = useState([]);
  const [pickedAccess, setPickedAccess] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [emailError, setEmailError] = useState("");

  //checkboxes
  const [watchnow, setWatchNow] = useState(false);
  const [events, setEvents] = useState(false);
  const [PartnerUs, setPartnerus] = useState(false);
  const [websiteAnnouncement, setWebsiteAnnouncement] = useState(false);
  const [markoknowEducation, setMarkoknowEducation] = useState(false);
  const [emails, setEmails] = useState(false);
  const [websiteAccess, setWebsiteAccess] = useState(false);
  const [blog, setBlog] = useState(false);
  const [user , setUser] = useState(false);
  const [startupshort , setStartupShort] = useState(false);
  const [adminBoard , setAdminBoard] = useState(false);
  const [mobileNumberCleanup , setMobileNumberCleanup] = useState(false);
  const [mainpage , setMainPage] = useState(false);
  const [kitpage , setKitPage] = useState(false);
  const [learner , setLearn] = useState(false);
  const [funding , setFunding] = useState(false);
  const [virtualCampus , setVirtualcampus] = useState(false);
  const [memberships , setMemberships] = useState(false);
  const [courses , setCourses] = useState(false);
  const [updateLink , setUpdateLink] = useState(false);
  const [casestudy , setCaseStudy] = useState(false);
  const [badge , setBadge] = useState(false);
  const [applycourses, setApplyCourses] = useState(false);
  const [eventcertificate, setEventCertificate] = useState(false);
  const [dailygoals, setDailyGoals] = useState(false);
  const [createcourse, setCreateCourse] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  const [events_register, setEventsRegister] = useState(false);
  const [events1, setEvents1] = useState(false);
  const [lab, setLab] = useState(false);
  const [workex, setWorkex] = useState(false);
  const [certificates , setCertificates] = useState(false);
  const [adminCertificates , setAdminCertificates] = useState(false);
  const [brochureDetails , setBrochureDetails] = useState(false);
  const [workEx , setWorkEx] = useState(false);
  const [workExApproval , setWorkExApproval] = useState(false);
  const [payments , setPayments] = useState(false);
  const [mentors , setMentors] = useState(false);
  const [editcourse, setEditCourse] = useState(false);
  const [generatelink, setGenerateLink] = useState(false);
  const [redeem, setRedeem] = useState(false);
  const [hire , setHire] = useState(false);
  const [booking , setBooking] = useState(false);
  const [queries , setQueries] = useState(false);
  const [scholarship, setScholarship] = useState(false);
  const [availability, setAvailability] = useState(false);

  




  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRevoke, setOpenRevoke] = useState(false);

  // ALl Data request
  useEffect(() => {
    axios
      .get(GET_ALL_DETAILS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setAllAccess(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  const makeAllFalse = () => {
    setWatchNow(false);
    setEvents(false);
    setPartnerus(false);
    setWebsiteAnnouncement(false);
    setMarkoknowEducation(false);
    setEmails(false);
    setWebsiteAccess(false);
    setBlog(false);
    setMobileNumberCleanup(false);
    setStartupShort(false);
    setAdminBoard(false);
    setUser(false);
    setKitPage(false);
    setMainPage(false);
    setLearn(false);
    setFunding(false);
    setVirtualcampus(false);
    setUpdateLink(true);
    setCourses(false);
    setMemberships(false);
    setCaseStudy(false);
    setBadge(false);
    setApplyCourses(false);
    setEventCertificate(false);
    setDailyGoals(false);
    setCreateCourse(false);
    setAnalytics(false);
    setEventsRegister(false);
    setEvents1(false);
    setLab(false);
    setWorkex(false);
    setEditCourse(false);
    setGenerateLink(false);
    setRedeem(false);
    setCertificates(false);
    setAdminCertificates(false);
    setBrochureDetails(false);
    setPayments(false);
    setWorkEx(false);
    setWorkExApproval(false);
    setHire(false);
    setAvailability(false);
    setScholarship(false);
    setQueries(false);
    setBooking(false);
  };

  const checksHandler = (value) => {
    if (value === "Watch Now")
      watchnow ? setWatchNow(false) : setWatchNow(true);
    if (value === "Events") events ? setEvents(false) : setEvents(true);
    if (value === "Partner Us")
      PartnerUs ? setPartnerus(false) : setPartnerus(true);
    if (value === "Website Announcements")
      websiteAnnouncement
        ? setWebsiteAnnouncement(false)
        : setWebsiteAnnouncement(true);
    if (value === "Markoknow Education")
      markoknowEducation
        ? setMarkoknowEducation(false)
        : setMarkoknowEducation(true);
    if (value === "Emails") emails ? setEmails(false) : setEmails(true);
    if (value === "Website Access")
      websiteAccess ? setWebsiteAccess(false) : setWebsiteAccess(true);
    if (value === "Admin Board")
      adminBoard ? setAdminBoard(false) : setAdminBoard(true); 
    if (value === "Blog")
      blog ? setBlog(false) : setBlog(true); 
    if (value === "Mobile Number Cleanup")
      mobileNumberCleanup ? setMobileNumberCleanup(false) : setMobileNumberCleanup(true);
    if (value === "User")
      user ? setUser(false) : setUser(true);
    if (value === "Startup Short")
      startupshort ? setStartupShort(false) : setStartupShort(true);
    if (value === "Funding")
      funding ? setFunding(false) : setFunding(true);
    if (value === "Main Page")
      mainpage ? setMainPage(false) : setMainPage(true);
    if (value === "Kit Page")
      kitpage ? setKitPage(false) : setKitPage(true);  
    if (value === "Learner")
      learner ? setLearn(false) : setLearn(true); 
    if (value === "Virtual Campus")  
      virtualCampus ? setVirtualcampus(false) : setVirtualcampus(true);
    if (value === "Membership")  
      memberships ? setMemberships(false) : setMemberships(true);  
    if (value === "Courses")  
      courses ? setCourses(false) : setCourses(true);  
    if (value === "Update Link")  
      updateLink ? setUpdateLink(false) : setUpdateLink(true);  
    if(value === "Case Study")
      casestudy ? setCaseStudy(false) : setCaseStudy(true);
    if(value === "Badge")
      badge ? setBadge(false) : setBadge(true);
      if (value === "Apply Courses") {
        applycourses ? setApplyCourses(false) : setApplyCourses(true);
      }
      if (value === "Event Certificate") {
        eventcertificate ? setEventCertificate(false) : setEventCertificate(true);
      }
      if (value === "Daily Goals") {
        dailygoals ? setDailyGoals(false) : setDailyGoals(true);
      }
      if (value === "Create Course") {
        createcourse ? setCreateCourse(false) : setCreateCourse(true);
      }
      if (value === "Analytics") {
        analytics ? setAnalytics(false) : setAnalytics(true);
      }
      if (value === "Events Register") {
        events_register ? setEventsRegister(false) : setEventsRegister(true);
      }
      if (value === "Events1") {
        events1 ? setEvents1(false) : setEvents1(true);
      }
      if (value === "Lab") {
        lab ? setLab(false) : setLab(true);
      }
      if (value === "Work Experience") {
        workex ? setWorkex(false) : setWorkex(true);
      }
      if (value === "Edit Course") {
        editcourse ? setEditCourse(false) : setEditCourse(true);
      }
      if (value === "Generate Link") {
        generatelink ? setGenerateLink(false) : setGenerateLink(true);
      }
      if (value === "Redeem") {
        redeem ? setRedeem(false) : setRedeem(true);
      }
      if (value === "Certificates") {
        certificates ? setCertificates(false) : setCertificates(true);
      }
      if (value === "Admin Certificate") {
        adminCertificates ? setAdminCertificates(false) : setAdminCertificates(true);
      }
      if (value === "Work Ex") {
        workEx ? setWorkEx(false) : setWorkEx(true);
      }
      if (value === "Work Ex Approval") {
        workExApproval ? setWorkExApproval(false) : setWorkExApproval(true);
      }
      if (value === "Payments") {
        payments ? setPayments(false) : setPayments(true);
      }
      if (value === "Brochure Details") {
        brochureDetails ? setBrochureDetails(false) : setBrochureDetails(true);
      }
      if (value === "Mentor") {
        mentors ? setMentors(false) : setMentors(true);
      }
      if (value === "Hire") {
        hire ? setHire(false) : setHire(true);
      }
      if (value === "Queries") {
        queries ? setQueries(false) : setQueries(true);
      }
      if (value === "Booking") {
        booking ? setBooking(false) : setBooking(true);
      }
      if (value === "Availability") {
        availability ? setAvailability(false) : setAvailability(true);
      }
      if (value === "Scholarship") {
        scholarship ? setScholarship(false) : setScholarship(true);
      }
      
  };

  const accessToCheckers = (checks) => {
    makeAllFalse();
    checks.map((checkbox) => {
      if (checkbox === "Watch Now") setWatchNow(true);
      if (checkbox === "Events") setEvents(true);
      if (checkbox === "Partner Us") setPartnerus(true);
      if (checkbox === "Website Announcement") setWebsiteAnnouncement(true);
      if (checkbox === "Markoknow Education") setMarkoknowEducation(true);
      if (checkbox === "Emails") setEmails(true);
      if (checkbox === "Website Access") setWebsiteAccess(true);
      if (checkbox === "Admin Board") setAdminBoard(true);
      if (checkbox === "Blog") setBlog(true);
      if (checkbox === 'Mobile Number Cleanup') setMobileNumberCleanup(true);
      if (checkbox === 'User') setUser(true);
      if (checkbox === 'Startup Short') setStartupShort(true);
      if (checkbox === 'Funding') setFunding(true);
      if (checkbox === 'Learner') setLearn(true);
      if (checkbox === 'Kit Page') setKitPage(true);
      if (checkbox === 'Main Page') setMainPage(true);
      if (checkbox === 'Virtual Campus') setVirtualcampus(true);
      if (checkbox === 'Membership') setMemberships(true);
      if (checkbox === 'Courses') setCourses(true);
      if (checkbox === 'Update Link') setUpdateLink(true);
      if (checkbox === 'Case Study') setCaseStudy(true);
      if (checkbox === 'Badge') setBadge(true);
      if (checkbox === 'Apply Courses') setApplyCourses(true);
      if (checkbox === 'Event Certificate') setEventCertificate(true);
      if (checkbox === 'Daily Goals') setDailyGoals(true);
      if (checkbox === 'Create Course') setCreateCourse(true);
      if (checkbox === 'Analytics') setAnalytics(true);
      if (checkbox === 'Events Register') setEventsRegister(true);
      if (checkbox === 'Events1') setEvents1(true);
      if (checkbox === 'Lab') setLab(true);
      if (checkbox === 'Work Experience') setWorkex(true);
      if (checkbox === 'Edit Course') setEditCourse(true);
      if (checkbox === 'Generate Link') setGenerateLink(true);
      if (checkbox === 'Redeem') setRedeem(true);
      if (checkbox === 'Certificates') setCertificates(true);
      if (checkbox === 'Admin Certificate') setAdminCertificates(true);
      if (checkbox === 'Brochure Details') setBrochureDetails(true);
      if (checkbox === 'Work Ex') setWorkEx(true);
      if (checkbox === 'Work Ex Approval') setWorkExApproval(true);
      if (checkbox === 'Payments') setPayments(true);
      if (checkbox === 'Mentor') setMentors(true);
      if (checkbox === 'Hire') setHire(true);
      if (checkbox === 'Scholarship') setScholarship(true);
      if (checkbox === 'Queries') setQueries(true);
      if (checkbox === 'Booking') setBooking(true);
      if (checkbox === 'Availability') setAvailability(true);


    });
  };
  

  const validationForm = () => {
    if (name.length < 1) return false;
    else if (email.lengh < 1) return false;
    else return true;
  };

  const createSubmit = () => {
    var checks = [];
    if (watchnow) checks.push("Watch Now");
    if (events) checks.push("Events");
    if (PartnerUs) checks.push("Partner Us");
    if (websiteAnnouncement) checks.push("Website Announcement");
    if (markoknowEducation) checks.push("Markoknow Education");
    if (emails) checks.push("Emails");
    if (websiteAccess) checks.push("Website Access");
    if (adminBoard) checks.push("Admin Board");
    if (startupshort) checks.push("Startup Short");
    if (blog) checks.push("Blog");
    if (user) checks.push("User");
    if (mobileNumberCleanup) checks.push("Mobile Number Cleanup");
    if (funding) checks.push("Funding");
    if (mainpage) checks.push("Main Page");
    if (kitpage) checks.push("Kit Page");
    if (learner) checks.push('Learner');
    if (updateLink) checks.push('Update Link');
    if (virtualCampus) checks.push('Virtual Campus');
    if (courses) checks.push('Courses');
    if (memberships) checks.push('Membership');
    if (casestudy) checks.push('Case Study');
    if (badge) checks.push('Badge');
    if (applycourses) checks.push('Apply Courses');
    if (eventcertificate) checks.push('Event Certificate');
    if (dailygoals) checks.push('Daily Goals');
    if (createcourse) checks.push('Create Course');
    if (analytics) checks.push('Analytics');
    if (events_register) checks.push('Events Register');
    if (events1) checks.push('Events1');
    if (lab) checks.push('Lab');
    if (workex) checks.push('Work Experience');
    if (editcourse) checks.push('Edit Course');
    if (generatelink) checks.push('Generate Link');
    if (redeem) checks.push('Redeem');
    if (certificates) checks.push('Certificates');
    if (adminCertificates) checks.push('Admin Certificate');
    if (brochureDetails) checks.push('Brochure Details');
    if (workEx) checks.push('Work Ex');
    if (workExApproval) checks.push('Work Ex Approval');
    if (payments) checks.push('Payments');
    if (mentors) checks.push('Mentor');
    if (hire) checks.push('Hire');
    if (scholarship) checks.push('Scholarship');
    if (booking) checks.push('Booking');
    if (availability) checks.push('Availability');
    if (queries) checks.push('Queries');


  


    
    if (validationForm() && checks.length !== 0) {
      const new_data = {
        name: name,
        email: email,
        accessTo: checks,
      };

      axios
        .post(NEW_ACCESS, new_data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            makeAllFalse();
            setOpenCreate(false);
            setResp("Created Successfully");
            setOpenPopUp(true);
            window.location.href = "/access";
          } else if (res.status === 203) {
            makeAllFalse();
            setOpenCreate(false);
            setResp(res.data.message);
            setOpenPopUp(true);
          }
        })
        .catch((err) => {
          makeAllFalse();
          setResp("Something error occured");
          setOpenPopUp(true);
        });
      setName("");
      setEmail("");
    } else {
      setResp("All inputs are required");
      setOpenPopUp(true);
    }
  };

  const editSubmit = (e) => {
    var checks = [];
    if (watchnow) checks.push("Watch Now");
    if (events) checks.push("Events");
    if (PartnerUs) checks.push("Partner Us");
    if (websiteAnnouncement) checks.push("Website Announcement");
    if (markoknowEducation) checks.push("Markoknow Education");
    if (emails) checks.push("Emails");
    if (websiteAccess) checks.push("Website Access");
    if (adminBoard) checks.push("Admin Board");
    if (startupshort) checks.push("Startup Short");
    if (blog) checks.push("Blog");
    if (user) checks.push("User");
    if (mobileNumberCleanup) checks.push("Mobile Number Cleanup");
    if (learner) checks.push("Learner");
    if (mainpage) checks.push("Main Page");
    if (kitpage) checks.push("Kit Page");
    if (funding) checks.push("Funding");
    if (updateLink) checks.push('Update Link');
    if (virtualCampus) checks.push('Virtual Campus');
    if (courses) checks.push('Courses');
    if (memberships) checks.push('Membership');
    if (casestudy) checks.push('Case Study');
    if (badge) checks.push('Badge');
    if (applycourses) checks.push('Apply Courses');
    if (eventcertificate) checks.push('Event Certificate');
    if (dailygoals) checks.push('Daily Goals');
    if (createcourse) checks.push('Create Course');
    if (analytics) checks.push('Analytics');
    if (events_register) checks.push('Events Register');
    if (events1) checks.push('Events1');
    if (lab) checks.push('Lab');
    if (workex) checks.push('Work Experience');
    if (editcourse) checks.push('Edit Course');
    if (generatelink) checks.push('Generate Link');
    if (redeem) checks.push('Redeem');
    if (certificates) checks.push('Certificates');
    if (adminCertificates) checks.push('Admin Certificate');
    if (brochureDetails) checks.push('Brochure Details');
    if (workEx) checks.push('Work Ex');
    if (workExApproval) checks.push('Work Ex Approval');
    if (payments) checks.push('Payments');
    if (mentors) checks.push('Mentor');
    if (hire) checks.push('Hire');
    if (scholarship) checks.push('Scholarship');
    if (booking) checks.push('Booking');
    if (availability) checks.push('Availability');
    if (queries) checks.push('Queries');

    axios
      .put(
        UPDATE_ACCESS + pickedAccess._id,
        { accessTo: checks },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setResp("Created Successfully");
        setOpenPopUp(true);
        setOpenEdit(false);
        window.location.href = "/access";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
        setOpenEdit(false);
      });
    makeAllFalse();
  };

  // Delete submit function
  const deleteSubmit = () => {
    const id = pickedAccess._id;
    const userId = window.localStorage.getItem("id");
    axios
      .delete(DELETE_ACCESS + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/access";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };

  const RevokeSubmit = () => {
    var checks = [];
    axios
      .put(
        REVOKE_ACCESS + pickedAccess._id,
        { accessTo: checks },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setResp("Revoked Successfully");
        setOpenPopUp(true);
        setOpenEdit(false);
        window.location.href = "/access";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
        setOpenEdit(false);
      });
    makeAllFalse();
  };

  const createBox = (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: 'auto', height: 'auto',background:'#fff', padding:'10px' }}>
      <Typography
        variant="h4"
        fontStyle="bold"
        textAlign="center"
        textTransform="uppercase"
        style={{textAlign:'center'}}
      >
        Create a New User
      </Typography>
      <FormGroup 
      
      method="POST"
      
      >
        <InputBase
          placeholder="Name"
          className={classes.inputsStyle}
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <span className="error">{emailError}</span>
        <InputBase
          placeholder="Email ID"
          className={classes.inputsStyle}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Box textAlign="center" marginTop="1rem">
          <Typography variant="h5">Access to</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row' , 
            flexWrap: 'wrap' , 
            justifyContent: 'space-evenly',
          }}
        >
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Watch Now")}
            />
          }
          label="Watch Now"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Events")}
            />
          }
          label="Events"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Partner Us")}
            />
          }
          label="Partner Us"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Website Announcements")}
            />
          }
          label="Website Announcements"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Markoknow Education")}
            />
          }
          label="Markoknow Education"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Emails")}
            />
          }
          label="Emails"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Website Access")}
            />
          }
          label="Website Access"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Blog")}
            />
          }
          label="Blog"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("User")}
            />
          }
          label="User"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Startup Short")}
            />
          }
          label="Startup Short"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Admin Board")}
            />
          }
          label="Admin Board"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Mobile Number Cleanup")}
            />
          }
          label="Mobile Number Cleanup"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Learner")}
            />
          }
          label="Learn"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Funding")}
            />
          }
          label="Funding"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Main Page")}
            />
          }
          label="Main Page"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Kit Page")}
            />
          }
          label="Kit Page"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Virtual Campus")}
            />
          }
          label="Virtual Campus"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Membership")}
            />
          }
          label="Memberships"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Courses")}
            />
          }
          label="Courses"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Update Link")}
            />
          }
          label="Update Links"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Case Study")}
            />
          }
          label="Case Study"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              onChange={() => checksHandler("Badge")}
            />
          }
          label="Badge"
        />
        <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Apply Courses")}
              />
            }
            label="Apply Courses"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Event Certificate")}
              />
            }
            label="Event Certificate"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Daily Goals")}
              />
            }
            label="Daily Goals"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Create Course")}
              />
            }
            label="Create Course"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Analytics")}
              />
            }
            label="Analytics"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Events Register")}
              />
            }
            label="Events Register"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Events1")}
              />
            }
            label="Events1"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Lab")}
              />
            }
            label="Lab"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Work Experience")}
              />
            }
            label="Work Experience"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Edit Course")}
              />
            }
            label="Edit Course"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Generate Link")}
              />
            }
            label="Generate Link"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Redeem")}
              />
            }
            label="Redeem"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Certificates")}
              />
            }
            label="Certificates"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Admin Certificate")}
              />
            }
            label="Admin Certificate"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Brochure Details")}
              />
            }
            label="Brochure Details"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Work Ex")}
              />
            }
            label="Work Ex"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Work Ex Approval")}
              />
            }
            label="Work Ex Approval"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Payments")}
              />
            }
            label="Payments Status"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Mentor")}
              />
            }
            label="Mentors"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Hire")}
              />
            }
            label="Hiring Entries"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Booking")}
              />
            }
            label="Bookings"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Availability")}
              />
            }
            label="Availability"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Scholarship")}
              />
            }
            label="Scholarship"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                onChange={() => checksHandler("Queries")}
              />
            }
            label="Queries"
          />
          </Box>

      </FormGroup>
      <Button
        onClick={createSubmit}
        style={{
          backgroundColor: "#81fccf",
          width: "250px",
          fontSize: "20px",
          color: "black",
          marginTop: "1rem",
          marginLeft: '35%'
        }}
      >
        Submit
      </Button>
    </Box>
  );

  const editBox = (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: 'auto', height: 'auto',background:'#fff', padding:'10px' }}>
      <Typography variant="h4" fontStyle="bold" textTransform="uppercase" textAlign="center">
        {pickedAccess.name}
      </Typography>
      <Typography variant="h6">{pickedAccess.email}</Typography>
      <Box style={{ textAlign: "center", marginTop: "1rem" }}>
      <Typography variant="h5">Access to</Typography>

        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly'
          }}
        >

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={watchnow}
                onChange={() => checksHandler("Watch Now")}
              />
            }
            label="Watch Now"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={events}
                onChange={() => checksHandler("Events")}
              />
            }
            label="Events"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={PartnerUs}
                onChange={() => checksHandler("Partner Us")}
              />
            }
            label="Partner Us"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={websiteAnnouncement}
                onChange={() => checksHandler("Website Announcements")}
              />
            }
            label="Website Announcements"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={markoknowEducation}
                onChange={() => checksHandler("Markoknow Education")}
              />
            }
            label="Markoknow Education"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={emails}
                onChange={() => checksHandler("Emails")}
              />
            }
            label="Emails"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={websiteAccess}
                onChange={() => checksHandler("Website Access")}
              />
            }
            label="Website Access"
          />
           <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={blog}
              onChange={() => checksHandler("Blog")}
            />
          }
          label="Blog"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={user}
              onChange={() => checksHandler("User")}
            />
          }
          label="User"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={startupshort}
              onChange={() => checksHandler("Startup Short")}
            />
          }
          label="Startup Short"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={adminBoard}
              onChange={() => checksHandler("Admin Board")}
            />
          }
          label="Admin Board"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={mobileNumberCleanup}
              onChange={() => checksHandler("Mobile Number Cleanup")}
            />
          }
          label="Mobile Number Cleanup"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={learner}
              onChange={() => checksHandler("Learner")}
            />
          }
          label="Learn"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={funding}
              onChange={() => checksHandler("Funding")}
            />
          }
          label="Funding"
        />
          <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={mainpage}
              onChange={() => checksHandler("Main Page")}
            />
          }
          label="Main Page"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={kitpage}
              onChange={() => checksHandler("Kit Page")}
            />
          }
          label="Kit Page"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={virtualCampus}
              onChange={() => checksHandler("Virtual Campus")}
            />
          }
          label="Virtual Campus"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={memberships}
              onChange={() => checksHandler("Membership")}
            />
          }
          label="Memberships"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={courses}
              onChange={() => checksHandler("Courses")}
            />
          }
          label="Courses"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={updateLink}
              onChange={() => checksHandler("Update Link")}
            />
          }
          label="Update Links"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={casestudy}
              onChange={() => checksHandler("Case Study")}
            />
          }
          label="Case Study"
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "black" }}
              checked={badge}
              onChange={() => checksHandler("Badge")}
            />
          }
          label="Badge"
        />
        <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={applycourses}
                onChange={() => checksHandler("Apply Courses")}
              />
            }
            label="Apply Courses"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={eventcertificate}
                onChange={() => checksHandler("Event Certificate")}
              />
            }
            label="Event Certificate"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={dailygoals}
                onChange={() => checksHandler("Daily Goals")}
              />
            }
            label="Daily Goals"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={createcourse}
                onChange={() => checksHandler("Create Course")}
              />
            }
            label="Create Course"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={analytics}
                onChange={() => checksHandler("Analytics")}
              />
            }
            label="Analytics"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={events_register}
                onChange={() => checksHandler("Events Register")}
              />
            }
            label="Events Register"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={events1}
                onChange={() => checksHandler("Events1")}
              />
            }
            label="Events1"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={lab}
                onChange={() => checksHandler("Lab")}
              />
            }
            label="Lab"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={workex}
                onChange={() => checksHandler("Work Experience")}
              />
            }
            label="Work Experience"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={editcourse}
                onChange={() => checksHandler("Edit Course")}
              />
            }
            label="Edit Course"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={generatelink}
                onChange={() => checksHandler("Generate Link")}
              />
            }
            label="Generate Link"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={redeem}
                onChange={() => checksHandler("Redeem")}
              />
            }
            label="Redeem"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={certificates}
                onChange={() => checksHandler("Certificates")}
              />
            }
            label="Certificates"
          />

          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={adminCertificates}
                onChange={() => checksHandler("Admin Certificate")}
              />
            }
            label="Admin Certificate"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={brochureDetails}
                onChange={() => checksHandler("Brochure Details")}
              />
            }
            label="Brochure Details"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={workEx}
                onChange={() => checksHandler("Work Ex")}
              />
            }
            label="Work Ex"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={workExApproval}
                onChange={() => checksHandler("Work Ex Approval")}
              />
            }
            label="Work Ex Approval"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={payments}
                onChange={() => checksHandler("Payments")}
              />
            }
            label="Payments Status"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={mentors}
                onChange={() => checksHandler("Mentor")}
              />
            }
            label="Mentors"
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "black" }}
                checked={hire}
                onChange={() => checksHandler("Hire")}
              />
            }
            label="Hiring Entries"
          />
            <FormControlLabel
            control={
              <Checkbox
              checked={booking}

                style={{ color: "black" }}
                onChange={() => checksHandler("Booking")}
              />
            }
            label="Bookings"
          />
          <FormControlLabel
            control={
              <Checkbox
              checked={availability}

                style={{ color: "black" }}
                onChange={() => checksHandler("Availability")}
              />
            }
            label="Availability"
          />
          <FormControlLabel
            control={
              <Checkbox
              checked={scholarship}

                style={{ color: "black" }}
                onChange={() => checksHandler("Scholarship")}
              />
            }
            label="Scholarship"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={queries}
                style={{ color: "black" }}
                onChange={() => checksHandler("Queries")}
              />
            }
            label="Queries"
          />
        </FormGroup>
      </Box>
      <Button
        onClick={editSubmit}
        style={{
          backgroundColor: "#81fccf",
          fontSize: "18px",
          marginTop: "1rem",
          paddin: "5px",
          marginLeft:'45%'
        }}
      >
        Update
      </Button>
    </Box>
  );

  // Delete PopUp
  const deleteBox = (
    <Box sx={style}>
      <Typography variant="h5" marginBottom="5px">
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={deleteSubmit}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );

  const RevokeBox = (
    <Box sx={style}>
      <Typography variant="h5" marginBottom="5px">
        Are you sure want to Revoke?
      </Typography>
      <Box>
        <Button
          onClick={RevokeSubmit}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenRevoke(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 80, cellClassName: "Cell-Col" },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
      //  headerAlign: 'center',
      cellAlign: "center",
    },
    {
      field: "email",
      headerName: "Emails",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
      // headerAlign: 'center',
    },
    {
      field: "accessTo",
      headerName: "AccessTo",
      width: 350,
      cellClassName: "column",
      headerClassName: "column-header",
      // headerAlign: 'center',
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      // headerAlign: 'center',
      cellClassName: "column",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedAccess(params.row);
          setOpenEdit(true);
          // setName(params.row.name);
          // setEmail(params.row.email);
          // setAccessTo(params.row.accessTo);
          accessToCheckers(params.row.accessTo);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
            {/* <EditIcon style={{color: 'white'}}/> */}
          </Button>
        );
      },
    },
    {
      field: "Revoke",
      headrName: "Revoke All Access",
      headerClassName: "column-header",
      // headerAlign: 'center',
      cellClassName: "column",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedAccess(params.row);
          setOpenRevoke(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#81bbe6" }}
            onClick={onClick}
          >
            Revoke
            {/* <DeleteIcon style={{color: 'white'}}/> */}
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      // headerAlign: 'center',
      cellClassName: "column",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedAccess(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
            {/* <DeleteIcon style={{color: 'white'}}/> */}
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      {a_websiteAccess ? (
        <div>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">Website Access</Typography>
            <Button
              onClick={() => setOpenCreate(true)}
              size="medium"
              variant="contained"
              color="primary"
            >
              new user
            </Button>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyCOntent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "purple",
                fontSize: "20px",
              },
            }}
          >
            <DataGrid
              rows={allAccess}
              className="table-box"
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <MessagePopup
            open={openPopUp}
            message={resp}
            handleAlertClose={() => setOpenPopUp(!openPopUp)}
          />
          <Modal 
          open={openCreate} 
          onClose={() => setOpenCreate(false)}
          style={{ width: '100%', height: '100vh', overflow: 'auto' }}
          >
            {createBox}
          </Modal>

          <Modal 
          open={openEdit} 
          onClose={() => setOpenEdit(false)}
          style={{ width: '100%', height: '100vh', overflow: 'auto' }}
          >
            {editBox}
          </Modal>
          <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
            {deleteBox}
          </Modal>
          <Modal open={openRevoke} onClose={() => setOpenRevoke(false)}>
            {RevokeBox}
          </Modal>
        </div>
      ) : (
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
    </div>
  );
}

const u = makeStyles({
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 400,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      height: 400,
      overflowY: "scroll",
      // left: '0%',
      p: 3,
      // margin: 100,
    },
  },
});
