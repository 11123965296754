import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { GET_COURSES } from '../../api/courseupload'; // Replace with the actual API path
import { GET_WORKEXPERIENCE, POST_WORKEXPERIENCE } from '../../api/workex';

const WorkExperience = () => {
  const [workExperiences, setWorkExperiences] = useState([]);
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [newWorkExperience, setNewWorkExperience] = useState({
    courseId: '',
    courseName: '',
    heading: 'Trainee',
    days: [{ day: 1, task: '' }],
  });

  useEffect(() => {
    // Fetching courses from the backend
    axios.get(GET_COURSES)
      .then(response => setCourses(response.data.data))
      .catch(error => console.error('Error fetching courses:', error));

    // Fetching work experiences
    axios.get(GET_WORKEXPERIENCE)
      .then(response => setWorkExperiences(response.data.data))
      .catch(error => console.error('Error fetching work experiences:', error));

      setRefresh(false);  
  }, [refresh]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddDay = () => {
    setNewWorkExperience(prev => ({
      ...prev,
      days: [...prev.days, { day: prev.days.length + 1, task: '' }]
    }));
  };

  const handleDayChange = (index, field, value) => {
    const updatedDays = [...newWorkExperience.days];
    updatedDays[index][field] = value;
    setNewWorkExperience({ ...newWorkExperience, days: updatedDays });
  };

  const handleSaveWorkExperience = () => {
    // Save the new work experience
    axios.post(POST_WORKEXPERIENCE+newWorkExperience.courseId, newWorkExperience)
      .then(response => {
        setWorkExperiences([...workExperiences, response.data]);
        setRefresh(true);
        handleClose();
      })
      .catch(error => console.error('Error saving work experience:', error));
  };

  const handleEditWorkExperience = (id) => {
    // Edit functionality
  };

  const handleDeleteWorkExperience = (id) => {
    if (window.confirm('Are you sure you want to delete this work experience?')) {
      axios.delete(`/api/work-experiences/${id}`)
        .then(() => {
          setWorkExperiences(workExperiences.filter(we => we._id !== id));
        })
        .catch(error => console.error('Error deleting work experience:', error));
    }
  };

  return (
    <Box>
      <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleOpen} mt={2}>
        Add Work Experience
      </Button>
      <Box sx={{ height: 400, width: '100%', mt: 2 }}>
        <DataGrid
          rows={workExperiences}
          columns={[
            { field: 'courseName', headerName: 'Course Name', flex: 1 },
            { field: 'heading', headerName: 'Heading', flex: 1 },
            {
              field: 'days',
              headerName: 'Days',
              flex: 2,
              valueGetter: (params) => params.row.days.map(day => `Day ${day.day}: ${day.task}`).join(', ')
            },
            {
              field: 'actions',
              headerName: 'Actions',
              flex: 1,
              renderCell: (params) => (
                <Box>
                  <IconButton onClick={() => handleEditWorkExperience(params.row._id)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteWorkExperience(params.row._id)}>
                    <Delete />
                  </IconButton>
                </Box>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          getRowId={(row) => row._id}
        />
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Work Experience</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Course</InputLabel>
            <Select
              value={newWorkExperience.courseId}
              onChange={(e) => {
                const selectedCourse = courses.find(course => course._id === e.target.value);
                setNewWorkExperience({
                  ...newWorkExperience,
                  courseId: e.target.value,
                  courseName: selectedCourse?.courseName || '',
                });
              }}
              label="Course"
            >
              {courses.map(course => (
                <MenuItem key={course._id} value={course._id}>
                  {course.courseName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Heading"
            value={newWorkExperience.heading}
            onChange={(e) => setNewWorkExperience({ ...newWorkExperience, heading: e.target.value })}
          />
          {newWorkExperience.days.map((day, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <TextField
                type="number"
                label={`Day ${index + 1}`}
                value={day.day}
                onChange={(e) => handleDayChange(index, 'day', e.target.value)}
                disabled
              />
              <TextField
                fullWidth
                label="Task"
                value={day.task}
                onChange={(e) => handleDayChange(index, 'task', e.target.value)}
              />
            </Box>
          ))}
          <Button variant="outlined" sx={{ mt: 2 }} onClick={handleAddDay}>
            Add Day
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveWorkExperience}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorkExperience;
