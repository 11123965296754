import React,{useState,useEffect} from "react";
import { Box,Typography,FormGroup,InputBase,Button, TextareaAutosize } from "@material-ui/core";
import axios from "axios";
import MessagePopup from "../MessagePopup";

const {UPDATE_B8_MAINPAGE,GET_B8_MAINPAGE} = require("../../api/MainPage/b8")

const B8Main = () => {
    useEffect(() => {
        axios.get(GET_B8_MAINPAGE,{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
        })
        .then(async(res) => {
            setChar1(res.data.char1);
            setChar2(res.data.char2);
            setChar3(res.data.char3);
            setChar4(res.data.char4);
            setDesc1(res.data.desc1);
            setDesc2(res.data.desc2);
            setDesc3(res.data.desc3);
            setDesc4(res.data.desc4);
        })
        .catch(err => {
            console.log(err);
            // setB2Details([])
        })
    },[]);
    
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [char1 , setChar1] = useState("");
    const [char2 , setChar2] = useState("");
    const [char3 , setChar3] = useState("");
    const [char4 , setChar4] = useState("");

    const [desc1 , setDesc1] = useState("");
    const [desc2 , setDesc2] = useState("");
    const [desc3 , setDesc3] = useState("");
    const [desc4 , setDesc4] = useState("");


    const handleUpdate = () => {
        const new_Data = {
            char1: char1,
            char2: char2,
            char3: char3,
            char4: char4,
            desc1: desc1,
            desc2: desc2,
            desc3: desc3,
            desc4: desc4,
          }
          axios.put( UPDATE_B8_MAINPAGE,new_Data,{
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then(response => {
            setResp("Edited Successfully");
            setOpenPopUp(true);
          })
          .catch(err => {
            setResp("Some error occured");
            setOpenPopUp(true);
          });
    }

    return ( 
        <div style={fullPage}>
            <Box  width={450}>
                    <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
                        Update b8 Page
                    </Typography>
                <Box sx={style}>
                    <FormGroup>
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                             1st
                        </Typography>
                        <InputBase
                            placeholder='Character 1' 
                            style={inputs}  
                            value={char1}
                            onChange={(e) => setChar1(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Description' 
                            style={inputs}
                            value={desc1}
                            onChange={(e) => setDesc1(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            2nd
                        </Typography>
                        <InputBase
                            placeholder='Character 2' 
                            style={inputs}  
                            value={char2}
                            onChange={(e) => setChar2(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Description' 
                            style={inputs}
                            value={desc2}
                            onChange={(e) => setDesc2(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                             3rd
                        </Typography>
                        <InputBase
                            placeholder='Character 3' 
                            style={inputs}  
                            value={char3}
                            onChange={(e) => setChar3(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Description' 
                            style={inputs}
                            value={desc3}
                            onChange={(e) => setDesc3(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            4th
                        </Typography>
                        <InputBase
                            placeholder='Character 4' 
                            style={inputs}  
                            value={char4}
                            onChange={(e) => setChar4(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Description' 
                            style={inputs}
                            value={desc4}
                            onChange={(e) => setDesc4(e.target.value)}
                        /> 
                    </FormGroup>
                    <Box display='flex' justifyContent='center' >
                        <Button style={btn} size='large' onClick={handleUpdate}>Update</Button>
                    </Box>
                </Box>
                </Box>
            <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
        </div>
     );
}

const style = {
    border: 'none',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    boxSizing: 'content-box',
    background: '#000',
    p: 2,
    borderRadius: 7,
};

const inputs ={
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
    background: '#eee',
    border: '1px solid #aaa',
}
    
const fullPage ={
width:'100%',
// height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
width: '100%',
}

const loader = {
    // backgroundColor: 'rgba(52, 52, 52, 0.8)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color : '#fff',
  }
 
export default B8Main;