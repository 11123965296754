import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from "@material-ui/core";
import JoditEditor from "jodit-react";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { CREATE_TEMPLATE, EDIT_TEMPLATE } from '../../../api/template';
import axios from 'axios';
const Addtemplate = ({ addTemplate, btn, sub, html, id }) => {
    const [open, setOpen] = useState(false);
    const [subject, setSubject] = useState(localStorage.getItem('templateSubject') || sub);
    const [message, setMessage] = useState(localStorage.getItem('templateMessage') || html);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubjectChange = (e) => {
        const value = e.target.value;
        setSubject(value);
        localStorage.setItem('templateSubject', value);
    };

    const handleTextareaChange = (value) => {
        setMessage(value);
        localStorage.setItem('templateMessage', value);
    };

    const handleCreate = () => {
        const data = {
            subject: subject,
            html: message
        }

        axios.post(CREATE_TEMPLATE, data)
            .then(res => {
                addTemplate('Created Successfully');
                localStorage.removeItem('templateSubject');
                localStorage.removeItem('templateMessage');
            })
            .catch(err => {
                console.log(err);
                addTemplate('Not Created');
            })
            .finally(() => handleClose());
    }

    const handleEdit = () => {
        const data = {
            subject: subject,
            html: message
        }

        axios.patch(EDIT_TEMPLATE + id, data)
            .then(res => {
                addTemplate('Edited Successfully');
                localStorage.removeItem('templateSubject');
                localStorage.removeItem('templateMessage');
            })
            .catch(err => {
                console.log(err);
                addTemplate('Not Edited');
            })
            .finally(() => handleClose());
    }

    return (
        <div>
            {btn === 'edit' ? (
                <Tooltip title="Edit">
                    <IconButton onClick={handleClickOpen}>
                        <EditSharpIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Add Template">
                    <IconButton onClick={handleClickOpen}>
                        <AddCircleOutlineRoundedIcon fontSize='large' color="grey" />
                    </IconButton>
                </Tooltip>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    {"Template"}
                </DialogTitle>
                <DialogContent>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "20px",
                        margin: '5%',
                    }}>
                        <input
                            type="text"
                            placeholder="Enter Subject"
                            className="subject"
                            value={subject}
                            onChange={handleSubjectChange}
                            style={{
                                height: '50px',
                                marginBottom: '10px'
                            }}
                        />
                        <div>
                            <JoditEditor
                                className="message"
                                value={message}
                                placeholder={message || "Enter Message"}
                                tabIndex={1}
                                onChange={handleTextareaChange}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        btn === 'edit' ? handleEdit() : handleCreate();
                    }} autoFocus>
                        {btn === 'edit' ? 'Save' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Addtemplate;
