const { BASE_URL } = require("./client");

const GET_ALL_BLOGS = BASE_URL + "/blogs";
const CREATE_BLOG = BASE_URL + "/blogs/create";
const UPDATE_BLOG = BASE_URL + "/blog/";
const DELETE_BLOG = BASE_URL + "/blog/";
const SHOW_VSC = BASE_URL + "/showvsc/";
const SHOW_D_VSC = BASE_URL + "/showdvsc/";


export {
  GET_ALL_BLOGS,
  CREATE_BLOG,
  UPDATE_BLOG,
  DELETE_BLOG,
  SHOW_VSC,
  SHOW_D_VSC
};