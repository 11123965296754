import React, { useEffect, useState } from "react";

import * as XLSX from "xlsx/xlsx";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./admin_emails-Style.css";
import Button from "@mui/material/Button";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import axios from "axios";
import MessagePopup from "../../MessagePopup";
const { SEND_PROMOTIONALEMAILS_EVENT } = require("../../../api/emailAdmin");

const Admin_emails_template = ({ isAuth, a_email }) => {
  const editor = useRef(null);
  const [subject, setSubject] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [emailList, setEmailList] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const emailList = [];

      for (let cell in sheet) {
        // Iterate over all non-empty cells in the sheet
        if (cell[0] === "A" && sheet[cell].v !== undefined) {
          const email = sheet[cell].v.trim();
          // Add email to emailList if it is a valid email address
          if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            emailList.push(email);
          }
        }
      }

      setEmailList(emailList);
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleTextareaFocus = () => {
    setMessage("");
  };

  const handleTextareaChange = (value) => {
    console.log(subject)
    setMessage(value);
  };

  const sendEmails = async () => {
    if (subject !== "" && message !== "" && emailList.length > 0) {
      const data = {
        emails: emailList,
        subject: subject,
        html: message,
      };
      axios
        .post(SEND_PROMOTIONALEMAILS_EVENT, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setSubject("");
          setMessage("");
          setEmailList([]);
          setFile(null);
          setResp("Sended Successfully");
          setOpenPopUp(true);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          setResp("Some error occured");
          setOpenPopUp(true);
        });
    } else {
      setResp("Kindly add all the details");
      setOpenPopUp(true);
    }
  };

  return (
    <div>
      {a_email ? (
        <>
          <div className="main-page">
            <div className="email-template" id="email-template">
              <div className="email-template-data">
                <h2>Email Template</h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "20px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Subject"
                    className="subject"
                    value={subject}
                    onChange={handleSubjectChange}
                  />

                  <div>
                    <JoditEditor
                      className="message"
                      ref={editor}
                      value={message}
                      placeholder={message || "Enter Message"}
                      onClick={handleTextareaFocus}
                      onFocus={handleTextareaFocus}
                      onBlur={() => {
                        if (message === "") {
                          setMessage("Enter Message");
                        }
                      }}
                      tabIndex={1}
                      onChange={handleTextareaChange}
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "11.9rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      id="button-template-save"
                      onClick={sendEmails}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </div>

              <div className="upload-exl">
                <div>
                  <label htmlFor="fileInput">
                    <div className="circle">
                      <UploadRoundedIcon className="upload-icon" />
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="fileInput">
                    <div className="upload-button">
                      <Button
                        variant="contained"
                        component="span"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        Upload
                      </Button>
                    </div>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <MessagePopup
              open={openPopUp}
              message={resp}
              handleAlertClose={() => setOpenPopUp(!openPopUp)}
            />
          </div>
        </>
      ) : (
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
    </div>
  );
};

export default Admin_emails_template;
