import { Box, Button, InputBase, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { FormGroup } from "@mui/material";
import MessagePopup from "../MessagePopup";

const {GET_COURSES_TESTIMONIALS,
  CREATE_COURSES_TESTIMONIALS,
  UPDATE_COURSES_TESTIMONIALS,
  DELETE_COURSES_TESTIMONIALS, } = require("../../api/courses");

const Usestyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
  },
  inputsStyle: {
    margin: "20px 0px 0px 0px",
    backgroundColor: "whitesmoke",
    color: "black",
    padding: "10px",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "3px",
  boxShadow: 25,
  textAlign: "center",
  color: "black",
  p: 4,
  paddingLeft: "3rem",
  paddingRight: "3rem",
};

export default function CourseTestimonial() {
  const [openPopUp,setOpenPopUp] = useState(false);
  const [resp,setResp] = useState("");

  const classes = Usestyles();

  const [testimonials , setTestimonials] = useState([]);

  const [inputTestimonial , setInputTestimonial] = useState("");
  const [editTestimonial , setEditTestimonial] = useState("");

  const [testimonial , setTestimonial] = useState([]);
  const [avtar , setAvtar] = useState(testimonial.avtar);

  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // ALl Data request
  useEffect(() => {
    axios
      .get(GET_COURSES_TESTIMONIALS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTestimonials(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Edit box submit
  const handleEditSubmit = (e) => {
    const data = new FormData();
      data.append("testimonial" , editTestimonial);
      if(avtar)
      data.append("avtar" , avtar);
    const ID = testimonial._id;
    axios
      .put(UPDATE_COURSES_TESTIMONIALS+ID , data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Edited Successfully");
        setOpenPopUp(true);
        window.location.href = "/courses/testimonial";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenEdit(false);
  };

  const validationForm = () => {
    if (inputTestimonial.length < 1) return false;
    else return true;
  };
  // Create submit
  const handleCreateSubmit = (e) => {
    if (validationForm()) {
      const data = new FormData();
      data.append("testimonial" , inputTestimonial);
      data.append("avtar" , avtar);

      axios
        .post(CREATE_COURSES_TESTIMONIALS , data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setResp("Created Successfully");
          setOpenPopUp(true);
          setInputTestimonial("");
          window.location.href = "/courses/testimonial";
        })
        .catch((err) => {
          setResp("Something error occured");
          setOpenPopUp(true);
        });
      setOpenCreate(false);
    } else {
      setResp("All inputs are required");
      setOpenPopUp(true);
    }
  };

  // Delete submit function
  const deleteSubmit = () => {
    const ID = testimonial._id;
    axios
      .delete(DELETE_COURSES_TESTIMONIALS+ID, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/courses/testimonial";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };

  // Edit Pop Up
  const editbox = (
    <Box sx={style}>
      <Typography
        variant="h4"
        fontWeight="6400"
        textAlign="center"
        textTransform="uppercase"
      >
        Update This Testimonial
      </Typography>
      <FormGroup method="POST">
        <InputBase
          fullWidth
          className={classes.inputsStyle}
          defaultValue={testimonial.testimonial}
          label="Testimonial"
          onChange={(e) => setEditTestimonial(e.target.value)}
        />
        <Button
        variant="contained"
        component="label"
        style={{marginTop: '15px'}}
        >
          Upload avtar
          <input
            type="file"
            hidden
            onChange={(e)=>setAvtar(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleEditSubmit}
        style={{
          background: "#81fccf",
          fontSize: "18px",
          marginTop: "25px",
          padding: "10px",
        }}
      >
        Update 
      </Button>
    </Box>
  );

  // Create PopUp
  const createBox = (
    <Box sx={style}>
      <Typography variant="h4">Create a testimonial</Typography>
      <FormGroup method="POST">
        <InputBase
          placeholder="Testimonial"
          fullWidth
          value={inputTestimonial}
          className={classes.inputsStyle}
          onChange={(e) => setInputTestimonial(e.target.value)}
        />
        <Button
        variant="contained"
        component="label"
        style={{marginTop: '15px'}}
        >
          Upload Poster
          <input
            type="file"
            hidden
            onChange={(e)=>setAvtar(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleCreateSubmit}
        style={{
          background: "#81fccf",
          marginTop: "25px",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Create 
      </Button>
    </Box>
  );

  // Delete PopUp
  const deleteBox = (
    <Box sx={style}>
      <Typography variant="h5" marginBottom="5px">
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={deleteSubmit}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 150, cellClassName: "Cell-Col" },
    {
      field: "testimonial",
      headerName: "Testimonial",
      width: 350,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setTestimonial(params.row);
          setOpenEdit(true);
          setEditTestimonial(params.row.testimonial);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      // headerAlign: 'center',
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setTestimonial(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  // UI
  return (

        <div className={classes.container}>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">Courses Testimonials</Typography>
            <Button
              onClick={() => setOpenCreate(true)}
              size="medium"
              variant="contained"
              color="primary"
            >
              create
            </Button>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyCOntent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "purple",
                fontSize: "20px",
              },
            }}
          >
            <DataGrid
              rows={testimonials}
              className="table-box"
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>

          <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
            {createBox}
          </Modal>

          <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
            {editbox}
          </Modal>
          <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
            {deleteBox}
          </Modal>
        </div>
  );
}

 
// export default CourseTestimonial;