import { Box, Button, InputBase, Modal, Typography } from "@material-ui/core";
import JoditEditor from "jodit-react";
import React, { useRef, useState , useEffect} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { FormGroup } from "@mui/material";
import MessagePopup from "../MessagePopup";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DELETE_WORKEX, GET_WORKEX, POST_WORKEX, UPDATE_WORKEX } from "../../api/workex";
import { GET_COURSES } from "../../api/courseupload";
const {
  GET_ALL_EMAIL_EVENTS,
  SAVE_NEW_EMAIL_EVENT,
  UPDATE_EMAIL_EVENT,
  DELETE_EMAIL_EVENT,
} = require("../../api/email");

const WorkEx = () => {
  const editor = useRef(null);
  const [openCreate, setOpenCreate] = useState(false);
  const classes = Usestyles();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState([]);
  const [name, setName] = useState("");
  const [heading, setHeading] = useState("");
  const [button, setButton] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");
  const [course , setCourse] = useState("");

  const [editName, setEditName] = useState("");
  const [workExId , setWorkExId] = useState(null);
  const [editHeading, setEditHeading] = useState("");
  const [editButton, setEditButton] = useState("");
  const [editContent, setEditContent] = useState("");
  const [editLink, setEditLink] = useState("");
  const [courses  , setCourses] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  useEffect(() => {
    axios
    .get(GET_COURSES)
    .then(res => {
      const newCourses = res.data.data.map((course) => ({
        value: course._id,
        label: course.courseName
    }));
    setCourses(newCourses);
     
    })
  },[])
  // ALl Data request
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_WORKEX);
        setEvents(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [openCreate, openEdit, openDelete, openPopUp]);

  //Edit box submit
  const handleEditSubmit = (e) => {
    const data = {
      heading: editHeading,
      subHeading: editContent,
      buttonName: editButton,
      buttonLink: editLink,
      courseId: course
    };
    const ID = event._id;
    axios
      .patch(UPDATE_WORKEX + ID, data)
      .then((res) => {
        setResp("Edited Successfully");
        setOpenPopUp(true);
        setOpenEdit(false);
      })
      .catch((err) => {
        setResp("Some error occured");
        setOpenPopUp(true);
      });
  };
 
  const handleCourseChange = (e) => {
    setCourse(e.target.value);
  }

  // Create submit
  const handleCreateSubmit = (e) => {

      const data = {
        heading: heading,
        subHeading: content,
        buttonName: button,
        buttonLink: link,
        courseId: course
      }
        axios
        .post(POST_WORKEX, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setHeading("");
          setContent("");
          setLink("");
          setButton("");
          setCourse("");
          setResp("Created Successfully");
          setOpenPopUp(true);
        })
        .catch((err) => {
          console.log(err);
          setResp("Some error occured");
          setOpenPopUp(true);
        });
      setOpenCreate(false);
  };

  // Delete submit function
  const deleteSubmit = () => {
    const ID = event._id;
    axios
      .delete(DELETE_WORKEX + ID , {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
      })
      .catch((err) => {
        setResp(err.message);
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };

  // Edit Pop Up
  const editbox = (
    <Box
      className={classes.style}
      style={{ maxHeight: "100%", overflow: "auto" }}
    >
      <Typography variant="h4">Update This Instance</Typography>
      <FormGroup method="POST">
        <InputBase
          className={classes.inputsStyle}
          defaultValue={event.heading}
          label="Heading"
          onChange={(e) => setEditHeading(e.target.value)}
        />

<div
          style={{
            border: "none",
            width: "100%",
            margin: "30px -7px 10px -7px",
            padding: "",
          }}
        >
          <JoditEditor
            ref={editor}
            value={editContent}
            config={{
                  placeholder: "Write The Subheading",
                  allowTabNavigation: true
                }}
            tabIndex={1}
            onBlur={(value) => {
                  setEditContent(value);
                }}
          />
        </div>

        <InputBase
          className={classes.inputsStyle}
          defaultValue={event.button}
          value={editButton}
          label="Button Name"
          onChange={(e) => setEditButton(e.target.value)}
        />
        <InputBase
          className={classes.inputsStyle}
          defaultValue={event.link}
          value={editLink}
          label="Button Link"
          onChange={(e) => setEditLink(e.target.value)}
        />
        <FormControl fullWidth style={{marginTop:'10px'}}>
        <InputLabel id="demo-simple-select-label">Course</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={course}
          label="Add Course"
          onChange={handleCourseChange}
        >
        {courses.map((course,index) => (
          <MenuItem value={course.value}>{course.label}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
      </FormGroup>
      <Button
        onClick={handleEditSubmit}
        style={{
          background: "#81fccf",
          fontSize: "18px",
          marginTop: "25px",
          padding: "10px",
        }}
      >
        Update this Instance
      </Button>
    </Box>
  );

  // Create PopUp
  const createBox = (
    <Box
      className={classes.style}
      style={{ maxHeight: "100%", width: "600px", overflow: "auto" }}
    >
      <Typography variant="h4">Create Another Instance</Typography>
      <FormGroup method="POST">
        <InputBase
          placeholder="Heading"
          value={heading}
          className={classes.inputsStyle}
          onChange={(e) => setHeading(e.target.value)}
        />

        <div
          style={{
            border: "none",
            width: "100%",
            margin: "30px -7px 10px -7px",
            padding: "",
          }}
        >
          <JoditEditor
            ref={editor}
            value={content}
            config={{
                  placeholder: "Write The Subheading",
                  allowTabNavigation: true
                }}
            tabIndex={1}
            onBlur={(value) => {
                  setContent(value);
                }}
          />
        </div>
        <InputBase
          placeholder="Button Name"
          value={button}
          className={classes.inputsStyle}
          onChange={(e) => setButton(e.target.value)}
        />
        <InputBase
          placeholder="Link"
          value={link}
          className={classes.inputsStyle}
          onChange={(e) => setLink(e.target.value)}
        />
         <FormControl fullWidth style={{marginTop:'10px'}}>
        <InputLabel id="demo-simple-select-label">Course</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={course}
          label="Course"
          onChange={handleCourseChange}
        >
        {courses.map((course,index) => (
          <MenuItem value={course.value}>{course.label}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
      </FormGroup>
      <Button
        onClick={handleCreateSubmit}
        style={{
          background: "#81fccf",
          marginTop: "25px",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Create this Instance
      </Button>
    </Box>
  );

  const deleteBox = (
    <Box className={classes.deletecontainer}>
      <Box className={classes.deletebox}>
        <Typography>Are you sure, you want to delete?</Typography>
        <Box>
          <Button
            onClick={deleteSubmit}
            className={classes.btn}
            style={{ marginRight: "10px" }}
          >
            Yes
          </Button>
          <Button onClick={() => setOpenDelete(false)} className={classes.btn}>
            No
          </Button>
        </Box>
      </Box>
    </Box>
  );

  // Columns in data grid
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 150,
      cellClassName: "Cell-Col",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "heading",
      headerName: "Heading",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "subHeading",
      headerName: "Sub Heading",
      width: 350,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "buttonName",
      headerName: "Button Name",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "buttonLink",
      headerName: "Button Link",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "courseId",
      headerName: "CourseId",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setEvent(params.row);
          setOpenEdit(true);
          setEditHeading(params.row.heading);
          setEditContent(params.row.subHeading);
          setEditButton(params.row.buttonName);
          setEditLink(params.row.buttonLink);
          setCourse(params.row.courseId);
          setWorkExId(params.row._id)
        };
        return (
          <Button
            variant="contained"
            color="primary=="
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setEvent(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  // UI
  return (
    <div>
      {true ? (
        <div className={classes.container}>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">WorkEx</Typography>
            <Button
              onClick={() => setOpenCreate(true)}
              size="medium"
              variant="contained"
              color="primary"
            >
              create
            </Button>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyContent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "purple",
                fontSize: "20px",
              },
            }}
          >
            {events.length > 0 ? (
              <DataGrid
                rows={events}
                className="table-box"
                getRowId={(row) => row._id}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
              />
            ) : (
              <p>Enter The Data...</p> 
            )}
          </Box>
          <MessagePopup
            open={openPopUp}
            message={resp}
            handleAlertClose={() => setOpenPopUp(!openPopUp)}
          />
          <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
            {createBox}
          </Modal>

          <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
            {editbox}
          </Modal>
          <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
            {deleteBox}
          </Modal>
        </div>
      ) : (
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
    </div>
  );
}
const Usestyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
    scrollBehavior: "auto",
  },
  inputsStyle: {
    margin: "20px 15px 0px -8px",
    backgroundColor: "whitesmoke",
    color: "black",
    padding: "10px",
    width: "100%",
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 400,
    p: 4,
    scrollBehavior: "auto",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      p: 3,
    },
  },
  deletecontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor:'yellow'
  },
  deletebox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    position: "absolute",
    top: "30%",
    borderRadius: "10px",
    padding: "30px",
  },
  btn: {
    backgroundColor: "#8dffcc",
    marginTop: "10px",
  },
});

export default WorkEx