// Loader.js

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
