const { BASE_URL } = require("./client");

const GET_DAILY_GOALS = BASE_URL +"/api/dailygoals";
const CREATE_DAILY_GOAL = BASE_URL +"/api/dailygoals/add";
const EDIT_DAILY_GOAL = BASE_URL +"/api/dailygoals/edit/";
const DELETE_DAILY_GOAL = BASE_URL +"/api/dailygoals/delete/";


module.exports = {
    GET_DAILY_GOALS,
    CREATE_DAILY_GOAL,
    EDIT_DAILY_GOAL,
    DELETE_DAILY_GOAL
}