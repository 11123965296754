import React, { useState, useEffect } from 'react';
import { DataGrid , GridToolbarQuickFilter } from '@mui/x-data-grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Box,
  IconButton, DialogContentText, 
} from '@mui/material';
import shortid from 'short-uuid';
import axios from 'axios';
import { GET_USER_DETAILS } from '../../api/userdetails';
import { GET_COURSES } from '../../api/courseupload';
import { GET_CERTIFICATES, SAVE_CERTIFICATE } from '../../api/certificate';
import MessagePopup from '../MessagePopup';
import UploadIcon from '@mui/icons-material/UploadFile';
import * as XLSX from 'xlsx';
import { ENROLLED_USER, ENROLLED_USER_ACCESS, PROGRAM_CERTIFICATE_UPLOAD , WORKEX_CERTIFICATE_UPLOAD} from '../../api/enrolleduser';
import { PDFDocument, rgb } from 'pdf-lib';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadDialog from './UploadDialog';
import Checkbox from '@mui/material/Checkbox';
const Certificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [loadingCertificates, setLoadingCertificates] = useState(true);
  const [loading , setLoading] = useState(false);
//   const [open, setOpen] = useState(false);
  const [openPopUp , setOpenPopUp] = useState(false);
  const [refresh , setRefresh] = useState(false);
  const [message , setMessage] = useState('');
  const [programCertificate , setProgramCertificate] = useState(null);
  const [newCertificate, setNewCertificate] = useState({
    userName: '',
    courseName: '',
    serielNumber: ''
  });
  const [open, setOpen] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event , params) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);

   
       axios.post(ENROLLED_USER_ACCESS + params.row.userId, {
            accessWorkExUpdate: {
                courseId: params.row.courseId, 
                access: newChecked
            }
        }).then(res => {
          setRefresh(true);
          setOpenPopUp(true);
          setMessage('Access updated successfully');
        }).catch(err => {
          console.error(err);
        })
        
};
  const handleClicksOpen = () => {
    setOpen(true);
  };

  const handlesClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const fetchCertificates = () => {
      setLoadingCertificates(true);
      axios.get(ENROLLED_USER)
        .then(response => {
            setCertificates(response.data.data.reverse());
            setLoadingCertificates(false);
            setRefresh(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingCertificates(false);
        });
    };

    fetchCertificates();
  },[openPopUp , refresh]);


  const handleAlertClose = () => {
    setOpenPopUp(false);
  }
 ;

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(certificates);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Certificates');
    XLSX.writeFile(wb, 'Enrolled-Users.xlsx');
  };


  const handleProgramCertificateUpload = (e, userId, courseId , courseName , sN) => {
    setLoading(true);
     const file = e.target.files[0];

      const formData = new FormData();
      formData.append('programCertificate', file);
      formData.append('certId' , sN);

      axios
      .post(`${PROGRAM_CERTIFICATE_UPLOAD}${userId}/${courseId}`, formData)
      .then(res => {
        console.log(res)
        setMessage('Successfully uploaded program certificate');
        setOpenPopUp(true);
        setLoading(false);
        handlesClose()
      })
      .catch(err => {
        console.log(err);
        setMessage('Failed to upload program certificate');
        setOpenPopUp(true);
      });

  };
  const handleWorkexCertificateUpload = (e, userId, courseId , courseName , sN) => {
    setLoading(true);
     const file = e.target.files[0];

      const formData = new FormData();
      formData.append('workExCertificate', file);
      formData.append('certId' , sN);

      axios
      .post(`${WORKEX_CERTIFICATE_UPLOAD}${userId}/${courseId}`, formData)
      .then(res => {
        console.log(res)
        setMessage('Successfully uploaded program certificate');
        setOpenPopUp(true);
        setLoading(false);
        handlesClose()
      })
      .catch(err => {
        console.log(err);
        setMessage('Failed to upload program certificate');
        setOpenPopUp(true);
      });

  };
  const columns = [
    { field: 'userName', headerName: 'User Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'courseName', headerName: 'Course Name', width: 200 },
    { field: 'date', headerName: 'Date Of Enroll', width: 200 },
    {
      field: 'programCertificate',
      headerName: 'Program Certificate',
      width: 100,
      renderCell: (params) => (
        <Box
            sx={{
                width: '100%',
                display: 'flex', 
                justifyContent: 'space-evenly',
                alignItems: 'center',
                background: params.row.programCertificate.status === "requested" && '#6a58c6',
                color: '#fff'
            }}
        >
        <UploadDialog params={params} handleProgramCertificateUpload={handleProgramCertificateUpload} type={'program'}/>
        {params.row.programCertificate.status === "uploaded" && <CheckCircleIcon sx={{ color: 'green' }} />}  
                  
        </Box>

      ),
    },
    {
      field: 'project',
      headerName: 'Project',
      width: 100,
      renderCell: (params) => (
        <Box
        sx={{
            width: '100%',
            display: 'flex', 
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }}
    >
    <label>
      <IconButton
        disabled={params.row.projectCertificate.status === "not completed"}
        sx={{
          color: (params.row.projectCertificate.status === "requested" && '#6a58c6') || (params.row.projectCertificate.status === "uploaded" && 'green')
        }}
        component="span"
      >
        <UploadIcon />
      </IconButton>
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        hidden
        onChange={(e) => handleProgramCertificateUpload(e, params.row.userId, params.row.courseId, params.row.courseName)}
      />
    </label>
    {params.row.projectCertificate.status === "uploaded" && <CheckCircleIcon sx={{ color: 'green' }} />}
  </Box>
      ),
    },
    {
      field: 'workEx',
      headerName: 'WorkEx',
      width: 100,
      renderCell: (params) => (
        <Box
            sx={{
                width: '100%',
                display: 'flex', 
                justifyContent: 'space-evenly',
                alignItems: 'center',
                background: params.row.workExCertificate.status === "requested" && '#6a58c6',
                color: '#fff'
            }}
        >

        <UploadDialog params={params} handleWorkexCertificateUpload={handleWorkexCertificateUpload} type={'workex'}/>
        {params.row.workExCertificate.status === "uploaded" && <CheckCircleIcon sx={{ color: 'green' }} />}  
                  
        </Box>
      ),
    },
    {
      field: 'workExAccess',
      headerName: 'WorkEx Access',
      width: 150,
      renderCell: (params) => (
        <Box
            sx={{
                width: '100%',
                display: 'flex', 
                justifyContent: 'space-evenly',
                alignItems: 'center',
                color: '#fff'
            }}
        >
         <Checkbox
                checked={params.row.workExAccess}
                onChange={(e) => handleCheckboxChange(e , params)}
            />
        </Box>
      ),
    },
  ];

  const rows = certificates.map((certificate) => ({
    ...certificate,
    id: certificate._id
  }));

  const loadings = loadingCertificates;

  return (
    <div style={{ height: '100vh' }}>
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding : '10px'
        }}
    >
    <h2 style={{textAlign: 'left' }}>Admin For Certificates</h2>
    <Button
        variant="outlined"
        onClick={handleExportExcel}
    >
        Extract Excel
    </Button>
    </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="400px">
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ height: '100%', width: '100%', marginTop: 20 }}>
          <DataGrid 
              rows={rows} 
              columns={columns} 
              pageSize={16} 
              components={{
              Toolbar: GridToolbarQuickFilter
            }}

            />
        </div>
      )}
     
      <MessagePopup
        handleAlertClose={handleAlertClose}
        open={openPopUp}
        message={message}
      />
    </div>
  );
};

export default Certificates;
