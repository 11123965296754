const { BASE_URL } = require("./client");

const GET_ALL_EVENTS = BASE_URL + "/watchnow";
const NEW_EVENT = BASE_URL + "/watchnow/create";
const EDIT_EVENT = BASE_URL + "/watchnow/";
const DELETE_EVENT = BASE_URL + "/watchnow/";
const SHOW_W_VSC = BASE_URL + "/watchnow/showvsc/";
const SHOW_W_D_VSC = BASE_URL + "/watchnow/showdvsc/";

export { GET_ALL_EVENTS, NEW_EVENT, EDIT_EVENT, DELETE_EVENT , SHOW_W_VSC ,SHOW_W_D_VSC };
