const { BASE_URL } = require("./client");

const GET_PROMOCODES = BASE_URL+ "/api/promocode";
const POST_PROMOCODES = BASE_URL + "/api/promocode/add";
const DELETE_PROMOCODES = BASE_URL + "/api/promocode/";
const UPDATE_PROMOCODE = BASE_URL +'/api/promocode/update/';
const ENABLE_PROMOCODE = BASE_URL +"/api/promocode";
const ENABLE_SINGLE_PROMOCODE = BASE_URL +'/api/promocode/enable/';
const DISABLE_SINGLE_PROMOCODE = BASE_URL +'/api/promocode/disable/';
const GET_COURSES = BASE_URL + '/api/courses/get';


export {
    GET_PROMOCODES ,
    POST_PROMOCODES,
    DELETE_PROMOCODES,
    UPDATE_PROMOCODE,
    ENABLE_PROMOCODE,
    ENABLE_SINGLE_PROMOCODE,
    DISABLE_SINGLE_PROMOCODE,
    GET_COURSES
};