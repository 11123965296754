const { BASE_URL } = require("./client");


const GET_COURSES = BASE_URL + '/courseupload';
const GET_COURSE = BASE_URL +'/program/courses/';

const GET_MODULES = BASE_URL + '/courseupload';
const GET_PROJECTS = BASE_URL + '/courseupload/project/';
const POST_COURSE = BASE_URL + '/courseupload/post/course';
const POST_MODULE = BASE_URL + '/courseupload/post/module';
const DELETE_COURSE = BASE_URL + '/courseupload/';
const DELETE_MODULE = BASE_URL + '/courseupload/module/';
const UPDATE_COURSE = BASE_URL + '/courseupload/update/course/';
const VIDEO_UPLOAD = BASE_URL + '/courseupload/video';
const PDF_UPLOAD = BASE_URL + '/courseupload/pdf';
const PROJECT_UPLOAD = BASE_URL + '/courseupload/project';
const QUESTIONS_UPLOAD = BASE_URL + '/courseupload/questions';
const GET_VIDEO = BASE_URL + '/courseupload/video/';
const GET_SINGLE_VIDEO = BASE_URL + '/courseupload/video/single/';
const GET_SINGLE_PDF = BASE_URL + '/courseupload/pdf/single/';
const GET_SINGLE_MODULE = BASE_URL + '/courseupload/module/single/';
const GET_PDF = BASE_URL + '/courseupload/pdf/';
const GET_QUESTIONS = BASE_URL + '/courseupload/questions/';
const DELETE_VIDEO = BASE_URL + '/courseupload/video/';
const DELETE_PDF = BASE_URL +'/courseupload/pdf/';
const DELETE_QUESTIONS = BASE_URL +'/courseupload/questions/';
const DELETE_PROJECT= BASE_URL +'/courseupload/project/delete/';
const GO_LIVE = BASE_URL + '/courseupload/golive/';
const FILE_UPDATE = BASE_URL + '/courseupload/updatefile/';
const CANCEL_LIVE = BASE_URL + '/courseupload/cancelgolive/';
const GET_PROJECT = BASE_URL + '/courseupload/project/fetch/';
const GET_USER = BASE_URL + "/user/";
const POST_PRIORITY = BASE_URL + '/courseupload/priority/';
const POST_PRIORITY_VIDEO = BASE_URL + '/courseupload/priority/video/';
const POST_PRIORITY_PDF = BASE_URL + '/courseupload/priority/pdf/';
const POST_PRIORITY_MODULE = BASE_URL + '/courseupload/priority/module/';
const UPDATE_PROJECT = BASE_URL +'/courseupload/edit/project/';
const UPDATE_VIDEO_MODULE = BASE_URL + '/courseupload/edit/video/module/';
const UPDATE_PDF_MODULE = BASE_URL + '/courseupload/edit/pdf/module/';
const UPDATE_MODULE = BASE_URL +'/courseupload/edit/module/'
export { 
    GET_COURSE,
    POST_COURSE , 
    POST_MODULE , 
    GET_COURSES , 
    GET_MODULES , 
    DELETE_COURSE , 
    UPDATE_COURSE , 
    DELETE_MODULE , 
    VIDEO_UPLOAD,
    PDF_UPLOAD,
    GET_VIDEO,
    GET_PDF,
    DELETE_VIDEO,
    DELETE_PDF,
    GO_LIVE,
    FILE_UPDATE,
    CANCEL_LIVE,QUESTIONS_UPLOAD,GET_QUESTIONS,DELETE_QUESTIONS,PROJECT_UPLOAD,GET_PROJECTS, DELETE_PROJECT , GET_PROJECT , GET_USER,
    POST_PRIORITY ,
    POST_PRIORITY_VIDEO,
    GET_SINGLE_VIDEO,
    UPDATE_PROJECT ,
    POST_PRIORITY_PDF,
    GET_SINGLE_PDF,
    GET_SINGLE_MODULE,
    POST_PRIORITY_MODULE ,
    UPDATE_VIDEO_MODULE ,
    UPDATE_PDF_MODULE,
    UPDATE_MODULE
};