const { BASE_URL } = require("../client");

const headingId =  "633a69d5456a4779c41404bb";

const UPDATE_B5_MAINPAGE = BASE_URL + "/main/b5/";
const GET_B5_MAINPAGE = BASE_URL + "/main/b5/";
const DELETE_B5_MAINPAGE = BASE_URL + "/main/b5/";
const CREATE_B5_MAINPAGE = BASE_URL + "/main/b5/";

export {
    UPDATE_B5_MAINPAGE,
    GET_B5_MAINPAGE,
    DELETE_B5_MAINPAGE,
    CREATE_B5_MAINPAGE,
    headingId
}