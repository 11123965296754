import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const YourComponent = ({ params, handleProgramCertificateUpload , type , handleWorkexCertificateUpload }) => {
  const [open, setOpen] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadChange = (e) => {
    if(type==="program"){
      handleProgramCertificateUpload(e, params.row.userId, params.row.courseId, params.row.courseName, serialNumber);
    }
    if(type==="workex"){
      handleWorkexCertificateUpload(e, params.row.userId, params.row.courseId, params.row.courseName, serialNumber);

    }
    handleClose();
  };

  return (
    <>
      <IconButton
        // disabled={type === "program" ? params.row.programCertificate.status === "not completed" :  params.row.workExCertificate.status === "not completed"}
        sx={{
          color: ((type === "program" ? params.row.programCertificate.status === "requested" :params.row.workExCertificate.status === "requested")  && '#fff') || ((type === "program" ? params.row.programCertificate.status === "uploaded" :params.row.workExCertificate.status === "uploaded") && 'green')
        }}
        onClick={handleClickOpen}
      >
        <UploadIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Program Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please upload the program certificate and enter the serial number.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Serial Number"
            type="text"
            fullWidth
            variant="standard"
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
          />
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            hidden
            onChange={handleUploadChange}
            id="upload-input"
          />
          <label htmlFor="upload-input">
            <Button variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default YourComponent;
