import React from "react";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";

const { GOGGLE_LOGIN_API , EMAIL_SUGGESTION} = require("../../api/auth");

function LoginwithGoogle({ PriorityAnswer, setIsAuth , setResp }) {

  const navigation=useNavigate();
  const responseSuccessGoogle = (response) => {
    axios
      .post(GOGGLE_LOGIN_API, { tokenId: response.tokenId })
      .then((response) => {
        if(response.data.user.isAdmin === true){
          window.localStorage.setItem("name", response.data.user.name);
          window.localStorage.setItem("email", response.data.user.email);
          window.localStorage.setItem("id", response.data.user._id);
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem("isAuth", true);
          setIsAuth(true);
          sendAnswer();
          window.location.href="/"
        }
        else{
          setResp("You are not allowed! 🚫")
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  //send priority answer to email on succes login of user
  const sendAnswer=()=>{
    if(PriorityAnswer!==undefined){
      axios.post(EMAIL_SUGGESTION,{description:PriorityAnswer},{headers
        :{authorization:window.localStorage.getItem('token')}})
        .then(res=>{})
        .catch((err) => {
          console.log(`${err}`);
        });
    } else {
    }
  };

  //gogole login error handler
  const responseErrorGoogle = (response) => {
    // console.log(response);
  };

  return (
    <GoogleLogin
      clientId={process.env.OAUTH_CLIENT_ID}
      buttonText="Login with Google"
      onSuccess={responseSuccessGoogle}
      onFailure={responseErrorGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
}

export default LoginwithGoogle;
