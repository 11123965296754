import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import logo from './Logo.jpg';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {CiDiscount1} from 'react-icons/ci';
import { AdminBoardData } from './AdminBoardData';
import {Link , useNavigate , useLocation , useSearchParams} from 'react-router-dom';
import CourseUpload from '../CourseUpload/index';
import PromoCode from '../PromoCode/index';
import ProjectUploads from '../ProjectUploads/index';
import welcome from './Welcome.png';
import Fade from '@mui/material/Fade';
import CaseStudy from '../CaseStudy';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CertLandingComponent from '../CertLandingComponent';


const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor:'#000833'
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function AdminBoard() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const setUrl = (url) => {
    navigate(`?url=${url}`);
  };

  const getUrlQueryParam = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('url');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar  open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Board
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader style={{backgroundColor:'#000833' }}>
        <div style={{width:'100%' , display:'flex' , flexDirection:'column'}}>
        <img src={logo} style={{margin:'auto' , width:'90px'}}/>
        </div>
        
        <IconButton onClick={handleDrawerClose} >
            {theme.direction === 'ltr' ? <ChevronLeftIcon style={{color:'#fff'}}/> : <ChevronRightIcon />}
        </IconButton>
        
          
          
        </DrawerHeader>
        <List style={{backgroundColor:'#000833', height:'100%'}}>
          
          {AdminBoardData.map((text, index) => (
            <ListItem key={text.name} disablePadding style={{color:'#fff'}}>
              <ListItemButton 
              onClick={()=> setUrl(text?.url)}
              style={{color:'#fff' ,width:'100%', display:'flex' , justifyContent:'space-between'}}>
                <ListItemIcon>
                  {(index === 0) && <RocketLaunchIcon style={{color:'#fff'}}/>}
                  {(index === 1) && <CloudUploadIcon style={{color:'#fff'}}/>}
                  {(index === 2) && <CiDiscount1  style={{color:'#fff'}}/>}
                  {(index === 3) && <MenuBookIcon  style={{color:'#fff'}}/>}
                  {(index === 4) && <CloudUploadIcon style={{color:'#fff'}}/>}
                </ListItemIcon>
                <ListItemText primary={text.name} />
                <ListItemIcon>
                 {index!==0 &&<ChevronRightIcon style={{color:'#fff'}}/>}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {!getUrlQueryParam() && (
          <Fade in={true} timeout={2000}>
            <img src={welcome} style={{ width: '100%', margin: 0 }} />
          </Fade>
        )}
        {getUrlQueryParam() === 'courseupload' && <CourseUpload />}
        {getUrlQueryParam() === 'promocode' && <PromoCode />}
        {getUrlQueryParam() === 'projects' && <ProjectUploads />}
        {getUrlQueryParam() === 'courselanding' && <CertLandingComponent />}
      </Main>
    </Box>
  );
}