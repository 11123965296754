const { BASE_URL } = require("./client");

const GET_ALL_LEARN_EVENTS = BASE_URL + "/learn";
const SAVE_NEW_LEARN_EVENT = BASE_URL + "/learn/add";
const UPDATE_LEARN_EVENT = BASE_URL + "/learn/";
const DELETE_LEARN_EVENT = BASE_URL + "/learn/";

export {
  GET_ALL_LEARN_EVENTS,
  SAVE_NEW_LEARN_EVENT,
  UPDATE_LEARN_EVENT,
  DELETE_LEARN_EVENT,
};
