
import { Box, Button, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MessagePopup from "../MessagePopup";
import AddDialog from "./AddDialog";
import { DELETE_DAILY_GOAL, GET_DAILY_GOALS } from "../../api/dailygoals";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

const DailyGoals = ({isAuth , setIsAuth}) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");const [openDelete, setOpenDelete] = useState(false)
  const [dailyGoals, setdailyGoals] = useState([
  ]);
  const [open, setOpen] = React.useState(false);
  const [added , setAdded] = useState(false);
  const [openEdit, setOpenEdit] = useState('');
  const [taskDetails , setTaskDetails] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    axios
      .get(GET_DAILY_GOALS)
      .then((res) => {
        const dataWithIds = res.data.data.map((row, index) => ({
            ...row,
            id: index,
          }));
        setdailyGoals(dataWithIds.reverse());
        setAdded(false);
        setOpenEdit(false);
        setResp("Added Daily Goal")
      })
      .catch((err) => {
        console.log(err);
      });
  },[openDelete,openPopUp,added,openEdit]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `APPLYCOURSELEADS_${Date.now()}`;

  const exportToCSV = (dailyGoals, fileName) => {
    const ws = XLSX.utils.json_to_sheet(dailyGoals);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const columns = [
      
    { field: "id", headerName: "ID", width: 20, cellClassName: "Cell-Col" ,headerClassName: "column-header",},
    { field: "_id", headerName: "DID", width: 200, cellClassName: "Cell-Col" ,headerClassName: "column-header",},
    { 
        field: "date", 
        headerName: "Date", 
        width: 200, 
        cellClassName: "Cell-Col",
        headerClassName: "column-header", 
    },
    { 
      field: "whichTag", 
      headerName: "Task", 
      width: 200, 
      cellClassName: "Cell-Col",
      headerClassName: "column-header", 
      renderCell: (params) => {
        if(params.row.whichTag === 'Blog'){
          setTaskDetails(params.row.blog)
        } 
        else if(params.row.whichTag === 'Watchnow'){
          setTaskDetails(params.row.watchnow);
        }
        else if(params.row.whichTag === 'Casestudy'){
          setTaskDetails(params.row.casestudy);
        }
        return (
          <div>
            <Button onClick={handleClickOpen}>
              {params.row.whichTag}
            </Button>
            
          </div>
        );
      }
    },
    {
        field: "goal", 
        headerName: "Goal", 
        width: 200, 
        cellClassName: "Cell-Col",
        headerClassName: "column-header", 
    },{
      field: "tag", 
      headerName: "Tag", 
      width: 200, 
      cellClassName: "Cell-Col",
      headerClassName: "column-header", 
  },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: "Cell-Col",
      headerClassName: "column-header", 
      width: 100,
      getActions: (params) => {
        return [
          <AddDialog btn = 'edit' setAdded={setAdded} editdata={params.row} />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={()=> {
              axios
              .delete(DELETE_DAILY_GOAL+params.row._id)
              .then(res => {
                setAdded(true);
              })
              .catch(err => {
                console.log(err);
              })
              setOpenDelete(true);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <>
        {isAuth? 
        (
            <div>
      <Box display="flex" justifyContent="space-between" padding="10px">
        <Typography variant="h5">Daily Goals</Typography>
        
        <div className="Dialog-Download-btn"> 
        <AddDialog setAdded={setAdded}/>
        <Button
          margin= "10px"
          onClick={(e) => exportToCSV(dailyGoals, fileName)}
          size="small"
          variant="contained"
          color="primary"
        >
          Download Data
        </Button>
        </div>
        
      </Box>
      <Box
        sx={{
          height: 800,
          width: "100%",
          "& .column": {
            fontSize: "16px",
            justifyCOntent: "center",
            textAlign: "center",
          },
          "& .column-header": {
            color: "purple",
            fontSize: "20px",
          },
        }}
      >
        <DataGrid
          rows={dailyGoals}
          className="table-box"
          getRowId={(row) => row._id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => {
          // setAdded(false);
          setOpenPopUp(!openPopUp)
          }}
      />
    </div>
        ): (
            <div>Not Authorised</div>
        )
        
        }
        <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{taskDetails?.title}</DialogTitle>
            </Dialog>
    </>
    
  );
};



export default DailyGoals;
