const { BASE_URL } = require("./client");

const GET_COURSE = BASE_URL + '/api/applycourse';
const GET_BROCHURE = BASE_URL + '/api/brochure/getbrochure';
const GET_SINGLE_COURSE = BASE_URL +'/program/courses/';
export {
    GET_COURSE,
    GET_SINGLE_COURSE,
    GET_BROCHURE
};
