import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@material-ui/core";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
const DOMAIN = process.env.REACT_APP_DOMAIN;

const ContactTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${DOMAIN}/api/getPartner`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  
  const handleDownload = (filePath) => {
    // Trigger download from the backend
    window.open(`${DOMAIN}/files/${filePath}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Full Name</TableCell>
            <TableCell>Organization</TableCell>
            <TableCell>Designation</TableCell>
            <TableCell>Contact No.</TableCell>
            <TableCell>Mail ID</TableCell>
            <TableCell>MarkoKnow Partner</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>LinkedIn ID</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row._id}>
              <TableCell>{row.fullName}</TableCell>
              <TableCell>{row.organization}</TableCell>
              <TableCell>{row.designation}</TableCell>
              <TableCell>{row.contactNo}</TableCell>
              <TableCell>{row.mailId}</TableCell>
              <TableCell>{row.MarkoKnowPartner}</TableCell>
              <TableCell>{row.details}</TableCell>
              <TableCell>{row.linkedinId}</TableCell>
              <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <Button onClick={() => handleDownload(row.filePath)}>
                  <DownloadIcon/>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContactTable;
