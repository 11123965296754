import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Divider from '@mui/material/Divider';
import MessagePopup from "../MessagePopup";
import {Typography} from '@mui/material';
import { makeStyles } from "@material-ui/core";
import Modal from '@mui/material/Modal'


import axios from 'axios';
import './index.css';


import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import AddDialog from './AddDialog';
import { DELETE_PROMOCODES, ENABLE_PROMOCODE, ENABLE_SINGLE_PROMOCODE, GET_PROMOCODES , DISABLE_SINGLE_PROMOCODE} from '../../api/promocode';



const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#000833' : '#000833',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#000833',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));



export default function PromoCode() {
  const classes = u();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [checked , setChecked] = React.useState(false);
  const [promoData , setPromoData] = React.useState([]);
  const [added , setAdded] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [resp, setResp] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteid , setDeleteId] = React.useState(null);
  const [pickedPromoData , setPickedPromoData] = React.useState([]);
  const [openAddDialog, setOpenAddDialog] = React.useState(false);

const handleOpenAddDialog = () => {
  setOpenAddDialog(true);
};

const handleCloseAddDialog = () => {
  setOpenAddDialog(false);
};


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `PromoCodes_${Date.now()}`;


  const exportToCSV = (promoData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(promoData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleChecked = async() => {
    const new_data={
      active : checked
    }
    await axios
    .patch(ENABLE_PROMOCODE , new_data)
    .then(res=> {
      setResp(checked!==true?"All Codes Are Active Now":"Deactivated Codes");
      setOpenPopUp(true);
    })
    .catch(err => {
      setResp("Some Error Occured");
      setOpenPopUp(true);
    })
  }
  const handleSingleChecked = (active , id) => {
      if(!active){
        axios
        .patch(ENABLE_SINGLE_PROMOCODE+id)
        .then(res => {
          axios
    .get(GET_PROMOCODES)
    .then(res => {
      const updatedData =res.data.data.map((promo) => ({
        ...promo,
        id: promo._id,
      }));
      setPromoData(updatedData);
      setAdded(false);
      const promoDataActive = res.data.data;
        const allActive = promoDataActive.every(promo => promo.active);
        setChecked(allActive);
    })
    .catch(err=> {
      console.log(err)
    })
        })
        .catch(err => {
          console.log(err);
        })
      } 
      if(active) {
          axios
          .patch(DISABLE_SINGLE_PROMOCODE+id)
          .then(res => {
            axios
    .get(GET_PROMOCODES)
    .then(res => {
      const updatedData =res.data.data.map((promo) => ({
        ...promo,
        id: promo._id,
      }));
      setPromoData(updatedData);
      setAdded(false);
      const promoDataActive = res.data.data;
        const allActive = promoDataActive.every(promo => promo.active);
        setChecked(allActive);
    })
    .catch(err=> {
      console.log(err)
    })
          })
          .catch(err => {
            console.log(err);
          })
        
      }     
     
  }

  React.useEffect(() => {
    axios
    .get(GET_PROMOCODES)
    .then(res => {
      const updatedData =res.data.data.map((promo) => ({
        ...promo,
        id: promo._id,
      }));
      setPromoData(updatedData);
      setAdded(false);
        const promoDataActive = res.data.data;
        const allActive = promoDataActive.every(promo => promo.active);
        setChecked(allActive);
    })
    .catch(err=> {
      console.log(err)
    })
    
    
  }, [added,openPopUp])
  
  const handleAdded = (value , resp , open) => {
    setAdded(value);
    setResp(resp);
    setOpenPopUp(open);
  }

  const handleDeleteClick = () => {
    axios
    .delete(DELETE_PROMOCODES+deleteid)
    .then(res=> {
      setResp("Deleted Successfully");
      setOpenPopUp(true);
    })
    .catch(err=> {
      setResp("Something error occured");
      setOpenPopUp(true);
    })
    setOpenDelete(false);
  };
  const deleteBox = (
    <Box className={classes.style}>
      <Typography variant="h5" style={{ marginBottom: "5px" }}>
        Are you sure want to delete ?
      </Typography>
      <Box>
        <Button
          onClick={handleDeleteClick}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          Yes
        </Button>
        <Button
          onClick={() => setOpenDelete(false)}
          style={{ background: "black", color: "white", margin: "3px" }}
        >
          No
        </Button>
      </Box>
    </Box>
  );

  const columns = [
    { field: 'created', headerName: 'CREATED ON',type: 'string', width: 200,cellClassName: 'custom-cell' },
    { field: 'tag', headerName: 'Tag',type: 'string', width: 200,cellClassName: 'custom-cell' },
    {
      field: 'firstpurchase',
      headerName: 'FIRST PURCHASE',
      type: 'string',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'custom-cell'
    },
    {
      field: 'code',
      headerName: 'CODE',
      type: 'string',
      width: 150,
      cellClassName: 'custom-cell'
    },
    {
      field: 'details',
      headerName: 'DETAILS',
      width: 150,
      cellClassName: 'custom-cell',
      renderCell: (params) => {
        const { maximumdiscountvalue, minimumcartvalue, discountpercentage } =
          params.value || {};
        return (
          <div>
            <p>Max Discount: {maximumdiscountvalue}</p>
            <p>Min cart :{minimumcartvalue}</p>
            <p>Discount:{`${discountpercentage*100}%`}</p>
          </div>
        );
      },
    },
    
    {
      field: 'validTill',
      headerName: 'VALID TILL',
      type:'string',
      width:150,
      height: 150,
      cellClassName: 'custom-cell'
    },
    {
      field:'usedCount',
      headerName:'USED COUNT',
      width:150,
      height: 150,
      cellClassName: 'custom-cell'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <AddDialog btn = 'edit' pickedPromoData={params.row} handleAdded = {handleAdded}/>
          
          ,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={()=> {
              setDeleteId(params.row.id)
              setOpenDelete(true);
            }}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: 'active',
      type: 'actions',
      headerName: 'Active',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          (
            
          <FormControlLabel
        control={<IOSSwitch {...label} 
        checked={params.row.active}
        onChange={(e) => {
          // setChecked((prevState) => !prevState)
          handleSingleChecked(params.row.active , params.row._id)
          }} /> } />
          )
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between'}}>  
      <div style={{display:'flex' , flexDirection:'column' , justifyContent:'flex-start' , marginBottom:'10px'}}>
      <div style={{display:'flex' , flexDirection:'row' }}>
      <Typography 
      variant='h6'
      style={{marginRight:'20px' , fontWeight:'bold'}}>
        Promo Codes {`(${promoData.length})`}
      </Typography>
      <FormControlLabel
        control={<IOSSwitch {...label} 
        checked={checked}
        onChange={(e) => {
          setChecked((prevState) => !prevState)
          handleChecked()
          }}
     
     />}
      label={checked===true? 'Enabled ': 'Disabled'}
      style={{padding:'5px'}}
      />
      </div>
      <div style={{margin:0 , padding:0 , display:'flex' , alignItems:'flex-start' , justifyContent:'flex-start'}}>
        <Typography
        variant='caption'
        style={{textAlign:'left'}}
         >Give Your Learners The Best Offers <a href="#" style={{color:'#000833' , textDecoration:'underline'}}>Learn More</a></Typography>
      </div>
      </div>
      <div style= {{display:'flex' , flexDirection:'row' , alignItems:'center' }}>
      <Button 
      variant='outlined'
      style={{color: '#000833' , borderColor:'#000833' , borderRadius:0, marginRight:'10px'}}
      onClick={(e) => exportToCSV(promoData, fileName)}
      >
        Export As CSV
      </Button>
      <AddDialog btn = 'add'handleAdded = {handleAdded}/>
      </div>
     
    
     </div> 
     <Divider style={{marginBottom:'10px'}}/>
    
  
          <Box
            sx={{
              height: '100vh'
            }}
          >

     <DataGrid
        rows={promoData}
        rowHeight={100}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbarQuickFilter }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
/>
</Box>


      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => {
          // setAdded(false);
          setOpenPopUp(!openPopUp)
          }}
      />
      <Box width={400}>
        <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
          {deleteBox}
        </Modal>
      </Box>
     
    </Box>
  );
}
const u = makeStyles({
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 600,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      p: 3,
    },
  },
});