import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'; 
import axios from 'axios';
import { GET_COURSES } from '../../../api/courseupload';
import { useLocation } from 'react-router-dom';
import CircularProgressWithLabel from './CircularProgressWithLabel';

export default function Courses() {
    const location = useLocation();
    const courses = location.state?.courses;
    const courseDetails = location.state?.courseDetails;
    const completedProjects = location.state?.completedProjects;
    const userDetails = location.state?.userDetails;
    const [bcourses , setBCourses] = React.useState([]);

    React.useEffect(() => {
        axios
            .get(GET_COURSES)
            .then(res => {
                const boughtcourses = res.data.data.filter(course => courses.includes(course._id));
                const coursesWithDetails = boughtcourses.map(course => {
                    const correspondingDetail = courseDetails.find(detail => detail.courseId === course._id);

                    // Filter completed projects for this course
                    const courseCompletedProjects = completedProjects.filter(project => project.courseId === course._id);

                    // Calculate completion rate
                    const completionRate = ((correspondingDetail?.videosCompleted.length + correspondingDetail?.pdfsCompleted.length) / correspondingDetail?.total) * 100;

                    const approvedProjectsCount = courseCompletedProjects.reduce((count, project) => {
                        return count + (project.isApproved === 'approved' ? 1 : 0);
                    }, 0);

                    const percentageApproved = (approvedProjectsCount / courseCompletedProjects.length) * 100 || 0;

                    return {
                        ...course,
                        ...correspondingDetail,
                        completedProjects: courseCompletedProjects, // Add completed projects to course
                        completionRate: completionRate,
                        percentageApproved: percentageApproved
                    };
                });

                setBCourses(coursesWithDetails);
            })
            .catch(err => {
                console.error(err);
            });
    }, [courses, courseDetails, completedProjects]);

    const totalCoursesCompleted = bcourses.filter(course => course.completionRate === 100).length;
    
    return (
        <Box>
            <Box
                boxShadow={3}
                padding={2}
                bgcolor="background.paper"
                borderRadius={8}
                marginBottom={2}
            >
                <Typography variant="h6">User Details</Typography>
                <Typography>Name: {userDetails.name}</Typography>
                <Typography>Email: {userDetails.email}</Typography>
                <Typography>Total Courses Bought: {userDetails.courses?.length}</Typography>
                <Typography>Total Certificates Generated: {userDetails?.earnedCertificates.length}</Typography>
                <Typography>Total Courses Completed: {totalCoursesCompleted}</Typography>
                <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' , marginTop: '10px'}}>
                    <Typography>Completion Rate: </Typography>
                    {/* <CircularProgressWithLabel value={Math.round((totalCoursesCompleted / userDetails?.courses.length) * 100)}/> */}
                    <CircularProgressWithLabel value={33}/>

                </Box>
            </Box>
            {bcourses.map(course => (
                <Accordion key={course._id}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        {course.courseName}
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap: '10px'
                    }}>
                        <Typography>
                            Video Completed: {course?.videosCompleted.length}
                        </Typography>
                        <Typography>
                            Pdfs Completed: {course?.pdfsCompleted.length}
                        </Typography>
                        <Typography sx={{display:'flex' , justifyContent:'center' , alignItems: 'center' , gap: '10px'}}>
                            {/* Completion Rate: {course.completionRate}% */}
                            Completion Rate: <CircularProgressWithLabel value={course.completionRate?course.completionRate:0}/>
                        </Typography>
                        {course.completedProjects.map(project => (
                            <Typography key={project._id}>
                                Project Title: {project.title} | Status: {project.isApproved === 'approved' ? 'Approved' : 'Not Approved'}
                            </Typography>
                        ))}
                        <Typography sx={{display:'flex' , justifyContent:'center' , alignItems: 'center' , gap: '10px'}}>
                            {/* Percentage of Projects Approved: {course.percentageApproved}% */}
                            Percentage of Projects Approved: <CircularProgressWithLabel value={course.percentageApproved?course.percentageApproved:0}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
