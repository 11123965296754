import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './index.css';
import axios from 'axios';
import MessagePopup from "../../MessagePopup";
import JoditEditor from 'jodit-react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import {ADD_STARTUPSHORT , UPDATE_STARTUPSHORT} from '../../../api/startupShortsAdmin'

export default function FormDialog({nameP , descriptionP , linkP, imageP, btn , btnP , id , onAdd}) {
 
  const editor = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(nameP || '');
  const [description, setDescription] = React.useState(descriptionP || '');
  const [link, setLink] = React.useState(linkP || '');
  const [image, setImage] = React.useState(imageP || '');
  const [resp, setResp] = React.useState("");
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [error , setError] = React.useState('');
  const [editorContent, setEditorContent] = React.useState('');
  const [imageError, setImageError] = React.useState('');
  const wordLimit = (value) => {
    const strippedText = value.replace(/<[^>]+>/g, '');
    const wordCount = strippedText.trim().split(/\s+/).length;
    const maxWordLimit = 250;
  
    if (wordCount > maxWordLimit) {
      setError('Limit Exceeded Write Words within 250 words');
      setDescription(strippedText.substring(0, maxWordLimit));
    } else {
      setError('');
      setDescription(strippedText);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const setAllEmpty = () => {
    setName("");
    setLink("");
    setDescription("");
    setImage("");
    setEditorContent("");
  }

  const handleError = () => {
    return <p>{error}</p>
  }
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile.size; 

    if (fileSize > 50 * 1024 * 1024) {
      setImageError('File size exceeds 50MB');
      setImage(null); 
    } else {
      setImage(selectedFile);
      setImageError('');
    }
  };
  const handleAdd = () => {
    // alert(`${description}`);
    const formData = new FormData();
    formData.append('name1',name);
    formData.append('description',description);
    formData.append('link',link);
    formData.append('image',image);
    axios
    .post(ADD_STARTUPSHORT, formData, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then(response => {
      setAllEmpty();
      onAdd(true);
      setResp("Added Successfully");
      setOpenPopUp(true);
    })
    .catch(err => {
      onAdd(false);
      setResp("Some Error Occured");
      setOpenPopUp(true);
    })
    setOpenPopUp(false);
    // window.location.reload();
    handleClose();
  }
  const handleEdit = () => {
       const formData = new FormData();
       formData.append('name1',name);
       formData.append('description',description);
       formData.append('link',link);
       formData.append('image',image);
       axios
      .put(UPDATE_STARTUPSHORT+id, formData, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
      })
      .then(response => {
        onAdd(true);
        setResp("Edited Successfully");
        setOpenPopUp(true);
      })
      .catch(err => {
        onAdd(false);
        setResp("Some Error Occured");
        setOpenPopUp(true);
      })
      setOpenPopUp(false);
      // window.location.reload();
      handleClose();
  }

  return (
    <div style = {{margin: '10px'}} >
      <Button variant="outlined" onClick={handleClickOpen} style={{color: btnP==='Save'?'#000':'Primary'}}>
        {btn}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle fontFamily="fantasy">Add StartUp Short</DialogTitle>
        <DialogContent>
          <DialogContentText margin="10px" fontFamily="fantasy">
            Add New StartUp Short
          </DialogContentText>
          <TextField
            style={{ paddingBottom: "10px", width: "100%" , fontWeight: "900" }}
            variant="outlined"
            type="text"
            label="Name"
            value= {name}
            onChange={(e)=> setName(e.target.value)}
          />
          <div  style={{ paddingBottom: "10px", width: "100%" }}>
          <JoditEditor
                label="Description"
                className="message"
                ref={editor}
                config={{
                  placeholder: "Write The Description",
                  allowTabNavigation: true
                }}
                tabIndex={1}
                value={editorContent}
                onBlur={(value) => {
                  setEditorContent(value);
                  wordLimit(value);
                }}
              />

  
                </div> 

               {error && <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="warning">{error}</Alert>
    </Stack>}  
                
          <TextField
            style={{ paddingBottom: "10px", width: "100%" }}
            variant="outlined"
            type="link"
            label="Link"
            value={link}
            
            onChange={(e)=> setLink(e.target.value)}
          />
           <Button
           variant="contained"
           component="label"
         >
          Upload Image
           <input
            type="file"
            hidden
            // value={image}
            onChange={handleImageChange}
            accept = '.png , .jpg , .jpeg'
          />
        </Button> 
        {imageError && <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="warning">{imageError}</Alert>
    </Stack>} 
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        {!error && !imageError &&
         <Button onClick={!error?btnP==='Save'?handleEdit:handleAdd : handleError}>{btnP}</Button>
        }
        </DialogActions>
      </Dialog>
      <MessagePopup 
      open={openPopUp}
      message={resp}
      handleAlertClose={() => setOpenPopUp(!openPopUp)}
     />
    </div>
    
  );
}


