import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from "@material-ui/core";
import "../Email/Component1/admin_emails-Style.css";
import axios from "axios";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { CREATE_BADGE , EDIT_BADGE , GET_BADGES} from '../../api/badge';
import Loader from './Loader/index';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const   Addtemplate = ({addTemplate , btn , sub , titl, id , t , p}) => {
    const [open, setOpen] = useState(false);  
    const [title , setTitle] = useState(""||titl);
    const [subject, setSubject] = useState(""||sub); 
    const [template , setTemplate] = useState(null);
    const [points  , setPoints] = useState(0||p);
    const [tag , setTag] = useState(''||t);
    const [conditions  , setConditions] = useState([]);
    const [added , setAdded] = useState(false);
    const [loading , setLoading] = useState(true);
    const backendConditions = [
        {
          value: 'Signup Badge',
          label: 'Signup Badge',
        },
        {
          value: 'Apply Now',
          label: 'Apply Now',
        },
        {
          value: 'First Completed',
          label: 'First Completed',
        },
        {
          value: 'Profile',
          label: 'Profile',
        },
        {
            value: 'First 7 Goals',
            label: 'First 7 Goals',
          },
          {
            value: '10 Goals',
            label: '10 Goals',
          },{
            value: '20 Goals',
            label: '20 Goals',
          },
          {
            value: '30 Goals',
            label: '30 Goals',
          },
          {
            value: '3 Month Consistent',
            label: '3 Month Consistent',
          },
          {
            value: '6 Month Consistent',
            label: '6 Month Consistent',
          },
          {
            value: 'Top Leaderboard',
            label: 'Top Leaderboard',
          },
          {
            value: '2 Times Top Leaderboard',
            label: '2 Times Top Leaderboard',
          },
          {
            value: 'First Course Purchase',
            label: 'First Course Purchase',
          }

      ];
      React.useEffect(() => {
        axios
        .get(GET_BADGES)
        .then(res => {
            const backendTags = res.data.data.map(badge => badge.tag);
            const uniqueTags = Array.from(new Set(backendTags.flat()));
            let newConditions = backendConditions;
            
            newConditions = newConditions.filter(condition => !uniqueTags.includes(condition.value));
            if (t) {
                newConditions = [...newConditions, { value: t, label: t }];
              }
            setConditions(newConditions);
            setAdded(false);
            setLoading(false);
        })
        .catch(err => {
        })
    },[added]);
    const handleTemplateChange = (event) => {
        setTemplate(event.target.files[0]);
    }

    const handleClickOpen = () => {
        setOpen(true);
        setAdded(true);
        setTag(t);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const ClearAll = () => {
        setTemplate(null);
        setTitle('');
        setSubject('');
        setTag('');
        setPoints(0);
    }
    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleCreate = () => {
        const data = new FormData();
        data.append('title' , title);
        data.append('message' , subject);
        data.append('badge' , template);
        data.append('tag' , tag);
        data.append('points' , points);

        axios
        .post(CREATE_BADGE , data)
        .then(res => {
            setAdded(true);
            ClearAll();
            addTemplate('Created Successfully');
        })
        .catch(err => {
            addTemplate(err.message);

        })

    }
    const handleEdit = () => {
        const data = new FormData();
        data.append('title' , title);
        data.append('message' , subject);
        data.append('badge' , template);
        data.append('tag' , tag);
        data.append('points' , points);

        axios
        .patch(EDIT_BADGE+id , data)
        .then(res => {
            setAdded(true);
            ClearAll();
            addTemplate('Edited Successfully');
        })
        .catch(err => {
            addTemplate(err.message);
        })

    }
  
    return (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div>
              {btn === 'edit' ? (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <EditSharpIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Add Badge">
                  <IconButton
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <AddCircleOutlineRoundedIcon fontSize='large' color="grey" />
                  </IconButton>
                </Tooltip>
              )}
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ textAlign: 'center' }}>
                  {"Badge"}
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "20px",
                      margin: '5%',
                    }}
                  >
                    <input
                    type="text"
                    placeholder="Enter Badge Title"
                    className="subject"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    style={{
                        height:'50px',
                        marginBottom:'10px'
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Enter Message"
                    className="subject"
                    value={subject}
                    onChange={handleSubjectChange}
                    style={{
                        height:'50px',
                        marginBottom:'10px'
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Points"
                    className="subject"
                    value={points}
                    onChange={(e)=> setPoints(e.target.value)}
                    style={{
                        height:'50px',
                        marginBottom:'10px'
                    }}
                  />
                   <TextField
                    id="filled-select-currency"
                    select
                    label="Select"
                    helperText="Please select the Condition"
                    variant="filled"
                    onChange={(e) => setTag(e.target.value)}
                    value={tag}
                    >
                    {conditions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
        </TextField>
                  <Button
                    style={{marginTop:'20px'}}
                    variant="contained"
                    component="label"
                    >
                    Upload Badge
                    <input
                        type="file"
                        hidden
                        onChange={handleTemplateChange}
                        accept = '.png , .jpg , .jpeg'
                    />
                    </Button> 
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      if (btn === 'edit') {
                        handleEdit();
                      } else {
                        handleCreate();
                      }
                      handleClose();
                    }}
                    autoFocus
                  >
                    {btn === 'edit' ? 'Save' : 'Add'}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </>
      );
}

export default  Addtemplate