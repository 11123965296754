import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./admin_emails-Style.css";
import Button from "@mui/material/Button";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "@mui/icons-material/ExpandMore";

const {
  GET_ALL_EMAILPERFORMANCE_EVENTS,
  GET_ALL_EMAILPERFORMANCE_OPENCOUNT,
  SAVE_NEW_EMAILADMIN_EVENT,
  UPDATE_EMAILADMIN_EVENT,
  DELETE_EMAILADMIN_EVENT,
} = require("../../../api/emailAdmin");
const Emails_performance = ({ isAuth, a_email }) => {
  const [data, setData] = useState([]);
  const ID = localStorage.getItem("id");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(GET_ALL_EMAILPERFORMANCE_EVENTS);
        console.log(res.data);
        setData(res.data);
      } catch (err) {
        console.log("error-->", err);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{marginTop:'23px',}}>
      {a_email ? (
        <>
          <div className="main-page">
            <div className="main-page-header">
              <h1>Emails Performance</h1>
            </div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell align="center">Created At&nbsp;(Date)</TableCell>
                    <TableCell align="center">Total Emails&nbsp;</TableCell>
                    <TableCell align="center">Success&nbsp;</TableCell>
                    <TableCell align="center">Open Rate&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(data) && data.length > 0 ? (
                    data.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.subject}
                        </TableCell>
                        <TableCell align="center">{row.createdAt}</TableCell>
                        <TableCell align="center">{row.totalEmail}</TableCell>
                        <TableCell align="center">{row.success}</TableCell>
                        <TableCell align="center">{row.openRate}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
    </div>
  );
};

export default Emails_performance;
