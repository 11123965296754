import { Box, Typography, CircularProgress  , Modal , Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MessagePopup from "../MessagePopup";

const {
  GET_COURSE,
  GET_SINGLE_COURSE
} = require("../../api/applycourse");

const ApplyCourse = ({ isAuth, setIsAuth }) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [startupShortData, setStartupShortData] = useState([]);
  const [added, setAdded] = useState(false);
  const [openEdit, setOpenEdit] = useState('');
  const [downloadData, setDownloadData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [courseNames, setCourseNames] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(GET_COURSE)
      .then((res) => {
        const dataWithIds = res.data.data.map((row, index) => ({
          ...row,
          id: index,
        }));
        const dataWithoutCourseId = dataWithIds.map(({ userId, courseId, ...rest }) => rest);
        setDownloadData(dataWithoutCourseId.reverse());
        setStartupShortData(dataWithIds.reverse());
        setAdded(false);
        setOpenEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [openDelete, openPopUp, added, openEdit]);

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `APPLYCOURSELEADS_${Date.now()}`;

  const exportToCSV = (startupShortData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(downloadData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 20, cellClassName: "Cell-Col", headerClassName: "column-header" },
    {
      field: "date",
      headerName: "Date & Time",
      width: 200,
      cellClassName: "Cell-Col",
      headerClassName: "column-header",
      renderCell: (params) => {
        const now = new Date(params.row.date);
        return now.toLocaleString('en-IN');
      }
    },
    {
      field: "courseName",
      headerName: "Course Name",
      width: 200,
      cellClassName: "Cell-Col",
      headerClassName: "column-header",
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
      valueGetter: (params) => params.row.fName + " " + params.row.lName
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "wNumber",
      headerName: "Whatsapp Number",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 200
    },
    {
      field: "year",
      headerName: "Year",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 50,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 200
    },
    {
      field: "course",
      headerName: "Course",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 200
    },
  ];

  return (
    <>
      {isAuth ? (
        <div>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">Apply Course Leads</Typography>
            <div className="Dialog-Download-btn">
              <Button
                margin="10px"
                onClick={(e) => exportToCSV(startupShortData, fileName)}
                size="small"
                variant="contained"
                color="primary"
              >
                Download Data
              </Button>
            </div>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyContent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "purple",
                fontSize: "20px",
              },
            }}
          >
            <DataGrid
              rows={startupShortData}
              className="table-box"
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <MessagePopup
            open={openPopUp}
            message={resp}
            handleAlertClose={() => setOpenPopUp(!openPopUp)}
          />
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
            }}>
              <Typography variant="h6" style={{ color: 'black', marginBottom: '20px' }}>
                {selectedCourse && selectedCourse.courseName}
              </Typography>
              <Button variant="contained" onClick={() => setModalOpen(false)}>Close</Button>
            </div>
          </Modal>
        </div>
      ) : (
        <div>Not Authorised</div>
      )}
    </>
  );
};

export default ApplyCourse;
