import React , {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormGroup} from '@mui/material';
import { InputBase } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Notification = () => {
    return (
        <div>
            <Box sx={style}>
                <Box display='flex' justifyContent='center'>
                    <Typography variant="h5" component="h2" fontWeight='650' >
                        Send Notifications
                    </Typography>
                    <Box marginTop='auto' marginLeft='5px'>
                        <ArrowForwardIcon style={{color:'#282828'}}/>
                    </Box>
                </Box>
                <FormGroup>
                    <InputBase 
                        placeholder='Title' 
                        style={inputs}
                    />
                    <InputBase 
                        placeholder='Description'
                        style={inputs}
                    />
                    {/* <Box display='flex' justifyContent='center'>
                        <Typography variant="h5" component="h2" fontWeight='650' >
                            You are
                        </Typography>
                    </Box>
                    <InputBase placeholder='Password' style={inputs}/> */}
                </FormGroup>
                <Box display='flex' justifyContent='center' >
                    <Button sx={{color: '#000' , background: '#60F2D6' , marginTop: '10px'  , fontWeight: '600'}} size='large'>Send</Button>
                </Box>
            </Box>
        </div>
      );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // bgcolor: '#fff',
    border: 'none',
    borderRadius: '3px',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    p: 4,
    paddingLeft:'3rem',
    paddingRight:'3rem'
};

const inputs ={
padding: 8,
margin: 7,
borderRadius: 5,
border: '1px solid #aaa',
background: '#eee',
color: '#121212',
}
 
export default Notification;