import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormGroup} from '@mui/material';
import { InputBase } from '@mui/material';
import { useState } from 'react';
import Axios from 'axios';
import MessagePopup from "../MessagePopup";

const {UPDATE_COMMUNITY_LINK , UPDATE_MENTORSHIP_LINK} = require("../../api/updateLinks");

const UpdateLinks = () => {
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [communityULink , setUCommunityLink] = useState("");
    const [mentorShipULink , setUMentorshipLink] = useState("");

    const updateCommunityLinkHandler =() => {
        if(communityULink.length > 0){
          const new_link = {
            link : communityULink
          }
          Axios.put(UPDATE_COMMUNITY_LINK ,new_link ,{
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then(response => {
            setResp("Updated Successfully");
            setOpenPopUp(true);
            setUCommunityLink("");
          })
          .catch((err) => {
            setResp("Something error occured");
            setOpenPopUp(true);
          });
        } 
        else {
            setResp("Please fill the input field!");
            setOpenPopUp(true);
        }
    }

    const updateMentorshipLinkHandler = () => {
        if(mentorShipULink.length > 0){
            const new_link = {
                link : mentorShipULink,
            }
            
            Axios.put(UPDATE_MENTORSHIP_LINK,new_link ,{
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
            })
            .then(response => {
                setResp("Updated Successfully");
                setOpenPopUp(true);
                setUMentorshipLink("");
            })
            .catch((err) => {
                setResp("Something error occured");
                setOpenPopUp(true);
            });
        }
        else {
            setResp("Please fill the input field!");
            setOpenPopUp(true);
        }
    }


    return ( 
        <div style={fullPage}>
            <Box >
                <Box width={400}  marginBottom='10px'>
                    <Typography variant="h5" component="h2" fontWeight='650' >MentorShip Link</Typography>
                    <Box sx={style}>
                        <FormGroup>
                            <InputBase 
                                placeholder='update mentorship link' 
                                style={inputs}
                                value={mentorShipULink}
                                onChange={(e) => setUMentorshipLink(e.target.value)}
                            />
                        </FormGroup>
                        <Box display='flex' justifyContent='center' >
                            <Button onClick={updateMentorshipLinkHandler} style={btn} size='large'>Update Mentor link</Button>
                        </Box>
                    </Box>
                </Box>

                <Box width={400}  marginTop='20px'>
                    <Box display='flex' justifyContent='center'>
                        <Typography variant="h5" component="h2" fontWeight='650'color="#fff" >
                            Community Link
                        </Typography>
                    </Box>
                    <Box sx={style}>
                        <FormGroup>
                            <InputBase 
                                placeholder='update Community Link' 
                                style={inputs}
                                value={communityULink}
                                onChange={(e) => setUCommunityLink(e.target.value)}
                            />
                        </FormGroup>
                   
                        <Box display='flex' justifyContent='center' >
                            <Button onClick={updateCommunityLinkHandler} style={btn} size='large'>Update Community Link</Button>
                        </Box>
                    </Box>
                </Box>
                <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
            </Box>
        </div>
     );
}


const style = {
    border: 'none',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    boxSizing: 'content-box',
};

const inputs ={
    padding: 8,
    marginTop: 7,
    marginBottom: 7,
    borderRadius: 5,
    background: '#eee',
    border: '1px solid #aaa',
}

const fullPage ={
    // width:'100%',
    height:'100vh',
    backgroundColor:'black',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
    color: '#fff',
    overflowX : 'none',
}

const btn={
    color: '#000' ,
    background: '#68F5D4' , 
    marginTop: '5px'  , 
    fontWeight: '650',
}

 
export default UpdateLinks;