const { BASE_URL } = require("../client");

const b2LinkId = "63351f355a55005eb8217b0f";

const UPDATE_B2_MAINPAGE = BASE_URL + "/main/b2/"+b2LinkId;
const GET_B2_MAINPAGE = BASE_URL + "/main/b2/"+b2LinkId;

export {
    UPDATE_B2_MAINPAGE,
    GET_B2_MAINPAGE,
}