import {
    Box,
    Button,
    InputBase,
    Modal,
    TextareaAutosize,
    Typography,
  } from "@material-ui/core";
  import React, { useRef, useState } from "react";
  import JoditEditor from "jodit-react";
  import { makeStyles } from "@material-ui/core";
  import { DataGrid } from "@mui/x-data-grid";
  import { useEffect } from "react";
  import axios from "axios";
  import { FormGroup } from "@mui/material";
  import MessagePopup from "../MessagePopup";
  import InputLabel from '@mui/material/InputLabel';
  import MenuItem from '@mui/material/MenuItem';
  import FormControl from '@mui/material/FormControl';
  import Select, { SelectChangeEvent } from '@mui/material/Select';
  import AddDialog from './AddDialog'
import QuestionsDialog from "./QuestionsDialog";
import { DELETE_CASE_STUDY, GET_CASE_STUDY } from "../../api/casestudy";
  
  const Usestyles = makeStyles({
    container: {
      width: "100%",
      height: "100vh",
    },
    inputsStyle: {
      margin: "20px 0px 0px 0px",
      backgroundColor: "whitesmoke",
      color: "black",
      padding: "10px",
    },
  });
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "3px",
    boxShadow: 25,
    textAlign: "center",
    color: "black",
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
  };
  
  export default function CaseStudy({ isAuth , a_casestudy }) {
    const editor = useRef(null);
    const classes = Usestyles();
  
    const [openPopUp, setOpenPopUp] = useState(false);
    const [resp, setResp] = useState("");
  
    const [caseStudies, setcaseStudies] = useState([]);
    const [caseStudy, setcaseStudy] = useState([]);
    const [selected, setSelected] = useState([]);
    const [clicked , setClicked] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [added , setAdded ] =  useState(false);

    React.useEffect(() => {
      axios
      .get(GET_CASE_STUDY)
      .then(res => {
        setcaseStudies(res.data.data.reverse());
        setAdded(false);
      })
      .catch(err => {
        console.log(err);
      })
    

    }, [openPopUp , added]);
    const checkAdded = () => {
      setAdded(true);
    }
    const handleOpenDialog = (user) => {
        setSelected(user);
      };
      const handleCloseDialog = () => {
        setSelected(null);
        setClicked(false);
      };
    
    const QuestionsRenderer = (params) => {
    
        return (
          // <Button onClick={() => {handleOpenDialog(params.row);setClicked(true);}}>
            <QuestionsDialog row={params.row} />
          // {/* </Button> */}
        );
      };

      const deleteSubmit = () => {
        axios.delete(DELETE_CASE_STUDY+caseStudy._id)
        .then(res => {
          setOpenDelete(false);
          setResp('Delete Successfully');
          setOpenPopUp(true);
        })
      }
   

    // Delete PopUp
    const deleteBox = (
      <Box sx={style}>
        <Typography variant="h5" style={{ marginBottom: "5px" }}>
          Are you sure want to delete ?
        </Typography>
        <Box>
          <Button
            onClick={deleteSubmit}
            style={{ background: "black", color: "white", margin: "3px" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setOpenDelete(false)}
            style={{ background: "black", color: "white", margin: "3px" }}
          >
            No
          </Button>
        </Box>
      </Box>
    );
  
    // Columns in data grid
    const columns = [
      { field: "_id", headerName: "ID", width: 80, cellClassName: "Cell-Col" },
      { field: "points", headerName: "POINTS", width: 100, cellClassName: "Cell-Col" },
      {
        field: "title",
        headerName: "Title",
        width: 150,
        cellClassName: "column",
        headerClassName: "column-header",
        cellAlign: "center",
      },
      {
        field: "subTitle",
        headerName: "SubTitle",
        width: 150,
        cellClassName: "column",
        headerClassName: "column-header",
      },
      {
        field: "questions",
        headerName: "Questions",
        width: 200,
        cellClassName: "column",
        headerClassName: "column-header",
        renderCell: QuestionsRenderer,
      },
      {
        field: "image",
        headerName: "Poster",
        width: 200,
        cellClassName: "column",
        headerClassName: "column-header",
        renderCell: (params) => {
          return (
            <img src={params.row.casestudy} width={'100%'} height={'100%'}/>
          )
        },
      },
      {
        field: "tag",
        headerName: "Tag",
        width: 100,
        cellClassName: "column",
        headerClassName: "column-header",
      },
      {
        field: "lcaseStudyId",
        headerName: "Left CaseStudy",
        width: 100,
        cellClassName: "column",
        headerClassName: "column-header",
      },
      {
        field: "rcaseStudyId",
        headerName: "Right CaseStudy",
        width: 100,
        cellClassName: "column",
        headerClassName: "column-header",
      },
      {
        field: "Edit",
        headrName: "Edit",
        headerClassName: "column-header",
        cellClassName: "column",
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onClick = () => {
            setcaseStudy(params.row);
          };
          return (
            <Button
            //   variant="contained"
            //   color="primary"
            //   size="small"
              style={{ marginRight: "16px", background: "#eb8546" }}
              onClick={onClick}
            >
              <AddDialog btn={'edit'} checkAdded={checkAdded} caseStudies={caseStudies} row={params.row} q={
    caseStudies.find((c) => c._id === params.row._id)?.qa || [] 
  }/>
            </Button>
          );
        },
      },
      {
        field: "Delete",
        headrName: "Delete",
        headerClassName: "column-header",
        cellClassName: "column",
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onClick = () => {
            setcaseStudy(params.row);
            setOpenDelete(true);
            
          };
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
              onClick={onClick}
            >
              Delete
            </Button>
          );
        },
      },
    ];
  
    // UI
    
      return isAuth && a_casestudy ? (
      <div className={classes.container}>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">Case Study</Typography>
            <AddDialog btn = {'add'} caseStudies={caseStudies} checkAdded={checkAdded}/>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyCOntent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "purple",
                fontSize: "20px",
              },
            }}
          >
            <DataGrid
              rows={caseStudies}
              className="table-box"
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          
          </Box>
    
          <MessagePopup
            open={openPopUp}
            message={resp}
            handleAlertClose={() => setOpenPopUp(!openPopUp)}
          />
          <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
            {deleteBox}
          </Modal>
        </div>
      ) : (<div>{isAuth ? 'You Are Not Allowed': 'Login First'}</div>)
      
  }
  
  const u = makeStyles({
    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      border: "none",
      borderRadius: "3px",
      textAlign: "center",
      color: "black",
      width: 600,
      p: 4,
      paddingLeft: "3rem",
      paddingRight: "3rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      height: 600,
      overflowY: "scroll",
      "@media only screen and (max-width: 726px)": {
        width: "90%",
        p: 3,
      },
    },
  });
  