const { BASE_URL } = require("../client");

const b6LinkId = "633e5940b8e574a2303b40e5";

const UPDATE_B6_MAINPAGE = BASE_URL + "/main/b6/"+b6LinkId;
const GET_B6_MAINPAGE = BASE_URL + "/main/b6/"+b6LinkId;

export {
    UPDATE_B6_MAINPAGE,
    GET_B6_MAINPAGE,
}