import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {IconButton , InputAdornment} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import JoditEditor from "jodit-react";
import axios from 'axios';
import { CREATE_CASE_STUDY, UPDATE_CASE_STUDY } from '../../api/casestudy';
import { CoPresentOutlined } from '@mui/icons-material';
import MessagePopup from "../MessagePopup";



export default function AddDialog({btn , caseStudies , checkAdded , row ,q}) {
  const editor = React.useRef(null);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [resp, setResp] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [title , setTitle] = React.useState(row?row.title:'');
  const [subTitle , setSubTitle] = React.useState(row?row.subTitle:'');
  const [points , setPoints] = React.useState(row?row.points:'');
  const [poster , setPoster] = React.useState(null);
  const [qa , setqa] = React.useState(q||[{q:'',a:''}]);
  const [imageError, setImageError] = React.useState('');
  const [lcaseStudyId , setlcaseStudyId] = React.useState(row?row.lcaseStudyId:null);
  const [rcaseStudyId , setrcaseStudyId] = React.useState(row?row.rcaseStudyId:null);
  const [tag , setTag] = React.useState('');
  const tags = [{
    value: "Startups",
    label: "Startups"
}, {
  value: "Technology",
  label: "Technology"
},{
  value: "Marketing",
  label: "Marketing"
},{
  value: "Product",
  label: "Product"
},{
  value: "Finance",
  label: "Finance"
}]
const handleTagChange = (e) => {
  setTag(e.target.value);
}
  const setAllEmpty = () => {
    setTitle("");
    setSubTitle("");
    setPoster(null);
    setqa([{q:'',a:''}]);
    setlcaseStudyId(null);
    setrcaseStudyId(null);
    setTag('');
  }
  const handleLeftChange = (event) => {
    setlcaseStudyId(event.target.value);
  };
  const handleRightChange = (event) => {
    setrcaseStudyId(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange=(e,i)=>{
    const {name,value}=e.target
    const onchangeVal = [...qa]
    onchangeVal[i][name]=value
    setqa(onchangeVal)
}
const handleDelete=(i)=>{
    const deleteVal = [...qa]
    deleteVal.splice(i,1)
    setqa(deleteVal)
}
const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile.size; 

    if (fileSize > 50 * 1024 * 1024) {
      setImageError('File size exceeds 50MB');
      setPoster(null); 
    } else {
      setPoster(selectedFile);
      setImageError('');
    }
  };
  const handleEdit = () => {
    const formdata = new FormData();
        formdata.append('title' , title);
        formdata.append('subTitle' , subTitle);
        formdata.append('qa', JSON.stringify(qa));
        formdata.append('casestudy' , poster);
        formdata.append('lcaseStudyId' , lcaseStudyId);
        formdata.append('rcaseStudyId' , rcaseStudyId);
        formdata.append('points' , points);
        formdata.append('tag' , tag);
      
        axios
        .patch(UPDATE_CASE_STUDY+row._id , formdata)
        .then(res =>
           {
            setResp('Edited Successfully');
            setOpenPopUp(true);
            checkAdded();
            handleClose();
           }
           )
        .catch(err => console.log(err)); 
  }

  const handleCreate = () => {
        const formdata = new FormData();
        formdata.append('title' , title);
        formdata.append('subTitle' , subTitle);
        formdata.append('qa', JSON.stringify(qa));
        formdata.append('casestudy' , poster);
        formdata.append('lcaseStudyId' , lcaseStudyId);
        formdata.append('rcaseStudyId' , rcaseStudyId);
        formdata.append('points' , points);
        formdata.append('tag' , tag);
        axios
        .post(CREATE_CASE_STUDY , formdata)
        .then(res =>
           {
            setResp('Added Successfully');
            setOpenPopUp(true);
            checkAdded();
            handleClose();
            setAllEmpty();
           }
           )
        .catch(err => console.log(err)); 
  }

  return (
    <div>
      <Button
            variant="contained"
            onClick={handleClickOpen}
            size={btn === 'add' ?"medium":"small"}
            color="primary"
        style={{ background: btn === 'edit' && "#eb8546"  }}>
        {btn === 'add' ? 'Create': 'Edit'}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Add Case Study
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <TextField
            style={{ paddingBottom: "10px", width: "100%" , fontWeight: "900" }}
            variant="outlined"
            type="text"
            label="Title"
            value= {title}
            onChange={(e)=> setTitle(e.target.value)}
          />
          <TextField
            style={{ paddingBottom: "10px", width: "100%" , fontWeight: "900" }}
            variant="outlined"
            type="text"
            label="Points"
            value= {points}
            onChange={(e)=> setPoints(e.target.value)}
          />
            <div style={{marginBottom:'10px'}}>
            <JoditEditor
            ref={editor}
            config={{
                  placeholder: "SubTitle",
                  allowTabNavigation: true
            }}
            value={subTitle}
            defaultValue={subTitle}
            tabIndex={1}
            onBlur={(newContent) => setSubTitle(newContent)}
          />
          </div> 
          {
            qa.map((data , i) => (
                <div style={{display:'flex' , flexDirection:'column'}}>
                <TextField 
                    style={{ paddingBottom: "10px", width: "100%" }}
                    variant="outlined"
                    label="Question"
                    name="q"
                    value={data.q}
                    onChange={(e) => {
                        handleChange(e,i);
                    }}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Delete Question">
                            <IconButton onClick={(e) => handleDelete(i)}>
                            <DeleteIcon />

                            </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                />
                <TextField 
                    style={{ paddingBottom: "10px", width: "100%" }}
                    variant="outlined"
                    label="Answer"
                    value={data.a}
                    name="a"
                    onChange={(e) => {
                        handleChange(e,i);
                    }}
                />
                
                </div>
            ))
          }
          <div style={{display:'flex' , marginBottom:'10px'}}>
          <Button 
                    variant='contained'
                    color='primary'
                    style={{
                        alignSelf:'flex-end',

                    }}
                    onClick={
                        ()=> {
                            setqa([...qa , {q:"",a:""}])
                        }
                    }
                >Add Questions</Button>
          </div>
          <FormControl fullWidth style={{marginTop:'10px', marginBottom:'10px'}}>
        <InputLabel id="demo-simple-select-label">Add Tag</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tag}
          label="Add Tag"
          onChange={handleTagChange}
        >
        {tags.map((blog,index) => (
          <MenuItem value={blog.value}>{blog.label}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
          <FormControl fullWidth style={{marginBottom:'10px'}}>
        <InputLabel id="demo-simple-select-label">Left Case Study</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={lcaseStudyId}
          label="Left Case Study"
          onChange={handleLeftChange}
        >
        {caseStudies.map((c,index) => (
          <MenuItem value={c._id}>{c.title}</MenuItem>
        ))}
          

        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Right Case Study</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={rcaseStudyId}
          label="Right Case Study "
          onChange={handleRightChange}
        >
        {caseStudies.map((c,index) => (
          <MenuItem value={c._id}>{c.title}</MenuItem>
        ))}
          

        </Select>
      </FormControl>

          
           <Button
           style={{marginTop:'20px'}}
           variant="contained"
           component="label"
         >
          Upload Poster
           <input
            type="file"
            hidden
            // value={poster}
            onChange={handleImageChange}
            accept = '.png , .jpg , .jpeg'
          />
        </Button> 
        {imageError && <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="warning">{imageError}</Alert>
    </Stack>} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={btn==='add'?handleCreate:handleEdit} autoFocus>
            {btn==='add' ? 'Add': 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <MessagePopup
          open={openPopUp}
          message={resp}
          handleAlertClose={() => setOpenPopUp(!openPopUp)}
        />
    </div>
  );
}
