const { BASE_URL } = require("./client");

const NEW_ANNOUNCEMENT = BASE_URL + "/announcement/insert";
const UPDATE_ANNOUNCEMENT = BASE_URL + "/announcement/update/";
const DELETE_ANNOUNCEMENT = BASE_URL + "/announcement/delete/";
const GET_ANNOUNCEMENTS = BASE_URL + "/announcement/adminPanel/read";
const GET_SINGLE = BASE_URL + '/announcement/single/'

export {
  NEW_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  GET_SINGLE
};
