const { BASE_URL } = require("../client");

const b7LinkId = "633f07ec62257a2acc1e1db8";

const UPDATE_B7_MAINPAGE = BASE_URL + "/main/b7/"+b7LinkId;
const GET_B7_MAINPAGE = BASE_URL + "/main/b7/"+b7LinkId;

export {
    UPDATE_B7_MAINPAGE,
    GET_B7_MAINPAGE,
}