const { BASE_URL } = require("../client");

const b1LinkId = "6333e954e41dfe695815f596";

const UPDATE_B1_MAINPAGE = BASE_URL + "/main/b1/"+b1LinkId;
const GET_B1_MAINPAGE = BASE_URL + "/main/b1/"+b1LinkId;

export {
    UPDATE_B1_MAINPAGE,
    GET_B1_MAINPAGE,
}