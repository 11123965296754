import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import { GET_BOOKINGS } from '../../api/availability';

// Define the columns for the DataGrid
const columns = [
  { field: 'userId', headerName: 'User ID', width: 150 },
  { field: 'bookingDate', headerName: 'Booking Date', width: 180 },
  { field: 'timeSlot', headerName: 'Time Slot', width: 150 },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'course', headerName: 'Course', width: 200 },
  { field: 'college', headerName: 'College', width: 200 },
  { field: 'year', headerName: 'Year', width: 100 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'whatsappNumber', headerName: 'WhatsApp Number', width: 150 },
  { field: 'resume', headerName: 'Resume', width: 150, renderCell: (params) => (
    <a href={params.value} target="_blank" rel="noopener noreferrer">View Resume</a>
  )},
];

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(GET_BOOKINGS)
      .then(response => {
        setBookings(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching bookings:', error);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ height: 600, width: '100%', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Bookings
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={bookings.map((booking) => ({
            id: booking._id,
            userId: booking.userId,
            bookingDate: booking.bookingDate,
            timeSlot: booking.timeSlot,
            name: booking.name,
            course: booking.course,
            college: booking.college,
            year: booking.year,
            email: booking.email,
            whatsappNumber: booking.whatsappNumber,
            resume: booking.resume,
          }))}
          columns={columns}
          pageSize={10}
        />
      )}
    </Box>
  );
};

export default Bookings;
