import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateAboutCources from './UpdateAboutCources';

const UpdatePage = () => {
  const { id } = useParams();

  return (
    <div>
      <h1>Update Page</h1>
      <UpdateAboutCources id={id} />
    </div>
  );
};

export default UpdatePage;
