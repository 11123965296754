const { BASE_URL } = require("./client");

const GET_ALL_EMAILADMIN_EVENTS =
  BASE_URL + "/api/admin/promotionalEmail/:page/:perPage";
const SAVE_NEW_EMAILADMIN_EVENT = BASE_URL + "/api/admin/promotionalEmail";
const UPDATE_EMAILADMIN_EVENT = BASE_URL + "/api/admin/promotionalEmail/";
const DELETE_EMAILADMIN_EVENT = BASE_URL + "/api/admin/promotionalEmail/";
const SEND_PROMOTIONALEMAILS_EVENT =
  BASE_URL + "/api/admin/promotionalEmail/send";
const GET_ALL_EMAILPERFORMANCE_EVENTS =
  BASE_URL + "/api/admin/promotionalEmail/getDetails/:page/:perPage";
const GET_ALL_EMAILPERFORMANCE_OPENCOUNT =
  BASE_URL + "/api/admin/promotionalEmail/getOpenCount";

export {
  GET_ALL_EMAILADMIN_EVENTS,
  SAVE_NEW_EMAILADMIN_EVENT,
  UPDATE_EMAILADMIN_EVENT,
  DELETE_EMAILADMIN_EVENT,
  SEND_PROMOTIONALEMAILS_EVENT,
  GET_ALL_EMAILPERFORMANCE_EVENTS,
  GET_ALL_EMAILPERFORMANCE_OPENCOUNT,
};
