const { BASE_URL } = require("./client");

const communityLinkId = "62dbcbb660c17e3670dd99e4";
const mentorShipLinkId = "62fb7e478f4ca450e8eef76e";

const UPDATE_COMMUNITY_LINK = BASE_URL + "/updatelinks/joinCommunity/" + communityLinkId;
const UPDATE_MENTORSHIP_LINK = BASE_URL + "/updatelinks/mentorship/"+mentorShipLinkId;

export {
    UPDATE_COMMUNITY_LINK,
    UPDATE_MENTORSHIP_LINK,
}