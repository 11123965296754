const { BASE_URL } = require("./client");

const POST_WORKEX = BASE_URL + '/api/workex/post';
const POST_WORKEXPERIENCE = BASE_URL + '/api/workex/post/experience/';
const GET_WORKEX = BASE_URL + '/api/workex/get';
const GET_WORKEXPERIENCE = BASE_URL + '/api/workex/get/experience';
const UPDATE_WORKEX = BASE_URL + '/api/workex/update/';
const DELETE_WORKEX = BASE_URL + '/api/workex/delete/';



export {
  POST_WORKEX,
  GET_WORKEX,
  UPDATE_WORKEX,
  DELETE_WORKEX,
  POST_WORKEXPERIENCE,
  GET_WORKEXPERIENCE
};
