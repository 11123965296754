import React,{useState,useEffect} from "react";
import { Box,Typography,FormGroup,InputBase,Button, TextareaAutosize } from "@material-ui/core";
import axios from "axios";
import MessagePopup from "../MessagePopup";

const {UPDATE_B7_MAINPAGE,GET_B7_MAINPAGE} = require("../../api/MainPage/b7")

const B7Main = () => {
    useEffect(() => {
        axios.get(GET_B7_MAINPAGE,{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
        })
        .then(async(res) => {
            setTitle(res.data.title);
            setDescription(res.data.description);
            setPointer1(res.data.pointer1);
            setPointer2(res.data.pointer2);
            setPointer3(res.data.pointer3);
            setPointer4(res.data.pointer4);
            setPointer5(res.data.pointer5);
            setLink1(res.data.link1);
            setLink2(res.data.link2);
            setLink3(res.data.link3);
            setLink4(res.data.link4);
            setLink5(res.data.link5);
        })
        .catch(err => {
            console.log(err);
            // setB2Details([])
        })
    },[]);

    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [pointer1,setPointer1] = useState("");
    const [pointer2,setPointer2] = useState("");
    const [pointer3,setPointer3] = useState("");
    const [pointer4,setPointer4] = useState("");
    const [pointer5,setPointer5] = useState("");

    const [link1,setLink1] = useState("");
    const [link2,setLink2] = useState("");
    const [link3,setLink3] = useState("");
    const [link4,setLink4] = useState("");
    const [link5,setLink5] = useState("");

    const handleUpdate = () => {
        const new_Data = {
            title: title,
            description: description,
            pointer1: pointer1,
            pointer2: pointer2,
            pointer3: pointer3,
            pointer4: pointer4,
            pointer5: pointer5,
            link1: link1,
            link2: link2,
            link3: link3,
            link4: link4,
            link5: link5,
          }
          axios.put( UPDATE_B7_MAINPAGE,new_Data,{
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then(response => {
            setResp("Edited Successfully");
            setOpenPopUp(true);
          })
          .catch(err => {
            setResp("Some error occured");
            setOpenPopUp(true);
          });
    }

    return ( 
        <div style={fullPage}>
            <Box  width={450}>
                    <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
                        Update B7 Page
                    </Typography>
                <Box sx={style}>
                    <FormGroup>
                        <TextareaAutosize
                            placeholder='Title'
                            minRows={2}
                            style={inputs}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <TextareaAutosize
                            placeholder='Description'
                            minRows={2}
                            style={inputs}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            pointer & Link 1
                        </Typography>
                        <InputBase
                            placeholder='Pointer1' 
                            style={inputs}  
                            value={pointer1}
                            onChange={(e) => setPointer1(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Link1' 
                            style={inputs}
                            value={link1}
                            onChange={(e) => setLink1(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            pointer & Link 2
                        </Typography>
                        <InputBase
                            placeholder='Pointer2' 
                            style={inputs}  
                            value={pointer2}
                            onChange={(e) => setPointer2(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Link2' 
                            style={inputs}
                            value={link2}
                            onChange={(e) => setLink2(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            pointer & Link 3
                        </Typography>
                        <InputBase
                            placeholder='Pointer3' 
                            style={inputs}  
                            value={pointer3}
                            onChange={(e) => setPointer3(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Link3' 
                            style={inputs}
                            value={link3}
                            onChange={(e) => setLink3(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            pointer & Link 4
                        </Typography>
                        <InputBase
                            placeholder='Pointer4' 
                            style={inputs}  
                            value={pointer4}
                            onChange={(e) => setPointer4(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Link4' 
                            style={inputs}
                            value={link4}
                            onChange={(e) => setLink4(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            pointer & Link 5
                        </Typography>
                        <InputBase
                            placeholder='Pointer5' 
                            style={inputs}  
                            value={pointer5}
                            onChange={(e) => setPointer5(e.target.value)}
                        />
                         <InputBase 
                            placeholder='Link5' 
                            style={inputs}
                            value={link5}
                            onChange={(e) => setLink5(e.target.value)}
                        /> 
                    </FormGroup>
                    <Box display='flex' justifyContent='center' >
                        <Button style={btn} size='large' onClick={handleUpdate}>Update</Button>
                    </Box>
                </Box>
                </Box>
            <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
        </div>
     );
}

const style = {
    border: 'none',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    boxSizing: 'content-box',
    background: '#000',
    p: 2,
    borderRadius: 7,
};

const inputs ={
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
    background: '#eee',
    border: '1px solid #aaa',
}
    
const fullPage ={
width:'100%',
// height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
width: '100%',
}

const loader = {
    // backgroundColor: 'rgba(52, 52, 52, 0.8)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color : '#fff',
  }
 
export default B7Main;