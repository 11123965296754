import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MessagePopup from "../../MessagePopup";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {IconButton , InputAdornment} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import {VIDEO_UPLOAD , PDF_UPLOAD , FILE_UPDATE, QUESTIONS_UPLOAD, PROJECT_UPLOAD, GET_PROJECTS, GET_PROJECT, UPDATE_PROJECT} from '../../../api/courseupload';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ProjectUpload({ courseId , setAdded }) {
  const editor = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState('');
  const [resp, setResp] = React.useState("");
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [add , setAdd] = React.useState(false);
  const [projects , setProjects] = React.useState([]);
  const [editorContent, setEditorContent] = React.useState(projects.description||'');
  const [fileHeading, setFileHeading] = React.useState(projects.heading||'');
  const [editMode , setEditMode] = React.useState(false);
  const [projectId , setProjectId] = React.useState(null);
  const handleEditorChange = (content, delta, source, editor) => {
    setEditorContent(content);
  };
  React.useEffect(() => {
    axios.get(GET_PROJECTS+ `${courseId}`)
    .then(moduledata => {
      setProjects(moduledata.data.message);
    })
    .catch(err => {
      console.log(err);
    })
  },[add]);
  
  const handleClickOpen = () => {
    setOpen(true);
    setEditMode(false);

  };
  const handleEditOpen = () => {
    setOpen(true);
    axios.get(GET_PROJECTS+ `${courseId}`)
    .then(moduledata => {
      setEditorContent(moduledata.data.message[0].description);
      setFileHeading(moduledata.data.message[0].heading);
      setProjectId(moduledata.data.message[0]._id);
      setEditMode(true);
    })
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleUpload = () => {
    if(!editMode){
      axios
      .post(PROJECT_UPLOAD , {
          heading: fileHeading,
          description: editorContent,
          courseId : courseId

      })
      .then(res => {
          setOpen(false);
          setAdded(true);
          setAdd(true);
      })
      .catch(err => {
          console.log(err);
      })
    }
    else {
      axios
      .patch(UPDATE_PROJECT+projectId , {
          heading: fileHeading,
          description: editorContent,
          courseId : courseId

      })
      .then(res => {
          setOpen(false);
          setAdded(true);
          setAdd(true);

      })
      .catch(err => {
          console.log(err);
      })
    }
        
  }

  return (
    <div>
      {projects && projects.length === 1 ?<Button variant="contained" onClick={handleEditOpen} style={{ backgroundColor: '#6A58C6' , color: '#fff' }}>
        Edit Project
      </Button>  :<Button variant="contained" onClick={handleClickOpen} style={{ backgroundColor: '#6A58C6' , color: '#fff' }}>
        Add Project 
      </Button>}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Upload Project
            </Typography>
            <Button 
              style={{color:'#fff'}}
              onClick={handleUpload}
              >Upload</Button>
          </Toolbar>
        </AppBar>
        <DialogTitle>Upload Project</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>

          <TextField
            id="standard-basic"
            variant="standard"
            placeholder='Heading'
            value={fileHeading}
            onChange={(e) => setFileHeading(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
   
           
              <ReactQuill
                  theme="snow"
                  value={editorContent}
                  onChange={handleEditorChange}
                  placeholder="Write something amazing..."
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'],        
                      ['blockquote', 'code-block'],
                      [{ 'header': 1 }, { 'header': 2 }],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      [{ 'script': 'sub'}, { 'script': 'super' }],      
                      [{ 'indent': '-1'}, { 'indent': '+1' }],
                      [{ 'direction': 'rtl' }],
                      [{ 'size': ['small', false, 'large', 'huge'] }],  
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      [{ 'color': [] }, { 'background': [] }],          
                      [{ 'font': [] }],
                      [{ 'align': [] }],
                      ['clean']                                         
                    ],
                  }}
                />
        
        </DialogContent>
      </Dialog>
      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => { 
          setOpenPopUp(!openPopUp)}}
      />
    </div>
  );
  }
