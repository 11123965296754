import { Box, Button, Modal, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { CardContent, Card } from '@mui/material';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MessagePopup from "../MessagePopup";

const {GET_ALL_EVENTS ,NEW_EVENT,INTO_COUROUSEL,INTO_EVENT,
        EDIT_EVENT, DELETE_EVENT} = require("../../api/bookevents");

const deleteButton = (params) => {
    return (
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                // onClick={() => {
                //     parseName(params.row.col6)
                // }}
            >
                Delete
            </Button>
        </strong>
    )
};
const switchButton = (params) => {
    return (
        <strong>
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                // onClick={() => {
                //     parseName(params.row.col6)
                // }}
            >
                Switch
            </Button>
        </strong>
    )
};
const Bookevent = ({isAuth,a_events}) => {
    const classes=Usestyles();

    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");
    
    const [events,setEvents]=useState([]);
    const [eventtype,setEventtype]=useState("meetups");
    const [credit,setCredit]=useState("");
    const [date,setDate]=useState("");
    const [fee,setFee]=useState("");
    const [file,setFile]=useState("");
    const [link,setLink]=useState("");
    const [meetlink,setMeetlink]=useState("");
    const [title,setTitle]=useState("");
    const [speaker,setSpeaker]=useState("");
    const [aboutspeaker,setAboutspeaker]=useState("");

    const [openedit,setOpenedit]=useState(false);
    const [event,setEvent]=useState([]);
    const [opendelete,setOpendelete]=useState(false);
    const [opencreate,setOpencreate]=useState(false);

    useEffect(()=>{
        axios
        .get(GET_ALL_EVENTS , {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
        .then((res) => {
            setEvents(res.data.result);
        })
        .catch((err) => {
            console.log(`Error: ${err}`);
        });
    },[]);

    const createsubmit=()=>{
        const data=new FormData();
        const ID = window.localStorage.getItem("id");
        data.append("event", eventtype);
        data.append("credit", credit);
        data.append("date", date);
        data.append("eventfee", fee);
        if(file)data.append("file", file);
        data.append("link", link);
        data.append("meetlink", meetlink);
        data.append("title", title);
        data.append("speaker", speaker);
        data.append("aboutSpeaker", aboutspeaker);
        axios
            .post(NEW_EVENT+ID, data, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
            })
            .then((response) => {
            setResp("Created Successfully");
            setOpenPopUp(true);
            setOpencreate(false);
            window.location.href = "/bookevents"
            })
            .catch((err) => {
                console.log(err);
                setResp("Some error occured");
                setOpenPopUp(true);
            });
    }

    const editsubmit=()=>{
        const data=new FormData();
        const ver = window.localStorage.getItem("id");
        data.append("event", eventtype);
        data.append("credit", credit);
        data.append("date", date);
        data.append("fee", fee);
        if(file)data.append("file", file);
        data.append("link", link);
        data.append("meetlink", meetlink);
        data.append("title", title);
        data.append("speaker", speaker);
        data.append("aboutSpeaker", aboutspeaker);
        axios
            .put(EDIT_EVENT +event._id , data, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
            })
            .then((response) => {
                setResp("Edited Successfully");
                setOpenPopUp(true);
                setOpenedit(false);
                window.location.href = "/bookevents"
            })
            .catch((err) => {
                console.log(err);
                setResp("Something error occured");
                setOpenPopUp(true);
            });
    };

    const deletesubmit=()=>{
        axios.delete(DELETE_EVENT+event._id,{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
        .then((res)=>{
            setResp("Deleted Successfully");
            setOpenPopUp(true);
            setOpendelete(false);
            window.location.href = "/bookevents"
        })
        .catch((err)=>{
            console.log(err);
            setResp("Something error occured");
            setOpenPopUp(true);
        })
    };

    const intoevents=(id)=>{
        axios.post(INTO_EVENT+id,{},{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
        .then((res)=>{
            setResp("added into events");
            setOpenPopUp(true);
            window.location.href = "/bookevents"
        })
        .catch((err)=>{
            console.log(err);
            setResp("Something error occured");
            setOpenPopUp(true);
        })
    };

    const intocourousal=(id)=>{
        axios.post(INTO_COUROUSEL+id,{},{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
        .then((res)=>{
            setResp("added into courousal");
            setOpenPopUp(true);
            window.location.href = "/bookevents"
        })
        .catch((err)=>{
            console.log(err);
            setResp("Something error occured");
            setOpenPopUp(true);
        })
    }

    const columns = [
        { field: '_id', headerName: 'ID', width: 90 },
        {
            field: 'app',
            headerName: 'Appearance',
            width: 150,
            editable: true,
            renderCell:(params)=>{
                const imp=params.row.isImportant;
                if(imp){
                    return(
                        <p>carousal</p>
                    )
                }else{
                    return(
                        <p>event</p>
                    )
                }
            }
        },
        {
          field: 'title',
          headerName: 'Heading of Event',
          width: 150,
          editable: true,
        },
        {
          field: 'aboutSpeaker',
          headerName: 'Description of event, speaker',
          width: 150, 
          editable: true,
        },
        {
            field: 'eventDate',
            headerName: 'Date',
            width: 150,
            editable: true,
        },
        {
            field: 'editbtn',
            headerName: 'Edit',
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (params)=>{
              const onClick=()=>{
                setEvent(params.row);
                setOpenedit(true);
                setDate(params.row.eventDate);
                setCredit(params.row.credit);
                setFee(params.row.eventfee);
                setFile(params.row.posterPath);
                setLink(params.row.link);
                setMeetlink(params.row.meetlink);
                setTitle(params.row.title);
                setAboutspeaker(params.row.aboutSpeaker);
                setSpeaker(params.row.speaker);
              };
              return(
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={onClick}
                >
                    Edit
                </Button>
              );
            },
        },
        {
            field: 'deletebtn',
            headerName: 'Delete',
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (params)=>{
              const onClick=()=>{
                setEvent(params.row);
                setOpendelete(true);
              };
              return(
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={onClick}
                >
                    Delete
                </Button>
              );
            },
        },
        {
            field: 'switchbtn',
            headerName: 'Switch',
            width: 120,
            disableClickEventBubbling: true,
            renderCell: (params)=>{
              const onClick=()=>{
                if(params.row.isImportant){
                    intoevents(params.row._id);
                }else{
                    intocourousal(params.row._id);
                }
              };
              return(
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={onClick}
                >
                    Switch
                </Button>
              );
            },
        },
    ];
    const createbox=(
        <Box className={classes.boxcontainer}>
          <Box className={classes.box}>
            <Card className={classes.cardbox}>
              <Typography variant='h4'>create event</Typography>
              <CardContent>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Event type</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="meetups"
                        name="radio-buttons-group"
                        onChange={(e)=>setEventtype(e.target.value)}
                    >
                        <FormControlLabel value="meetups" control={<Radio />} label="Meet ups" />
                        <FormControlLabel value="workshops" control={<Radio />} label="Workshops" />
                        <FormControlLabel value="sessions" control={<Radio />} label="Sessions" />
                    </RadioGroup>
                </FormControl>
              </CardContent>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    label="Title" 
                    onChange={(e)=> setTitle(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    label="speaker" 
                    onChange={(e)=> setSpeaker(e.target.value)}
                    />
                </CardContent>
              </Box>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    label="about Speaker" 
                    onChange={(e)=> setAboutspeaker(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    {/* <TextField
                    fullWidth 
                    className={classes.name} 
                    label="date" 
                    onChange={(e)=> setDate(e.target.value)}
                    /> */}
                    {/* <TextField
                        id="datetime-local"
                        label="Next appointment"
                        type="datetime-local"
                        defaultValue="2017-05-24T10:30"
                        className={classes.name}
                        onChange={(e)=>setDate(e.target.value)}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    /> */}
                    <TextField
                        id="datetime-local"
                        label="Event Date"
                        type="date"
                        size="small"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        defaultValue="1999-01-01"
                        className={classes.name}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </CardContent>
              </Box>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    label="credit" 
                    onChange={(e)=> setCredit(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    label="fee" 
                    onChange={(e)=> setFee(e.target.value)}
                    />
                </CardContent>
              </Box>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    label="meetlink" 
                    onChange={(e)=> setMeetlink(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    label="link" 
                    onChange={(e)=> setLink(e.target.value)}
                    />
                </CardContent>
              </Box>
              <Button
              variant="contained"
              component="label"
              >
                Upload Poster
                <input
                  type="file"
                  hidden
                  onChange={(e)=>setFile(e.target.files[0])}
                />
              </Button>
            </Card>
            <Button className={classes.btn} onClick={createsubmit} >Submit</Button>
          </Box>
        </Box>
    )
    const editbox=(
        <Box className={classes.boxcontainer}>
          <Box className={classes.box}>
            <Card className={classes.cardbox}>
              <Typography>edit event</Typography>
              <CardContent>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Event type</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="meetups"
                        name="radio-buttons-group"
                        onChange={(e)=>setEventtype(e.target.value)}
                    >
                        <FormControlLabel value="meetups" control={<Radio />} label="Meet ups" />
                        <FormControlLabel value="workshops" control={<Radio />} label="Workshops" />
                        <FormControlLabel value="sessions" control={<Radio />} label="Sessions" />
                    </RadioGroup>
                </FormControl>
              </CardContent>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.title}
                    label="Title" 
                    onChange={(e)=> setTitle(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.speaker}
                    label="speaker" 
                    onChange={(e)=> setSpeaker(e.target.value)}
                    />
                </CardContent>
              </Box>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.aboutSpeaker}
                    label="about Speaker" 
                    onChange={(e)=> setAboutspeaker(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.eventDate}
                    label="date" 
                    onChange={(e)=> setDate(e.target.value)}
                    />
                </CardContent>
              </Box>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.credit}
                    label="credit" 
                    onChange={(e)=> setCredit(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.eventfee}
                    label="fee" 
                    onChange={(e)=> setFee(e.target.value)}
                    />
                </CardContent>
              </Box>
              <Box className={classes.editmini}>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.meetlink}
                    label="meetlink" 
                    onChange={(e)=> setMeetlink(e.target.value)}
                    />
                </CardContent>
                <CardContent className={classes.cardbox}>
                    <TextField
                    fullWidth 
                    className={classes.name} 
                    defaultValue={event.link}
                    label="link" 
                    onChange={(e)=> setLink(e.target.value)}
                    />
                </CardContent>
              </Box>
              <Button
              variant="contained"
              component="label"
              >
                Upload Poster
                <input
                  type="file"
                  hidden
                  onChange={(e)=>setFile(e.target.files[0])}
                />
              </Button>
            </Card>
            <Button className={classes.btn} onClick={editsubmit} >Submit</Button>
          </Box>
        </Box>
    );
    const deleteBox=(
        <Box className={classes.deletecontainer}>
          <Box className={classes.deletebox}>
            <Typography>Are you sure, you want to delete?</Typography>
            <Box>
              <Button onClick={deletesubmit} className={classes.btn} style={{"marginRight":'10px'}}>Yes</Button>
              <Button onClick={()=> setOpendelete(false) } className={classes.btn}>No</Button> 
            </Box>
          </Box>
        </Box>
    );
  return (
    <div>
        {
            a_events?(
                <div className={classes.container}>
                    <Box display='flex' justifyContent='space-between' padding='10px'>
                        <Typography variant='h5'>Book Events</Typography>
                        <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={()=>{setOpencreate(true);}}
                        >Create</Button>
                    </Box>
                    <DataGrid
                    rows={events}
                    getRowId={(row) => row._id}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    disableSelectionOnClick
                    />
                    <Modal
                    open={opencreate}
                    onClose={()=>setOpencreate(false)}
                    >
                        {createbox}
                    </Modal>
                    <Modal
                    open={openedit}
                    onClose={()=>setOpenedit(false)}
                    >
                        {editbox}
                    </Modal>
                    <Modal
                    open={opendelete}
                    onClose={()=>setOpendelete(false)}
                    >
                        {deleteBox}
                    </Modal>
                    <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
                </div>
            ):(
                <Box>
                    <Typography>You are not allowed</Typography>
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <Button>Go back</Button>
                    </Link>
                </Box>
            )
        }
    </div>
  )
}

const Usestyles=makeStyles({
    container:{
        width:'100%',
        height:'100vh'
    },
    boxcontainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        //width:'100%',
        //height:'100%'
    },
    box:{
        width:'70%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        //backgroundColor:'yellow',
        "@media only screen and (max-width: 420px)":{
            width:'90%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column',
            //backgroundColor:'yellow',
            marginTop:'20px'
        }
    },
    cardbox:{
        width:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column'
    },
    name:{
        //margin:'5px'
    },
    btn:{
        backgroundColor:'#8dffcc',
        marginTop:'10px'
    },
    deletecontainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        //backgroundColor:'yellow'
    },
    deletebox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
        backgroundColor:'white',
        marginTop:'100px',
        borderRadius:'10px',
        padding:'30px'
    },
    editmini:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'100%'
    }
});

export default Bookevent