import { Box, Button, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MessagePopup from "../MessagePopup";

const {GET_ALL_DETAILS ,DELETE_FUND } = require("../../api/funding");

const Funding = () => {
    const classes = u();

    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [openDelete,setOpenDelete]=useState(false);

    const [FundingsData , setFundingsData] = useState([]);
    const [pickedFund , setPickedFund] = useState([]); 

    const [openDetails , setOpenDetails] = useState(false);

    useEffect(()=>{
        axios
            .get(GET_ALL_DETAILS, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setFundingsData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },[]);

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = `Fundings_${Date.now()}`;

    const exportToCSV = (FundingsData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(FundingsData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const deleteSubmit = () => {
        const id = pickedFund._id;
    
        axios
            .delete(DELETE_FUND+id,{
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                setResp("Deleted Successfully");
                setOpenPopUp(true);
                window.location.href="/funding"
            })
            .catch((err) => {
                setResp("Something error occured");
                setOpenPopUp(true);
            });
        setOpenDelete(false);
    }

    const deleteBox = (
        <Box className={classes.style}>
            <Typography variant='h5' style={{marginBottom: '5px'}}>Are you sure want to delete ?</Typography>
            <Box>
                <Button 
                    onClick={deleteSubmit} 
                    style={{background: 'black' , color: 'white' , margin: '3px'}}
                >
                    Yes
                </Button>
                <Button 
                    onClick={() => setOpenDelete(false)} 
                    style={{background: 'black' , color: 'white',margin: '3px'}}
                >
                    No
                </Button>
            </Box>
        </Box>
    )

    const DetailsBox = (
        <Box>
            <Box className={classes.style}>
                <Typography variant='h4' style={{fontStyle:'bold', textTransform:'uppercase', marginBottom:'10px'}}>
                            {pickedFund.name}
                </Typography>
                <Box display='flex'>
                    <Box marginBottom='auto' marginTop='auto' textAlign='left' marginRight='10px'>
                        <Typography fontSize='20px' style={{textDecoration: 'underline'}} >email</Typography>
                    </Box>
                    <Typography style={{fontSize: '17px'}}>{pickedFund.email}</Typography>
                </Box>
                <Box display='flex'>
                    <Box marginBottom='auto' marginTop='auto' textAlign='left' marginRight='10px'>
                        <Typography fontSize='20px' style={{textDecoration: 'underline'}}>contactNo</Typography>
                    </Box>
                    <Typography style={{fontSize: '17px'}}>{pickedFund.contactNo}</Typography>
                </Box>
                <Box display='flex'>
                    <Box marginBottom='auto' marginTop='auto' textAlign='left' marginRight='10px'>
                        <Typography fontSize='20px' style={{textDecoration: 'underline'}}>WebsiteLink</Typography>
                    </Box>
                    <Typography style={{fontSize: '17px'}}>{pickedFund.websiteLink}</Typography>
                    {/* <a href={pickedFund.websiteLink} target='_blank' ><Typography>click e</Typography></a> */}
                </Box>
                <Box display='flex'>
                    <Box marginBottom='auto' marginTop='auto' textAlign='left' marginRight='10px'>
                        <Typography fontSize='20px' style={{textDecoration: 'underline'}}>FounderLinkedin</Typography>
                    </Box>
                    {/* <Typography></Typography> */}
                    <a href={pickedFund.founderLinkedin} target='_blank' rel="noreferrer">Click here</a>
                </Box>
                <Box display='flex'>
                    <Box marginBottom='auto' marginTop='auto' textAlign='left' marginRight='10px'>
                        <Typography fontSize='20px' style={{textDecoration: 'underline'}}>Stage</Typography>
                    </Box>
                    <Typography style={{fontSize: '17px'}}>{pickedFund.stage}</Typography>
                </Box>
                <Box display='block'>
                    <Box marginBottom='auto' marginTop='auto' textAlign='left' marginRight='10px'>
                        <Typography fontSize='20px' style={{textDecoration: 'underline'}}>Description </Typography>
                    </Box>
                    <Box textAlign='left'>
                        <Typography style={{fontSize: '17px'}}>{pickedFund.description}</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column'>
                    <Box marginBottom='auto' marginTop='auto' textAlign='left' marginRight='10px'>
                        <Typography fontSize='20px' style={{textDecoration: 'underline'}}>Any Further Support: </Typography>
                    </Box>
                    <Typography style={{fontSize: '17px'}}>{pickedFund.furtherSupport}</Typography>
                </Box>
            </Box>
        </Box>
    )

    // Columns in data grid
    const columns = [
        { field: '_id', headerName: 'ID', width: 80 ,cellClassName: 'Cell-Col',},
        {
            field: 'name' ,
             headerName: 'Startup Name',
             width: 200,
             cellClassName: 'column',
             headerClassName: 'column-header',
             cellAlign: 'center',
        },
        {
            field: 'email' , 
            headerName: 'Email', 
            width: 250,
            cellClassName: 'column',
            headerClassName: 'column-header',
        },
        {
            field: 'Delete',
            headrName: 'Delete',
            headerClassName: 'column-header',
            cellClassName: 'column',
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params)=>{
              const onClick=()=>{
                setPickedFund(params.row);
                setOpenDelete(true);
              };
              return(
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 ,padding: '5px' ,background: '#ed4253'}}
                    onClick={onClick}
                >
                    Delete
                </Button>
              );
            }
        },
        {
            field: 'Details',
            headrName: 'Details',
            headerClassName: 'column-header',  
            cellClassName: 'column',
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const onClick = () => {
                    setPickedFund(params.row);
                    setOpenDetails(true);
                };
                return(
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{marginRight: '16px', background: '#eb8546'}}
                        onClick={onClick}
                    >
                        Details
                    </Button>
                );
            }
        },

    ];

    return (  
        <div>
            <Box display='flex' justifyContent='space-between' padding='10px'>
                <Typography variant='h5'>Fundings</Typography>
                <Button onClick={(e) => exportToCSV(FundingsData, fileName)}  size='medium' variant="contained" color='primary'>Download Data</Button>
            </Box>
            <Box
                sx={{
                    height: 800,
                    width: '100%',
                    '& .column': {
                        fontSize: '16px',
                        justifyCOntent: 'center',
                        textAlign: 'center'
                    },
                    '& .column-header': {
                        color: 'purple',
                        fontSize: '20px',
                    },
                }}
                >   
                <DataGrid
                rows={FundingsData}
                className='table-box'
                getRowId= {(row) => row._id}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                />
            </Box>    

           <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>

            <Box width={400}>
                <Modal
                    open={openDelete}
                    onClose={()=>setOpenDelete(false)}
                >
                    {deleteBox}
                </Modal>      
            </Box> 
           <Box display='flex' justifyContent='center'>
            <Modal
                    open={openDetails}
                    onClose={()=>setOpenDetails(false)}
                >
                    {DetailsBox}
                </Modal>   
            </Box>  
        </div>
    );
}

const u = makeStyles({
    style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: '#fff',
        border: "none",
        borderRadius: "3px",
        textAlign: 'center',
        color: "black",
        width: 400,
        p: 4,
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: '1rem',
        paddingBottom: '1rem',
        "@media only screen and (max-width: 726px)":{
            width: '90%',
            p: 3,
        }
    }
})
 
export default Funding;