const { BASE_URL } = require("./client");

const GET_ALL_DETAILS = BASE_URL + "/websiteaccess";
const NEW_ACCESS = BASE_URL + "/websiteaccess/register";
const UPDATE_ACCESS = BASE_URL + "/websiteaccess/";
const DELETE_ACCESS = BASE_URL + "/websiteaccess/";
const REVOKE_ACCESS = BASE_URL + "/websiteaccess/";
const USER_DATA =  BASE_URL + "/websiteaccess/userData";

export {
  GET_ALL_DETAILS,
  NEW_ACCESS,
  UPDATE_ACCESS,
  DELETE_ACCESS,
  REVOKE_ACCESS,
  USER_DATA,
};
