import React,{useState,useEffect} from "react";
import { Box,Typography,FormGroup,InputBase,Button, TextareaAutosize } from "@material-ui/core";
import axios from "axios";
import MessagePopup from "../MessagePopup";

const {UPDATE_B3_MAINPAGE,GET_B3_MAINPAGE} = require("../../api/MainPage/b3")

const B3Main = () => {
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [num1,setNum1] = useState("");
    const [num2,setNum2] = useState("");
    const [num3,setNum3] = useState("");

    const [title1,setTitle1] = useState("");
    const [title2,setTitle2] = useState("");
    const [title3,setTitle3] = useState("");

    const [desc1 , setDesc1] = useState("");
    const [desc2 , setDesc2] = useState("");
    const [desc3 , setDesc3] = useState("");

    useEffect(() => {
        axios.get(GET_B3_MAINPAGE,{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
        })
        .then(async(resp) => {
            setNum1(resp.data.num1);
            setNum2(resp.data.num2);
            setNum3(resp.data.num3);
            setDesc1(resp.data.desc1);
            setDesc2(resp.data.desc2);
            setDesc3(resp.data.desc3);
            setTitle1(resp.data.title1);
            setTitle2(resp.data.title2);
            setTitle3(resp.data.title3);
        })
        .catch(err => {
            console.log(err);
        })
    },[]);

    const handleUpdate = () => {
        const new_Data = {
            title1: title1,
            title2: title2,
            title3: title3,
            desc1: desc1,
            desc2: desc2,
            desc3: desc3,
            num1: num1,
            num2: num2,
            num3: num3,
          }
          axios.put( UPDATE_B3_MAINPAGE,new_Data,{
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then(response => {
            setResp("Edited Successfully");
            setOpenPopUp(true);
          })
          .catch(err => {
            setResp("Some error occured");
            setOpenPopUp(true);
          });
    }

    return ( 
        <div style={fullPage}>
            <Box  width={450}>
                <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
                    Update B3 Page
                </Typography>
                <Box sx={style}>
                    <FormGroup>
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Details 1
                        </Typography>
                        <InputBase
                            placeholder='Heading' 
                            style={inputs}  
                            value={num1}
                            onChange={(e) => setNum1(e.target.value)}
                        />
                         <InputBase 
                            placeholder='SubTitle' 
                            style={inputs}
                            value={title1}
                            onChange={(e) => setTitle1(e.target.value)}
                        /> 
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={desc1}
                            onChange={(e) => setDesc1(e.target.value)}
                        /> 
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Details 2
                        </Typography>
                        <InputBase
                            placeholder='Heading' 
                            style={inputs}  
                            value={num2}
                            onChange={(e) => setNum2(e.target.value)}
                        />
                         <InputBase 
                            placeholder='SubTitle' 
                            style={inputs}
                            value={title2}
                            onChange={(e) => setTitle2(e.target.value)}
                        /> 
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={desc2}
                            onChange={(e) => setDesc2(e.target.value)}
                        />  
                        <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff', borderBottom: '2px solid #68F5D4',marginTop: 10, marginBottom: 10,}}>
                            Details 3
                        </Typography>
                        <InputBase
                            placeholder='Heading' 
                            style={inputs}  
                            value={num3}
                            onChange={(e) => setNum3(e.target.value)}
                        />
                         <InputBase 
                            placeholder='SubTitle' 
                            style={inputs}
                            value={title3}
                            onChange={(e) => setTitle3(e.target.value)}
                        /> 
                        <TextareaAutosize
                            minRows={2}
                            placeholder='Description' 
                            style={inputs}
                            value={desc3}
                            onChange={(e) => setDesc3(e.target.value)}
                        /> 
                    </FormGroup>
                    <Box display='flex' justifyContent='center' marginTop={2}>
                        <Button style={btn} size='large' onClick={handleUpdate}>Update</Button>
                    </Box>
                </Box>
                </Box>
                <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
        </div>
     );
}

const style = {
    border: 'none',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    boxSizing: 'content-box',
    background: '#000',
    p: 2,
    borderRadius: 7,
};

const inputs ={
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
    background: '#eee',
    border: '1px solid #aaa',
}
    
const fullPage ={
width:'100%',
// height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
width: '100%',
}
 
export default B3Main;