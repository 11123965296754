import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'

const Learner = () => {
    return ( 
        <div>
            <Box>
                <Link to="/Mainside_learner" style={{textDecoration: "none"}}><Button>Main side</Button></Link>
                <Link to="/bluebox1" style={{textDecoration: "none"}}><Button>Blue box1</Button></Link>
                <Link to="/bluebox2" style={{textDecoration: "none"}}><Button>Blue box2</Button></Link>
                <Link to="/block1" style={{textDecoration: "none"}}><Button>Block 1</Button></Link>
                <Link to="/block2" style={{textDecoration: "none"}}><Button>Block 2</Button></Link>
                <Link to="/shorts" style={{textDecoration: "none"}}><Button>Shorts</Button></Link>
            </Box>
        </div>
     );
}
 
export default Learner;