import * as React from 'react';
import {Box, Typography} from '@mui/material';
import axios from 'axios';
import { GET_MENTORS } from '../../api/mentor';
import { DataGrid } from '@mui/x-data-grid';

const Mentors = () => {
    const [mentors , setMentors] = React.useState([]);
    React.useEffect(() => {
        axios
        .get(GET_MENTORS)
        .then(res => {
            const men = res.data.data.map((mentor , index) => {
                mentor.id = index+1; 
                return mentor;
            });
            
            setMentors(men);
        })
        .catch(err => {
            console.error(err);
        })
    },[]);
    const columns = [
        { field: '_id', headerName: 'ID', width: 90 },
        {
          field: 'firstName',
          headerName: 'First name',
          width: 150,
        },
        {
          field: 'lastName',
          headerName: 'Last name',
          width: 150,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 250,
        },
        {
          field: 'whatsappNumber',
          headerName: 'Whatsapp Number',
          width: 160,
        },
        {
            field: 'domain',
            headerName: 'Domain',
            width: 160,
          },
          {
            field: 'hoursPerWeek',
            headerName: 'Hours Per Week',
            width: 160,
          },
          {
            field: 'currentCompany',
            headerName: 'Current Company',
            width: 160,
          },
          {
            field: 'offlineSessions',
            headerName: 'Offline Sessions',
            width: 160,
          },
          {
            field: 'source',
            headerName: 'Source',
            width: 160,
          },
      ];

  return (
    <Box>
    <Typography variant='h6' sx={{marginBottom: 3}}>Applied Mentors</Typography>
    <Box sx={{ height: '100vh', width: '100%' }}>
      <DataGrid
        rows={mentors}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
    </Box>
    </Box>
  )
}

export default Mentors