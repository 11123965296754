const { BASE_URL } = require("./client");

const GET_ALL_EVENTS = BASE_URL + "/api/eventAbout";
const NEW_EVENT = BASE_URL + "/api/eventupload/";
const EDIT_EVENT = BASE_URL + "/api/event/";
const DELETE_EVENT = BASE_URL + "/api/event/";
const INTO_EVENT = BASE_URL + "/api/eventnotimp/";
const INTO_COUROUSEL = BASE_URL + "/api/eventimp/";

export {
  GET_ALL_EVENTS,
  NEW_EVENT,
  EDIT_EVENT,
  DELETE_EVENT,
  INTO_COUROUSEL,
  INTO_EVENT,
};
