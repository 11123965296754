import React from 'react'
import { FormGroup, makeStyles, Modal, TextField } from '@material-ui/core'
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Typography , MenuItem } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CREATE_C_L_COMPONENT, DELETE_C_L_COMPONENT, EDIT_C_L_COMPONENT, GET_C_L_COMPONENT } from '../../api/courselandingcomponent';
import MessagePopup from '../MessagePopup';      
import { GET_COURSES } from '../../api/promocode';


const CertLandingComponent = ({isAuth ,a_watchnow}) => {
    const classes=Usestyles();
    const classe = u();
    const [data, setData] = useState([]);
    const [openedit,setOpenedit]=useState(false);
    const [watchdata,setWatchdata]=useState([]);
    const [courseName , setCourseName] = useState(watchdata.courseName);
    const [crossedPrice , setCrossedPrice] = useState(watchdata.crossedPrice);
    const [finalPrice , setFinalPrice] = useState(watchdata.finalPrice);
    const [subheading , setSubheading] = useState(watchdata.subheading);
    const [btn1Name , setbtn1Name] = useState(watchdata.btn1Name);
    const [btn1Link , setbtn1Link] = useState(watchdata.btn1Link);
    const [btn2Name , setbtn2Name] = useState(watchdata.btn2Name);
    const [btn2Link , setbtn2Link] = useState(watchdata.btn2Link);
    const [scc , setScc] = useState(watchdata.scc);
    const [duration , setDuration] = useState(watchdata.duration);
    const [startingDate , setStartingDate] = useState(watchdata.startingDate);
    const [opendelete,setOpendelete]=useState(false);
    const [opencreate,setOpencreate]=useState(false);
    const [refresh , setRefresh] = useState(false);
    const [resp, setResp] = React.useState("");
    const [openPopUp, setOpenPopUp] = React.useState(false);
    const [courses , setCourses] = React.useState([]);
    const [course , setCourse] = React.useState('');

    useEffect(() => {
      axios
        .get(GET_C_L_COMPONENT)
        .then((res) => {
          setData(res.data.data);
          setRefresh(false);
        });
    }, [refresh]);
    useEffect(()=> {
        axios
        .get(GET_COURSES)
        .then(res => {
          const currenciesData = res.data.data.map((course) => ({
            value: course.courseName,
            label: course.courseName
          }));
          setCourses(currenciesData);
        })
        .catch(err => {
          console.log(err);
        })
    },[])
    const createSubmit=()=>{
        const data = {
            courseName,
            subheading,
            btn1Name,
            btn1Link,
            btn2Name,
            btn2Link,
            crossedPrice,
            finalPrice,
            scc,
            duration,
            startingDate
        }
      axios
      .post(CREATE_C_L_COMPONENT,data)
      .then((res)=>{
        setResp("Created Successfully");
        setOpenPopUp(true);
        setOpencreate(false);
        setRefresh(true)
      })
      .catch((err)=>{
        setResp("please try again later");
        setOpenPopUp(true);

      })
    }

    const editsubmit=()=>{
      const data = {
        courseName,
        subheading,
        btn1Name,
        btn1Link,
        btn2Name,
        btn2Link,
        crossedPrice,
        finalPrice,
        scc,
        duration,
        startingDate
      }
      axios
      .patch(EDIT_C_L_COMPONENT+watchdata._id, data)
      .then((response) => {
        setResp("sucessfully edited component");
        setOpenPopUp(true);
        setOpenedit(false);
        setRefresh(true)
      })
      .catch((err) => {
        setResp("Please Try again later!");
        setOpenPopUp(true);

      });
    };

    const deletesubmit=()=>{
      axios.delete(DELETE_C_L_COMPONENT+watchdata._id)
      .then((response)=>{
        setResp("sucessfully deleted");
        setOpenPopUp(true);
        setRefresh(true);
        setOpendelete(false);
      })
      .catch((err)=>{
        setResp("please try again later,some error occured");
        setOpenPopUp(true);

      })
    };
    const columns = [
      { field: '_id', headerName: '_id', width: 90 },
      {
        field: 'courseName',
        headerName: 'Course Name',
        width: 150,
        headerClassName: 'column-header',
      },
      {
        field: 'subheading',
        headerName: 'Sub Heading',
        headerClassName: 'column-header',
        width: 150, 
      },
      {
        field: 'crossedPrice',
        headerName: 'Crossed Price',
        headerClassName: 'column-header',
        width: 100, 
      },{
        field: 'finalPrice',
        headerName: 'Final Price',
        headerClassName: 'column-header',
        width: 100, 
      },{
        field: 'scc',
        headerName: 'Small Case Component',
        headerClassName: 'column-header',
        width: 150, 
      },{
        field: 'btn1Name',
        headerName: 'Button 1 Name',
        headerClassName: 'column-header',
        width: 100, 
      },{
        field: 'btn1Link',
        headerName: 'Button 1 Link',
        headerClassName: 'column-header',
        width: 100, 
      },{
        field: 'btn2Name',
        headerName: 'Button 2 Name',
        headerClassName: 'column-header',
        width: 100, 
      },{
        field: 'btn2Link',
        headerName: 'Button 2 Link',
        headerClassName: 'column-header',
        width: 100, 
      },{
        field: 'duration',
        headerName: 'Duration',
        headerClassName: 'column-header',
        width: 150, 
      },{
        field: 'startingDate',
        headerName: 'Starting Date',
        headerClassName: 'column-header',
        width: 150, 
      },
      {
        field: 'editbtn',
        headerName: 'Edit',
        headerClassName: 'column-header',
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (params)=>{
          const onClick=()=>{
            setOpenedit(true);
            setWatchdata(params.row);
            setCourseName(params.row.courseName);
            setSubheading(params.row.subheading);
            setbtn1Name(params.row.btn1Name);
            setbtn1Link(params.row.btn1Link);
            setbtn2Name(params.row.btn2Name);
            setbtn2Link(params.row.btn2Link);
            setCrossedPrice(params.row.crossedPrice);
            setFinalPrice(params.row.finalPrice);
            setScc(params.row.scc);
            setDuration(params.row.duration);
            setStartingDate(params.row.startingDate);
          };
          return(
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{marginRight: '16px', background: '#eb8546'}}
                onClick={onClick}
            >
                Edit
            </Button>
          );
        },
      },
      {
        field: 'deletebtn',
        headerName: 'Delete',
        headerClassName: 'column-header',
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (params)=>{
          const onClick=()=>{
            setWatchdata(params.row);
            setOpendelete(true);
          };
          return(
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{marginRight: '16px', background: '#ed4253'}}
                onClick={onClick}
            >
                Delete
            </Button>
          );
        },
      }
    ];
    const createbox=(
      <Box className={classe.style}>
        <Typography variant='h4'>CREATE A COMPONENT</Typography>
          <FormGroup>
          
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Course Name"
            label="Course Name" 
            onChange={(e)=> setCourseName(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Crossed Price"
            label="Crossed Price" 
            onChange={(e)=> setCrossedPrice(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Final Price"
            label="Final Price" 
            onChange={(e)=> setFinalPrice(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Subheading"
            label="Subheading" 
            onChange={(e)=> setSubheading(e.target.value)}
            />
             <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 1 Name"
            label="Button 1 Name" 
            onChange={(e)=> setbtn1Name(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 1 Link"
            label="Button 1 Link" 
            onChange={(e)=> setbtn1Link(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 2 Name"
            label="Button 2 Name" 
            onChange={(e)=> setbtn2Name(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 2 Link"
            label="Button 2 Link" 
            onChange={(e)=> setbtn2Link(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Small Case Content"
            label="Small Case Content" 
            onChange={(e)=> setScc(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Duration"
            label="Duration" 
            helperText="Format: 1 Month Course"
            onChange={(e)=> setDuration(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Starting Date"
            label="Starting Date" 
            helperText="Format: 12th March 2023(Year Optional)"
            onChange={(e)=> setStartingDate(e.target.value)}
            />
        </FormGroup>
        <div>
          <Button className={classes.btn} onClick={createSubmit}>Submit</Button>
        </div>
    </Box>
    );

    const editbox=(
      <Box className={classe.style}>
        <Typography variant='h4'>EDIT COMPONENT</Typography>
        <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Course Name"
            label="Course Name" 
            value={courseName}
            onChange={(e)=> setCourseName(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Crossed Price"
            label="Crossed Price" 
            value={crossedPrice}
            onChange={(e)=> setCrossedPrice(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Final Price"
            label="Final Price" 
            value={finalPrice}
            onChange={(e)=> setFinalPrice(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Subheading"
            label="Subheading" 
            value={subheading}
            onChange={(e)=> setSubheading(e.target.value)}
            />
             <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 1 Name"
            label="Button 1 Name" 
            value={btn1Name}
            onChange={(e)=> setbtn1Name(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 1 Link"
            label="Button 1 Link"
            value={btn1Link} 
            onChange={(e)=> setbtn1Link(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 2 Name"
            label="Button 2 Name"
            value={btn2Name} 
            onChange={(e)=> setbtn2Name(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Button 2 Link"
            label="Button 2 Link" 
            value={btn2Link}
            onChange={(e)=> setbtn2Link(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Small Case Content"
            label="Small Case Content" 
            value={scc}
            onChange={(e)=> setScc(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Duration"
            label="Duration" 
            helperText="Format: 1 Month Course"
            value={duration}
            onChange={(e)=> setDuration(e.target.value)}
            />
            <TextField
            fullWidth 
            className={classes.name} 
            placeholder="Starting Date"
            label="Starting Date" 
            helperText="Format: 12th March 2023(Year Optional)"
            value={startingDate}
            onChange={(e)=> setStartingDate(e.target.value)}
            />
            {/* <TextField
                id="outlined-select-currency"
                select
                label="Course"
                defaultValue={course}
                style={{
                    width:'100%',
                    marginBottom:'10px'
                }}
                onChange = {(e) => {
                    setCourse(e.target.value);
                }}
                >
                {courses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </TextField> */}
        <div>
          <Button className={classes.btn} onClick={editsubmit}>Submit</Button>
        </div>
      </Box>
    );

    const deleteBox=(
      <Box className={classes.deletecontainer}>
        <Box className={classes.deletebox}>
          <Typography>Are you sure, you want to delete?</Typography>
          <Box>
            <Button onClick={deletesubmit} className={classes.btn} style={{"marginRight":'10px'}}>Yes</Button>
            <Button onClick={()=> setOpendelete(false) } className={classes.btn}>No</Button> 
          </Box>
        </Box>
      </Box>
    );

  return (
    <div>
      {
        true?(
          <div className={classes.container}>
            <Box display='flex' justifyContent='space-between' padding='10px'>
              <Typography variant='h5'>List Of Components For Course</Typography>
              <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>{setOpencreate(true); }}
              >Create</Button>
            </Box>
            <Box 
              sx={{
                height: 800,
                width: '100%',
                '& .column': {
                    fontSize: '16px',
                    justifyCOntent: 'center',
                    textAlign: 'center'
                },
                '& .column-header': {
                    color: 'purple',
                    fontSize: '20px',
                },
            }}
            >
              <DataGrid
              rows={data}
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              />
            </Box>
              <Modal
              open={opencreate}
              onClose={()=>setOpencreate(false)}
              >
                {createbox}
              </Modal>
              <Modal
              open={openedit}
              onClose={()=>setOpenedit(false)}
              >
                {editbox}
              </Modal>
              <Modal
              open={opendelete}
              onClose={()=>setOpendelete(false)}
              >
                {deleteBox}
              </Modal>
          </div>
        ):(
          <Box>
            <Typography>You are not allowed</Typography>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button>Go back</Button>
            </Link>
          </Box>
        )
      }
      <MessagePopup
          open={openPopUp}
          message={resp}
          handleAlertClose={() => setOpenPopUp(!openPopUp)}
        />
    </div>
  )
}

const Usestyles = makeStyles({
  container:{
    width:'100%',
    height:'100vh'
  },
  boxcontainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    overflow:'scroll',
    height:'100vh',
    "@media only screen and (max-width: 420px)":{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      flexDirection:'column',
      //width:'100%',
      overflow:'scroll',
      height:'100vh',
    }
  },
  box:{
    width:'70%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    //backgroundColor:'red',
    marginTop:'50px',
    "@media only screen and (max-width: 420px)":{
      width:'75%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      flexDirection:'column',
      //backgroundColor:'red',
      marginTop:'300px',
    }
  },
  cardbox:{
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column'
  },
  name:{
    //margin:'5px'
  },
  btn:{
    backgroundColor:'#8dffcc',
    marginTop:'10px'
  },
  deletecontainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    //backgroundColor:'yellow'
  },
  deletebox:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    backgroundColor:'white',
    marginTop:'100px',
    borderRadius:'10px',
    padding:'30px'
  },
  tagsbox:{
    display:'flex',
    "@media only screen and (max-width: 420px)":{
      display:"flex",
      flexDirection:'column'
    }
  }
});

const u = makeStyles({
  style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: '#fff',
      border: "none",
      borderRadius: "3px",
      textAlign: 'center',
      color: "black",
      width: 900,
      padding: 10,
    //   paddingLeft: "3rem",
    //   paddingRight: "3rem",
    //   paddingTop: '1rem',
    //   paddingBottom: '1rem',
      "@media only screen and (max-width: 726px)":{
          width: '90%',
          p: 3,
          height: 500,
          overflowY: 'scroll',
      }
  }
})

export default CertLandingComponent;