import * as React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CircularProgress from '@mui/material/CircularProgress';
import CircularWithValueLabel from '../Courses/CircularProgressWithLabel';
import dayjs from 'dayjs';
import axios from 'axios';
import {useLocation , useNavigate} from 'react-router-dom';
import { GET_CERTIFICATES } from '../../../api/certificate';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
export default function ParticularCourse() {
    const location = useLocation();
    const navigate = useNavigate();
    const course = location.state.course;
    const users = location.state.users;
    const [certificates, setCertificates] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [averageDaysToCertificate, setAverageDaysToCertificate] = React.useState(null);
    const [averageDaysToCompletion, setAverageDaysToCompletion] = React.useState(null);
    const [completedProj , setCompletedProj] = React.useState([]);
    const [allCert , setAllCert] = React.useState([]);
    React.useEffect(() => {
        let totalCertificates = 0;
        let totalDays = 0;
        let totalDaysToComplete = 0;

        users.forEach(user => {
            setCompletedProj(user.completedProjects.filter(proj => proj.courseId === course._id));
            let completionPercentage = 0;
            const userCourseDetails = user.courseDetails.find(details => details.courseId.toString() === course._id.toString());
            if (userCourseDetails) {
                const completedVideos = userCourseDetails.videosCompleted.length;
                const completedPdfs = userCourseDetails.pdfsCompleted.length;
                const total = userCourseDetails.total;
                if (completedVideos + completedPdfs > 0) {
                    completionPercentage = ((completedVideos + completedPdfs) / total) * 100;
                    const signUpDateParts = user.signupDate?.split('/');
                    const signUpDate = signUpDateParts && signUpDateParts.length === 3 ? new Date(`20${signUpDateParts[2]}`, signUpDateParts[1] - 1, signUpDateParts[0]) : null;
                    const completionDateParts = userCourseDetails.completedOn?.split('/');
                    const completionDate = completionDateParts && completionDateParts.length === 3 ? new Date(`20${completionDateParts[2]}`, completionDateParts[1] - 1, completionDateParts[0]) : null;
                    if (signUpDate && completionDate) {
                        const differenceInDays = Math.floor((completionDate - signUpDate) / (1000 * 60 * 60 * 24));
                        totalDaysToComplete += differenceInDays;
                    }
                }
            }
            user.completionRate = completionPercentage.toFixed(2);
            user.earnedCertificates.forEach(cert => {
                if (cert.courseId === course._id) {
                    totalCertificates++;
                    const signUpDateParts = user.signupDate?.split('/');
                    const signUpDate = signUpDateParts && signUpDateParts.length === 3 ? new Date(`20${signUpDateParts[2]}`, signUpDateParts[1] - 1, signUpDateParts[0]) : null;
                    const certDateParts = cert.date?.split('/');
                    const certDate = certDateParts && certDateParts.length === 3 ? new Date(`20${certDateParts[2]}`, certDateParts[1] - 1, certDateParts[0]) : null;
                    if (signUpDate && certDate) {
                        const differenceInDays = Math.floor((certDate - signUpDate) / (1000 * 60 * 60 * 24));
                        totalDays += differenceInDays;
                    }
                }
            });
        });

        const average = totalDays / totalCertificates || 0;
        const averageC = totalDaysToComplete / users.length || 0;
        setAverageDaysToCertificate(isNaN(average) ? null : average.toFixed(2)); // Round to two decimal places
        setAverageDaysToCompletion(isNaN(averageC) ? null : averageC.toFixed(2)); // Round to two decimal places
        setCertificates(totalCertificates);
        setLoading(false);
    }, [course, users]);

    return (
        <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <CardHeader
                        sx={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems: 'center' }}
                        title={
                            <>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', fontWeight: 'medium', letterSpacing: '0.05em' }}>
                                    Course
                                </Typography>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', letterSpacing: '0.02em' }}>
                                    {course.courseName}
                                </Typography>
                            </>
                        }
                    />
                    <CardContent sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 4, textAlign: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', fontWeight: 'medium', letterSpacing: '0.05em' }}>
                                Enrolled Users
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {users.length}
                            </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ height: '2.5rem' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', fontWeight: 'medium', letterSpacing: '0.05em' }}>
                                Certificates Generated
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {certificates}
                            </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ height: '2.5rem' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', fontWeight: 'medium', letterSpacing: '0.05em' }}>
                                Average Days To Complete This Course
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {averageDaysToCompletion}
                            </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ height: '2.5rem' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', fontWeight: 'medium', letterSpacing: '0.05em' }}>
                                Avg. Days to Generate Certificate
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {averageDaysToCertificate !== null ? averageDaysToCertificate : 'N/A'}
                            </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ height: '2.5rem' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', fontWeight: 'medium', letterSpacing: '0.05em' }}>
                                Completion Rate
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                <CircularWithValueLabel value={course.completionRate}/>
                            </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ height: '2.5rem' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                            <Typography variant="subtitle1" sx={{ fontSize: '0.75rem', fontWeight: 'medium', letterSpacing: '0.05em' }}>
                                Completed Projects 
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {completedProj.length}
                            </Typography>
                        </div>
                    </CardContent>
                <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Certificate Generated</TableCell>
                                        <TableCell>Project Completed</TableCell>
                                        <TableCell>Completion Percentage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map(user => (
                                        <TableRow key={user.id} sx={{cursor:'pointer'}} onClick={(e) => {navigate("/courses/bought/"+user._id , { state: { courses: user.courses , courseDetails: user.courseDetails , completedProjects: user.completedProjects , userDetails: user} })}}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.earnedCertificates.some(cert => cert.courseId === course._id) ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{completedProj.length>0 ? 'Yes' : 'No'}</TableCell>
                                            <TableCell><CircularWithValueLabel value={user.completionRate}/></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </>
            )}

        </Card>
    );
}
