import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import CircularProgress from '@mui/material/CircularProgress';

const VideoPlayer = ({ videoUrl }) => {
  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    // Reset the loading state when the video URL changes
    setVideoLoading(true);
  }, [videoUrl]);

  const handleVideoReady = () => {
    setVideoLoading(false);
  };

  const handleVideoError = (error) => {
    console.error('Error loading video:', error);
    setVideoLoading(false);
  };

  return (
    <div>
      {videoUrl && (
        <div>
          {videoLoading && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress style={{ color: '#6A58C6' }} />
            </div>
          )}
          <ReactPlayer url={videoUrl} controls onReady={handleVideoReady} onError={handleVideoError} />
        </div>
      )}
      {!videoUrl && <p style={{ color: '#000' }}>Please select a video.</p>}
    </div>
  );
};

export default VideoPlayer;
