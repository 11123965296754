import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import axios from 'axios';
import { GET_COURSES } from '../../api/courseupload';
import { CHECKOUT_URL } from '../../api/client';

const LinkForCourseCheckout = () => {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedTrainingCourses, setSelectedTrainingCourses] = useState([]);
  const [copied, setCopied] = useState(false);
  const [coursesData, setCoursesData] = useState([]);

  useEffect(() => {
    axios
      .get(GET_COURSES)
      .then(res => {
        setCoursesData(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleCourseToggle = (courseName, isTraining) => {
    if (isTraining) {
      setSelectedTrainingCourses(prevSelectedCourses => {
        if (prevSelectedCourses.includes(courseName)) {
          return prevSelectedCourses.filter(name => name !== courseName);
        } else {
          return [...prevSelectedCourses, courseName];
        }
      });
    } else {
      setSelectedCourses(prevSelectedCourses => {
        if (prevSelectedCourses.includes(courseName)) {
          return prevSelectedCourses.filter(name => name !== courseName);
        } else {
          return [...prevSelectedCourses, courseName];
        }
      });
    }
  };

  const generateLink = (courses, baseUrl) => {
    const encodedCourses = courses.map(courseName => encodeURIComponent(courseName));
    return baseUrl + '[' + encodedCourses.join(',') + ']';
  };

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    alert('Link Copied To Clipboard');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const checkoutLink = generateLink([...selectedCourses, ...selectedTrainingCourses], CHECKOUT_URL);
  const trainingLink = generateLink(selectedTrainingCourses, `${CHECKOUT_URL}training/`);

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Generate Link
      </Typography>
      {coursesData.map((course) => (
        <FormControlLabel
          key={course._id}
          control={
            <Checkbox
              checked={selectedCourses.includes(course.courseName) || selectedTrainingCourses.includes(course.courseName)}
              onChange={() => handleCourseToggle(course.courseName, course.trainingEnabled)}
            />
          }
          label={course.courseName}
        />
      ))}
<br/>
      <TextField
        label="Checkout Link"
        variant="outlined"
        value={checkoutLink}
        sx={{ width: '80%' }}
        InputProps={{
          endAdornment: (
            <Button
              onClick={() => copyToClipboard(checkoutLink)}
              color="primary"
              size="small"
              disabled={copied}
            >
              <FileCopyIcon />
            </Button>
          ),
          readOnly: true,
        }}
        margin="normal"
      />
      {/* {coursesData.filter(course => course.trainingEnabled).map((course) => (
        <FormControlLabel
          key={course._id}
          control={
            <Checkbox
              checked={selectedTrainingCourses.includes(course.courseName)}
              onChange={() => handleCourseToggle(course.courseName, true)}
            />
          }
          label={course.courseName}
        />
      ))} */}

      <TextField
        label="Training Link"
        variant="outlined"
        value={trainingLink}
        sx={{ width: '80%' }}
        InputProps={{
          endAdornment: (
            <Button
              onClick={() => copyToClipboard(trainingLink)}
              color="primary"
              size="small"
              disabled={copied}
            >
              <FileCopyIcon />
            </Button>
          ),
          readOnly: true,
        }}
        margin="normal"
      />
    </div>
  );
};

export default LinkForCourseCheckout;
