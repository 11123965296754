import React,{useState,useEffect} from "react";
import { Box,Typography,FormGroup,InputBase,Button, TextareaAutosize } from "@material-ui/core";
import axios from "axios";
import MessagePopup from "../MessagePopup";

const {
    UPDATE_B6_MAINPAGE,
    GET_B6_MAINPAGE,
  } = require("../../api/MainPage/b6");

const DOMAIN = process.env.REACT_APP_DOMAIN;

const B6Main = () => {
    const [openPopUp,setOpenPopUp] = useState(false);
    const [resp,setResp] = useState("");

    const [title,setTitle] = useState("");
    const [description , setDescription] = useState("");
    const [buttonName,setButtonName] = useState("");
    const [buttonLink,setButtonLink] = useState("");

    const [B6Data,setB6Data] = useState([]);
    const [poster,setPoster] = useState(B6Data.poster);

    useEffect(() => {
        axios
        .get(GET_B6_MAINPAGE, {
            headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
        .then(async(res) => {
            await setB6Data(res.data);
            setTitle(res.data.title);
            setDescription(res.data.description);
            setButtonLink(res.data.buttonLink);
            setButtonName(res.data.buttonName);
            // setPoster(res.data.poster);
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    const handleUpdate = () => {
        const data = new FormData();

        data.append("title" , title);
        data.append("description" , description);
        data.append("buttonName",buttonName);
        data.append("buttonLink" , buttonLink);
        if(poster)data.append("poster",poster)

        axios.put( UPDATE_B6_MAINPAGE,data,{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
        .then(response => {
            setResp("Edited Successfully");
            setOpenPopUp(true);
            window.location.href="/B6_Main"
        })
        .catch(err => {
            setResp("Some error occured");
            setOpenPopUp(true);
          });
    }

    return ( 
        <Box style={fullPage}>
            <Box  width={450}>
                    <Typography variant="h5" component="h2" fontWeight='650' style={{color: '#fff'}}>
                        Update B6 Page
                    </Typography>
                <Box sx={style}>
                    <FormGroup>
                        <TextareaAutosize
                            placeholder='Title'
                            minRows={2}
                            style={inputs}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <TextareaAutosize
                            placeholder='Description'
                            minRows={2}
                            style={inputs}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <InputBase 
                            placeholder='Button Name' 
                            style={inputs}
                            value={buttonName}
                            onChange={(e) => setButtonName(e.target.value)}
                        /> 
                        <InputBase 
                            placeholder='Button Link' 
                            style={inputs}
                            value={buttonLink}
                            onChange={(e) => setButtonLink(e.target.value)}
                        /> 
                        <img 
                            src={`${DOMAIN}/mainpage/${B6Data.poster?.name}`} alt=""
                            style={{width: '100px' , height: '100px'}}
                        ></img>
                        <Button
                            variant="contained"
                            component="label"
                            style={{ marginTop: "10px" }}
                            >
                            update poster
                            <input
                                type="file"
                                hidden
                                onChange={(e) => setPoster(e.target.files[0])}
                            />
                            </Button>
                    </FormGroup>
                    <Box display='flex' justifyContent='center' >
                        <Button style={btn} size='large' onClick={handleUpdate}>Update</Button>
                    </Box>
                </Box>
            </Box>
            <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
    </Box>
);
}

const style = {
border: 'none',
boxShadow: 25,
textAlign: 'center',
color:'black',
boxSizing: 'content-box',
background: '#000',
p: 2,
borderRadius: 7,
};

const inputs ={
padding: 8,
marginTop: 7,
marginBottom: 7,
borderRadius: 5,
background: '#eee',
border: '1px solid #aaa',
}

const fullPage ={
width:'100%',
height:'90vh',
backgroundColor:'black',
display: 'flex',
justifyContent: 'center',
paddingTop: '2rem'
}

const btn={
color: '#000' ,
background: '#68F5D4' , 
marginTop: '10px'  , 
fontWeight: '650',
}
 
export default B6Main;