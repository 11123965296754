import React from "react";
import Snackbar from "@mui/material/Snackbar";

export default function MessagePopup({ handleAlertClose, open, message }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleAlertClose}
      message={message}
      key={"topenter"}
    />
  );
}