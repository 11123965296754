const { BASE_URL } = require("./client");

const POST_LAB = BASE_URL+'/api/postlab';
const GET_LAB = BASE_URL +"/api/labs";
const EDIT_LAB = BASE_URL +"/api/labs/edit/";
const DELETE_LAB = BASE_URL +"/api/labs/delete/";

module.exports = {
    POST_LAB,
    GET_LAB,
    EDIT_LAB,
    DELETE_LAB
}