// AddReedom.js
import React, { useState } from "react";
import axios from "axios";
import { Button, TextField , MenuItem } from "@material-ui/core";
import { Link , useLocation } from "react-router-dom";
import { ADD_REEDOM , EDIT_REDEEM} from '../../../api/Reedom/Reedom';
import { GET_PROMOCODES } from "../../../api/promocode";

const AddReedom = () => {
  const location = useLocation();
  const data = location?.state?.data;
  const [promocodes , setPromoCodes] = useState([]);
  React.useEffect(() => {
    axios
    .get(GET_PROMOCODES)
    .then(res => {
      const updatedData =res.data.data.map((promo) => ({
        ...promo,
        id: promo._id,
        value: promo.code ,
        label: promo.code
      }));  
      setPromoCodes(updatedData);
    })
    .catch(err=> {
      console.log(err)
    })

  },[]);
  const [reedom, setReedom] = useState({
    percentageDiscount: data?.percentageDiscount || 0,
    requiredPoints: data?.requiredPoints ||0,
    requiredOffer:data?.requiredOffer || "",
    typeOfOffer: data?.typeOfOffer ||"",
    withHowMuchPoints:data?.withHowMuchPoints || "",
    code: data?.code || "",
    count: data?.count || 0
  });

  const handleChange = (e) => {
    setReedom({
      ...reedom,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(data) {
      axios
      .patch(EDIT_REDEEM+data._id, reedom)
      .then(res => {
        window.location.href = "/redeem";
      })
      .catch(err => {
        alert(`${err.message}`);
      })
    }
    else {
      axios
      .post(ADD_REEDOM, reedom)
      .then(res => {
        window.location.href = "/redeem";

      })
      .catch(err => {
        alert(`${err.message}`);
      })
    }
   
    
  };

  return (
    <div style={{
      display:'flex' , alignItems:'center' , justifyContent: 'center',
      flexDirection:'column'
    }}>
      <h1>Add Reedom</h1>
      <form onSubmit={handleSubmit} style={{
        display:'flex',
        flexDirection:'column',
        gap:'10px',
        width: '60%'
      }}>
        <TextField
          label="Percentage Discount"
          type="number"
          name="percentageDiscount"
          value={reedom.percentageDiscount}
          onChange={handleChange}
          required
        />
        <TextField
          label="Required Points"
          type="number"
          name="requiredPoints"
          value={reedom.requiredPoints}
          onChange={handleChange}
          required
        />
         <TextField
          label="Use Count"
          type="number"
          name="count"
          value={reedom.count}
          onChange={handleChange}
          required
        />
        <TextField
          label="Top Caption"
          name="requiredOffer"
          value={reedom.requiredOffer}
          onChange={handleChange}
          required
        />
        {/* <TextField
          label="Type Of Offer"
          name="typeOfOffer"
          value={reedom.typeOfOffer}
          onChange={handleChange}
          required
        />
        <TextField
          label="With How Much Points"
          name="withHowMuchPoints"
          value={reedom.withHowMuchPoints}
          onChange={handleChange}
          required
        /> */}
        <TextField
          id="standard-select-currency"
          select
          label="Select"
          name="code"
          helperText="Please select the promocode (if you want to redeem)"
          variant="standard"
          value={reedom.code}
          onChange={handleChange}
        >
          {promocodes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Button type="submit" variant="contained" color="primary">
          {data ? "Edit Redeem" :"Add Redeem"}
        </Button>
        <Link to="/redeem">
          <Button variant="contained" color="default">
            Cancel
          </Button>
        </Link>
      </form>
    </div>
  );
};

export default AddReedom;
