import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { GET_REFERENCE_EMAIL_EVENT } from '../../../api/email';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';

export default function EmailSentReference() {
  const [references, setReferences] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedEmails, setSelectedEmails] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState('');
  const [modalType, setModalType] = React.useState('');

  const handleOpenModal = (emails, template, type) => {
    setSelectedEmails(emails);
    setSelectedTemplate(template);
    setModalType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([['Emails'], ...selectedEmails.map(email => [email])]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Emails');
    XLSX.writeFile(workbook, 'emails.xlsx');
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'subject', headerName: 'Subject', width: 160 },
    {field: 'date' , headerName: 'Date & Time', width: 200 ,  renderCell: (params) => {
      const now = new Date(params.row.date);
      return now.toLocaleString('en-IN');
   }},
    {
      field: 'accepted',
      headerName: 'Sent Emails',
      width: 160,
      renderCell: (params) => (
        <Button onClick={() => handleOpenModal(params.row.accepted, '', 'sent')}>
          View Sent Emails
        </Button>
      ),
    },
    {
      field: 'rejected',
      headerName: 'Rejected Emails',
      width: 200,
      renderCell: (params) => (
        <Button onClick={() => handleOpenModal(params.row.rejected, '', 'rejected')}>
          View Rejected Emails
        </Button>
      ),
    },
    {
      field: 'templateButton',
      headerName: 'Template',
      width: 160,
      renderCell: (params) => (
        <Button onClick={() => handleOpenModal([], params.row.template, 'template')}>
          View Template
        </Button>
      ),
    },
    { field: 'sent', headerName: 'Email', width: 160 },
    { field: 'succesful', headerName: 'Success', width: 160, renderCell: (params) => params.row.accepted.length },
    { field: 'unsuccesful', headerName: 'Failure', width: 160, renderCell: (params) => params.row.rejected.length },

  ];

  React.useEffect(() => {
    axios
      .get(GET_REFERENCE_EMAIL_EVENT)
      .then((res) => {
        const data = res.data.data.map((data) => {
          return {
            ...data,
            id: data._id,
          };
        });
        setReferences(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={references}
        columns={columns}
        pageSize={5}
      />
    <Modal open={modalOpen} onClose={handleCloseModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      width: '80%',
      maxHeight: '80%',
      overflowY: 'auto',
      p: 4,
    }}
  >
    <h2>{modalType === 'sent' ? 'Sent Emails' : modalType === 'rejected' ? 'Rejected Emails' : 'Template'}</h2>
    {modalType === 'sent' || modalType === 'rejected' ? (
      <ul>
        {selectedEmails.map((email, index) => (
          <li key={index}>{email}</li>
        ))}
      </ul>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: selectedTemplate }} />
    )}
    {(modalType === 'sent' || modalType === 'rejected') && (
      <Button onClick={handleDownloadExcel}>Download Excel</Button>
    )}
  </Box>
</Modal>

    </Box>
  );
}
