import { Box, Button, InputBase, Modal, TextareaAutosize, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { FormGroup } from "@mui/material";
import MessagePopup from "../MessagePopup";

const {
    UPDATE_B9_MAINPAGE,
    GET_B9_MAINPAGE,
    DELETE_B9_MAINPAGE,
    CREATE_B9_MAINPAGE,
    headingId
  } = require("../../api/MainPage/b9");


export default function B9Main() {
  const classes = Usestyles();

  const [openPopUp,setOpenPopUp] = useState(false);
  const [resp,setResp] = useState("");

  const [heading,setHeading] = useState("");
  const [subHeading,setSubHeading] = useState("");

  const [B9Data,setB9Data] = useState([]);
  const [pickedData,setPickedData] = useState([]);

  const [title , setTitle] = useState("");
  const [num,setNum] = useState("");
  const [desc,setDesc] = useState("");

  const [editTitle, setEditTitle] = useState("");
  const [editNum, setEditNum] = useState("");
  const [editDesc, setEditDesc] = useState("");

  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // ALl Data request
  useEffect(() => {
    axios
      .get(GET_B9_MAINPAGE, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setB9Data(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Edit box submit
  const handleEditSubmit = (e) => {
    let data ={};
    if(pickedData._id === headingId)
        data = {
            title: editTitle,
            num: editNum,
            desc: editDesc,
            heading: heading,
            subHeading:subHeading,
        }
    else{
        data = {
            title: editTitle,
            num: editNum,
            desc: editDesc,
        }
    }

    const ID = pickedData._id;
    axios
      .put(UPDATE_B9_MAINPAGE+ID , data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Edited Successfully");
        setOpenPopUp(true);
        window.location.href = "/B9_Main";
      })
      .catch(err => {
        setResp("Some error occured");
        setOpenPopUp(true);
      });
  };

 
  // Create submit
  const handleCreateSubmit = (e) => {
      const data = {
        title: title,
        num: num,
        desc: desc,
      };

      axios
        .post(CREATE_B9_MAINPAGE , data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setResp("Created Successfully");
          setOpenPopUp(true);
          window.location.href = "/B9_Main";
        })
        .catch((err) => {
          setResp("Some error occured");
          setOpenPopUp(true);
        });
  };

  // Delete submit function
  const deleteSubmit = () => {
    const ID = pickedData._id;

    axios
      .delete(DELETE_B9_MAINPAGE+ID, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/B9_Main";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
  };

  // Edit Pop Up
  const editbox = (
    <Box className={classes.style}>
      <Typography
        variant="h4"
        style={{textAlign:"center",textTransform:"uppercase"}}
      >
        Update This Instance
      </Typography>
      <FormGroup method="POST">
        {
            headingId === pickedData._id &&
            <Box>
                <InputBase
                    placeholder="Heading"
                    className={classes.inputsStyle}
                    defaultValue={pickedData.heading}
                    onChange={(e) => setHeading(e.target.value)}
                />  
                <InputBase
                    placeholder="subHeading"
                    className={classes.inputsStyle}
                    defaultValue={pickedData.subHeading}
                    onChange={(e) => setSubHeading(e.target.value)}
                />  
            </Box>
        }
        <InputBase
          placeholder="Title"
          className={classes.inputsStyle}
          defaultValue={pickedData.title}
          onChange={(e) => setEditTitle(e.target.value)}
        />
        <InputBase
          className={classes.inputsStyle}
          height="auto"
          defaultValue={pickedData.num}
          placeholder="Num"
          onChange={(e) => setEditNum(e.target.value)}
        />
        <TextareaAutosize
          className={classes.inputsStyle}
          defaultValue={pickedData.desc}
          label="Description"
          minRows={2}
          onChange={(e) => setEditDesc(e.target.value)}
        />
      </FormGroup>
      <Button
        onClick={handleEditSubmit}
        style={{
          background: "#81fccf",
          fontSize: "18px",
          marginTop: "25px",
          padding: "10px",
        }}
      >
        Update this Instance
      </Button>
    </Box>
  );

  // Create PopUp
  const createBox = (
    <Box className={classes.style}>
      <Typography variant="h4">Create Another Instance</Typography>
      <FormGroup method="POST">
        <InputBase
          placeholder="Title"
          value={title}
          className={classes.inputsStyle}
          onChange={(e) => setTitle(e.target.value)}
        />
        <InputBase
          placeholder="Number"
          value={num}
          className={classes.inputsStyle}
          onChange={(e) => setNum(e.target.value)}
        />
        <TextareaAutosize
          placeholder="description"
          minRows={2}
          value={desc}
          className={classes.inputsStyle}
          onChange={(e) => setDesc(e.target.value)}
        />
      </FormGroup>
      <Button
        onClick={handleCreateSubmit}
        style={{
          background: "#81fccf",
          marginTop: "25px",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Create this Instance
      </Button>
    </Box>
  );

  // Delete PopUp
  const deleteBox = (
    <Box className={classes.style}>
        {   
            pickedData._id === headingId ?  
            <Typography>You can't delete it! it is related to heading & subHeading</Typography>
            :
            <Box>
                <Typography variant="h5" style={{marginBottom: '5px'}}>
                    Are you sure want to delete ?
                </Typography>
                <Box>
                    <Button
                    onClick={deleteSubmit}
                    style={{ background: "black", color: "white", margin: "3px" }}
                    >
                    Yes
                    </Button>
                    <Button
                    onClick={() => setOpenDelete(false)}
                    style={{ background: "black", color: "white", margin: "3px" }}
                    >
                    No
                    </Button>
                </Box>
            </Box>
        }
     
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 150, cellClassName: "Cell-Col" },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "desc",
      headerName: "Description",
      width: 300,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "num",
      headerName: "Number",
      width: 100,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedData(params.row);
          setOpenEdit(true);
          setEditDesc(params.row.desc);
          setEditNum(params.row.num);
          setEditTitle(params.row.title);
          setHeading(params.row.heading);
          setSubHeading(params.row.subHeading);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
            
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      // headerAlign: 'center',
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedData(params.row);
          setOpenDelete(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  // UI
  return (
    <div>
        <div className={classes.container}>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">B9 Page</Typography>
            <Button
              onClick={() => setOpenCreate(true)}
              size="medium"
              variant="contained"
              color="primary"
            >
              create
            </Button>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyCOntent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "purple",
                fontSize: "20px",
              },
            }}
          >
            <DataGrid
              rows={B9Data}
              className="table-box"
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>

          <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
            {createBox}
          </Modal>

          <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
            {editbox}
          </Modal>
          <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
            {deleteBox}
          </Modal>
        </div>
    </div>
  );
}

const Usestyles = makeStyles({
    container: {
      width: "100%",
      height: "100vh",
    },
    inputsStyle: {
      margin: "10px 0px 10px 0px",
      backgroundColor: "whitesmoke",
      color: "black",
      padding: "10px",
    },
    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: '#fff',
      border: "none",
      borderRadius: "3px",
      textAlign: 'center',
      color: "black",
      width: 400,
      p: 4,
      paddingLeft: "3rem",
      paddingRight: "3rem",
      paddingTop: '1rem',
      paddingBottom: '1rem',
      "@media only screen and (max-width: 726px)":{
          width: '90%',
          p: 3,
      }
    }
  });