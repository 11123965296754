import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'

const MainPage = () => {
    return ( 
        <div>
            <Box>
                <Link to="/B1_Main" style={{textDecoration: "none"}}><Button>B1_Page</Button></Link>
                <Link to="/B2_Main" style={{textDecoration: "none"}}><Button>B2_Page</Button></Link>
                <Link to="/B3_Main" style={{textDecoration: "none"}}><Button>B3_Page</Button></Link>
                <Link to="/B4_Main" style={{textDecoration: "none"}}><Button>B4_Page</Button></Link>
                <Link to="/B5_Main" style={{textDecoration: "none"}}><Button>B5_Page</Button></Link>
                <Link to="/B6_Main" style={{textDecoration: "none"}}><Button>B6_Page</Button></Link>
                <Link to="/B7_Main" style={{textDecoration: "none"}}><Button>B7_Page</Button></Link>
                <Link to="/B8_Main" style={{textDecoration: "none"}}><Button>B8_Page</Button></Link>
                <Link to="/B9_Main" style={{textDecoration: "none"}}><Button>B9_Page</Button></Link>
            </Box>
        </div>
     );
}
 
export default MainPage;