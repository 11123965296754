import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'

const KitPage = () => {
    return ( 
        <div>
            <Box>
                <Link to="/Main_side" style={{textDecoration: "none"}}><Button>Main_side</Button></Link>
                <Link to="/Features" style={{textDecoration: "none"}}><Button>Features</Button></Link>
            </Box>
        </div>
     );
}
 
export default KitPage;