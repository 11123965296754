import React, { useState, useEffect } from "react";
import "./admin_emails-Style.css";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MessagePopup from "../../MessagePopup";

const {
  GET_ALL_EMAILADMIN_EVENTS,
  SAVE_NEW_EMAILADMIN_EVENT,
  UPDATE_EMAILADMIN_EVENT,
  DELETE_EMAILADMIN_EVENT,
} = require("../../../api/emailAdmin");

export default function Admin_emails({ isAuth, a_email }) {
  const [adminEmail, setAdminEmail] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [event, setEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [adminPassword, setAdminPassword] = useState("");
  const [show, setShow] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [editIndex, setEditIndex] = useState();

  useEffect(() => {
    fetchEmailAdminEvents();
  }, []);

  const fetchEmailAdminEvents = () => {
    axios
      .get(GET_ALL_EMAILADMIN_EVENTS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => {
        console.log("error->", err);
      });
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setAdminEmail(email);

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setAdminPassword(password);

    // Validate password format (at least 8 characters with at least one digit and one uppercase letter)
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    setIsValidPassword(passwordRegex.test(password));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleAdd = (e) => {
    if (
      adminEmail !== "" &&
      adminPassword !== "" &&
      isValidEmail &&
      isValidPassword
    ) {
      const data = {
        email: adminEmail,
        password: adminPassword,
      };
      axios
        .post(SAVE_NEW_EMAILADMIN_EVENT, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setAdminEmail("");
          setAdminPassword("");
          setResp("Created Successfully");
          setOpenPopUp(true);
          fetchEmailAdminEvents();
        })
        .catch((err) => {
          console.log(err);
          setResp("Some error occurred");
          setOpenPopUp(true);
        });
    } else {
      setResp("Kindly add all the details");
      setOpenPopUp(true);
    }
  };

  const handleUpdate = (e) => {
    if (
      adminEmail !== "" &&
      adminPassword !== "" &&
      isValidEmail &&
      isValidPassword
    ) {
      const data = {
        email: adminEmail,
        password: adminPassword,
      };

      const ID = event;

      axios
        .put(UPDATE_EMAILADMIN_EVENT + ID, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setAdminEmail("");
          setAdminPassword("");
          setResp("Updated Successfully");
          setOpenPopUp(true);
          setShow(false);
          fetchEmailAdminEvents(); // Fetch the updated events
        })
        .catch((err) => {
          console.log(err);
          setResp("Some error occurred");
          setOpenPopUp(true);
        });
    } else {
      setResp("Kindly add all the details");
      setOpenPopUp(true);
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(DELETE_EMAILADMIN_EVENT + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        fetchEmailAdminEvents();
      })
      .catch((err) => {
        setResp("Something error occurred");
        setOpenPopUp(true);
      });
  };

  const handleEdit = (data) => {
    setEvent(data._id);
    setAdminEmail(data.email);
    setAdminPassword(data.password);
    setShow(true);
    setEditIndex(data);
  };

  return (
    <div style={{marginTop:'20px', height:'100vh'}}>
      {a_email ? (
        <>
          <div className="main-page">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height:'100vh'
              }}
            >
              <div className="main-content" id="email-ids">
                <h2>Admin Emails</h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="main-content-email">
                    <div>
                      <input
                        value={adminEmail}
                        className="input1"
                        type="email"
                        placeholder="Enter Email ID"
                        onChange={handleEmailChange}
                        style={{
                          borderColor: isValidEmail ? "initial" : "red",
                        }}
                      />
                    </div>
                    {!isValidEmail && (
                      <div style={{ color: "red", textAlign: "start" }}>
                        Please enter a valid email address
                      </div>
                    )}
                  </div>
                  <div className="main-content-password">
                    <div style={{ display: "flex" }}>
                      <input
                        value={adminPassword}
                        className="input2"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={handlePasswordChange}
                        style={{
                          borderColor: isValidPassword ? "initial" : "red",
                        }}
                      />
                      <div className="btn-eye" onClick={toggleShowPassword}>
                        {showPassword ? (
                          <RemoveRedEyeIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </div>
                    </div>
                    {!isValidPassword && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                          width: "auto",
                        }}
                      >
                        Please enter a valid password (at least 8 characters
                        with at least one digit and one uppercase letter)
                      </div>
                    )}
                  </div>
                  {!show ? (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleAdd}
                      style={{ width: "10.2rem", marginRight: "10px" }}
                    >
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      onClick={handleUpdate}
                      variant="contained"
                      style={{ width: "10.2rem", marginRight: "10px" }}
                    >
                      UPDATE
                    </Button>
                  )}
                </div>
                {events && Array.isArray(events) && events.length > 0 ? (
                  events.map((data) => (
                    <div key={data._id} className="admin-emails">
                      <h2>Email: {data.email}</h2>
                      <h2>Password: {Array(data.password.length).fill('*').join('')}</h2>
                      <div className="btns-admin">
                        <Button
                          variant="contained"
                          onClick={() => handleEdit(data)}
                          style={{ marginRight: "1rem" }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleDelete(data._id)}
                          color="error"
                          style={{ marginRight: "1rem" }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    style={{
                      margin: "7rem 1rem 0rem 1rem ",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "1rem",
                      fontSize: "2rem",
                    }}
                  >
                    No events found
                  </Typography>
                )}
              </div>
             
            </div>
          </div>
          <MessagePopup
            open={openPopUp}
            message={resp}
            handleAlertClose={() => setOpenPopUp(!openPopUp)}
          />
        </>
      ) : (
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
    </div>
  );
}
