import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useParams } from 'react-router-dom';  // Import useParams
const DOMAIN = process.env.REACT_APP_DOMAIN;

// ... (Previous imports remain unchanged)

const UpdateCourcesAbout = ({ match }) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    heading: '',
    yellowPart: '',
    subHeading: '',
    batchStarting: '',
    programSnapshot1: '',
    programSnapshot2: '',
    programSnapshot3: '',
    programSnapshot4: '',
    programSnapshot5: '',
    programSnapshot6: '',
    programSnapshot7: '',
    programSnapshot8: '',
    threePointersHeading1: '',
    threePointersHeading2: '',
    threePointersHeading3: '',
    threePointersContent1: '',
    threePointersContent2: '',
    threePointersContent3: '',
    PathwayWeek1Heading: '',
    PathwayWeek1Content: '',
    PathwayWeek2Heading: '',
    PathwayWeek2Content: '',
    PathwayWeek3Heading: '',
    PathwayWeek3Content: '',
    PathwayWeek4Heading: '',
    PathwayWeek4Content: '',
    toolsToLearn1: '',
    toolsToLearn2: '',
    toolsToLearn3: '',
    SuitableFor1:"",
    SuitableFor2:"",
    SuitableFor3:"",
    Duration: '',
  });

  const [brochurePath, setBrochurePath] = useState(null);
  const [brochureSyllabus, setBrochureSyllabus] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${DOMAIN}/api/getuniqueURLbyId/${id}`);
        const data = response.data;

        setFormData({
          heading: data.heading,
          yellowPart: data.yellowPart,
          subHeading: data.subHeading,
          batchStarting: data.batchStarting,
          programSnapshot1: data.programSnapshot1,
          programSnapshot2: data.programSnapshot2,
          programSnapshot3: data.programSnapshot3,
          programSnapshot4: data.programSnapshot4,
          programSnapshot5: data.programSnapshot5,
          programSnapshot6: data.programSnapshot6,
          programSnapshot7: data.programSnapshot7,
          programSnapshot8: data.programSnapshot8,
          threePointersHeading1: data.threePointersHeading1,
          threePointersHeading2: data.threePointersHeading2,
          threePointersHeading3: data.threePointersHeading3,
          threePointersContent1: data.threePointersContent1,
          threePointersContent2: data.threePointersContent2,
          threePointersContent3: data.threePointersContent3,
          PathwayWeek1Heading: data.PathwayWeek1Heading,
          PathwayWeek1Content: data.PathwayWeek1Content,
          PathwayWeek2Heading: data.PathwayWeek2Heading,
          PathwayWeek2Content: data.PathwayWeek2Content,
          PathwayWeek3Heading: data.PathwayWeek3Heading,
          PathwayWeek3Content: data.PathwayWeek4Content,
          PathwayWeek4Heading: data.PathwayWeek4Heading,
          PathwayWeek4Content: data.PathwayWeek3Content,
          toolsToLearn1: data.toolsToLearn1,
          toolsToLearn2: data.toolsToLearn2,
          toolsToLearn3: data.toolsToLearn3,
          SuitableFor1: data.SuitableFor1,
          SuitableFor2: data.SuitableFor2,
          SuitableFor3: data.SuitableFor3,
          Duration: data.Duration,
        });

        setBrochurePath(data.brochurePath);
        setBrochureSyllabus(data.brochureSyllabus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleFileChange = (event, field) => {
    if (field === 'brochurePath') {
      setBrochurePath(event.target.files[0]);
    } else if (field === 'brochureSyllabus') {
      setBrochureSyllabus(event.target.files[0]);
    }
  };

  const handleChange = (event, field) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

 

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    formDataToSend.append('brochurePath', brochurePath);
    formDataToSend.append('brochureSyllabus', brochureSyllabus);

    try {
      await axios.put(`${DOMAIN}/api/uniqueURL/${id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert("Data Updated");
      // Redirect or display success message
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5">Update Form</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Heading"
                fullWidth
                value={formData.heading}
                onChange={(e) => handleChange(e, 'heading')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Yellow Part"
                fullWidth
                value={formData.yellowPart}
                onChange={(e) => handleChange(e, 'yellowPart')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sub Heading"
                fullWidth
                value={formData.subHeading}
                onChange={(e) => handleChange(e, 'subHeading')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Batch Starting"
                fullWidth
                value={formData.batchStarting}
                onChange={(e) => handleChange(e, 'batchStarting')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 1"
                fullWidth
                value={formData.programSnapshot1}
                onChange={(e) => handleChange(e, 'programSnapshot1')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 2"
                fullWidth
                value={formData.programSnapshot2}
                onChange={(e) => handleChange(e, 'programSnapshot2')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 3"
                fullWidth
                value={formData.programSnapshot3}
                onChange={(e) => handleChange(e, 'programSnapshot3')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 4"
                fullWidth
                value={formData.programSnapshot4}
                onChange={(e) => handleChange(e, 'programSnapshot4')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 5"
                fullWidth
                value={formData.programSnapshot5}
                onChange={(e) => handleChange(e, 'programSnapshot5')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 6"
                fullWidth
                value={formData.programSnapshot6}
                onChange={(e) => handleChange(e, 'programSnapshot6')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 7"
                fullWidth
                value={formData.programSnapshot7}
                onChange={(e) => handleChange(e, 'programSnapshot7')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Program Snapshot 8"
                fullWidth
                value={formData.programSnapshot8}
                onChange={(e) => handleChange(e, 'programSnapshot8')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="3 Pointers Heading 1"
                fullWidth
                value={formData.threePointersHeading1}
                onChange={(e) => handleChange(e, 'threePointersHeading1')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="3 Pointers Heading 2"
                fullWidth
                value={formData.threePointersHeading2}
                onChange={(e) => handleChange(e, 'threePointersHeading2')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="3 Pointers Heading 3"
                fullWidth
                value={formData.threePointersHeading3}
                onChange={(e) => handleChange(e, 'threePointersHeading3')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="3 Pointers Content 1"
                fullWidth
                value={formData.threePointersContent1}
                onChange={(e) => handleChange(e, 'threePointersContent1')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="3 Pointers Content 2"
                fullWidth
                value={formData.threePointersContent2}
                onChange={(e) => handleChange(e, 'threePointersContent2')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="3 Pointers Content 3"
                fullWidth
                value={formData.threePointersContent3}
                onChange={(e) => handleChange(e, 'threePointersContent3')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 1 Heading"
                fullWidth
                value={formData.PathwayWeek1Heading}
                onChange={(e) => handleChange(e, 'PathwayWeek1Heading')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 1 Content"
                fullWidth
                value={formData.PathwayWeek1Content}
                onChange={(e) => handleChange(e, 'PathwayWeek1Content')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 2 Heading"
                fullWidth
                value={formData.PathwayWeek2Heading}
                onChange={(e) => handleChange(e, 'PathwayWeek2Heading')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 2 Content"
                fullWidth
                value={formData.PathwayWeek2Content}
                onChange={(e) => handleChange(e, 'PathwayWeek2Content')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 3 Heading"
                fullWidth
                value={formData.PathwayWeek3Heading}
                onChange={(e) => handleChange(e, 'PathwayWeek3Heading')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 3 Content"
                fullWidth
                value={formData.PathwayWeek3Content}
                onChange={(e) => handleChange(e, 'PathwayWeek3Content')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 4 Heading"
                fullWidth
                value={formData.PathwayWeek4Heading}
                onChange={(e) => handleChange(e, 'PathwayWeek4Heading')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pathway Week 4 Content"
                fullWidth
                value={formData.PathwayWeek4Content}
                onChange={(e) => handleChange(e, 'PathwayWeek4Content')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Tools to Learn 1"
                fullWidth
                value={formData.toolsToLearn1}
                onChange={(e) => handleChange(e, 'toolsToLearn1')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Tools to Learn 2"
                fullWidth
                value={formData.toolsToLearn2}
                onChange={(e) => handleChange(e, 'toolsToLearn2')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Tools to Learn 3"
                fullWidth
                value={formData.toolsToLearn3}
                onChange={(e) => handleChange(e, 'toolsToLearn3')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="SuitableFor 1"
                fullWidth
                value={formData.SuitableFor1}
                onChange={(e) => handleChange(e, 'SuitableFor1')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="SuitableFor 2"
                fullWidth
                value={formData.SuitableFor2}
                onChange={(e) => handleChange(e, 'SuitableFor2')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="SuitableFor 3"
                fullWidth
                value={formData.SuitableFor3}
                onChange={(e) => handleChange(e, 'SuitableFor3')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Duration"
                fullWidth
                value={formData.Duration}
                onChange={(e) => handleChange(e, 'Duration')}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Syllabus 
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'brochurePath')}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Certificate
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'brochureSyllabus')}
              />
            </Grid>
          </Grid>

          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
            Update
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default UpdateCourcesAbout;
