import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Divider from '@mui/material/Divider';
import {
  DataGrid,
} from '@mui/x-data-grid';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { GET_EVENT, GET_REGISTERED_EVENTS } from '../../../api/event';
;
const Events = () => {
  
  const [eventsData , setEventsData] = React.useState([]);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);

  const handleButtonClick = async(event) => {
    try {
        const response = await axios.get(GET_EVENT+event.eventId);
        setSelectedEvent(response.data.data);
        setOpenPopup(true);
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `PromoCodes_${Date.now()}`;


  const exportToCSV = (eventsData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(eventsData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
 

  React.useEffect(() => {
    axios
    .get(GET_REGISTERED_EVENTS)
    .then(res => {
      const dataWithIds = res.data.data.map((data , index) => ({
        ...data,
        id: data._id,
      }))
      setEventsData(dataWithIds);
    })
    .catch(err => {
      console.log(err);
    })
    
    
  }, [])


  const columns = [
    { field: 'id', headerName: 'ID',type: 'string', width: 70, cellClassName: 'custom-cell' }
    ,
    {
      field: 'userId',
      headerName: 'UID',
      type: 'string',
      width: 70,      
      cellClassName: 'custom-cell'
    },
    {
      field: 'eventId',
      headerName: 'EVENT',
      type: 'string',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'custom-cell',
      renderCell: (params) => (
        <Button variant="outlined" onClick={() => handleButtonClick(params.row)}>
          EVENT DETAILS
        </Button>
      ),
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 200,
        cellClassName: 'custom-cell',
      },
    {
      field: 'collegeName',
      headerName: 'College Name',
      width: 200,
      cellClassName: 'custom-cell',
    },
    {
        field: 'wNumber',
        headerName: 'Whatsapp No.',
        width: 200,
        cellClassName: 'custom-cell',
      }, 
      {
        field: 'email',
        headerName: 'Email',
        width: 200,
        cellClassName: 'custom-cell',
      },
      {
        field: 'topics',
        headerName: 'Topics',
        width: 200,
        cellClassName: 'custom-cell',
      },
   
  ];

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between'}}>  
      <div style={{display:'flex' , flexDirection:'column' , justifyContent:'flex-start' , marginBottom:'10px'}}>
      <div style={{display:'flex' , flexDirection:'row' }}>
      <Typography 
      variant='h6'
      style={{marginRight:'20px' , fontWeight:'bold'}}>
          Registrations  {`(${eventsData.length})`}
      </Typography>
      </div>
      </div>
      <div style= {{display:'flex' , flexDirection:'row' , alignItems:'center' }}>
      <Button 
      variant='outlined'
      style={{color: '#000833' , borderColor:'#000833' , borderRadius:0, marginRight:'10px'}}
      onClick={(e) => exportToCSV(eventsData, fileName)}
      >
        Export As CSV
      </Button>
      </div>
     
    
     </div> 
     <Divider style={{marginBottom:'10px'}}/>
    
  

     <DataGrid
        rows={eventsData}
        rowHeight={100}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbarQuickFilter }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        height="100vh"
/>

     
<Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>Event Details</DialogTitle>
        <DialogContent>
          {selectedEvent && (
            <div>
              <Typography variant="body1">Event ID: {selectedEvent._id}</Typography>
              <Typography variant="body1">Event Name: {selectedEvent.title}</Typography>
              <Typography variant="body1">Event Date: {selectedEvent.date}</Typography>
              <img src={selectedEvent.eventPoster} alt={selectedEvent.title} width={400} height={300}/>            
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Events