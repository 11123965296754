import React, { useState,useEffect } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import { Box, Button, Modal, Typography } from '@material-ui/core'

const {GET_MEMBERS , DELETE_MEMBERSHIP} = require("../../api/membership");

const MemberShips = () => {
    const [allMemberShips , setAllMemberShips] = useState([]);
    const [pickedMembership , setPickedMembership] = useState([]);
    const [openDelete,setOpenDelete]=useState(false);

    useEffect(()=> {
        axios.get(GET_MEMBERS,{
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
        .then(async(res)=> {
            await setAllMemberShips(res.data);
        })
        .catch(err=>{
            console.log(err);
        })
    },[])

    // Delete submit function
    const deleteSubmit = () => {
        const id = pickedMembership._id;
        const userId = window.localStorage.getItem("id");
        console.log(userId);
        axios
            .delete(DELETE_MEMBERSHIP+id,{
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                window.alert("Successfully deleted");
                window.location.href="/memberships"
            })
            .catch((err) => window.alert("Not Deleted"));
        setOpenDelete(false);
    }

    // Delete PopUp
    const deleteBox = (
        <Box sx={style}>
            <Typography variant='h5' style={{marginBottom: '5px'}}>Are you sure want to delete ?</Typography>
            <Box>
                <Button 
                    onClick={deleteSubmit} 
                    style={{background: 'black' , color: 'white' , margin: '3px'}}
                >
                    Yes
                </Button>
                <Button 
                    onClick={() => setOpenDelete(false)} 
                    style={{background: 'black' , color: 'white',margin: '3px'}}
                >
                    No
                </Button>
            </Box>
        </Box>
    )

    // Columns in data grid
    const columns = [
        { field: '_id', headerName: 'ID', width: 80 ,cellClassName: 'Cell-Col',},
        {
            field: 'name' ,
             headerName: 'Full Name',
             width: 150,
             cellClassName: 'column',
             headerClassName: 'column-header',
            //  headerAlign: 'center',
             cellAlign: 'center',
        },
        {
            field: 'contactNo' , 
            headerName: 'Contact No.', 
            width: 150,
            cellClassName: 'column',
            headerClassName: 'column-header',
            // headerAlign: 'center',
        },
        {
            field: 'email' , 
            headerName: 'Email',
            width: 350,
            cellClassName: 'column',
            headerClassName: 'column-header',
            // headerAlign: 'center',  
        },
        {
            field: 'Delete',
            headrName: 'Delete',
            headerClassName: 'column-header',
            // headerAlign: 'center',  
            cellClassName: 'column',
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params)=>{
              const onClick=()=>{
                setPickedMembership(params.row);
                setOpenDelete(true);
              };
              return(
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 ,padding: '5px' ,background: '#ed4253'}}
                    onClick={onClick}
                >
                    Delete
                    {/* <DeleteIcon style={{color: 'white'}}/> */}
                </Button>
              );
            }
        }
    ];

    return (  
        <div>
            <Box display='flex' justifyContent='center' padding='10px'>
                <Typography variant='h5'>MemberShips</Typography>
            </Box>
            <Box
                sx={{
                    height: 800,
                    width: '100%',
                    '& .column': {
                        fontSize: '16px',
                        justifyCOntent: 'center',
                        textAlign: 'center'
                    },
                    '& .column-header': {
                        color: 'purple',
                        fontSize: '20px',
                    },
                }}
                >   
                <DataGrid
                rows={allMemberShips}
                className='table-box'
                getRowId= {(row) => row._id}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                />
            </Box>
            <Modal
            open={openDelete}
            onClose={()=>setOpenDelete(false)}
            >
                {deleteBox}
            </Modal>
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '3px',
    boxShadow: 25,
    textAlign: 'center',
    color:'black',
    p: 4,
    paddingLeft:'3rem',
    paddingRight:'3rem'
};
 
export default MemberShips;