import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import axios from 'axios';
import { GET_PAYMENTS } from '../../api/payment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const formatDate = (date) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-GB', options);

  const day = formattedDate.split(' ')[0];
  const daySuffix = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return day + daySuffix(day) + ' ' + formattedDate.slice(day.length + 1);
};

const PaymentDetails = () => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(GET_PAYMENTS)
      .then(res => {
        const modifiedData = res.data.data.map(item => ({
          ...item,
          id: item._id
        }));
        setData(modifiedData.reverse());
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const columns = [
    { field: "_id", headerName: "ID", width: 50, cellClassName: "Cell-Col" },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "courseNames",
      headerName: "Course Name",
      width: 250,
      cellClassName: "column",
      headerClassName: "column-header",
    },
    {
      field: "providerReferenceId",
      headerName: "Transaction ID",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 200
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 100
    },
    {
      field: "code",
      headerName: "Status",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 100,
      renderCell: (params) => {
        return (
          <Box alignItems="center" justifyContent={'center'}>
            <Typography
              sx={{
                background: params.row.code === "PAYMENT_SUCCESS" ? '#ddffdd' : 'red',
                color: '#000',
                padding: '5px',
                borderRadius: '10px'
              }}
            >
              {params.row.code === "PAYMENT_SUCCESS" ? 'Success' : 'Failure'}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: "paymentOn",
      headerName: "Payment Date",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 200,
      renderCell: (params) => formatDate(params.row.paymentOn)
    },
  ];

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payments");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, `payments-till-${formatDate(new Date())}.xlsx`);
  };

  return (
    <Box>
      <h1>Payment Details</h1>
      <Button variant="outlined" color="primary" onClick={downloadExcel} sx={{marginBottom:"20px"}}>
        Download Data
      </Button>
      <Box
        sx={{
          height: '100vh'
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={16}
          components={{
            Toolbar: GridToolbarQuickFilter
          }}
        />
      </Box>
    </Box>
  );
};

export default PaymentDetails;
