import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Box
} from '@mui/material';
import shortid from 'short-uuid';
import axios from 'axios';
import { GET_USER_DETAILS } from '../../api/userdetails';
import { GET_COURSES } from '../../api/courseupload';
import { GET_CERTIFICATES, SAVE_CERTIFICATE } from '../../api/certificate';
import MessagePopup from '../MessagePopup';

const Certificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [loadingCertificates, setLoadingCertificates] = useState(true);
  const [open, setOpen] = useState(false);
  const [openPopUp , setOpenPopUp] = useState(false);
  const [message , setMessage] = useState('');
  const [newCertificate, setNewCertificate] = useState({
    userName: '',
    courseName: '',
    serielNumber: ''
  });

  useEffect(() => {
    let isMounted = true;

    const fetchCertificates = () => {
      setLoadingCertificates(true);
      axios.get(GET_CERTIFICATES)
        .then(response => {
          if (isMounted) {
            setCertificates(response.data.data.reverse());
            setLoadingCertificates(false);
          }
        })
        .catch(error => {
          console.error(error);
          setLoadingCertificates(false);
        });
    };

    fetchCertificates();

    return () => {
      isMounted = false;
    };
  }, [openPopUp]);

  const handleClickOpen = () => {
    setNewCertificate({ userName: '', courseName: '', serielNumber: '' });
    setOpen(true);
  };
  const handleAlertClose = () => {
    setOpenPopUp(false);
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const { userName, courseName, serielNumber } = newCertificate;
    const certificateData = { userName, courseName, serielNumber };

    axios.post(SAVE_CERTIFICATE, certificateData)
      .then(() => {
        // fetchCertificates();
        setMessage('Successfully Created Certificate');
        setOpenPopUp(true);
        handleClose();
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setOpenPopUp(true);
      });
  };

  const handleChange = (event) => {
    setNewCertificate({
      ...newCertificate,
      [event.target.name]: event.target.value
    });
  };

  const generateCertificateLink = (courseName, certificateId) => {
    switch (courseName) {
      case 'Prompt Engineering Work Experience':
        return `/certificate/prompt/internship/${certificateId}`;
      case 'Prompt Engineering':
        return `/certificate/prompt/program/${certificateId}`;
      case 'Prompt Engineering Project Experience':
        return `/certificate/prompt/capstone/${certificateId}`;
      case 'Product Management':
        return `/certificate/product/experience/${certificateId}`;
      case 'Product Management Project Experience':
        return `/certificate/product/project/${certificateId}`;
      case 'Product Management Work Experience':
        return `/certificate/product/workex/${certificateId}`;
      case 'Management Consulting':
        return `/certificate/managementconsulting/${certificateId}`;
      default:
        return '';
    }
  };

  const columns = [
    { field: 'id', headerName: 'Certificate ID', width: 200 },
    { field: 'userId', headerName: 'User ID', width: 100 },
    { field: 'userName', headerName: 'User Name', width: 200 },
    { field: 'courseName', headerName: 'Course Name', width: 200 },
    { field: 'serielNumber', headerName: 'Certificate ID', width: 300 },
  ];

  const rows = certificates.map((certificate) => ({
    ...certificate,
    id: certificate._id
  }));

  const loading = loadingCertificates;

  return (
    <div style={{ height: '100vh' }}>
      <h2>Certificates</h2>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Certificate
      </Button>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="400px">
          <CircularProgress />
        </Box>
      ) : (
        <div style={{ height: '100%', width: '100%', marginTop: 20 }}>
          <DataGrid rows={rows} columns={columns} pageSize={16} />
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Certificate</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="userName"
            label="User Name"
            type="text"
            fullWidth
            value={newCertificate.userName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="courseName"
            label="Course Name"
            type="text"
            fullWidth
            value={newCertificate.courseName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="serielNumber"
            label="Certificate ID"
            type="text"
            fullWidth
            value={newCertificate.serielNumber}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <MessagePopup
        handleAlertClose={handleAlertClose}
        open={openPopUp}
        message={message}
      />
    </div>
  );
};

export default Certificates;
