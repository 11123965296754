const { BASE_URL } = require("../client");
const getReedomUrl = (schema, id) => `${BASE_URL}/api/get/${schema}/${id}`;

const ADD_REEDOM = BASE_URL+'/api/redeem/post';
const REWARD_FORM = BASE_URL +"/userdetails";
const GET_REEDOM = BASE_URL +"/api/redeem/get";
const DELETE_REDEEM = BASE_URL +"/api/redeem/delete/";
const EDIT_REDEEM = BASE_URL +"/api/redeem/edit/";

module.exports = {
    ADD_REEDOM,
    REWARD_FORM,
    getReedomUrl,
    DELETE_REDEEM,
    GET_REEDOM,
    EDIT_REDEEM
}