
import Divider from '@mui/material/Divider';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import * as React from 'react';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MessagePopup from "../../MessagePopup";
import ReactQuill from 'react-quill';




import IconButton from '@mui/material/IconButton';
import './index.css';
import Collapse from '@mui/material/Collapse';
import axios from 'axios';
import {PDF_UPLOAD, POST_MODULE , VIDEO_UPLOAD , FILE_UPDATE} from '../../../api/courseupload';


export default function MaxWidthDialog({ onAddCourse , id , index , onAdd ,courseNew , btn , setAdded}) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');
  const [radiopdfUpload , setRadiopdfUpload] = React.useState(false);
  const [radiovideoUpload , setRadiovideoUpload] = React.useState(false);
  const [radioaudioUpload , setRadioaudioUpload] = React.useState(false);
  const [radiofileUpload , setRadiofileUpload] = React.useState(false);
  const [radioAssignment , setRadioAssignment] = React.useState(false);
  const [headingInput,setheadingInput] = React.useState(false);
  const [textInput,settextInput] = React.useState(false);
  const [linkInput,setlinkInput] = React.useState(false);
  const [questionInput,setQuestionInput] = React.useState(false);

const [selectedRadio, setSelectedRadio] = React.useState('');
const [moduleData , setModuleData] = React.useState([]);
const [heading , setheading] = React.useState('');
const [text, setText] = React.useState('');
const [link , setLink] = React.useState('');
const [pdf , setPdf] = React.useState(null);
const [video, setVideo] = React.useState(null);
const [audio , setAudio] = React.useState(null);
const [file , setFile] = React.useState(null);
const [pdfTitle , setPdfTitle] = React.useState(null);
const [pdfPath , setPdfPath] = React.useState('');
const [posted, setPosted] = React.useState('');
const [assignment , setAssignment] = React.useState('');
const [videoTitle , setVideoTitle] = React.useState(null);
const [audioTitle , setAudioTitle] = React.useState(null);
const [fileTitle , setFileTitle] = React.useState(null);
const [videoLink, setVideoLink] = React.useState('');
const [videoPath, setVideoPath] = React.useState('');
const [resp, setResp] = React.useState("");
const [openPopUp, setOpenPopUp] = React.useState(false);
const [moduleNew , setModuleNew] = React.useState(null);
const [description , setDescription] = React.useState('');





  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAdd = async () => {
    try {
      const formData = {
        title: heading,
        description: description,
        courseId: btn === 'edit' ? id : courseNew
      };
  
      const moduleResponse = await axios.post(POST_MODULE, formData);
      onAdd(true);
      setResp("Added Module Successfully");
      setOpenPopUp(true);
      onAddCourse(moduleResponse.data.data);
      setModuleNew(moduleResponse.data.message._id);
  
      const video_data = {
        title: videoTitle,
        link: videoLink,
        moduleId: moduleResponse.data.message._id
      };
      if (videoTitle && videoLink) {
        await axios.post(VIDEO_UPLOAD, video_data);
        setResp("Added Video Successfully");
        setAdded(true);
      }
  
      const pdf_data = new FormData();
      pdf_data.append('title', pdfTitle);
      pdf_data.append('file', pdf);
      pdf_data.append('moduleId', moduleResponse.data.message._id);
      if (pdfTitle && pdf) {
        await axios.post(PDF_UPLOAD, pdf_data);
        setResp("Added Pdf Successfully");
        setAdded(true);
      }
  
      setOpenPopUp(true);
      handleClose();
    } catch (error) {
      setResp("Some Error Occurred");
      setOpenPopUp(true);
      handleClose();
    }
  };
  
  const handleRadiopdfChange = (event) => {
    setRadiopdfUpload((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });
  };
  const handleRadiovideoChange = (event) => {
    setRadiovideoUpload((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });

  };
  const handleRadioaudioChange = (event) => {
    setRadioaudioUpload((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });

  };
  const handleRadiofileChange = (event) => {
    setRadiofileUpload((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });

  };
  const handleheadingInput = (event) => {
    setheadingInput((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });
  }
  const handletextInput = (event) => {
    settextInput((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });
    
  } 
  const handlelinkInput = (event) => {
    setlinkInput((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });
  }
  const handleQuestionInput = (event) => {
    setQuestionInput((prevState)=> {
      if(prevState) {
        setSelectedRadio('');
        return !prevState;
      }
      else {
        setSelectedRadio(event.target.value);
        return !prevState;
      }
    });
  }
  const handleEditorChange = (content, delta, source, editor) => {
    setDescription(content);
  };


  

  return (
    <React.Fragment>
      <Button variant="standard" onClick={handleClickOpen} style={{color:'#fff'}}>
        Add New Chapter 
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullHeight="xl"
        maxHeight="xl"
        open={open}
        onClose={handleClose}
      >
       
        <DialogContent style={{margin:'0' , padding:'0'}}>
          <Box sx={{display:'flex' , flexDirection:'row' , padding:'20px' , alignItems:'flex-start' , justifyContent:'flex-start'}}>
          <div style={{margin:'0', width:'100%'}}>
          <DialogContentText style={{textAlign:'center'}}>
            Upload New Item
          </DialogContentText>
          <input type='radio' 
          value="pdf" 
          onClick={handleRadiopdfChange}
          checked = {selectedRadio === 'pdf'}  
          />
          <label><b>PDF:</b>  Add a pdf file to the Course</label>
          <Collapse in={radiopdfUpload}>
          <TextField id="standard-basic" 
          label="Title of PDF File:"
          variant="standard"
          style={{marginBottom:'10px'}}
    
          onChange={(event) => setPdfTitle(event.target.value)}
         />
         <Button
           variant="contained"
           component="label"
         >
          Upload pdf
           <input
            type="file"
            hidden
            onChange={(e)=>setPdf(e.target.files[0])}
          />
          </Button>
          </Collapse>
          <br/>
          <input type="radio" 
          value = "video" 
          onClick={handleRadiovideoChange}
          checked = {selectedRadio === 'video'} 
          />
          <label><b>Video:</b> All uploaded videos are completely secure and non downloadable. It can also be used to embed Youtube and Vimeo Videos</label>
          <Collapse in={radiovideoUpload}>
          <TextField id="standard-basic" 
          label="Title of Video File:"
          variant="standard"
          style={{marginBottom:'10px'}}
    
          onChange={(event) => setVideoTitle(event.target.value)}
         />
         <TextField id="standard-basic" 
          label="Link For Vimeo Video"
          variant="standard"
          style={{marginBottom:'10px'}}
 
          onChange={(event)=> setVideoLink(event.target.value)}
         />
          </Collapse>
          <br/>
          <input 
          type="radio" 
          value = "audio" 
          onClick={handleRadioaudioChange}
          checked = {selectedRadio === 'audio'} 
          />
          <label><b>Audio</b></label>
          <Collapse in={radioaudioUpload}>
          <TextField id="standard-basic" 
          label="Title of Audio File:"
          variant="standard"
          style={{marginBottom:'10px'}}

          onChange={(event) => setAudioTitle(event.target.value)}
         />
          <Button
           variant="contained"
           component="label"
          style={{marginBottom:'10px'}}

         >
          Upload Audio
           <input
            type="file"
            hidden
            onChange={(e)=>setAudio(e.target.files[0])}

          />
          </Button>
          </Collapse>
          <br/>
          <input type="radio" 
          value = "file" 
          onClick={handleRadiofileChange}
            checked = {selectedRadio === 'file'}
          />
          <label><b>File:</b> Add Any File For Leaners To Download</label>
          <Collapse in={radiofileUpload}>
          <TextField id="standard-basic" 
          label="Title of  File:"
          variant="standard"
          style={{marginBottom:'10px'}}
   
          onChange={(event)=> setFileTitle(event.target.value)}
         />
          <Button
           variant="contained"
           component="label"
         >
          Upload File
           <input
            type="file"
            hidden
            onChange={(e)=>setFile(e.target.files[0])}
          />
          </Button>
          </Collapse>
          </div>
          <Divider orientation='vertical' flexItem style={{margin:'10px' , color:'#F0F0F0' , fontSize:'10px'}} >OR</Divider>
          <div style={{margin:'0px', width:'100%'}}>
          <DialogContentText style={{textAlign:'center'}}>
            Create New Item
          </DialogContentText>
          <input type='radio'
            onClick={handleheadingInput}
            value='heading'
            checked={selectedRadio === 'heading'}
          />
          <label><b>Heading:</b> Define your chapter or section headings</label>
          <Collapse in ={headingInput}>
          <TextField id="standard-basic"
           label={selectedRadio.toUpperCase()} 
           variant="standard" 
           onChange={(e)=> setheading(e.target.value)} />
          </Collapse>
          <br/>
          <input type="radio" 
            onClick={handletextInput}
            value='text'
            checked = {selectedRadio === 'text'}
          />
          <label><b>Text:</b> Create your textual lessons in the course . It can also be used to embed iFrame , add HTML code through the Source Option</label>
          <Collapse in ={textInput}>
          <TextField id="standard-basic" 
           label={selectedRadio.toUpperCase()} 
           variant="standard" 
            value={text}
            onChange={(e)=> setText(e.target.value)}
           />
          </Collapse>
          <br/>
          <input type="radio" 
            onClick={handlelinkInput}
            value='link'
            checked = {selectedRadio === 'link'}
          />
          <label><b>Link:</b> Add Link which will be embedded in iFrame</label>
          <Collapse in ={linkInput}>
          <TextField id="standard-basic" 
          label={selectedRadio.toUpperCase()} 
          variant="standard"
          value={link}
          onChange={(e)=>setLink(e.target.value)}
           />
          </Collapse>
          <br/>
          <input onClick={handleQuestionInput} type="radio" value="assignment" checked={selectedRadio === 'assignment'}/>
          <label><b>Assignment:</b> Used to take or give assignment to learners</label>
          <Collapse in ={questionInput}>
          <TextField id="standard-basic" 
          label={selectedRadio.toUpperCase()} 
          variant="standard"
          value={assignment}
          onChange={(e)=>setAssignment(e.target.value)}
           />
          </Collapse>
          <br/>
        <div style={{margin:'10px'}}>
          <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={handleEditorChange}
                  placeholder="Write Description..."
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'],        
                      ['blockquote', 'code-block'],
                      [{ 'header': 1 }, { 'header': 2 }],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      [{ 'script': 'sub'}, { 'script': 'super' }],      
                      [{ 'indent': '-1'}, { 'indent': '+1' }],
                      [{ 'direction': 'rtl' }],
                      [{ 'size': ['small', false, 'large', 'huge'] }],  
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      [{ 'color': [] }, { 'background': [] }],          
                      [{ 'font': [] }],
                      [{ 'align': [] }],
                      ['clean']                                         
                    ],
                  }}
                />
                </div>
          <input type="radio"/>
          <label><b>Form:</b> Use it to take or give any information from learners while taking the course</label>
          </div>
          <Divider orientation='vertical' flexItem style={{margin:'10px' , color:'#F0F0F0' , fontSize:'10px'}} >OR</Divider>
          <div style={{margin:'0px', width:'100%' , alignSelf:'center' , justifySelf:'center'}}>
          
          <div style={{display:'flex' , justifyContent:'center' , flexDirection:'column'}}>
          <IconButton maxWidth='fit-content'><AddToPhotosIcon/></IconButton>
          <p style={{fontSize:'10px' , textAlign:'center'}}>Import From Existing Course Content</p>
          <Button variant='outlined' style={{color:'#000'}}>Import From Asset Library</Button>
          </div> 
          
          
          </div>
          </Box>
    
        </DialogContent>
     
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => { 
          setOpenPopUp(!openPopUp)}}
      />
    </React.Fragment>
  );
}