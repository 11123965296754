const { BASE_URL } = require("./client");

const GET_ALL_DETAILS = BASE_URL + "/funding/";
const DELETE_FUND = BASE_URL + "/funding/";
const DOWNLOAD_EXCEL_SHEET = BASE_URL+"/funding/download";

export {
  GET_ALL_DETAILS,
  DELETE_FUND,
  DOWNLOAD_EXCEL_SHEET,
};