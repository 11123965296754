import { Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Typography , IconButton } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';


const LoginTimesDialog = ({ open, onClose, loginDatesAndTimes }) => {
    
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Login Times</DialogTitle>
        <DialogContent>
          {loginDatesAndTimes ? (
            <List>
              {loginDatesAndTimes.map((loginTime) => (
                <ListItem key={loginTime}>
                  <ListItemText primary={loginTime} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>No login times available</Typography>
          )}
          <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogContent>
      </Dialog>
    );
  };
  

  export default LoginTimesDialog