const { BASE_URL } = require("./client");

const GET_AVAILABILTIES = BASE_URL + '/api/booking/save/availability';
const PUT_AVAILABILTIES = BASE_URL + '/api/booking/save/availability/';
const POST_AVAILABILTIES = BASE_URL + '/api/booking/save/availability';
const GET_BOOKINGS = BASE_URL + '/api/booking/availabilities';



export {
    GET_AVAILABILTIES,
    PUT_AVAILABILTIES,
    POST_AVAILABILTIES,
    GET_BOOKINGS
};
