const { BASE_URL } = require("./client");

const CREATE_CASE_STUDY = BASE_URL + '/api/casestudy/create';
const GET_CASE_STUDY = BASE_URL + '/api/casestudy/get';
const DELETE_CASE_STUDY = BASE_URL+ '/api/casestudy/delete/';
const GET_CASE_SINGLE_STUDY = BASE_URL +'/api/casestudy/get/' ;
const UPDATE_CASE_STUDY = BASE_URL+ '/api/casestudy/update/'


module.exports = {
    CREATE_CASE_STUDY,
    GET_CASE_STUDY,
    DELETE_CASE_STUDY,
    GET_CASE_SINGLE_STUDY, 
    UPDATE_CASE_STUDY 
}