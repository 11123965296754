const { BASE_URL } = require("./client");

const CREATE_BADGE = BASE_URL + '/api/badge/post';
const GET_BADGES = BASE_URL + '/api/badge/get';
const DELETE_BADGE = BASE_URL + '/api/badge/delete/';
const EDIT_BADGE = BASE_URL + '/api/badge/edit/';


module.exports = {
  CREATE_BADGE,
  GET_BADGES,
  DELETE_BADGE,
  EDIT_BADGE
}