import * as React from 'react';
import {Box , Chip ,IconButton , Tooltip} from '@mui/material';
import { DataGrid , GridToolbar } from '@mui/x-data-grid';
import { GET_REJECTED_EVENT_CERTIFICATES, GET_SENT_EVENT_CERTIFICATES , ACCEPT_CERTIFICATE } from '../../../api/event';
import axios from 'axios';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const RejectedCertificates = () => {
    const [rows  , setRows] = React.useState([]);
    const [changed , setChanged] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    React.useEffect(() => {
        axios
        .get(GET_REJECTED_EVENT_CERTIFICATES)
        .then(res => {
           const dataWithIds =  res.data.data.map((data) => { return { ...data, id: data._id}})
            setRows(dataWithIds);
            setChanged(false);
        })
        .catch(err => {
            console.error(err);
        })
    },[changed])

    const handleSelectionChange = (newSelection) => {
        setSelectedRows(newSelection.selectionModel);
    };
    const handleAccept = (eventCertficateId) => {
        axios
        .patch(ACCEPT_CERTIFICATE+eventCertficateId)
        .then(res => {
            setChanged(true);
        })
        .catch(err => {
            console.error(err);
        })
    };
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'userId',
          headerName: 'User',
          width: 150,
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
              `${params.row.fName || ''} ${params.row.lName || ''}`,
          },
        {
          field: 'eventId',
          headerName: 'Event',
          width: 150,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 110,
        },
        {
            field: 'wNumber',
            headerName: 'Whatsapp Number',
            type: 'number',
            width: 110,
          },
          {
            field: 'collegeName',
            headerName: 'College Name',
            width: 150,
          },
        {
          field: 'fullName',
          headerName: 'Full name',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 160,
          valueGetter: (params) =>
            `${params.row.fName || ''} ${params.row.lName || ''}`,
        },
        {
            field: 'topics',
            headerName: 'Topics',
            sortable: false,
            width: 160,
          },
        {
          field: 'status',
          headerName: 'Status',
          width: 150,
          renderCell: (params) => {
            const { status } = params.row;
      
              return (
                <Chip
                  label={status}
                  style={{ backgroundColor:  status === 'accepted' ? 'green' : 'red' }}
                />
              );
          },
        },
        {
            field: 'change',
            headerName: 'Change',
            width: 150,
            renderCell: (params) => {
              const { status } = params.row;
        
              if ( status.toLowerCase() === 'rejected') {
                return (
                  <>
                  <Tooltip title="Accept">
                  <IconButton onClick={() => handleAccept(params.row._id)}>
                          <DoneIcon/>
                  </IconButton>
                  </Tooltip>
                  </>
                );
              } 
            },
          },
      ];
    
  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
    <h1>REJECTED CERTIFICATES({rows?rows.length:0})</h1>
    {rows.length > 0 ? (
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        checkboxSelection
        onSelectionModelChange={handleSelectionChange}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        slots={{ toolbar: GridToolbar }}
      />
    ) : (
      <p>Loading...</p>
    )}
  </Box>
  );
}

export default RejectedCertificates