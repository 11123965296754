import React, { useState, useEffect } from "react";
import "./App.css";
import Bookevent from "./components/BookEvent";
import Email from "./components/Email";
import Admin_emails from "./components/Email/Component1/admin_emails";
import Admin_emails_template from "./components/Email/Component1/admin_emails_template";
import Emails_performance from "./components/Email/Component1/emails_performance";
import WatchNow from "./components/WatchNow";
import Login from "./components/auth/Login";
import { Routes, Route , useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import axios from "axios";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Learn from "./components/Learn";
import WebsiteAccess from "./components/WebsiteAccess";
import Announcement from "./components/Announcement";
import Notification from "./components/Notification";
import MemberShips from "./components/membership";
import UpdateLinks from "./components/updateLinks";
import CourseTestimonial from "./components/courses/testimonial";
import Funding from "./components/funding";
import VirtualCampus from "./components/virtualCampus";
import StartupShort from "./components/StartupShort";
import Blog from "./components/Blog";
import MainPage from "./components/MainPage";
import B1Main from "./components/MainPage/b1";
import B2Main from "./components/MainPage/b2";
import B3Main from "./components/MainPage/b3";
import B4Main from "./components/MainPage/b4";
import B5Main from "./components/MainPage/b5";
import B6Main from "./components/MainPage/b6";
import Courses from "./components/courses/index.jsx";
import B7Main from "./components/MainPage/b7";
import B8Main from "./components/MainPage/b8";
import B9Main from "./components/MainPage/b9";
import User from "./components/User";
import KitPage from "./components/KitPage/index";
import Main_side from "./components/KitPage/Main_side";
import Features from "./components/KitPage/Features";
import Learner from "./components/Learner_landing/index";
import Block1 from "./components/Learner_landing/Block1";
import Block2 from "./components/Learner_landing/Block2";
import BlueBox1 from "./components/Learner_landing/Blue_Box1";
import BlueBox2 from "./components/Learner_landing/Blue_Box2";
import Mainside from "./components/Learner_landing/Main_side";
import Shorts from "./components/Learner_landing/Shorts";
import StarupStartupShortAdmin from "./components/StartUpShortsAdmin";
import UserDetails from "./components/UserDetails";
import AdminBoard from "./components/AdminBoard";
import MobileNumberCleanup from "./components/MobileNumberCleanup";
import Certificate from './components/CourseUpload/Certificate/index';
import CaseStudy from './components/CaseStudy/index';
import Admin_emails_template_index from "./components/Email/Component1/admin_emails_template_index";
import Email_Index from './components/Email/Component1/email_index';
import ApplyCourse from "./components/ApplyCourse";
import Badge from "./components/Badge";
import DailyGoals from "./components/DailyGoals";
import { Button } from "@material-ui/core";
import Partner from "./components/PartnerWithUs/Partner.js"
import Contact from "./components/contact/Contact.js"
import AddReedom from "./components/Reedom/AddRedeem/index.jsx";
import ReedomList from "./components/Reedom/index.jsx";
import Upload from "./components/Lab/Upload.js"
import UpdateAboutCourses from "./components/courcesAboutURL/UpdateFormAboutURL.js"
import AboutCourses from "./components/courcesAboutURL/AboutCourses.js"
import CreatePage from "./components/courcesAboutURL/CreatePage.js"
import Events from "./components/Event";
import RegisteredEvents from './components/Event/RegisteredEvents'
import WorkEx from "./components/WorkEx";
import ApproveWorkEx from "./components/WorkEx/ApproveWorkEx";
import EventCertificarteGen from "./components/EventCertificateGen";
import SentEventCertificarteGen from "./components/EventCertificateGen/SentCertificates";
import RejectedCertificates from "./components/EventCertificateGen/RejectedCertificates";
import Lab from "./components/Lab";
import Analytics from './components/Analytics/Dashboard';
import BoughtCourses from './components/Analytics/Courses';
import ParticularCourse from "./components/Analytics/ParticularCourse";
import LinkForCourseCheckout from './components/LinkForCourseCheckout';
import Certificates from './components/Certificates';
import AdminCertificate from './components/AdminCertificate';
import BrochureDetails from './components/BrochureDetails';
import PaymentDetails from "./components/PaymentDetails";
import Mentor from './components/Mentors';
import Hire from './components/Hire';
import WorkExperience from './components/WorkExperience';
import Scholarship from './components/Scholarship';
import Query from './components/Query';
import Availability from './components/Availability'
import Bookings from "./components/Booking/index.jsx";

const { USER_DATA } = require("./api/websiteaccess");

function App() {
  const [isAuth, setIsAuth] = useState(
    window.localStorage.getItem("isAuth") || false
  );
  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();

  const [userAccess, setUserAccess] = useState([]);

  const [a_events, setAEvents] = useState(false);
  const [a_announce, setAAnnounce] = useState(false);
  const [a_watchnow, setAWatchnow] = useState(false);
  const [a_partner, setAPartner] = useState(false);
  const [a_education, setAEducation] = useState(false);
  const [a_email, setAemail] = useState(false);
  const [a_websiteAccess, setAWebsiteAccess] = useState(false);
  const [a_adminboard , setAAdminBoard] = useState(false);
  const [a_startupshort , setAStartupShort] = useState(false);
  const [a_blog , setABlog] = useState(false);
  const [a_user , setAUser] = useState(false);
  const [a_mobilenumbercleanup , setAMobileNumberCleanup] = useState(false);
  const [a_mainpage , setAMainpage] = useState(false);
  const [a_kitpage , setAKitpage] = useState(false);
  const [a_learner , setALearner] = useState(false);
  const [a_funding , setAFunding] = useState(false);
  const [a_memberships , setAmemberships] = useState(false);
  const [a_virtualcampus , setAvirtualcampus] = useState(false);
  const [a_updatelink , setAupdatelinks] = useState(false);
  const [a_courses , setAcourses] = useState(false);
  const [a_casestudy , setAcasestudy] = useState(false);
  const [a_badge , setAbadge] = useState(false);
  const [a_applycourses , setAApplyCourses] = useState(false);
  const [a_eventcertificate , setAEventCertificate] = useState(false);
  const [a_dailygoals , setADailyGoals] = useState(false);
  const [a_analytics , setAAnalytics] = useState(false);
  const [a_events_register , setAEventRegister] = useState(false);
  const [a_events1 , setAEvents1] = useState(false);
  const [a_lab , setALab] = useState(false);
  const [a_workex , setAWorkex] = useState(false);
  const [a_editcourse , setAEditCourse] = useState(false);
  const [a_generatelink , setAGenerateLink] = useState(false);
  const [a_redeem , setARedeem] = useState(false);
  const [a_createcourse , setACreateCourse] = useState(false);
  const [a_certificates, setACertificates] = useState(false);
  const [a_admincertificates, setAAdminCertificates] = useState(false);
  const [a_brochuredetails, setABrochureDetails] = useState(false);
  const [a_workexperience, setAWorkExperience] = useState(false);
  const [a_workexperienceapproval, setAWorkExperienceApproval] = useState(false);
  const [a_payments, setAPayments] = useState(false);
  const [a_mentors, setAMentors] = useState(false);
  const [a_hire, setAHire] = useState(false);
  const [a_queries , setAQueries] = useState(false);
  const [a_scholarship , setAScholarship] = useState(false);
  const [a_availability , setAAvailability] = useState(false);
  const [a_booking , setABooking] = useState(false);

  





  useEffect(() => {
    const data = {
      email: window.localStorage.getItem("email"),
    };
    axios
      .post(USER_DATA, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then(async (resp) => {
        await setUserAccess(resp.data.accessTo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const accessess = () => {
    if (userAccess.length > 0) {
      userAccess.map((access) => {
        if (access === "Watch Now") setAWatchnow(true);
        if (access === "Website Announcement") setAAnnounce(true);
        if (access === "Events") setAEvents(true);
        if (access === "Partner Us") setAPartner(true);
        if (access === "Website Access") setAWebsiteAccess(true);
        if (access === "Markoknow Education") setAEducation(true);
        if (access === "Emails") setAemail(true);
        if (access === "Admin Board") setAAdminBoard(true);
        if (access === "Startup Short") setAStartupShort(true);
        if (access === "Blog") setABlog(true);
        if (access === "User") setAUser(true);
        if (access === "Mobile Number Cleanup") setAMobileNumberCleanup(true);
        if (access === "Funding") setAFunding(true);
        if (access === "Main Page") setAMainpage(true);
        if (access === "Kit Page") setAKitpage(true);
        if (access === "Learner") setALearner(true);
        if (access === "Membership") setAmemberships(true);
        if (access === "Update Link") setAupdatelinks(true);
        if (access === "Courses") setAcourses(true);
        if (access === "Virtual Campus") setAvirtualcampus(true);
        if (access === "Case Study") setAcasestudy(true);
        if (access === "Badge") setAbadge(true);
        if (access === "Apply Courses") setAApplyCourses(true);
        if (access === "Event Certificate") setAEventCertificate(true);
        if (access === "Daily Goal") setADailyGoals(true);
        if (access === "Create Course") setACreateCourse(true);
        if (access === "Analytics") setAAnalytics(true);
        if (access === "Events Register") setAEventRegister(true);
        if (access === "Events 1") setAEvents1(true);
        if (access === "Lab") setALab(true);
        if (access === "Workex") setAWorkex(true);
        if (access === "Edit Course") setAEditCourse(true);
        if (access === "Generate Link") setAGenerateLink(true);
        if (access === "Redeem") setARedeem(true);
        if (access === "Certificates") setACertificates(true);
        if (access === "Admin Certificate") setAAdminCertificates(true);
        if (access === "Brochure Details") setABrochureDetails(true);
        if (access === "Work Ex") setAWorkExperience(true);
        if (access === "Work Ex Approval") setAWorkExperienceApproval(true);
        if (access === "Payments") setAPayments(true);
        if (access === "Mentor") setAMentors(true);
        if (access === "Hire") setAHire(true);
        if (access === "Booking") setABooking(true);
        if (access === "Scholarship") setAScholarship(true);
        if (access === "Queries") setAQueries(true);
        if (access === "Availability") setAAvailability(true);


      });
    }
  };

  useEffect(() => {
    accessess();
  });

  useEffect(() => {
    ReactGA.initialize("UA-211886888-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    setShowNavbar(location.pathname !== "/adminboard");
  }, [location]);
  return (
    
    <div className="App">
     
      {showNavbar&&
        <Navbar isAuth={isAuth} setIsAuth={setIsAuth} path = {window.location.pathname==="/startupshortsadmin"?"Startup Shorts ":"MarkoKnow"} />}
        <Routes location={useLocation()} key={useLocation().pathname}>
          <Route
            exact
            path="/"
            element={
              <Home
              isAuth={isAuth}
              a_watchnow={a_watchnow}
              a_events={a_events}
              a_announce={a_announce}
              a_websiteAccess={a_websiteAccess}
              a_education={a_education}
              a_email={a_email}
              a_startupshort={a_startupshort}
              a_adminboard={a_adminboard}
              a_blog={a_blog}
              a_mobilenumbercleanup={a_mobilenumbercleanup}
              a_user={a_user}
              a_learner={a_learner}
              a_mainpage={a_mainpage}
              a_kitpage={a_kitpage}
              a_funding={a_funding}
              a_virtualcampus={a_virtualcampus}
              a_memberships={a_memberships}
              a_courses={a_courses}
              a_updatelink={a_updatelink}
              a_casestudy={a_casestudy}
              a_badge={a_badge}
              a_applycourses={a_applycourses}
              a_eventcertificate={a_eventcertificate}
              a_dailygoals={a_dailygoals}
              a_createcourse={a_createcourse}
              a_analytics={a_analytics}
              a_events_register={a_events_register}
              a_events1={a_events1}
              a_lab={a_lab}
              a_workex={a_workex}
              a_editcourse={a_editcourse}
              a_generatelink={a_generatelink}
              a_redeem={a_redeem}
              a_certificates={a_certificates}
              a_admincertificates={a_admincertificates}
              a_brochuredetails={a_brochuredetails}
              a_workexperience={a_workexperience}
              a_workexperienceapproval={a_workexperienceapproval}
              a_payments={a_payments}
              a_mentors={a_mentors}
              a_hire={a_hire}
              a_scholarship={a_scholarship}
              a_availability={a_availability}
              a_queries={a_queries}
              a_booking={a_booking}
            />

            }
          />
          <Route exact path="/notifications" element={<Notification />} />
          <Route exact path="/memberships" element={<MemberShips />} />
          <Route exact path="/funding" element={<Funding isAuth={isAuth} />} />
          <Route
            exact
            path="/virtualCampus"
            element={<VirtualCampus isAuth={isAuth} />}
          />
          <Route
            exact
            path="/startupShort"
            element={<StartupShort isAuth={isAuth} />}
          />
          <Route exact path="/blog" element={<Blog isAuth={isAuth} />} />
          <Route
            exact
            path="/login"
            element={<Login isAuth={isAuth} setIsAuth={setIsAuth} />}
          />
          <Route
            exact
            path="/watchnow"
            element={<WatchNow isAuth={isAuth} a_watchnow={a_watchnow} />}
          />
          <Route exact path="/courses" element={<Courses />} />
          <Route
            exact
            path="/courses/testimonial"
            element={<CourseTestimonial />}
          />
          <Route
            exact
            path="/bookevents"
            element={<Bookevent isAuth={isAuth} a_events={a_events} />}
          />

          <Route
            exact
            path="/announcement"
            element={<Announcement isAuth={isAuth} a_announce={a_announce} />}
          />
          <Route
            exact
            path="/updateLinks"
            element={<UpdateLinks isAuth={isAuth} />}
          />
          <Route
            exact
            path="/access"
            element={
              <WebsiteAccess
                isAuth={true}
                a_websiteAccess={true}
              />
            }
          />
          <Route
            exact
            path="/learn"
            element={<Learn isAuth={isAuth} a_education={a_education} />}
          />
          <Route
            exact
            path="/email"
            element={<Email isAuth={isAuth} a_email={a_email} />}
          />
          <Route
            exact
            path="/admin-emails"
            element={<Admin_emails isAuth={isAuth} a_email={a_email} />}
          />
          <Route
            exact
            path="/emails"
            element={<Email_Index isAuth={isAuth} a_email={a_email} />}
          />

          <Route
            exact
            path="/admin-emails/admin-emails-template"
            element={
              <Admin_emails_template_index isAuth={isAuth} a_email={a_email} />
            }
          />
        
          <Route
            exact
            path="/admin-emails/performance"
            element={<Emails_performance isAuth={isAuth} a_email={a_email} />}
          />

          <Route exact path="/MainPage" element={<MainPage />} />
          <Route exact path="/B1_Main" element={<B1Main />} />
          <Route exact path="/B2_Main" element={<B2Main />} />
          <Route exact path="/B3_Main" element={<B3Main />} />
          <Route exact path="/B4_Main" element={<B4Main />} />
          <Route exact path="/B5_Main" element={<B5Main />} />
          <Route exact path="/B6_Main" element={<B6Main />} />
          <Route exact path="/B7_Main" element={<B7Main />} />
          <Route exact path="/B8_Main" element={<B8Main />} />
          <Route exact path="/B9_Main" element={<B9Main />} />
          <Route exact path="/user" element={<User />} />
          <Route exact path="/KitPage" element={<KitPage />} />
          <Route exact path="/Main_side" element={<Main_side />} />
          <Route exact path="/Features" element={<Features />} />
          <Route exact path="/Learner_landing" element={<Learner />} />
          <Route exact path="/Mainside_learner" element={<Mainside />} />
          <Route exact path="/bluebox1" element={<BlueBox1 />} />
          <Route exact path="/bluebox2" element={<BlueBox2 />} />
          <Route exact path="/block1" element={<Block1 />} />
          <Route exact path="/block2" element={<Block2 />} />
          <Route exact path="/shorts" element={<Shorts />} />
          <Route exact path="/startupshortsadmin" element={<StarupStartupShortAdmin isAuth={isAuth} setIsAuth={setIsAuth}/>}/>
          <Route exact path="/userdetails" element={<UserDetails isAuth={isAuth} setIsAuth={setIsAuth}/>}/>
          <Route exact path="/adminboard" element={<AdminBoard isAuth={isAuth} setIsAuth={setIsAuth}/>}/>
          <Route exact path="/mobilenumbercleanup" element={<MobileNumberCleanup isAuth={isAuth} setIsAuth={setIsAuth}/>}/>
          <Route exact path="/viewcertificate/:courseid" element={<Certificate/>} />
          <Route exact path="/casestudy" element={<CaseStudy isAuth={isAuth} setIsAuth={setIsAuth} a_casestudy={a_casestudy}/>} />
          <Route exact path="/applycourse" element={<ApplyCourse isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/badge" element={<Badge isAuth={isAuth} a_badge={a_badge}/>} />  

          <Route exact path="/dailygoals" element={<DailyGoals isAuth={isAuth} />} />



          <Route exact path="/partnerwithus" element={<Partner/>} />  
          <Route exact path="/contact" element={<Contact/>} />  
          <Route exact path="/redeem" element={<ReedomList/>} />  
          <Route exact path="/redeem/add" element={<AddReedom/>} />   
          <Route exact path="/lab/upload" element={<Upload/>} />  
          <Route exact path="/lab" element={<Lab/>} />  
          <Route exact path="/update/:id" element={<UpdateAboutCourses/>} />  
          <Route exact path="/aboutcources" element={<AboutCourses/>} />  
          <Route exact path="/CreateCoursePage" element={<CreatePage/>} />  
          <Route exact path="/events" element={<Events/>} />  
          <Route exact path="/registeredEvents" element={<RegisteredEvents/>} />  
          <Route exact path="/workex" element={<WorkEx/>} /> 
          <Route exact path="/workex/approval" element={<ApproveWorkEx/>} /> 
          <Route exact path="/events/certificates" element={<EventCertificarteGen/>} />  
          <Route exact path="/events/certificates/sent" element={<SentEventCertificarteGen/>} />  
          <Route exact path="/events/certificates/rejected" element={<RejectedCertificates/>} />  
          <Route exact path="/analytics" element={<Analytics/>} />  
          <Route exact path="/courses/bought/:id" element={<BoughtCourses />} />
          <Route exact path="/analytics/courses" element={<ParticularCourse />} />
          <Route exact path="/checkoutlink" element={<LinkForCourseCheckout />} />
          <Route exact path="/certificates" element={<Certificates />} />
          <Route exact path="/admin/certificates" element={<AdminCertificate />} />
          <Route exact path="/brochuredetails" element={<BrochureDetails isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/payments" element={<PaymentDetails isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/mentors" element={<Mentor isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/workexperience" element={<WorkExperience isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/hires" element={<Hire isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/scholarship" element={<Scholarship isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/queries" element={<Query isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/availability" element={<Availability isAuth={isAuth} setIsAuth={setIsAuth}/>} />
          <Route exact path="/bookings" element={<Bookings isAuth={isAuth} setIsAuth={setIsAuth}/>} />



          

                  </Routes>
    </div>
    
  );
}

export default App;
