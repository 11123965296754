const { BASE_URL } = require("../client");

const b3LinkId = "6336684174939e23c02719cd";

const UPDATE_B3_MAINPAGE = BASE_URL + "/main/b3/"+b3LinkId;
const GET_B3_MAINPAGE = BASE_URL + "/main/b3/"+b3LinkId;

export {
    UPDATE_B3_MAINPAGE,
    GET_B3_MAINPAGE,
}