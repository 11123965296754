// Redeem.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link , useNavigate} from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { GET_REEDOM , DELETE_REDEEM} from '../../api/Reedom/Reedom';
import { IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { Edit, LineAxisSharp } from "@mui/icons-material";
import {Typography} from '@mui/material';
import { makeStyles } from "@material-ui/core";
const Redeem = () => {
  const [reedomData, setReedomData] = useState([]);
  const [refresh , setRefresh] = useState(false);
  const navigate = useNavigate();
  const classes = u();
  useEffect(() => {
    const fetchData =  () => {
      axios
      .get(GET_REEDOM)
      .then(res => {
        setReedomData(res.data);
      })
      .catch(err => {
        console.log("Error", err);
      })
      
    };

    fetchData();
  }, [refresh]);

  const handleDelete = (id) => {
    axios
    .delete(DELETE_REDEEM+id)
    .then(res => {
      setRefresh(true);
    })
    .catch(err => {
      setRefresh(false);
    })
  };

  return (
    <div>
    <Box sx={{
      display:'flex' ,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px'
    }}>
      <h1>Redeem</h1>
      <Link to="/redeem/add">
        <Button variant="contained" color="primary">
          Add Redeem
        </Button>
      </Link>
    </Box>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Percentage Discount</TableCell>
              <TableCell>Required Points</TableCell>
              <TableCell>Top Caption</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Use Count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(reedomData) && reedomData.length > 0 && reedomData.map((reedom) => (
              <TableRow key={reedom._id}>
                <TableCell>{reedom.percentageDiscount}</TableCell>
                <TableCell>{reedom.requiredPoints}</TableCell>
                <TableCell>{reedom.requiredOffer}</TableCell>
                <TableCell>{reedom.code}</TableCell>
                <TableCell>{reedom.count}</TableCell>

                <TableCell>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(reedom._id)}
                  >
                    <Delete/>
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/redeem/add" , {state: {data: reedom}})}
                  >
                    <Edit/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
const u = makeStyles({
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 600,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      p: 3,
    },
  },
});
export default Redeem;
