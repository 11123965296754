import * as React from 'react';
import {Box, Typography} from '@mui/material';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { GET_SCHOLARSHIPS } from '../../api/scholarship';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
const Scholarships = () => {
    const [scholarships , setScholarships] = React.useState([]);
    React.useEffect(() => {
        axios
        .get(GET_SCHOLARSHIPS)
        .then(res => {
            const men = res.data.data.map((mentor , index) => {
                mentor.id = index+1; 
                return mentor;
            });
            
            setScholarships(men);
        })
        .catch(err => {
            console.error(err);
        })
    },[]);
    const columns = [
        { field: '_id', headerName: 'ID', width: 90 },
        {
          field: 'fName',
          headerName: 'First name',
          width: 150,
        },
        {
          field: 'lName',
          headerName: 'Last name',
          width: 150,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 250,
        },
        {
          field: 'wNumber',
          headerName: 'Whatsapp Number',
          width: 160,
        },
        {
            field: 'course',
            headerName: 'Course',
            width: 160,
          },
          {
            field: 'college',
            headerName: 'College',
            width: 160,
          },
          {
            field: 'linkedin',
            headerName: 'LinkedIn Profile Url',
            width: 160,
          },
          {
            field: 'training',
            headerName: 'Training',
            width: 160,
          },
          {
            field: 'need',
            headerName: 'Need Of Scholarship',
            width: 160,
          },
          {
            field: 'scorecard',
            headerName: 'Scorecard',
            width: 160,
            renderCell: (params) => (
                <IconButton
                  href={params.row.scorecard} // assuming params.value contains the URL to the PDF
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="download scorecard"
                >
                  <DownloadIcon />
                </IconButton>
              ),
          },
      ];

  return (
    <Box>
    <Typography variant='h6' sx={{marginBottom: 3}}>Applied Scholarships</Typography>
    <Box sx={{ height: '100vh', width: '100%' }}>
      <DataGrid
        rows={scholarships}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
    </Box>
    </Box>
  )
}

export default Scholarships