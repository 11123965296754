import React from 'react'
import { FormGroup, makeStyles, Modal, TextField } from '@material-ui/core'
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
const {GET_ALL_EVENTS , NEW_EVENT ,
      EDIT_EVENT ,DELETE_EVENT , SHOW_W_VSC , SHOW_W_D_VSC} = require("../../api/watchnow");
      

const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#000833' : '#000833',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#000833',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

const WatchNow = ({isAuth ,a_watchnow}) => {
    const classes=Usestyles();
    const classe = u();

    const [data, setData] = useState([]);
    const [openedit,setOpenedit]=useState(false);
    const [watchdata,setWatchdata]=useState([]);
    const [title, setTitle] = useState(watchdata.title);
    const [speaker, setSpeaker] = useState(watchdata.speaker);
    const [aboutSpeaker, setAboutSpeaker] = useState(watchdata.aboutSpeaker);
    const [link, setLink] = useState(watchdata.link);
    const [poster, setPoster] = useState(watchdata.poster);
    const [vtags,setVtags] = useState(watchdata.tags);
    const [opendelete,setOpendelete]=useState(false);
    const [opencreate,setOpencreate]=useState(false);
    //these are the tags
    const [all,setAll]=useState(true);
    const [startups,setStartups]=useState(false);
    const [marketing,setMarketing]=useState(false);
    const [finance,setFinance]=useState(false);
    const [funding,setFunding]=useState(false);
    const [founders,setFounders]=useState(false);
    const [techD,setTechandD]=useState(false);
    const [others,setOthers]=useState(false);
    const [points , setPoints]=useState(0);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    //const[important,setImportant]=useState(0);
    

    useEffect(() => {
      axios
        .get(GET_ALL_EVENTS, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setData(res.data.reverse());
        });
    }, []);
    const handleSingleChecked = (active , id) => {
      if(!active){
        axios
        .patch(SHOW_W_VSC+id)
        .then(res => {
         axios
        .get(GET_ALL_EVENTS, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setData(res.data.reverse());
        })
        .catch((err) => {
          console.log(err);
        });
        })
        .catch(err => {
          console.log(err);
        })
      } 
      if(active) {
          axios
          .patch(SHOW_W_D_VSC+id)
          .then(res => {
            axios
            .get(GET_ALL_EVENTS, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
              },
            })
            .then((res) => {
              setData(res.data.reverse());
            })
            .catch((err) => {
              console.log(err);
            });
          })
          .catch(err => {
            console.log(err);
          })
        
      }     
     
  }
    const createSubmit=()=>{
      var tags=[];
      if(all)tags.push("all");
      if(startups)tags.push("startups");
      if(marketing)tags.push("marketing");
      if(finance)tags.push("finance");
      if(funding)tags.push("funding");
      if(founders)tags.push("founders");
      if(techD)tags.push("tech and design");
      if(others)tags.push("others");
      const formdata=new FormData();
      formdata.append("title", title);
      formdata.append("speaker", speaker);
      formdata.append("aboutSpeaker", aboutSpeaker);
      formdata.append("link", link);
      formdata.append("points", points);
      //formdata.append("tags",tags);
      tags.forEach((tag)=>formdata.append("tags",tag));
      if (poster) formdata.append("poster", poster);
      axios
      .post(NEW_EVENT,formdata,{
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res)=>{
        alert("created a new watchnow");
        setOpencreate(false);
        window.location.href = "/watchnow";
      })
      .catch((err)=>{
        console.log(err);
        alert("please try again later");
      })
    }

    const editsubmit=()=>{
      var tags=[];
      if(all)tags.push("all");
      if(startups)tags.push("startups");
      if(marketing)tags.push("marketing");
      if(finance)tags.push("finance");
      if(funding)tags.push("funding");
      if(founders)tags.push("founders");
      if(techD)tags.push("tech and design");
      if(others)tags.push("others");

      const fdata=new FormData();
      fdata.append("title", title);
      fdata.append("speaker", speaker);
      fdata.append("aboutSpeaker", aboutSpeaker);
      fdata.append("link", link);
      fdata.append("points", points);
      tags.forEach((tag)=>fdata.append("tags",tag));
      if (poster) fdata.append("poster", poster);
      axios
      .put(EDIT_EVENT+watchdata._id, fdata, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        alert("sucessfully edited watchnow");
        setOpenedit(false);
        window.location.href = "/watchnow";
      })
      .catch((err) => {
        alert("Please Try again later!");
      });
    };

    const deletesubmit=()=>{
      axios.delete(DELETE_EVENT+watchdata._id,{
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response)=>{
        alert("sucessfully deleted");
        setOpendelete(false);
        window.location.href = "/watchnow";
      })
      .catch((err)=>{
        alert("please try again later,some error occured");
      })
    };

    const MakeitFalse=()=>{
      setAll(false);
      setStartups(false);
      setMarketing(false);
      setFinance(false);
      setFunding(false);
      setFounders(false);
      setTechandD(false);
      setOthers(false);
    }

    const tagsHandler=(value)=>{
      if(value==="all"){
        all?(setAll(false)):(setAll(true));
      }
      if(value==="startup"){
        startups?(setStartups(false)):(setStartups(true));
      }
      if(value==="marketing"){
        marketing?(setMarketing(false)):(setMarketing(true));
      }
      if(value==="techD"){
        techD?(setTechandD(false)):(setTechandD(true));
      }
      if(value==="finance"){
        finance?(setFinance(false)):(setFinance(true));
      }
      if(value==="funding"){
        funding?(setFunding(false)):(setFunding(true));
      }
      if(value==="founders"){
        founders?(setFounders(false)):(setFounders(true));
      }
      if(value==="others"){
        others?(setOthers(false)):(setOthers(true));
      }
    };

    const columns = [
      { field: '_id', headerName: '_id', width: 90 },
      {
        field: 'title',
        headerName: 'Heading of video',
        width: 150,
        headerClassName: 'column-header',
        editable: true,
      },
      {
        field: 'aboutSpeaker',
        headerName: 'Description of watch, speaker',
        headerClassName: 'column-header',
        width: 350, 
        editable: true,
      },
      {
        field: 'points',
        headerName: 'Points',
        headerClassName: 'column-header',
        width: 100, 
        editable: true,
      },
      {
        field: 'editbtn',
        headerName: 'Edit',
        headerClassName: 'column-header',
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (params)=>{
          const onClick=()=>{
            setOpenedit(true);
            setWatchdata(params.row);
            setTitle(params.row.title);
            setSpeaker(params.row.speaker);
            setAboutSpeaker(params.row.aboutSpeaker);
            setLink(params.row.link);
            setPoster(params.row.poster);
            setVtags(params.row.tags);
            tagschecker(params.row.tags);
          };
          return(
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{marginRight: '16px', background: '#eb8546'}}
                onClick={onClick}
            >
                Edit
            </Button>
          );
        },
      },
      {
        field: 'deletebtn',
        headerName: 'Delete',
        headerClassName: 'column-header',
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (params)=>{
          const onClick=()=>{
            // setOpenedit(true);
            setWatchdata(params.row);
            setOpendelete(true);
          };
          return(
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{marginRight: '16px', background: '#ed4253'}}
                onClick={onClick}
            >
                Delete
            </Button>
          );
        },
      },
      {
        field: 'Show Vsc',
        type: 'actions',
        headerName: 'Active',
        width: 100,
        cellClassName: 'actions',
        getActions: (params) => {
          // console.log(params);
          return [
            (
              
            <FormControlLabel
          control={<IOSSwitch {...label} 
          checked={params.row.showVsc}
          onChange={(e) => {
            // setChecked((prevState) => !prevState)
            handleSingleChecked(params.row.showVsc , params.row._id)
            }} /> } />
            )
          ];
        },
      },
    ];

    const tagschecker=(vediotags)=>{
      MakeitFalse();
      vediotags.map((videotag)=>{
        if(videotag==="all")setAll(true);
        if(videotag==="startups")setStartups(true);
        if(videotag==="marketing")setMarketing(true);
        if(videotag==="finance")setFinance(true);
        if(videotag==="funding")setFunding(true);
        if(videotag==="founders")setFounders(true);
        if(videotag==="tech and design")setTechandD(true);
        if(videotag==="others")setOthers(true);
      })
    }
    const createbox=(
      <Box className={classe.style}>
        <Typography variant='h4'>CREATE A WATCHNOW</Typography>
          <FormControlLabel  control={<Checkbox onChange={()=>tagsHandler("all")}/>} label="All" />
          <FormControlLabel control={<Checkbox onChange={()=>tagsHandler("startup")} />} label="Startup" />
          <FormControlLabel control={<Checkbox onChange={()=>tagsHandler("marketing")} />} label="Marketing" />
          <FormControlLabel control={<Checkbox onChange={()=>tagsHandler("techD")} />} label="Tech & Design" />
          <FormControlLabel control={<Checkbox onChange={()=>tagsHandler("finance")} />} label="Finance" />
          <FormControlLabel control={<Checkbox onChange={()=>tagsHandler("funding")} />} label="Funding" />
          <FormControlLabel control={<Checkbox onChange={()=>tagsHandler("founders")} />} label="Founders" />
          <FormControlLabel control={<Checkbox onChange={()=>tagsHandler("others")} />} label="Others" />
          <FormGroup>
          
            <TextField
            fullWidth 
            margin='normal'
            className={classes.name} 
            placeholder="Finance bootcamp"
            label="Title" 
            onChange={(e)=> setTitle(e.target.value)}
            />
            <TextField
            fullWidth 
            margin='normal'
            className={classes.name} 
            placeholder="Kunal Kushwaha"
            label="speaker" 
            onChange={(e)=> setSpeaker(e.target.value)}
            />
            <TextField
            fullWidth 
            margin='normal'
            className={classes.name} 
            placeholder="Has a slef start-up and a philanthrophist"
            label="about Speaker" 
            onChange={(e)=> setAboutSpeaker(e.target.value)}
            />
            <TextField
            fullWidth 
            margin='normal'
            className={classes.name} 
            placeholder="Video link"
            label="link" 
            onChange={(e)=> setLink(e.target.value)}
            />
            <TextField
            fullWidth 
            margin='normal'
            className={classes.name} 
            placeholder="Points"
            type="number"
            label="points" 
            onChange={(e)=> setPoints(e.target.value)}
            />
        </FormGroup>
        <Button
        variant="contained"
        component="label"
        >
          Upload Poster
          <input
            type="file"
            hidden
            onChange={(e)=>setPoster(e.target.files[0])}
          />
        </Button>
        <div>
          <Button className={classes.btn} onClick={createSubmit}>Submit</Button>
        </div>
    </Box>
    );

    const editbox=(
      <Box className={classe.style}>
        <Typography variant='h4'>EDIT WATCHNOW</Typography>
          <FormControlLabel  control={<Checkbox checked={all} onChange={()=>tagsHandler("all")}/>} label="All" />
          <FormControlLabel control={<Checkbox  checked={startups} onChange={()=>tagsHandler("startup")} />} label="Startup" />
          <FormControlLabel control={<Checkbox checked={marketing} onChange={()=>tagsHandler("marketing")} />} label="Marketing" />
          <FormControlLabel control={<Checkbox checked={techD}  onChange={()=>tagsHandler("techD")} />} label="Tech & Design" />
          <FormControlLabel control={<Checkbox checked={finance} onChange={()=>tagsHandler("finance")} />} label="Finance" />
          <FormControlLabel control={<Checkbox checked={funding} onChange={()=>tagsHandler("funding")} />} label="Funding" />
          <FormControlLabel control={<Checkbox checked={founders} onChange={()=>tagsHandler("founders")} />} label="Founders" />
          <FormControlLabel control={<Checkbox checked={others} onChange={()=>tagsHandler("others")} />} label="Others" />
  
        
          <TextField
          fullWidth 
          margin='normal'
          className={classes.name} 
          defaultValue={watchdata.title}
          label="Title" 
          onChange={(e)=> setTitle(e.target.value)}
          />

          <TextField
          fullWidth 
          margin='normal'
          className={classes.name} 
          defaultValue={watchdata.speaker}
          label="speaker" 
          onChange={(e)=> setSpeaker(e.target.value)}
          />

          <TextField
          fullWidth 
          margin='normal'
          className={classes.name} 
          defaultValue={watchdata.aboutSpeaker}
          label="about Speaker" 
          onChange={(e)=> setAboutSpeaker(e.target.value)}
          />
 
          <TextField
          fullWidth 
          margin='normal'
          className={classes.name} 
          defaultValue={watchdata.link}
          label="link" 
          onChange={(e)=> setLink(e.target.value)}
          />
          <TextField
            fullWidth 
            margin='normal'
            className={classes.name} 
            placeholder="Points"
            type="number"
            defaultValue={watchdata.points}
            label="Points" 
            onChange={(e)=> setPoints(e.target.value)}
            />
        <Button
        variant="contained"
        component="label"
        >
          Upload Poster
          <input
            type="file"
            hidden
            onChange={(e)=>setPoster(e.target.files[0])}
          />
        </Button>
        <div>
          <Button className={classes.btn} onClick={editsubmit}>Submit</Button>
        </div>
      </Box>
    );

    const deleteBox=(
      <Box className={classes.deletecontainer}>
        <Box className={classes.deletebox}>
          <Typography>Are you sure, you want to delete?</Typography>
          <Box>
            <Button onClick={deletesubmit} className={classes.btn} style={{"marginRight":'10px'}}>Yes</Button>
            <Button onClick={()=> setOpendelete(false) } className={classes.btn}>No</Button> 
          </Box>
        </Box>
      </Box>
    );

  return (
    <div>
      {
        a_watchnow?(
          <div className={classes.container}>
            <Box display='flex' justifyContent='space-between' padding='10px'>
              <Typography variant='h5'>List of WatchNows Data</Typography>
              <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>{setOpencreate(true); MakeitFalse();}}
              >Create</Button>
            </Box>
            <Box 
              sx={{
                height: 800,
                width: '100%',
                '& .column': {
                    fontSize: '16px',
                    justifyCOntent: 'center',
                    textAlign: 'center'
                },
                '& .column-header': {
                    color: 'purple',
                    fontSize: '20px',
                },
            }}
            >
              <DataGrid
              rows={data}
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              />
            </Box>
              <Modal
              open={opencreate}
              onClose={()=>setOpencreate(false)}
              >
                {createbox}
              </Modal>
              <Modal
              open={openedit}
              onClose={()=>setOpenedit(false)}
              >
                {editbox}
              </Modal>
              <Modal
              open={opendelete}
              onClose={()=>setOpendelete(false)}
              >
                {deleteBox}
              </Modal>
          </div>
        ):(
          <Box>
            <Typography>You are not allowed</Typography>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button>Go back</Button>
            </Link>
          </Box>
        )
      }
    </div>
  )
}

const Usestyles = makeStyles({
  container:{
    width:'100%',
    height:'100vh'
  },
  boxcontainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    overflow:'scroll',
    height:'100vh',
    "@media only screen and (max-width: 420px)":{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      flexDirection:'column',
      //width:'100%',
      overflow:'scroll',
      height:'100vh',
    }
  },
  box:{
    width:'70%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    //backgroundColor:'red',
    marginTop:'50px',
    "@media only screen and (max-width: 420px)":{
      width:'75%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      flexDirection:'column',
      //backgroundColor:'red',
      marginTop:'300px',
    }
  },
  cardbox:{
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column'
  },
  name:{
    //margin:'5px'
  },
  btn:{
    backgroundColor:'#8dffcc',
    marginTop:'10px'
  },
  deletecontainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    //backgroundColor:'yellow'
  },
  deletebox:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    backgroundColor:'white',
    marginTop:'100px',
    borderRadius:'10px',
    padding:'30px'
  },
  tagsbox:{
    display:'flex',
    "@media only screen and (max-width: 420px)":{
      display:"flex",
      flexDirection:'column'
    }
  }
});

const u = makeStyles({
  style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: '#fff',
      border: "none",
      borderRadius: "3px",
      textAlign: 'center',
      color: "black",
      width: 600,
      p: 4,
      paddingLeft: "3rem",
      paddingRight: "3rem",
      paddingTop: '1rem',
      paddingBottom: '1rem',
      "@media only screen and (max-width: 726px)":{
          width: '90%',
          p: 3,
          height: 500,
          overflowY: 'scroll',
      }
  }
})

export default WatchNow;