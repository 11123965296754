import * as React from 'react';
import {Box, Typography} from '@mui/material';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { GET_SCHOLARSHIPS } from '../../api/scholarship';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { GET_QUERIES } from '../../api/query';
const Query = () => {
    const [scholarships , setQuery] = React.useState([]);
    React.useEffect(() => {
        axios
        .get(GET_QUERIES)
        .then(res => {
            const men = res.data.data.map((mentor , index) => {
                mentor.id = index+1; 
                return mentor;
            });
            
            setQuery(men);
        })
        .catch(err => {
            console.error(err);
        })
    },[]);
    const columns = [
        { field: '_id', headerName: 'ID', width: 90 },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 250,
        },
        {
          field: 'chat',
          headerName: 'Query',
          width: 300,
        },
        {
          field: 'whatsapp',
          headerName: 'Whatsapp Number',
          width: 160,
        },
        {
          field: 'ticketNumber',
          headerName: 'Ticket Number',
          width: 160,
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          width: 180,
          renderCell: (params) => new Date(params.value).toLocaleDateString(), // Formatting the date
        },
      ];
      

  return (
    <Box>
    <Typography variant='h6' sx={{marginBottom: 3}}>Queries</Typography>
    <Box sx={{ height: '100vh', width: '100%' }}>
      <DataGrid
        rows={scholarships}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
      />
    </Box>
    </Box>
  )
}

export default Query