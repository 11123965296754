import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from "@material-ui/core";
import axios from "axios";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TextField from '@mui/material/TextField';
import DatePickerValue from '../../PromoCode/AddDialog/DatePicker';
import { POST_EVENT, UPDATE_EVENT } from '../../../api/event';
const AddDialog = ({btn , handleAdded , pickedEventData }) => {
    const [open, setOpen] = useState(false); 
    const [title , setTitle] = useState(""||pickedEventData?.title);
    const [file, setFile] = useState(null); 
    const [date , setDate] = useState(''||pickedEventData?.date);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleCreate = () => {
        const data = new FormData();
        data.append('title' , title);
        data.append('file' , file);
        data.append('date' , date);
        axios
        .post(POST_EVENT , data)
        .then(res => {
            handleAdded(true , 'Added Event' , true);
        })
        .catch(err => {
            handleAdded(true , err.message , true);
        })

    }
    const handleEdit = () => {
        const data = new FormData();
        data.append('title' , title);
        data.append('file' , file);
        data.append('date' , date);
        axios
        .patch(UPDATE_EVENT+pickedEventData.id , data)
        .then(res => {
            handleAdded(true , 'Edited Event' , true);
        })
        .catch(err => {
            handleAdded(true , err.message , true);
        })
    }
    const handleSaveDate = (value) => {
        setDate(value);
      }
  
    return (
      <div>
        {btn === 'edit' ? (
            <Tooltip title="Edit Event">
            
            <IconButton
             onClick={
                        ()=> {
                            handleClickOpen();
                        }
                    }
            >
                <EditSharpIcon/>
            </IconButton>
            </Tooltip>
        ): (
            <Tooltip title="Add Event">
            <IconButton onClick={
                        ()=> {
                            handleClickOpen();
                        }
                    }>
                    <AddCircleOutlineRoundedIcon fontSize='large' color="grey"/>
             </IconButton>
             </Tooltip>
            
        )}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                    <DialogTitle  style={{textAlign:'center'}}>
                        {"Event"}
                    </DialogTitle>
                    <DialogContent>
                <div

                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "20px",
                    margin:'5%',
                  }}
                >
                <input
                    type="text"
                    placeholder="Enter Event Title"
                    className="subject"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    style={{
                        height:'50px',
                        marginBottom:'10px'
                    }}
                  />
                  <DatePickerValue style={{marginBottom:'10px'}} handleValidTill = {handleSaveDate} editvalue={date}/>

                  <Button
                    style={{marginTop:'20px'}}
                    variant="contained"
                    component="label"
                    >
                    Upload Event Poster
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        accept = '.png , .jpg , .jpeg'
                    />
                    </Button> 
                  </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={
                        ()=>{
                            if(btn==='edit'){
                                handleEdit();
                                handleClose();
                            }
                            else{
                                handleCreate(); handleClose();

                            }
                            
                        }
                        } autoFocus>
                        {btn==='edit'?'Save':'Add'}
                    </Button>
                    </DialogActions>
                  </Dialog>
                  

      </div>
    );
}

export default AddDialog