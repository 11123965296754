import * as React from 'react';
import "./admin_emails-Style.css";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Admin_Emails from './admin_emails';
import Admin_emails_template_index from './admin_emails_template_index';
import Performance from './emails_performance';
import EmailSentReference from '../EmailSentReference';

const EmailIndex = ({ isAuth, a_email}) => {
    const [url, setUrl] = React.useState("template");

    return (
        <div style={{
            display:'flex',
            
        }}>
            <div style={{
                width:'90%',
                height:'100vh'
            }}>
            {url==='emails' && <Admin_Emails isAuth={isAuth} a_email={a_email}/>}
            {url==='template' && <Admin_emails_template_index isAuth={isAuth} a_email={a_email}/>}
            {url==='performance' && <Performance isAuth={isAuth} a_email={a_email}/>}
            {url==='reference' && <EmailSentReference isAuth={isAuth} a_email={a_email}/>}

            </div>
            <div className="side-bar" style={{width:'10%',height:'100vh'}}>
            
                <div className="data">
                    <Link
                        onClick={() => setUrl('emails')}
                        style={{ textDecoration: "none" }}
                    >
                        {" "}
                        <p>Email IDs</p>{" "}
                    </Link>
                    <Link
                        onClick={() => setUrl('template')}
                        style={{ textDecoration: "none" }}
                    >
                        <p>Email Template</p>
                    </Link>
                    <Link
                        onClick={() => setUrl('performance')}
                        style={{ textDecoration: "none" }}
                    >
                        <p>Performance</p>
                    </Link>
                    <Link
                        onClick={() => setUrl('reference')}
                        style={{ textDecoration: "none" }}
                    >
                        <p>Reference</p>
                    </Link>
                </div>
            </div>
            
        </div>
    );
}

export default EmailIndex;
