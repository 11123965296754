import React from 'react';
import './RotatingBadge.css'; // Import the CSS file for styling
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
const RotatingBadge = ({sBadge}) => {
  return (
    <div className="rotating-badge-container">
      <div className="rotating-badge" style={{width: 300, height: 250,clipPath: 'polygon(0 0, 100% 0, 100% 66%, 50% 100%, 0 66%)', border: 'solid #000 5px'}}>
        <img src={sBadge.badgeUrl} width="100%"/>
        <p align="center" style={{fontWeight:'bold'}}>{sBadge.title}</p>
        <p align="center" style={{fontWeight:'bold'}}>{sBadge.message}</p>
      </div>
    </div>
  );
};

export default RotatingBadge;
