import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Typography , IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import { GET_CASE_SINGLE_STUDY } from '../../api/casestudy';


const QuestionsDialog = ({ row }) => {
  const [open, setOpen] = React.useState(false);
  const [questions,setQuestions] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleFetchQuestions = () => {
      setOpen(true);
      axios
      .get(GET_CASE_SINGLE_STUDY+row._id)
      .then(res => {
        setQuestions(res.data.data.qa);
      })
      .catch(err => {
        console.log(err);
      })
  }

  return (
    <div> 
    <Button
            variant="contained"
            onClick={handleFetchQuestions}
            size={"small"}
            color="primary">
        Show Questions
      </Button>
    <Dialog open={open} >
    <DialogTitle>Questions</DialogTitle>
    <DialogContent>
      {questions ? (
        <List>
          {questions.map((q,i) => (
            <>
            <ListItem key={q.q}>
              <ListItemText  >{(i+1) + `. ${q.q}`}</ListItemText>
            </ListItem>
            <ListItem key={q.a}>
            <ListItemText  >{ ` ANS: ${q.a}`}</ListItemText>
            </ListItem>
            </>
          ))}
        </List>
      ) : (
        <Typography>No Questions</Typography>
      )}
      <IconButton
      edge="end"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{
        position: 'absolute',
        top: 5,
        right: 5,
      }}
    >
      <CloseIcon />
    </IconButton>
    </DialogContent>
  </Dialog></div>
  )
}

export default QuestionsDialog