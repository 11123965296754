import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Box, Button, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import {GET_USER_DETAILS} from "../../api/userdetails";
import LoginTimesDialog from "./LoginTimesDialog";


const UserDetails = ({ isAuth, setIsAuth }) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
  
    useEffect(() => {
      axios
        .get(GET_USER_DETAILS, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setUsers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  
    const handleOpenDialog = (user) => {
      setSelectedUser(user);
    };
  
    const handleCloseDialog = () => {
      setSelectedUser(null);
    };
  
    const LoginTimeCellRenderer = (params) => {
      const loginDatesAndTimes = params.value;
  
      return (
        <Button onClick={() => handleOpenDialog(params.row)}>
          Show Login Times
        </Button>
      );
    };
  
    const columns = [
        { field: "_id", headerName: "ID", width: 100, cellClassName: "Cell-Col" },
        {
          field: "email",
          headerName: "Email",
          width: 160,
          cellClassName: "column",
          headerClassName: "column-header",
          cellAlign: "center",
        },
        {
          field: "name",
          headerName: "Username",
          width: 160,
          cellClassName: "column",
          headerClassName: "column-header",
        },
        {
          field: "signupDate",
          headerName: "Signup Date",
          width: 160,
          cellClassName: "column",
          headerClassName: "column-header",
        },
        {
          field: "signupTime",
          headerName: "Signup Time",
          width: 160,
          cellClassName: "column",
          headerClassName: "column-header",
        },
        {
          field: "loginDatesAndTimes",
          headerName: "Login Time",
          width: 200,
          cellClassName: "column",
          headerClassName: "column-header",
          renderCell: LoginTimeCellRenderer,
        },
        {
          field: "loginCount",
          headerName: "LC",
          width: 100,
          cellClassName: "column",
          headerClassName: "column-header",
        },
      ];
  
    if (isAuth) {
      return (
        <div>
          <Box display="flex" justifyContent="space-between" padding="10px">
            <Typography variant="h5">User Details</Typography>
          </Box>
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .column": {
                fontSize: "16px",
                justifyContent: "center",
                textAlign: "center",
              },
              "& .column-header": {
                color: "Black",
                fontSize: "24px",
              },
            }}
          >
            <DataGrid
              rows={users}
              className="table-box"
              getRowId={(row) => row._id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
            <LoginTimesDialog
              open={!!selectedUser}
              onClose={handleCloseDialog}
              loginDatesAndTimes={
                selectedUser ? selectedUser.loginDatesAndTimes : []
              }
            />
          </Box>
        </div>
      );
    } else {
      return (
        <div>
          <p>Please Login</p>
        </div>
      );
    }
  };
  
  export default UserDetails;
  