const { BASE_URL } = require("./client");
const Courseid = "63045711d1e7668480fdc568"

const GET_ALL_COURSES = BASE_URL + "/courses";
const CREATE_COURSE = BASE_URL + "/courses/create";
const UPDATE_COURSE = BASE_URL + "/courses/"+Courseid;
const DELETE_COURSE = BASE_URL + "/courses/";
const GET_COURSE = BASE_URL+"/courses/"+Courseid;

const GET_COURSES_TESTIMONIALS = BASE_URL + "/course/testimonial/";
const CREATE_COURSES_TESTIMONIALS = BASE_URL+"/course/testimonial/";
const UPDATE_COURSES_TESTIMONIALS = BASE_URL+"/course/testimonial/";
const DELETE_COURSES_TESTIMONIALS = BASE_URL+"/course/testimonial/";

export {
  GET_ALL_COURSES,
  CREATE_COURSE,
  UPDATE_COURSE,
  DELETE_COURSE,
  GET_COURSE,
  GET_COURSES_TESTIMONIALS,
  CREATE_COURSES_TESTIMONIALS,
  UPDATE_COURSES_TESTIMONIALS,
  DELETE_COURSES_TESTIMONIALS,
};