import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
const DOMAIN = process.env.REACT_APP_DOMAIN;

const YourFormComponent = () => {
  const [formData, setFormData] = useState({
    heading: "",
    yellowPart: "",
    subHeading: "",
    batchStarting: "",
    programSnapshot1: "",
    programSnapshot2: "",
    programSnapshot3: "",
    programSnapshot4: "",
    programSnapshot5: "",
    programSnapshot6: "",
    programSnapshot7: "",
    programSnapshot8: "",
    threePointersHeading1: "",
    threePointersHeading2: "",
    threePointersHeading3: "",
    threePointersContent1: "",
    threePointersContent2: "",
    threePointersContent3: "",
    PathwayWeek1Heading: "",
    PathwayWeek1Content: "",
    PathwayWeek2Heading: "",
    PathwayWeek2Content: "",
    PathwayWeek3Heading: "",
    PathwayWeek3Content: "",
    PathwayWeek4Heading: "",
    PathwayWeek4Content: "",
    toolsToLearn1: "",
    toolsToLearn2: "",
    toolsToLearn3: "",
    SuitableFor1: "",
    SuitableFor2: "",
    SuitableFor3: "",
    Duration: "",
    brochurePath: null,
    brochureSyllabus: null,
  });

  const handleFileChange = (event, field) => {
    setFormData({
      ...formData,
      [field]: event.target.files[0],
    });
  };

  const handleChange = (event, field) => {
    // If the field is one of the SuitableFor fields, convert the value to an array
    const newValue = field.startsWith('SuitableFor') ? [event.target.value] : event.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: newValue,
    }));
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await axios.post(`${DOMAIN}/api/uniqueURLupload`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5">Your Form</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {Object.keys(formData).map((key) => (
              <Grid key={key} item xs={12} sm={6}>
                {key.startsWith('brochure') ? (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, key)}
                  />
                ) : key.startsWith('SuitableFor') ? (
                  <FormControl fullWidth>
                    <TextField
                    label={key}
                    fullWidth
                    value={formData[key]}
                    onChange={(e) => handleChange(e, key)}
                  />
                  </FormControl>
                ) : (
                  <TextField
                    label={key}
                    fullWidth
                    value={formData[key]}
                    onChange={(e) => handleChange(e, key)}
                  />
                )}
              </Grid>
            ))}
          </Grid>

          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default YourFormComponent;