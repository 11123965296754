import {
  Box,
  Button,
  InputBase,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { FormGroup } from "@mui/material";
import MessagePopup from "../MessagePopup";

const DOMAIN = process.env.REACT_APP_DOMAIN;

const {
  UPDATE_B5_MAINPAGE,
  GET_B5_MAINPAGE,
  DELETE_B5_MAINPAGE,
  CREATE_B5_MAINPAGE,
  headingId,
} = require("../../api/MainPage/b5");

export default function B5Main() {
  const classes = Usestyles();
  const [openPopUp,setOpenPopUp] = useState(false);
  const [resp,setResp] = useState("");

  const [heading, setHeading] = useState("");

  const [B5Data, setB5Data] = useState([]);

  const [inputLink, setInputLink] = useState("");
  const [editLink, setEditLink] = useState("");
  
  const[picked , setPicked] = useState(false);

  const [PickedData, setPickedData] = useState([]);
  const [logo,setLogo] = useState(PickedData.logo);

  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // ALl Data request
  useEffect(() => {
    axios
      .get(GET_B5_MAINPAGE, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setB5Data(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Edit box submit
  const handleEditSubmit = (e) => {
    const data = new FormData();

    data.append("link",editLink)
    if (heading) data.append("heading", heading);
    if (logo) data.append("logo", logo);

    const ID = PickedData._id;

    axios
      .put(UPDATE_B5_MAINPAGE + ID, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Edited Successfully");
        setOpenPopUp(true);
        window.location.href = "/B5_Main";
      })
      .catch((err) => {
        setResp("Some error occured");
        setOpenPopUp(true);
      });
    setOpenEdit(false);
  };

  const validationForm = () => {
    if (inputLink.length < 1) return false;
    else return true;
  };

  // Create submit
  const handleCreateSubmit = (e) => {
    if (validationForm()) {
      const data = new FormData();
      data.append("link",inputLink)
      if (heading) data.append("heading", heading);
      if (logo) data.append("logo", logo);
  
      axios
        .post(CREATE_B5_MAINPAGE, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setResp("Created Successfully");
          setOpenPopUp(true);
          window.location.href = "/B5_Main";
        })
        .catch((err) => {
          setResp("Some error occured");
          setOpenPopUp(true);
        });
      setOpenCreate(false);
    } else {
      setResp("Kindly add all the details");
      setOpenPopUp(true);
    }
  };

  // Delete submit function
  const deleteSubmit = () => {
    const ID = PickedData._id;
    axios
      .delete(DELETE_B5_MAINPAGE + ID, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setResp("Deleted Successfully");
        setOpenPopUp(true);
        window.location.href = "/B5_Main";
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
    setOpenDelete(false);
  };

  // Edit Pop Up
  const editbox = (
    <Box className={classes.style}>
      <Typography
        variant="h4"
        fontWeight="6400"
        style={{textAlign:"center",textTransform:"uppercase"}}
      >
        Update
      </Typography>
      <FormGroup method="POST">
        {headingId === PickedData._id && (
          <InputBase
            className={classes.inputsStyle}
            defaultValue={PickedData.heading}
            placeholder="Heading"
            onChange={(e) => setHeading(e.target.value)}
          />
        )}
        <InputBase
          className={classes.inputsStyle}
          defaultValue={PickedData.link}
          label="Name"
          onChange={(e) => setEditLink(e.target.value)}
        />
        {
            picked &&
            <img 
                src={`${DOMAIN}/mainpage/${PickedData.logo?.name}`} alt=""
                style={{width: '100px' , height: '100px'}}
            ></img>
        }
        <Button
          variant="contained"
          component="label"
          style={{ marginTop: "10px" }}
        >
          Change logo
          <input
            type="file"
            hidden
            onChange={(e) => setLogo(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleEditSubmit}
        style={{
          background: "#81fccf",
          fontSize: "18px",
          marginTop: "25px",
          padding: "10px",
        }}
      >
        Update
      </Button>
    </Box>
  );

  // Create PopUp
  const createBox = (
    <Box className={classes.style}>
      <Typography variant="h4">Create</Typography>
      <FormGroup method="POST">
        <InputBase
          placeholder="Name"
          value={inputLink}
          className={classes.inputsStyle}
          onChange={(e) => setInputLink(e.target.value)}
        />
        <Button variant="contained" component="label">
          Upload Poster
          <input
            type="file"
            hidden
            onChange={(e) => setLogo(e.target.files[0])}
          />
        </Button>
      </FormGroup>
      <Button
        onClick={handleCreateSubmit}
        style={{
          background: "#81fccf",
          marginTop: "15px",
          fontSize: "18px",
          padding: "10px",
        }}
      >
        Create
      </Button>
    </Box>
  );

  // Delete PopUp
  const deleteBox = (
    <Box className={classes.style}>
      {headingId === PickedData._id ? (
        <Typography variant="h5" style={{marginBottom:"5px"}}>
          This Data is linked to heading so You can't delete it!
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" style={{marginBottom:"5px"}}>
            Are you sure want to delete ?
          </Typography>
          <Box>
            <Button
              onClick={deleteSubmit}
              style={{ background: "black", color: "white", margin: "3px" }}
            >
              Yes
            </Button>
            <Button
              onClick={() => setOpenDelete(false)}
              style={{ background: "black", color: "white", margin: "3px" }}
            >
              No
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );

  // Columns in data grid
  const columns = [
    { field: "_id", headerName: "ID", width: 150, cellClassName: "Cell-Col" },
    {
      field: "link",
      headerName: "Link",
      width: 150,
      cellClassName: "column",
      headerClassName: "column-header",
      cellAlign: "center",
    },
    {
      field: "Edit",
      headrName: "Edit",
      headerClassName: "column-header",
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedData(params.row);
          setOpenEdit(true);
          setEditLink(params.row.link);
          setPicked(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: "16px", background: "#eb8546" }}
            onClick={onClick}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headrName: "Delete",
      headerClassName: "column-header",
      // headerAlign: 'center',
      cellClassName: "column",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setPickedData(params.row);
          setOpenDelete(true);
          setPicked(true);
        };
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, padding: "5px", background: "#ed4253" }}
            onClick={onClick}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  // UI
  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between" padding="10px">
        <Typography variant="h5">B5 Page </Typography>
        <Button
          onClick={() => setOpenCreate(true)}
          size="medium"
          variant="contained"
          color="primary"
        >
          create
        </Button>
      </Box>
      <Box
        sx={{
          height: 800,
          width: "100%",
          "& .column": {
            fontSize: "16px",
            justifyCOntent: "center",
            textAlign: "center",
          },
          "& .column-header": {
            color: "purple",
            fontSize: "20px",
          },
        }}
      >
        <DataGrid
          rows={B5Data}
          className="table-box"
          getRowId={(row) => row._id}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
      <MessagePopup open={openPopUp} message={resp} handleAlertClose={() => setOpenPopUp(!openPopUp)}/>
      <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
        {createBox}
      </Modal>

      <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
        {editbox}
      </Modal>
      <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
        {deleteBox}
      </Modal>
    </div>
  );
}

const Usestyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
  },
  inputsStyle: {
    margin: "10px 0px 10px 0px",
    backgroundColor: "whitesmoke",
    color: "black",
    padding: "10px",
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "3px",
    textAlign: "center",
    color: "black",
    width: 400,
    p: 4,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    // height: 400,
    // overflowY: 'scroll',
    "@media only screen and (max-width: 726px)": {
      width: "90%",
      p: 3,
    },
  },
});
