const { BASE_URL } = require("./client");


const GET_TEMPLATES = BASE_URL + '/api/admin/templates/get';
const CREATE_TEMPLATE = BASE_URL + '/api/admin/templates/create';
const EDIT_TEMPLATE = BASE_URL + '/api/admin/templates/edit/';
const DELETE_TEMPLATE = BASE_URL + '/api/admin/templates/delete/'

module.exports = {
    GET_TEMPLATES,
    CREATE_TEMPLATE,
    EDIT_TEMPLATE,
    DELETE_TEMPLATE
}