import * as React from 'react';
import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { DataGrid , GridActionsCellItem } from '@mui/x-data-grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GET_AUDIENCE, UPLOAD_AUDIENCE } from '../../../api/audience';
import { DELETE_GROUP, GET_GROUP } from '../../../api/group';

import * as XLSX from 'xlsx';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Tooltip } from '@mui/material';
import GroupView from './GroupView';
import AudienceTable from './AudienceTable';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Audience({emailList , setEmailList , setOpenPopUp , setResp}) {
  const [open, setOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [file , setFile] = React.useState(null);
  const [rowData , setRowData] = React.useState([]);
  const [added , setAdded] = React.useState(false);
  const [deleteId , setDeleteId] = React.useState(null);

  React.useEffect(() => {
    
    axios
    .get(GET_GROUP)
    .then(res => {
        const dataWithIds = res.data.data.map((row, index) => ({
            ...row,
            id: index,
          }));
    
        setRowData(dataWithIds);
        setAdded(false);
    })
    .catch(err => {
        console.log(err);
    })
    
  }, [added])
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: '_id', headerName: 'GID', width: 250 },
    {
      field: 'emails',
      headerName: 'No. Of Emails',
      width: 250,
      valueGetter: (params) => params.row.emails.length,
    },
    {
        field: 'name',
        headerName: 'Group Name',
        width: 250,
      },
      {
        field: 'emailsGet',
        headerName: 'Emails',
        width: 150,
        renderCell: (cellValues) => {
            // console.log(cellValues)
            return <AudienceTable emails={cellValues.row.emails} id={cellValues.row._id} setAdded={setAdded}/>
        }
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={()=> {
                handleDelete(params.row._id);
                // setOpenDelete(true);
              }}
              color="inherit"
            />,
          ];
        },
      },
  ];
  const handleUpload = () =>{

    if(file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
        const reader = new FileReader();
      
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
          const csvBlob = new Blob([csvData], { type: 'text/csv' });
          const formData = new FormData();
          formData.append('file', csvBlob, 'converted.csv');
          axios
          .post(UPLOAD_AUDIENCE , formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
            }
            })
          .then(res => {
            console.log(res);
            setAdded(true)
            
        })
          .catch(err => console.log(err));
        }
        reader.readAsArrayBuffer(file)
    }
    else if(file.type==="text/csv"){
        const formData = new FormData();
        formData.append('file' , file);
        axios
        .post(UPLOAD_AUDIENCE , formData)
        .then(res => setAdded(true))
        .catch(err => console.log(err));
    }
    
  }
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }
  const handleDelete = (id) => {
    axios.delete(DELETE_GROUP+id)
    .then(res => {
        console.log(res)
        setAdded(true);
    })
    .catch(err => {console.log(err)});
  }
  const handleRowSelection = (selection) => {
    setSelectedRows(selection);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
  };
  const handleSave = () => {
    const selectedGroups = selectedRows.map(index => rowData[index]);
    const selectedEmails = selectedGroups.flatMap(group => group.emails);
    setEmailList([...emailList, ...selectedEmails]);
    setOpenPopUp(true);
    setResp("Selected");
    handleClose();
  };
  
  return (
    <div>
      <Button 
        variant="contained"
        component="span"
        style={{ width: "100%", borderRadius: "10px" }} onClick={handleClickOpen}
      >
        Select Group
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Group
            </Typography>
            <GroupView setAdded={setAdded}/>
            <Button autoFocus color="inherit" onClick={handleSave}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={rowData}
        columns={columns}
        onSelectionModelChange={(newSelectionModel) => {
            handleRowSelection(newSelectionModel);
          }}
          selectionModel={selectedRows}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
      </Dialog>
    </div>
  );
}
