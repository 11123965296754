const { BASE_URL } = require("./client");

const GET_EVENTS = BASE_URL +'/api/event/get';
const GET_EVENT = BASE_URL +'/api/event/get/';
const POST_EVENT = BASE_URL +'/api/event/post';
const UPDATE_EVENT = BASE_URL +'/api/event/update/';
const DELETE_EVENT = BASE_URL +'/api/event/delete/';
const GET_REGISTERED_EVENTS = BASE_URL + '/api/event/register/get';
const GET_EVENT_CERTIFICATES = BASE_URL + '/api/event/certificate/get';
const GET_SENT_EVENT_CERTIFICATES = BASE_URL + '/api/event/certificate/sent';
const GET_REJECTED_EVENT_CERTIFICATES = BASE_URL + '/api/event/certificate/reject';
const ACCEPT_CERTIFICATE = BASE_URL + '/api/event/certificate/accept/'; 
const REJECT_CERTIFICATE = BASE_URL + '/api/event/certificate/reject/'; 



export {
    GET_EVENT,
    POST_EVENT,
    GET_EVENTS,
    UPDATE_EVENT,
    DELETE_EVENT,
    GET_REGISTERED_EVENTS,
    GET_EVENT_CERTIFICATES,
    ACCEPT_CERTIFICATE,
    REJECT_CERTIFICATE,
    GET_SENT_EVENT_CERTIFICATES,
    GET_REJECTED_EVENT_CERTIFICATES
};