const { BASE_URL } = require("./client");

const GET_ALL_EMAIL_EVENTS = BASE_URL + "/api/admin/email";
const SAVE_NEW_EMAIL_EVENT = BASE_URL + "/api/admin/email/add";
const UPDATE_EMAIL_EVENT = BASE_URL + "/api/admin/email/";
const DELETE_EMAIL_EVENT = BASE_URL + "/api/admin/email/";
const GET_REFERENCE_EMAIL_EVENT = BASE_URL + "/api/email/get/reference";

export {
  GET_ALL_EMAIL_EVENTS,
  SAVE_NEW_EMAIL_EVENT,
  UPDATE_EMAIL_EVENT,
  DELETE_EMAIL_EVENT,
  GET_REFERENCE_EMAIL_EVENT
};
